import { useLanguage } from '@infominds/react-native-components'

import api from '../apis/apiCalls'
import { ArticleDTO, SerialNumber, StockTemplate } from '../apis/apiTypes'
import { useUserSettings } from '../contexts/UserSettingsContext'
import { Position } from '../types'
import { Error_Utils } from '../utils/ErrorUtils'
import useAlert from './useAlert'

export default function useArticleTransfer(stockTemplate: StockTemplate | undefined) {
  const { i18n } = useLanguage()

  const userSettings = useUserSettings()
  const alert = useAlert()

  async function transferArticle(
    article: ArticleDTO,
    quantity: number,
    unitId: string,
    source: Position,
    target: Position | undefined,
    serialNumber?: SerialNumber,
    stockId?: string
  ) {
    if (!userSettings?.employeeId || !article || !userSettings?.employeeId) return

    try {
      const result = await api.postStockAccounting({
        articleId: article.id,
        collaboratorId: userSettings?.employeeId,
        stockTempleteId: stockTemplate?.id ?? '',
        depositId: source.deposit?.id ?? source.stockPosition?.deposit?.id ?? '',
        stockpositionId: source.stockPosition?.id ?? '',
        quantity: quantity ?? 0,
        unitId: unitId,
        documentDate: new Date(),
        depositIdTarget: target?.deposit?.id ?? target?.stockPosition?.deposit?.id ?? '',
        stockpositionIdTarget: target?.stockPosition?.id,
        serialnumberId: serialNumber?.id,
        stockId: stockId,
      })
      if (result.warning) {
        alert.info(result.warning)
      }
      return result
    } catch (exception) {
      alert.error(i18n.t('TransferFailed'), Error_Utils.extractErrorMessageFromException(exception))
    }

    return
  }

  return { transferArticle }
}
