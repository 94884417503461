import { useApi } from '@infominds/react-api'
import { useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'

import api from '../../../apis/apiCalls'
import { Collo, PackingListColloState } from '../../../apis/apiTypes'
import ColloCard from '../../../cards/Collo/ColloCard'
import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { useRuntimeStorage } from '../../../contexts/RuntimeStorageContext'
import { PackingListMovementHandler } from '../../../hooks/specific/usePackingListMovementHandler'
import useAlert from '../../../hooks/useAlert'
import useModalController from '../../../hooks/useModalController'
import SelectionModalFullScreen from '../../../modals/SelectionModalFullScreen'
import { runtimeStorageSelectedColloKey } from '../../../screens/Packinglist/PackingListScreen'
import colloUtils from '../../../utils/colloUtils'
import packingListUtils from '../../../utils/packingListUtils'
import IMIcon from '../../IMIcon'
import { IMTextWithIcon } from '../../IMTextWithIcon'
import CardBasic from '../../old/CardBasic'

export default function PackingListColloSelector(props: { style?: StyleProp<ViewStyle>; handler: PackingListMovementHandler }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const alert = useAlert()
  const { i18n } = useLanguage()
  const colloStorage = useRuntimeStorage(runtimeStorageSelectedColloKey)
  const [availableCollos, loadAvailableCollos, _loadingAvailableCollos, setAvailableCollos] = useApi(api.getCollo, [])
  const selectedCollo = colloStorage.getValue<string>()

  const selectorModal = useModalController<Collo[]>()

  useEffect(() => {
    // load available open collos from packingList and associated packingLists (same customer & deliveryAddress)
    const defaultCollos = packingListUtils.getCollosFromPackingListArticles(props.handler.packingListHandler.movements, true).sort(colloUtils.sort)
    const packingList = props.handler.packingListHandler.packingLists?.find(p => !!p)
    if (props.handler.packingListHandler.isPreConsignment || !packingList || !packingList.customerId) {
      setAvailableCollos(defaultCollos)
      return
    }

    const hasDeliveryAddress = !!packingList.deliveryaddressId

    loadAvailableCollos(
      {
        customerId: packingList.customerId,
        deliveryAddressId: hasDeliveryAddress ? packingList.deliveryaddressId : undefined,
        states: [PackingListColloState.open],
        onlyEmptyDeliveryAddress: hasDeliveryAddress ? undefined : true,
      },
      collos => Utils.keepUniques(collos, collo => collo.number).sort(colloUtils.sort)
    )
      .then(result => {
        if (!result.length) setAvailableCollos(defaultCollos)
      })
      .catch(error => {
        console.error(error)
        setAvailableCollos(defaultCollos)
      })
  }, [])

  function handleOnPress() {
    if (!availableCollos?.length) return showNoCollosInfo()
    selectorModal.show(availableCollos)
  }

  function showNoCollosInfo() {
    alert.info(i18n.t('PackingListContainsNoCollos'))
  }

  function handleSelection(collo: Collo | undefined) {
    selectorModal.close()
    if (!collo) return
    colloStorage.setValue(collo.number)
  }

  function handleNewColloSelected() {
    selectorModal.close()
    colloStorage.setValue(undefined)
  }

  return (
    <TouchableOpacity style={[{ flexDirection: 'row' }, props.style]} onPress={handleOnPress}>
      <CardBasic
        style={{
          flex: 1,
          flexDirection: 'row',
          margin: 0,
          marginHorizontal: 0,
          paddingHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <IMTextWithIcon
          viewStyle={{ flex: 1 }}
          style={{ textAlign: 'left', flex: 1 }}
          numberOfLines={1}
          primary={!!selectedCollo}
          icon={'box-taped'}
          text={selectedCollo || i18n.t('SELECT_PLACEHOLDER')}
        />
        <IMIcon color={theme.textDetail} style={{ justifyContent: 'center' }} icon={'angle-down'} size={15} />
      </CardBasic>
      <SelectionModalFullScreen
        show={selectorModal.isShown}
        title={i18n.t('SelectCollo')}
        items={selectorModal.data ?? []}
        close={handleSelection}
        renderItem={item => <ColloCard collo={item} />}
        onButtonNewPressed={handleNewColloSelected}
      />
    </TouchableOpacity>
  )
}
