import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, TouchableOpacity, TouchableOpacityProps, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import IMIcon from './IMIcon'
import { IMIconName } from './IMIconNames'

export function IMIconButton(
  props: { icon: IMIconName; iconColor?: string; iconSize?: number; iconStyle?: StyleProp<ViewStyle> } & TouchableOpacityProps
) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { icon, iconColor, iconStyle, ...touchableProps } = props

  return (
    <TouchableOpacity {...touchableProps}>
      <IMIcon
        size={props.iconSize ?? STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
        icon={icon ?? 'notDef'}
        style={iconStyle}
        color={iconColor ?? theme.textDetail}
      />
    </TouchableOpacity>
  )
}
