import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useMemo } from 'react'

import api from '../../apis/apiCalls'
import { ProductionMaster } from '../../apis/apiTypes'
import ProductionMasterCard from '../../cards/Production/ProductionMasterCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import useInputHistory from '../../hooks/useInputHistory'
import useModalController from '../../hooks/useModalController'
import ProductionAllocationListSelectionFilterModal from '../../modals/ProductionAllocationListSelectionFilterModal'
import { SelectionViewProps } from '../../types'

export default function ProductionAllocationListSelectionView(
  props: {
    allowPreloading?: boolean
    preLoadedData?: ProductionMaster[]
    filterResultByOrder?: boolean
    onMultiSelect?: (productionLists: ProductionMaster[]) => void
  } & SelectionViewProps<ProductionMaster>
) {
  const { i18n } = useLanguage()

  const { allowPreloading, preLoadedData, filterResultByOrder, ...inputProps } = props
  const { history, add: addHistoryItem } = useInputHistory<ProductionMaster>('ProductionAllocationList')
  const lastUsed = useMemo(() => history.map(h => h.item), [history])

  const productionListSelectionModal = useModalController<ProductionMaster[]>()

  function load() {
    if (preLoadedData) return Promise.resolve<ProductionMaster[]>(props.preLoadedData ?? [])
    return new Promise<ProductionMaster[]>((resolve, reject) => {
      api
        .getProductionsMaster()
        .then(result => {
          resolve(result)
        })
        .catch(reject)
    })
  }

  function handleProductionMasterListSelection(selectedItems: ProductionMaster[]) {
    if (!selectedItems.length) return
    if (props.onMultiSelect) {
      props.onMultiSelect(selectedItems)
      return
    }
    addHistoryItem(selectedItems[0].id, selectedItems[0])
    props.onSelected(selectedItems[0])
  }

  function handleShowListResult(result: ProductionMaster[]) {
    if (!result.length) return
    productionListSelectionModal.show(result)
  }

  const RenderItem = useCallback((item: ProductionMaster) => <ProductionMasterCard production={item} />, [])

  return (
    <>
      <MWS_Input
        type={'ProductionAllocationList'}
        title={i18n.t('SelectProductionMaster')}
        load={load}
        clearResultsOnScreenFocus
        itemRenderer={RenderItem}
        handleShowListResult={filterResultByOrder ? handleShowListResult : undefined}
        preload={
          allowPreloading
            ? () =>
                new Promise<ProductionMaster[]>(resolve =>
                  setTimeout(() => {
                    resolve(api.getProductionsMaster())
                  }, 5000)
                )
            : undefined
        }
        {...inputProps}
      />
      <ProductionAllocationListSelectionFilterModal
        controller={productionListSelectionModal}
        multiSelection={!!props.onMultiSelect}
        onSelected={handleProductionMasterListSelection}
        lastUsed={lastUsed}
      />
    </>
  )
}
