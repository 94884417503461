import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { View } from 'react-native'

import IMText from '../../components/IMText'
import { Position } from '../../types'
import { depositUtils } from '../../utils/depositUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function MultiTransferPositionCard(props: { positions: Position[] | undefined }) {
  const { i18n } = useLanguage()

  const texts: string[] = useMemo(getTexts, [props.positions])

  function getTexts() {
    const deposits = Utils.keepUniques(props.positions ?? [], item => item.deposit?.id)
    if (!deposits || deposits.length === 0) return []
    if (deposits.length > 1) return [i18n.t('MultipleDeposit'), Utils.join(deposits, item => item.deposit?.code?.trim() ?? '')]
    const stockPositions = Utils.keepUniques(props.positions ?? [], item => item.stockPosition?.id)
    return [depositUtils.getDepositTitle(deposits[0].deposit), Utils.join(stockPositions, item => item.stockPosition?.code?.trim() ?? '')]
  }

  return (
    <MWS_BaseCard icon={'warehouse-full'}>
      <View style={{ flex: 1 }}>
        <IMText style={{ flex: 1 }}>{texts[0]}</IMText>
        {texts.length > 1 && <IMText style={{ flex: 1 }}>{texts[1]}</IMText>}
      </View>
    </MWS_BaseCard>
  )
}
