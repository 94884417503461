import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import api from '../apis/apiCalls'
import { ArticleDTO, StockPosition } from '../apis/apiTypes'
import ArticleCard from '../cards/Article/ArticleCard'
import PackingListArticleCard from '../cards/Packinglist/PackingListArticleCard'
import { Article } from '../classes/Article'
import FullScreenModal from '../components/FullScreenModal'
import IMIcon from '../components/IMIcon'
import StockPositionSelector from '../components/MWS/StockPosition/StockPositionSelector'
import SubmitButton from '../components/SubmitButton'
import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { PackingListMovementHandler } from '../hooks/specific/usePackingListMovementHandler'
import useAlert from '../hooks/useAlert'
import { ModalController } from '../hooks/useModalController'
import { Error_Utils } from '../utils/ErrorUtils'
import stockPositionUtils from '../utils/stockPositionUtils'
import ItemSelectionView from '../views/InputViews/ItemSelectionView'

export default function PackingListReplaceMovementModal(props: {
  controller: ModalController<void>
  handler: PackingListMovementHandler
  onDone: () => void
  onNotDeliverable: () => void
}) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const alert = useAlert()
  const loader = useLoadingIndicator()
  const handler = props.handler
  const [selectedStockPosition, setSelectedStockPosition] = useState<StockPosition | undefined>()
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>()

  const anyPackingList = handler.packingListHandler.packingLists?.length ? handler.packingListHandler.packingLists[0] : undefined

  useEffect(() => {
    if (!props.controller.isShown) return
    setSelectedStockPosition(undefined)
    setSelectedArticle(undefined)
  }, [props.controller.isShown])

  function handleArticleSelection(article: ArticleDTO) {
    loader.setLoading(true)
    const tmpArt = new Article(article, { useSalesUnit: !!handler.packingListHandler.userSettings?.isVpk1PickingActive })
    tmpArt
      .loadAvailability(false, handler.anyMovement?.deposit?.id)
      .catch(console.error)
      .finally(() => {
        setSelectedArticle(tmpArt)
        setSelectedStockPosition(
          stockPositionUtils.checkIfOnlyOneStockPositionIsAvailable(
            tmpArt.getStockPositions(true),
            handler.anyMovement?.deposit?.id,
            handler.anyMovement?.stockposition?.id
          )?.stockposition
        )
        loader.setLoading(false)
      })
  }

  async function handleReplacing() {
    if (!anyPackingList || !handler.anyMovement || !selectedArticle) {
      return
    }
    try {
      loader.setLoading(true)
      await api.patchPackingListMovement({
        packinglistmovementId: handler.anyMovement?.id,
        articleId: selectedArticle?.info.id,
        stockpositionId: selectedStockPosition?.id,
      })
      props.onDone()
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToChangePackingListMovement'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
    }
  }

  async function handleNotDeliverable() {
    if (!anyPackingList || !handler.anyMovement) return

    try {
      loader.setLoading(true)
      await api.patchPackingListMovement({
        packinglistmovementId: handler.anyMovement?.id,
        articleId: handler.anyMovement?.article.id,
        state: 4,
      })
      props.onNotDeliverable()
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToChangePackingListMovement'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
      }}
      title={i18n.t('PackingListChangeMovement')}>
      <PackingListArticleCard
        item={handler.packingListArticle}
        showAvailability
        showPackingLists={handler.packingListHandler.isPreConsignment || handler.packingListHandler.isProductionConsignment}
        style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
      />
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', paddingVertical: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
          <IMIcon icon={'arrow-down'} size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} color={theme.text} />
        </View>
        {!selectedArticle && <ItemSelectionView showResultInModal onSelected={item => handleArticleSelection(item.article)} />}
        {!!selectedArticle && (
          <>
            <ArticleCard article={selectedArticle.info} onDelete={() => setSelectedArticle(undefined)} />
            {!!anyPackingList?.deposit?.isStockpositionActive && (
              <StockPositionSelector
                deposit={anyPackingList?.deposit}
                stockPosition={selectedStockPosition}
                onSelected={setSelectedStockPosition}
                availability={selectedArticle.getStockPositions()}
              />
            )}
          </>
        )}
      </View>
      <SubmitButton hide={props.handler.anyMovement?.state === 4} title={i18n.t('NotDeliverable')} onPress={handleNotDeliverable} hideFromKeyBoard />
      <SubmitButton disabled={!selectedArticle} title={i18n.t('Confirm')} onPress={handleReplacing} hideFromKeyBoard />
    </FullScreenModal>
  )
}
