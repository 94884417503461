/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import api from '../apis/apiCalls'
import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../contexts/UserSettingsContext'
import useAlert from '../hooks/useAlert'
import IMText from './IMText'
import Switch from './Switch'

type StockPositionCheckSwitchProps = {
  stockpositionId: string
}

export default function StockPositionCheckSwitch({ stockpositionId }: StockPositionCheckSwitchProps) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const userSettings = useUserSettings()
  const loader = useLoadingIndicator()

  const [value, setValue] = useState(false)

  useEffect(() => {
    getAdditionalFields()
  }, [])

  function checkAllParameters() {
    if (!stockpositionId) {
      return false
    }
    if (!userSettings) {
      return false
    }
    if (!userSettings.additionalFieldDefId_CheckStockposition) {
      return false
    }

    return true
  }

  function getAdditionalFields() {
    if (!checkAllParameters) {
      return
    }
    loader.setLoading(true)
    api
      .getAdditionalFields({
        defId: userSettings?.additionalFieldDefId_CheckStockposition,
        fkIds: [stockpositionId],
      })
      .then(result => {
        if (result && result.length > 0) {
          setValue(result[0].logicValue != null ? result[0].logicValue : false)
        }
      })
      .catch(reason => {
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  function postAdditionalField() {
    if (!checkAllParameters) {
      return
    }
    loader.setLoading(true)
    api
      .postAdditionalField({
        fkId: stockpositionId,
        definitionId: userSettings?.additionalFieldDefId_CheckStockposition,
        value: !value ? 'true' : 'false',
      })
      .catch(reason => {
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => {
        loader.setLoading(false)
        getAdditionalFields()
      })
  }

  return (
    <View style={styles.main}>
      <IMText style={styles.text}>{i18n.t('ControllingStockPosition')}</IMText>
      <Switch onColor={Colors.tint} value={value} onValueChange={postAdditionalField} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  text: {
    flex: 1,
    textAlign: 'left',
    marginLeft: 10,
  },
})
