import { Customer } from '../apis/apiTypes'

export const customerUtils = {
  sort(a: Customer | undefined, b: Customer | undefined) {
    return (a?.number ?? 0) - (b?.number ?? 0)
  },
  getCustomerTitle(customer: Customer, includeNumber?: boolean) {
    if (!customer) return ''
    return (!!includeNumber && !!customer.number ? `${customer.number} - ` : '') + customer.description
  },
}
