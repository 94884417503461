import { useIsFocused } from '@react-navigation/native'
import { useEffect } from 'react'

/**
 * Hook calls callback function every time the screen gets focused (f.ex after back-navigation)
 */
export default function useOnScreenFocus(callback: () => void) {
  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) callback()
  }, [isFocused])
}
