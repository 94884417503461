import React from 'react'

import IMText from '../components/IMText'
import { Colors } from '../constants/Colors'
import MWS_BaseCard, { MWSBaseCardProps } from './MWS_BaseCard'

type KeyCodeCardProps = { keyCode: number; lastUsed?: boolean } & MWSBaseCardProps

export default function KeyCodeCard({ keyCode, lastUsed, icon, ...cardProps }: KeyCodeCardProps) {
  return (
    <MWS_BaseCard icon={'keyCode' || icon} cardStyle={!!lastUsed && { borderColor: Colors.tint, borderWidth: 1 }} {...cardProps}>
      <IMText>{keyCode.toString()}</IMText>
    </MWS_BaseCard>
  )
}
