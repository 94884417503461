import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import KanbanBoxCard from '../../cards/Kanban/KanbanBoxCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import CompletedTag from '../../components/CompletedTag'
import ElusiveButton from '../../components/ElusiveButton'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import SubmitButton from '../../components/SubmitButton'
import { IMLayout } from '../../constants/Styles'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticleTransfer from '../../hooks/useArticleTransfer'
import useJobExecParameter from '../../hooks/useJobExecParameter'
import useRoute from '../../hooks/useRoute'
import useToastMessage from '../../hooks/useToastMessage'
import { KanbanBox } from '../../types'
import { KanbanUtils } from '../../utils/KanbanUtils'
import KanbanBoxSelectionView from '../../views/InputViews/KanbanBoxSelectionView'
import KanbanInputView from '../../views/Kanban/KanbanInputView'

export default function KanbanWorkListScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const loader = useLoadingIndicator()
  const toast = useToastMessage()
  const navigation = useNavigation()
  const routeParams = useRoute<'KanbanWorkList'>()
  const { transferArticle } = useArticleTransfer(routeParams?.stockTemplate)
  const { printLabel } = useJobExecParameter()
  const [boxes, setBoxes] = useState<KanbanBox[]>(routeParams?.boxes ?? [])
  const [currentBoxId, setCurrentBoxId] = useState(boxes[0]?.id)
  const [articleConfirmed, setArticleConfirmed] = useState(false)
  const gotoNext = useRef(false)
  const currentBox = useMemo(() => boxes.find(b => b.id === currentBoxId), [boxes, currentBoxId])
  const currentBoxIndex = useMemo(() => boxes.findIndex(box => box.id === currentBoxId), [currentBoxId])
  const kanbanStockPosition = useMemo(() => routeParams?.kanbanStockPosition, [])
  const showCompleteButton = useMemo(
    () =>
      !currentBox?.transferResult &&
      currentBox?.stockPositionAvailability?.stockposition &&
      (!currentBox.article.isSerialNumberActive || !!currentBox?.serialNumberAvailability) &&
      currentBox.quantity &&
      currentBox.quantity > 0,
    [currentBox]
  )
  const checkArticleRequired = !articleConfirmed && !currentBox?.transferResult

  function updateBox(boxToUpdate: KanbanBox) {
    setBoxes(prev => [...prev.map(item => (item.id !== boxToUpdate.id ? item : boxToUpdate))])
  }

  function prevBox() {
    setArticleConfirmed(false)
    const currentIndex = boxes.findIndex(b => b.id === currentBoxId)
    if (currentIndex > 0) setCurrentBoxId(boxes[currentIndex - 1].id)
    else setCurrentBoxId(boxes[boxes.length - 1].id)
  }

  function nextBox() {
    setArticleConfirmed(false)
    const currentIndex = boxes.findIndex(b => b.id === currentBoxId)
    if (currentIndex + 1 < boxes.length) {
      setCurrentBoxId(boxes[currentIndex + 1].id)
      return
    }
    const anyFinished = boxes.find(box => box.transferResult)
    if (!anyFinished) {
      setCurrentBoxId(boxes[0].id)
      return
    }
    const anyUnfinished = boxes.findIndex(box => !box.transferResult)
    alert
      .yesNo(anyUnfinished > -1 ? i18n.t('KANBAN_END_OF_LIST_ALERT_UNFINISHED') : i18n.t('KANBAN_END_OF_LIST_ALERT'))
      .then(result => {
        if (!result) {
          if (anyUnfinished > -1) {
            setCurrentBoxId(boxes[anyUnfinished].id)
          } else {
            setCurrentBoxId(boxes[0].id)
          }
          return
        }
        handleCompletion()
      })
      .catch(console.error)
  }

  async function handleTransfer(quantity?: number) {
    if (!currentBox?.article.info || (!quantity && !currentBox?.quantity)) return
    if (!quantity) quantity = currentBox.quantity ?? 0
    try {
      loader.setLoading(true)
      const result = await transferArticle(
        currentBox?.article.info,
        quantity,
        currentBox?.article.getUsedUnit().id,
        { stockPosition: currentBox?.stockPositionAvailability?.stockposition },
        { stockPosition: kanbanStockPosition },
        currentBox?.serialNumberAvailability?.serialnumber,
        boxes.find(b => b.transferResult?.stockId)?.transferResult?.stockId
      )

      printLabel(currentBox?.article.info.id, quantity, currentBox?.serialNumberAvailability?.serialnumber?.id).catch(console.error)

      if (result) {
        updateBox({ ...currentBox, transferResult: result, quantity })
        gotoNext.current = true
      }
    } catch (exception) {}
    loader.setLoading(false)
  }

  useEffect(() => {
    if (!gotoNext.current) return
    gotoNext.current = false
    nextBox()
  }, [boxes])

  function handleCompletion() {
    loader.setLoading(true)
    Promise.all(
      boxes
        .filter(b => !!b.transferResult)
        .map(box =>
          api.patchStockAccounting({
            stockId: box.transferResult?.stockId ?? '',
            stockaccountingId: box.transferResult?.stockaccountingId ?? '',
            stockpositionIdOld: kanbanStockPosition?.id ?? '',
            stockpositionIdNew: box.stockPositionProduction.id,
          })
        )
    )
      .then(() => {
        toast.show(i18n.t('TransferOk'))
        navigation.goBack()
      })
      .catch(console.error)
      .finally(() => loader.setLoading(false))
  }

  function handleArticleSelected(selected: KanbanBox) {
    if (selected.article.info.id === currentBox?.article.info.id) {
      setArticleConfirmed(true)
      return
    }
    const foundBox = boxes.find(box => KanbanUtils.compare(box, selected))
    if (foundBox) {
      setCurrentBoxId(foundBox.id)
      setArticleConfirmed(true)
      return
    }
    alert.info(i18n.t('KANBAN_BOX_NOT_FOUND'), KanbanUtils.getCode(selected))
  }

  return (
    <MWS_Screen title={`${i18n.t('BOX')} (${currentBoxIndex + 1} / ${boxes.length})`}>
      <ScrollView style={IMLayout.flex.f1}>
        <StockPositionCard stockPosition={kanbanStockPosition} />
        <KanbanBoxCard box={currentBox} navigateToArticleInfoOnPress />
        {checkArticleRequired && <KanbanBoxSelectionView kanbanStockPosition={kanbanStockPosition} onSelected={handleArticleSelected} noInfo />}
        {!checkArticleRequired && (
          <KanbanInputView box={currentBox} setBox={updateBox} kanbanStockPosition={kanbanStockPosition} onSubmit={handleTransfer} />
        )}
        {showCompleteButton && <SubmitButton title={i18n.t('CompleteTransfer')} onPress={handleTransfer} />}
        {currentBox?.transferResult && <CompletedTag style={styles.completedTag}>{i18n.t('TransferOk')}</CompletedTag>}
      </ScrollView>
      {boxes.length > 1 && (
        <View style={[IMLayout.flex.row]}>
          <ElusiveButton style={IMLayout.flex.f1} title={i18n.t('BACK')} onPress={prevBox} hideFromKeyBoard />
          <ElusiveButton style={IMLayout.flex.f1} title={i18n.t('Continue')} onPress={() => nextBox()} hideFromKeyBoard />
        </View>
      )}
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  completedTag: {
    marginTop: 10,
  },
})
