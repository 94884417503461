import React from 'react'
import { View } from 'react-native'

import { Colors } from '../constants/Colors'
import { IMIconButton } from './IMIconButton'
import { IMIconName } from './IMIconNames'
import IMText, { IMTextProps } from './IMText'

export type TextWithIconButtonProps = {
  icon: IMIconName
  onIconPressed?: () => void
  onIconLongPressed?: () => void
}

export default function TextWithIconButton({ style, icon, onIconPressed, onIconLongPressed, ...textProps }: TextWithIconButtonProps & IMTextProps) {
  return (
    <View style={[style, { flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }]}>
      <View style={{ flex: 1 }} />
      <IMText {...textProps} />
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
        <IMIconButton
          icon={icon}
          iconSize={20}
          style={{ padding: 5, marginLeft: 5, backgroundColor: Colors.tint, borderRadius: 100 }}
          iconColor={Colors.white}
          onPress={onIconPressed}
          onLongPress={onIconLongPressed}
        />
      </View>
    </View>
  )
}
