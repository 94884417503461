//import { WorksheetOutgoingPostRequest, WorksheetPostQuantity } from '../apis/apiRequestTypes'

import { WorksheetConfirmPostRequest, WorksheetConfirmSerialNumber } from '../apis/apiRequestTypes'
import { Worksheet, WorksheetSerialNumbers } from '../apis/apiTypes'

const worksheetUtils = {
  getTitle(worksheet: Worksheet | undefined) {
    if (!worksheet?.number) return 0
    return worksheet.number
  },

  showInsertArticleNetWeight(netWeightToConfirm: boolean): boolean {
    return netWeightToConfirm
  },

  showQuantitySelector(netWeightToConfirm: boolean, worksheetSerialNumbers: WorksheetSerialNumbers[] | undefined): boolean {
    if (this.showInsertArticleNetWeight(netWeightToConfirm)) return false
    return !worksheetSerialNumbers
  },

  showStockpositionSelectorAndConfirm(netWeightToConfirm: boolean, worksheetSerialNumbers: WorksheetSerialNumbers[] | undefined): boolean {
    if (this.showInsertArticleNetWeight(netWeightToConfirm) || this.showQuantitySelector(netWeightToConfirm, worksheetSerialNumbers)) return false
    return worksheetSerialNumbers != null
  },

  generateConfirmPostRequest(
    worksheet: Worksheet,
    stockPositionId: string,
    quantity: number,
    serialNumbers: WorksheetSerialNumbers[]
  ): WorksheetConfirmPostRequest {
    const snQuantities: WorksheetConfirmSerialNumber[] = []
    serialNumbers.forEach(serialNumber => {
      snQuantities.push({
        serialNumberId: serialNumber.id,
        serialNumber: serialNumber.number,
        quantity: serialNumber.quantity,
      })
    })
    return { worksheetId: worksheet?.id, stockPositionId: stockPositionId, quantity: quantity, serialNumberQuantities: snQuantities }
  },
}

export default worksheetUtils
