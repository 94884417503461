import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { QualityControlPatchValue, QualityControlPostValue } from '../../apis/apiRequestTypes'
import { Production, QualityCharacteristic, QualityCharacteristicType, SerialNumber } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import IMLoadingIndicator from '../../components/IMLoadingIndicator'
import IMText from '../../components/IMText'
import ListSpacer from '../../components/MWS/ListSpacer'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Button from '../../components/old/Button'
import Separator from '../../components/Separator'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import { useCustomAlert } from '../../hooks/useCustomAlert'
import useRouteParam from '../../hooks/useRouteParam'
import useToastMessage from '../../hooks/useToastMessage'
import { QualityCharacteristicValue, QualityValidationResultType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import productionUtils from '../../utils/ProductionUtils'
import { QualityUtils } from '../../utils/QualityUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import QualityCharacteristicInput from '../../views/QualityManagement/QualityCharacteristicInput'

type CharacteristicValue = {
  characteristicId: string
  value: QualityCharacteristicValue
  note?: string
  order?: number
}

export default function QualityManagementScreen() {
  const navigation = useNavigation()
  const userSettings = useUserSettings()
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()
  const toast = useToastMessage()
  const editAlert = useCustomAlert(i18n.t('INFO'), i18n.t('QUALITY_CONTROL_ALREADY_EXISTS'), [
    { id: 'Edit', text: i18n.t('EDIT'), type: 'deny' },
    { id: 'New', text: i18n.t('New'), type: 'confirm' },
  ])

  const [characteristics, loadCharacteristics, loadingCharacteristics] = useApi(api.getQualityManagementCharacteristics, [])
  const [values, setValues] = useState<CharacteristicValue[]>([])
  const [loadingValues, setLoadingValues] = useState(false)

  const isEditing = useRef('')
  const loading = loadingValues || loadingCharacteristics
  const serialNumber = useRouteParam<SerialNumber>('serialNumber')
  const production = useRouteParam<Production>('production')
  const articleId = useMemo(() => serialNumber?.article.id ?? production?.article.id ?? '', [serialNumber, production])
  const validationResults = useMemo(
    () => characteristics?.map(c => QualityUtils.validateValue(values.find(v => v.characteristicId === c.id)?.value, c)),
    [characteristics, values]
  )
  const anyRequiredMissing = useMemo(
    () => validationResults?.find(vr => vr.required !== QualityValidationResultType.notRequired && vr.required !== QualityValidationResultType.ok),
    [validationResults]
  )

  useEffect(() => {
    if (!serialNumber?.article && !production?.article) {
      handleLoadingError('both serialNumber and production are undefined')
      return
    }
    loadCharacteristics({ articleId }, result => result?.filter(r => r.fieldTyp && r.fieldTyp !== QualityCharacteristicType.None).sort() ?? [])
      .then(resultCharacteristics => {
        setLoadingValues(true)
        loadValues(resultCharacteristics)
          .catch(console.error)
          .finally(() => setLoadingValues(false))
      })
      .catch(handleLoadingError)
  }, [])

  async function loadValues(loadedCharacteristics: QualityCharacteristic[]) {
    const qualityControls = await api.getQualityControl({ serialNumberId: serialNumber?.id, productionId: production?.id })

    if (!qualityControls.length) {
      return
    }

    const alertResult = await editAlert.show()

    if (alertResult === 'New') return
    qualityControls.sort(QualityUtils.sortQualityControlByDate)
    const qualityControlToUse = qualityControls.pop()
    isEditing.current = qualityControlToUse?.id ?? ''
    setValues(
      qualityControlToUse?.values?.map<CharacteristicValue>(v => ({
        characteristicId: v.qualityCharacteristicId,
        value: QualityUtils.parseValueFromQualityControlValue(v, loadedCharacteristics),
        note: v.note,
        order: v.order,
      })) ?? []
    )
  }

  function handleLoadingError(error: unknown) {
    console.error(error)
    let info = ''
    if (serialNumber) info = serialnumberUtils.getSerialNumberString(serialNumber, true)
    else if (production) info = productionUtils.getTitle(production)
    alert.error(i18n.t('QUALITY_CHARACTERISTICS_NOT_FOUND'), info)
  }

  function handleValueChange(id: string, value: QualityCharacteristicValue) {
    setValues(prev => {
      const item = prev.find(p => p.characteristicId === id)
      if (item) item.value = value
      else prev.push({ characteristicId: id, value })
      return [...prev]
    })
  }

  function handleSubmit() {
    loader.setLoading(true)

    const submitPromise = isEditing.current
      ? api.patchQualityControl({
          id: isEditing.current,
          values:
            characteristics
              ?.map<QualityControlPatchValue>(characteristic => {
                const value = values.find(v => v.characteristicId === characteristic.id)
                return {
                  qualityCharacteristicId: characteristic.id,
                  value: QualityUtils.formatValueForRequest(value?.value, characteristic)?.toString() ?? '',
                }
              })
              ?.filter(v => v.value !== undefined && v.value !== null) ?? [],
        })
      : api.postQualityControl({
          articleId,
          serialnumberId: serialNumber?.id,
          productionId: production?.id,
          employeeId: userSettings?.employeeId,
          values:
            characteristics?.map<QualityControlPostValue>(characteristic => {
              const value = values.find(v => v.characteristicId === characteristic.id)
              return {
                qualityCharacteristicId: characteristic.id,
                order: characteristic?.order,
                value: QualityUtils.formatValueForRequest(value?.value, characteristic)?.toString() ?? '',
              }
            }) ?? [],
        })

    submitPromise
      .then(() => {
        navigation.goBack()
        toast.show(i18n.t('QUALITY_CONTROL_SAVED'))
      })
      .catch(handleSubmitError)
      .finally(() => {
        loader.setLoading(false)
      })
  }

  function handleSubmitError(error: unknown) {
    console.error(error)
    const info = Error_Utils.extractErrorMessageFromException(error)
    alert.error(i18n.t('QUALITY_CONTROL_POST_ERROR'), info)
  }

  return (
    <MWS_Screen title={i18n.t('QUALITY_MANAGEMENT')} noTopPadding>
      <ScrollView>
        <ListSpacer />
        <ArticleCard
          article={serialNumber?.article ?? production?.article}
          serialNumber={serialNumber}
          addInfo={production ? productionUtils.getTitle(production) : undefined}
        />

        <IMLoadingIndicator isVisible={loading} />

        {!loading && (
          <>
            {characteristics?.map(item => (
              <View key={`Characteristic-${item.id}`} style={styles.inputContainer}>
                <QualityCharacteristicInput
                  characteristic={item}
                  value={values?.find(v => v.characteristicId === item.id)?.value}
                  setValue={changedValue => handleValueChange(item.id, changedValue)}
                  validationResult={validationResults?.find(vr => vr.id === item.id)}
                />
                <Separator />
              </View>
            ))}
            <View>
              {!!anyRequiredMissing && <IMText style={styles.requiredFieldsMissingText}>{i18n.t('REQUIRED_FIELD_MISSING')}</IMText>}
              <Button style={styles.submitButton} title={i18n.t('SAVE')} disabled={!!anyRequiredMissing || loader.loading} onPress={handleSubmit} />
            </View>
          </>
        )}
      </ScrollView>
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS,
  },
  requiredFieldsMissingText: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    color: Colors.modern.red,
  },
  submitButton: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
})
