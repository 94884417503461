import React from 'react'
import { View } from 'react-native'

import { QualityCharacteristicInputProps } from '../../../views/QualityManagement/QualityCharacteristicInput'
import BaseTextInput from '../../BaseTextInput'
import QualityCharacteristicTitle from './QualityCharacteristicTitle'

export default function QualityControlTextInput({ characteristic, value, setValue, validationResult }: QualityCharacteristicInputProps) {
  return (
    <View>
      <QualityCharacteristicTitle characteristic={characteristic} validationResult={validationResult} />
      <BaseTextInput value={value?.toString() ?? ''} onChangeText={text => setValue(text)} multiline maxLength={characteristic.length} />
    </View>
  )
}
