import React from 'react'
import { StyleProp, Text, View, ViewStyle } from 'react-native'

export type DotProps = {
  size?: number
  color: string
  style?: StyleProp<ViewStyle>
}

export default function Dot({ size = 12, color, style }: DotProps) {
  return <View style={[{ width: size, height: size, borderRadius: 100, backgroundColor: color }, style]} />
}
