import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useRef } from 'react'

import api from '../../apis/apiCalls'
import { Worksheet } from '../../apis/apiTypes'
import WorksheetCard from '../../cards/Worksheet/WorksheetCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { SelectionViewProps } from '../../types'

export default function WorksheetSelectionView(props: SelectionViewProps<Worksheet>) {
  const { i18n } = useLanguage()

  const { onSelected, ...otherProps } = props
  const info = useRef<string | undefined>()

  function load(inputResult: ScannerInputResult) {
    const input = inputResult.scannerInput ?? inputResult.textInput ?? ''
    return api.getWorksheet({ id: input })
  }

  const RenderItem = useCallback((item: Worksheet) => <WorksheetCard handler={item} article={undefined} />, [])

  return (
    <MWS_Input
      {...otherProps}
      onSelected={item => {
        onSelected(item, info.current)
        info.current = undefined
      }}
      type="Worksheet"
      title={props.title ?? i18n.t('SelectWorksheet')}
      load={load}
      itemRenderer={RenderItem}
      failedToLoadText={i18n.t('NOWORKSHEETWITHID')}
      modalTitle={i18n.t('SelectWorksheet')}
      historyNameExtractor={item => item.id}
      loadFromHistory={item => api.getWorksheet({ id: item.id })}
    />
  )
}
