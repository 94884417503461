import { useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { Collo, StockPosition } from '../../apis/apiTypes'
import ColloCard from '../../cards/Collo/ColloCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import { IMLayout } from '../../constants/Styles'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import useToastMessage from '../../hooks/useToastMessage'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import ColloSelectionView from '../../views/InputViews/ColloSelectionView'
import StockPositionSelectionView from '../../views/InputViews/StockPositionSelectionView'

export default function TransferColloScreen() {
  const { i18n } = useLanguage()

  const toast = useToastMessage()
  const alert = useAlert()

  const [collo, setCollo] = useState<Collo>()
  const loader = useLoadingIndicator()
  const userSettings = useUserSettings()

  function transferCollo(toStockPos: StockPosition) {
    if (!toStockPos || !collo) return
    loader.setLoading(true)
    api
      .postTransferCollo({ stockpositionId: toStockPos.id, colloNumber: collo.number ?? '', collaboratorId: userSettings?.employeeId ?? '' })
      .then(() => {
        toast.show(Utils.stringValueReplacer(i18n.t('ColloTransferOk'), collo.number, stockPositionUtils.getTitle(toStockPos)))
        setCollo(undefined)
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message || i18n.t('ColloTransferFailed'), collo.number, stockPositionUtils.getTitle(toStockPos))
      })
      .finally(() => loader.setLoading(false))
  }

  return (
    <MWS_Screen>
      {!collo && <ColloSelectionView onSelected={selectedCollo => setCollo(selectedCollo)} allowEmptyInput history />}
      {collo && (
        <View style={IMLayout.flex.f1}>
          <ColloCard collo={collo} onDelete={() => setCollo(undefined)} />
          <Title>{i18n.t('TransferTo')}</Title>
          <StockPositionSelectionView onSelected={transferCollo} allowEmptyInput history />
        </View>
      )}
    </MWS_Screen>
  )
}
