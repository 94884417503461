import { useModalController } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import SelectionModal from '../modals/SelectionModal'
import Chip from './Chip'
import FilterSortButtons from './FilterSortButtons'
import Grid from './Grid'
import { IMIconName } from './IMIconNames'
import IMText from './IMText'

export type SortOption = {
  id: string
  icon: IMIconName
}

export type FilterSortBarProps<T> = {
  filterItems: T[]
  activeFilter?: T[]
  setFilter: (filter: T[]) => void
  renderItem: (item: T, index: number, selected: boolean) => JSX.Element
  activeFilterCaptionExtractor: (item: T, index: number) => string
  sortOptions: SortOption[]
  activeSort: SortOption
  setSort: (sort: SortOption) => void
  style?: StyleProp<ViewStyle>
}

export default function FilterSortBar<T>({
  filterItems,
  activeFilter,
  setFilter,
  renderItem,
  activeFilterCaptionExtractor,
  sortOptions,
  activeSort,
  setSort,
  style,
}: FilterSortBarProps<T>) {
  const filterModal = useModalController<void>()

  function handleSortButtonPressed() {
    let nextSortIndex = sortOptions.indexOf(activeSort) + 1
    if (nextSortIndex >= sortOptions.length) nextSortIndex = 0
    setSort(sortOptions[nextSortIndex])
  }

  return (
    <View style={[styles.main, style]}>
      <View style={styles.content}>
        <Grid
          style={{ marginRight: 5 }}
          data={activeFilter ?? []}
          elementWidth={120}
          maxNumberOfRows={3}
          hiddenElementsIndicator={hiddenElements => <IMText style={{ alignSelf: 'center' }} secondary>{`+${hiddenElements.length}`}</IMText>}
          renderItem={(item, index) => (
            <Chip
              key={`Chip${index}`}
              text={activeFilterCaptionExtractor(item, index)}
              onDelete={() => setFilter(activeFilter?.filter(a => a !== item) ?? [])}
              maxWidth={120}
            />
          )}
        />
      </View>
      <FilterSortButtons onFilterPressed={() => filterModal.show()} onSortPressed={handleSortButtonPressed} sortIcon={activeSort.icon} />
      <SelectionModal
        show={filterModal.isShown}
        items={filterItems}
        close={() => filterModal.close()}
        renderItem={renderItem}
        preSelection={activeFilter}
        multiSelection
        onMultiSelection={setFilter}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  content: {
    flex: 1,
  },
})
