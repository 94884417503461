import { ClientProvider } from '@infominds/react-api'

import {
  PackagingTypeRequest,
  PackingListAddMovementRequest,
  PackingListConcludePostRequest,
  PackingListMovementsRequest,
  PackingListPatchMovementRequest,
  PackingListPatchRequest,
  PackingListRequest,
  PackingListShippingRequest,
} from './apiRequestTypes'
import { PackagingType, PackingList, PackingListMovement } from './apiTypes'

export const packagingList = ClientProvider<PackingList[], PackingListRequest, unknown, unknown, boolean, PackingListPatchRequest>('packingLists')
export const packagingListConclude = ClientProvider<unknown, unknown, boolean, PackingListConcludePostRequest>('packingLists/Conclude')
export const packagingType = ClientProvider<PackagingType[], PackagingTypeRequest>('packingLists/packingTypes')
export const packagingMovements = ClientProvider<PackingListMovement[], PackingListMovementsRequest>('packingLists/movements')
export const packagingShipping = ClientProvider<unknown, unknown, boolean, PackingListShippingRequest>('packingLists/shipping')
export const packagingMovement = ClientProvider<unknown, unknown, string, PackingListAddMovementRequest, boolean, PackingListPatchMovementRequest>(
  'packingLists/movement'
)
