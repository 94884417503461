import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useRef } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { Item, SerialNumber } from '../../apis/apiTypes'
import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import ElusiveButton from '../../components/ElusiveButton'
import IMRefreshControl from '../../components/IMRefreshControl'
import TitleWithInfoButton from '../../components/MWS/TitleWithInfoButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useRuntimeStorage } from '../../contexts/RuntimeStorageContext'
import { PackingListHandler } from '../../hooks/specific/usePackingListHandler'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import PackingListAddItemModal from '../../modals/PackingListAddItemModal'
import { runtimeStorageSelectedColloKey } from '../../screens/Packinglist/PackingListScreen'
import { ScreenType } from '../../types'
import packingListUtils from '../../utils/packingListUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import PackingListMovementView from '../../views/PackingList/PackingListMovementView'

export default function PackingListView(props: { handler: PackingListHandler; reload: (openMovementWithId?: string) => void }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const navigation = useNavigation()
  const listRef = useRef<FlatList<PackingListArticle> | null>(null)
  const listMemIndex = useRef<number | null>(null)
  const handler = props.handler
  const scannedSerialNumber = useRef<SerialNumber | undefined>()
  const colloStorage = useRuntimeStorage(runtimeStorageSelectedColloKey)
  const activeCollo = colloStorage.getValue<string>()
  const showInfo = !!handler.packingLists && handler.packingLists.length === 1 ? handler.packingLists[0].additionalinfos : undefined
  const addMovementModal = useModalController<Item>()

  function scrollToLastIndex() {
    if (loader.loading || handler.selectedPackingListMovement) return
    if (listMemIndex.current !== null) {
      const index = listMemIndex.current
      listRef.current?.scrollToIndex({ index: index, animated: false })

      listMemIndex.current = null
    }
  }

  function handleItemInput(item: Item) {
    if (loader.loading) return
    const foundMovement = findMatchingMovement(item)
    if (foundMovement) {
      handlePackingListMovementSelected(foundMovement, item.serialnumber)
      return
    }

    //item was not found in movements. If bit ask if its supposed to be added
    if (handler.userSettings?.isPackingAddItemActive && !handler.isPreConsignment && !handler.isProductionConsignment) {
      alert
        .yesNo(i18n.t('PackingListArticleNotFoundAddAlert'), item.article.code)
        .then(result => {
          if (!result) return
          addMovementModal.show(item)
        })
        .catch(console.error)
      return
    }
    alert.error(i18n.t('PackingListArticleNotFound'), item.article.code)
  }

  function findMatchingMovement(item: Item) {
    const foundPackingListMovements = handler.movements?.filter(m => m.article.id === item.article.id)
    if (!foundPackingListMovements?.length) return undefined
    //if only one movement matches the input navigate to that
    if (foundPackingListMovements?.length === 1) return foundPackingListMovements[0]
    //if movement has an order with the serialNumber (can only ever be 1)
    if (item.serialnumber) {
      const foundMovementWithSerialOrder = foundPackingListMovements.find(plm =>
        plm.movements?.find(m => m.movement.orderSerialnumberquantities?.find(sn => sn.serialnumber?.id === item.serialnumber?.id))
      )
      if (foundMovementWithSerialOrder) return foundMovementWithSerialOrder
    }

    const openMovements = foundPackingListMovements.filter(plm => packingListUtils.getIncompleteMovements([plm]).length > 0)
    const startedMovements = openMovements.filter(plm => plm.movements.find(m => m.movement.collonumbers?.find(c => c.quantity > 0)))
    //navigate to any already started, but unfinished movement
    if (startedMovements.length > 0) return startedMovements[0]
    //navigate to any matching open movement
    if (openMovements.length > 0) return openMovements[0]

    if (item.serialnumber) {
      const foundMovementWithSerial = foundPackingListMovements.find(om =>
        om.movements.find(m => m.movement.collonumbers?.find(c => c.serialnumber?.id === item.serialnumber?.id))
      )
      //navigate to movement with matching serialnumber
      if (foundMovementWithSerial) return foundMovementWithSerial
    }
    //navigate to any movement
    return foundPackingListMovements[0]
  }

  function handlePackingListMovementSelected(packingListArticle: PackingListArticle, serialNumber?: SerialNumber, index?: number) {
    listMemIndex.current = index ?? null
    scannedSerialNumber.current = serialNumber
    if (handler.packingLists != null || packingListArticle != null) {
      handler.setSelectedPackingListMovement(packingListArticle)
    }
  }

  async function handleSubmit() {
    if (handler.isProductionConsignment) {
      const openMovements = handler.movements?.filter(plm => packingListUtils.getIncompleteMovements([plm]).length > 0)
      if (openMovements != null && openMovements.length > 0) {
        const answer = await alert.yesNo(i18n.t('PackingListIncompleteMovementsQuestion'))
        if (!answer) return
      }
    }

    navigation.navigate(ScreenType.PackingListCompletion, {
      packingLists: handler.packingLists ?? [],
      isPreConsignment: handler.isPreConsignment,
      isProductionConsignment: handler.isProductionConsignment,
    })
  }

  if (handler.selectedPackingListMovement) {
    return (
      <PackingListMovementView
        packingListHandler={handler}
        packingListArticle={handler.selectedPackingListMovement}
        scannedSerialNumber={scannedSerialNumber.current}
        reloadPackingLists={props.reload}
      />
    )
  }

  return (
    <View style={{ flex: 1 }}>
      {!!handler.packingLists && <TitleWithInfoButton infoText={showInfo} text={packingListUtils.getPackingListsTitle(handler.packingLists)} />}
      <ItemSelectionView onSelected={handleItemInput} showResultInModal fetchArticleAndSerial ignoreHistoricalSN={handler.ignoreHistoricalSN} />
      <View style={styles.list}>
        <FlatList
          ref={listRef}
          data={handler.movements}
          onLayout={() => scrollToLastIndex()}
          onScrollToIndexFailed={console.error}
          renderItem={({ item, index }) => {
            const allowPress = !handler.forceScan || item.movements?.find(m => m.movement.collonumbers?.find(c => c.quantity > 0))
            return (
              <PackingListArticleCard
                item={item}
                onPress={allowPress ? () => handlePackingListMovementSelected(item, undefined, index) : undefined}
                showAvailability
                showOrderSerialnumber
                showPackingLists={handler.isPreConsignment || handler.isProductionConsignment}
              />
            )
          }}
          refreshControl={<IMRefreshControl refreshing={false} onRefresh={props.reload} />}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <ElusiveButton
          style={{ flex: 1 }}
          title={i18n.t('Unload')}
          hide={!handler.isPreConsignment || !activeCollo || handler.isProductionConsignment}
          hideFromKeyBoard
          onPress={() => handler.setUnloadCollos(true)}
        />
        <ElusiveButton
          style={{ flex: 1 }}
          title={i18n.t('Conclude')}
          hide={handler.isPreConsignment && !handler.isProductionConsignment && !!activeCollo}
          hideFromKeyBoard
          onPress={() => {
            handleSubmit().catch(console.error)
          }}
        />
      </View>
      <PackingListAddItemModal
        controller={addMovementModal}
        handler={handler}
        onDone={openMovementWithId => {
          addMovementModal.close()
          props.reload(openMovementWithId)
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    flex: 1,
  },
})
