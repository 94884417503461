import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { StockAccounting } from '../../apis/apiTypes'
import IMIcon from '../../components/IMIcon'
import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { articleUtils } from '../../utils/articleUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function TransferHistoryCard(props: {
  stockAccount: StockAccounting
  cardTitle?: string
  onPressEndButton?: (() => void) | false
  onDelete?: (() => Promise<void>) | false
  endButtonIcon?: IMIconName
  style?: StyleProp<ViewStyle>
}) {
  return (
    <MWS_BaseCard
      isHiddenIcon={true}
      endButton={props.endButtonIcon ? { icon: props.endButtonIcon, onPress: props.onPressEndButton } : undefined}
      onDelete={props.onDelete}>
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-start',
          marginRight: 10,
        }}>
        {!!props.cardTitle && <IMText primary>{props.cardTitle}</IMText>}
        <IMTextWithIcon secondary numberOfLines={1} icon={'article'} text={articleUtils.getArticleTitle(props.stockAccount.article)} />
        {props.stockAccount.serialNumber && (
          <IMTextWithIcon secondary icon={'barcode'} text={serialnumberUtils.getSerialNumberString(props.stockAccount.serialNumber)} />
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          {!props.stockAccount.stockPosition && props.stockAccount.deposit && (
            <IMTextWithIcon secondary icon={'warehouse-full'} text={props.stockAccount.deposit.code} />
          )}
          {!!props.stockAccount.stockPosition && (
            <IMTextWithIcon secondary icon={'shelf'} text={stockPositionUtils.getTitle(props.stockAccount.stockPosition)} />
          )}
          {props.stockAccount.depositTarget && <IMIcon style={{ justifyContent: 'center', paddingHorizontal: 10 }} icon={'arrow-right'} />}
          {!props.stockAccount.stockPositionTarget && props.stockAccount.depositTarget && (
            <IMTextWithIcon secondary icon={'warehouse-full'} text={props.stockAccount.depositTarget.code} />
          )}
          {!!props.stockAccount.stockPositionTarget && (
            <IMTextWithIcon secondary icon={'shelf'} text={stockPositionUtils.getTitle(props.stockAccount.stockPositionTarget)} />
          )}
        </View>
      </View>
      <View style={{ justifyContent: 'center' }}>
        <IMText style={{ alignSelf: 'flex-end', marginLeft: 10 }}>
          {articleUtils.formatQuantity(props.stockAccount.quantity, props.stockAccount.article?.measurementUnitCode)}
        </IMText>
      </View>
    </MWS_BaseCard>
  )
}
