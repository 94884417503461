import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { SerialNumber } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import { Article } from '../../classes/Article'
import useModalController from '../../hooks/useModalController'
import ImageModal from '../../modals/ImageModal'

interface ArticleInfoProps {
  article: Article
  showUnit?: boolean
  style?: StyleProp<ViewStyle>
  limitDescription?: boolean
  serialNumber?: SerialNumber
  imageUri?: string
  configurableDescription?: string
}

export default function ArticleInfoView(props: ArticleInfoProps) {
  const { i18n } = useLanguage()

  const unitText = props.showUnit && props.article.getUsedUnitText()
  const imgModal = useModalController<string>()

  return (
    <>
      <ArticleCard
        article={props.article.info}
        addInfo={unitText ? `${i18n.t('Unit')}: ${unitText}` : undefined}
        style={[{ marginBottom: 0 }, props.style]}
        maxLinesDescription={props.limitDescription ? 5 : 0}
        serialNumber={props.serialNumber}
        endButton={props.imageUri ? { icon: 'image', onPress: () => imgModal.show(props.imageUri) } : undefined}
        configurableDescription={props.configurableDescription}
      />
      <ImageModal controller={imgModal} title={props.article.getTitle()} />
    </>
  )
}
