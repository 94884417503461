import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import ArticleCard from '../cards/Article/ArticleCard'
import SerialnumberCard from '../cards/Serialnumber/SerialnumberCard'
import ElusiveButton from '../components/ElusiveButton'
import FullScreenModal from '../components/FullScreenModal'
import VKUnitAmountSelector from '../components/MWS/Inputs/VKUnitAmountSelector'
import Title from '../components/MWS/Title'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { TransferHandler } from '../hooks/specific/useTransferHandler'
import useAlert from '../hooks/useAlert'
import { ModalController } from '../hooks/useModalController'
import { articleUtils } from '../utils/articleUtils'
import { depositUtils } from '../utils/depositUtils'
import stockPositionUtils from '../utils/stockPositionUtils'
import handlerUtils from '../utils/transferUtils'
import { ArticleAmountSelectionResult } from '../views/Article/ArticleAmountSelectorV2'

export default function TransferSerialEditModal(props: { controller: ModalController<any>; handler: TransferHandler }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const handler = props.handler
  const [selectedItem, setSelectedItem] = useState<ArticleAmountSelectionResult | undefined>(undefined)

  useEffect(() => {
    setSelectedItem(undefined)
  }, [props.controller.isShown])

  function handleQuantityChange(item: ArticleAmountSelectionResult, quantity: number) {
    const maxAvailability = handler.article?.serialNumberQuantities?.find(
      snQ =>
        snQ.serialnumber?.id === item.serialNumber?.id &&
        stockPositionUtils.comparePosition(snQ.deposit, item.deposit, snQ.stockposition, item.stockPosition)
    )?.quantity
    if (!articleUtils.quantityValidator(quantity, maxAvailability)) {
      alert.error(i18n.t('SelectedAmountInvalidAlert'), maxAvailability?.toString())
      return
    }
    const newItem = { ...item, quantity: quantity }
    handler.articleQuantities.remove(item)
    handler.articleQuantities.add(newItem)
    setSelectedItem(undefined)
  }

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
      }}
      title={handlerUtils.getScreenTitle(handler.stockTemplate)}>
      <ArticleCard
        article={handler.article?.info}
        style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        quantity={handler.quantity}
        unit={handler.article?.getUsedUnit()}
      />

      {selectedItem ? (
        <>
          <SerialnumberCard
            serialnumber={selectedItem.serialNumber}
            hideManufacturer
            quantity={handler.article?.getQuantityWithUnitText(selectedItem.quantity)}
            infoText={depositUtils.getTitleWithStockPosition(selectedItem.deposit, selectedItem.stockPosition)}
            onDelete={() => {
              handler.articleQuantities.remove(selectedItem)
            }}
          />
          <VKUnitAmountSelector
            value={selectedItem.quantity}
            setValue={value => handleQuantityChange(selectedItem, value)}
            usedUnit={selectedItem.unit}
            autoFocus
          />
        </>
      ) : (
        <>
          <View style={{ flex: 1 }}>
            <Title>{i18n.t('SerialNumbers')}</Title>
            <FlatList
              data={handler.articleQuantities.items}
              renderItem={({ item }) => (
                <SerialnumberCard
                  serialnumber={item.serialNumber}
                  hideManufacturer
                  quantity={handler.article?.getQuantityWithUnitText(item.quantity)}
                  infoText={depositUtils.getTitleWithStockPosition(item.deposit, item.stockPosition)}
                  onPress={handler.article?.isLottoSerialNumber ? () => setSelectedItem(item) : undefined}
                  onDelete={() => {
                    handler.articleQuantities.remove(item)
                  }}
                />
              )}
            />
          </View>
          <ElusiveButton onPress={props.controller.close} title={i18n.t('Close')} />
        </>
      )}
    </FullScreenModal>
  )
}
