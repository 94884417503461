import { useLanguage } from '@infominds/react-native-components'
import { forEach } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import SerialNumberAvailabilitySelector from '../../components/MWS/Inputs/SerialNumberAvailabilitySelector'
import PackingListColloSelector from '../../components/MWS/PackingList/PackingListColloSelector'
import Button from '../../components/old/Button'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { PackingListMovementHandler } from '../../hooks/specific/usePackingListMovementHandler'
import useAlert from '../../hooks/useAlert'
import MultiSerialSelectionModal from '../../modals/MultiSerialSelectionModal'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import StockPositionWithAvailabilitySelectionView from '../InputViews/StockPositionWithAvailabilitySelectionView'

export default function PackingListMovementInputView(props: { handler: PackingListMovementHandler; style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const loader = useLoadingIndicator()
  const handler = props.handler
  const [showSerialSelectionModal, setShowSerialSelectionModal] = useState<boolean>(false)
  const serialFilter = useRef<{ serialNumber: string | undefined; stockPosition: StockPosition | undefined } | undefined>(undefined)
  const loadedDepositSerialNumberAvailability = useRef(false)

  useEffect(() => {
    if (!handler.packingListArticle) return
    if (handler.colloSelector.any && !handler.scannedSerialNumber) return
    if (handler.article?.isSerialNumberActive) {
      if (handler.article.isHistoricalSerialNumber) handleHistoricalSerialScanned(handler.deposit, handler.stockPosition)
      else loadSerialAvailability(handler.deposit, handler.stockPosition)
      return
    }
    if (handler.packingListHandler.forceScan) return
    if (handler.missingQuantity > 0 && handler.deposit) addColloFromPosition(handler.deposit, handler.stockPosition, true)
  }, [])

  function handleError(error: unknown) {
    loader.setLoading(false)
    console.error(error)
  }

  function addColloFromPosition(deposit: Deposit | undefined, stockPosition?: StockPosition, ignoreError?: boolean) {
    if (!handler.anyMovement || !handler.article || handler.article.isSerialNumberActive) return
    if (!handler.article.hasStock) {
      handler.colloSelector.add(colloUtils.create(handler.anyMovement, handler.missingQuantity, deposit, stockPosition))
      return
    }
    loader.setLoading(true)
    api
      .getItemAvailability({
        articleId: handler.article.info.id,
        depositId: deposit?.id,
        stockPositionId: stockPosition?.id,
      })
      .then(result => {
        if (!result || result <= 0) {
          if (!ignoreError) {
            alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
          }
          return
        }
        result *= packingListUtils.getMasterToUsedUnitConversionFactor(handler.anyMovement)
        if (handler.anyMovement) {
          handler.colloSelector.add(
            colloUtils.create(handler.anyMovement, Math.max(Math.min(result, handler.missingQuantity), 0), deposit, stockPosition)
          )
        }
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function filterSerials(serial: StockPositionAvailability) {
    if (!serial) return false
    if (serial.stockposition?.notProposedInPackinglists) return false
    if (!handler.anyMovement?.orderSerialnumberquantities?.length) return true
    return !!handler.anyMovement.orderSerialnumberquantities.find(orderSN => serialnumberUtils.compare(serial.serialnumber, orderSN.serialnumber))
  }

  function loadSerialAvailability(deposit?: Deposit, stockPosition?: StockPosition) {
    if (!handler.anyMovement || !handler.article) return
    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    handler.article
      .loadSerialNumberQuantities(
        deposit,
        !handler.scannedSerialNumber ? stockPosition : undefined,
        handler.scannedSerialNumber,
        filterSerials,
        undefined,
        true,
        true
      )
      .then(() => {
        const serials = handler.article?.getConvertedSerialNumberQuantities() ?? []
        serialFilter.current = { serialNumber: undefined, stockPosition: undefined }
        if (handler.scannedSerialNumber) {
          const matchingSerials = serials.filter(s => serialnumberUtils.compareByNumber(s.serialnumber, handler.scannedSerialNumber))
          if (matchingSerials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), handler.scannedSerialNumber.number)
          if (matchingSerials.length === 1) return addColloWithSerialFromPosition(matchingSerials[0])
          const matchingSerialsOnPosition = matchingSerials.filter(s =>
            stockPositionUtils.comparePosition(s.deposit, deposit, s.stockposition, stockPosition)
          )
          if (matchingSerialsOnPosition.length === 1) return addColloWithSerialFromPosition(matchingSerialsOnPosition[0])
          serialFilter.current.serialNumber = handler.scannedSerialNumber.number
          setShowSerialSelectionModal(true)
          return
        }

        if (!serials.length || handler.missingQuantity <= 0 || handler.packingListHandler.forceScan) return
        let stockPosSerials = serials.filter(s => stockPositionUtils.comparePosition(s.deposit, deposit, s.stockposition, stockPosition))

        serialFilter.current.stockPosition = handler.anyMovement?.stockposition
        if (stockPosSerials.length <= 0 && !!stockPosition) {
          serialFilter.current.stockPosition = undefined
          stockPosSerials = serials.filter(s => stockPositionUtils.comparePosition(s.deposit, deposit, undefined, undefined))
        }

        if (stockPosSerials.length <= 0) return

        if (stockPosSerials.length === 1 && handler.anyMovement?.orderSerialnumberquantities?.length === 0) {
          // Add the first available serial number if no order serial numbers have been defined
          addColloWithSerialFromPosition(stockPosSerials[0])
        } else if (!handler.colloSelector.any) {
          // Automatically add available order serial numbers
          let showSerialSelection = true
          let remainingQuantity = handler.missingQuantity
          for (const serial of stockPosSerials) {
            let quantity = handler.anyMovement?.orderSerialnumberquantities?.find(q => q.serialnumber.id === serial.serialnumber?.id)?.quantity
            if (quantity) {
              quantity = quantity * packingListUtils.getMasterToUsedUnitConversionFactor(handler.anyMovement)
              if (quantity > 0) {
                if (quantity > remainingQuantity) quantity = remainingQuantity
                addColloWithSerialFromPosition(serial, quantity)
                remainingQuantity -= quantity
                showSerialSelection = false
                if (remainingQuantity === 0) break
              }
            }
          }

          // show available serial numbers
          if (showSerialSelection) setShowSerialSelectionModal(true)
          return
        }
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function handleHistoricalSerialScanned(deposit?: Deposit, stockPosition?: StockPosition) {
    if (!handler.anyMovement || !handler.article || !handler.scannedSerialNumber) return
    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    packingListUtils
      .getHistoricalSerialNumberAvailability(handler.scannedSerialNumber, handler.article.info, deposit, stockPosition)
      .then(availability => {
        serialFilter.current = { serialNumber: undefined, stockPosition: undefined }
        if (handler.article) handler.article.serialNumberQuantities = availability ?? []
        if (!availability) return
        if (handler.scannedSerialNumber) {
          const matchingSerials = availability.filter(s => serialnumberUtils.compareByNumber(s.serialnumber, handler.scannedSerialNumber))
          if (matchingSerials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), handler.scannedSerialNumber.number)
          if (matchingSerials.length === 1) return addColloWithSerialFromPosition(matchingSerials[0])
          const matchingSerialsOnPosition = matchingSerials.filter(s =>
            stockPositionUtils.comparePosition(s.deposit, deposit, s.stockposition, stockPosition)
          )
          if (matchingSerialsOnPosition.length === 1) return addColloWithSerialFromPosition(matchingSerialsOnPosition[0])
          serialFilter.current.serialNumber = handler.scannedSerialNumber.number
          setShowSerialSelectionModal(true)
          return
        }

        if (availability.length <= 0 || handler.missingQuantity <= 0 || handler.packingListHandler.forceScan) return
        const stockPosSerials = (handler.article?.serialNumberQuantities ?? []).filter(s =>
          stockPositionUtils.comparePosition(s.deposit, deposit, s.stockposition, stockPosition)
        )
        if (stockPosSerials.length <= 0) return
        if (stockPosSerials.length === 1) {
          addColloWithSerialFromPosition(stockPosSerials[0])
        } else if (!handler.colloSelector.any) {
          serialFilter.current.stockPosition = handler.anyMovement?.stockposition
          setShowSerialSelectionModal(true)
          return
        }
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function addColloWithSerialFromPosition(serialNumberAvailability: StockPositionAvailability, suggestedQuantity?: number) {
    if (!handler.anyMovement || !handler.article || !serialNumberAvailability || checkSerialAlreadySelected(serialNumberAvailability)) return
    let quantity = 1
    if (handler.article.isLottoSerialNumber) {
      quantity = Math.max(Math.min(serialNumberAvailability.quantity ?? 0, suggestedQuantity ?? handler.missingQuantity), 0)
      const orderSerialNumberQuantity = handler.anyMovement.orderSerialnumberquantities.find(
        q => q.serialnumber.id === serialNumberAvailability.serialnumber?.id
      )
      if (orderSerialNumberQuantity) {
        quantity = orderSerialNumberQuantity.quantity * packingListUtils.getMasterToUsedUnitConversionFactor(handler.anyMovement)
        if (quantity > handler.missingQuantity) quantity = handler.missingQuantity
      }
    }
    handler.colloSelector.add(
      colloUtils.create(
        handler.anyMovement,
        quantity,
        serialNumberAvailability.deposit,
        serialNumberAvailability.stockposition,
        serialNumberAvailability.serialnumber
      )
    )
  }

  function filterSerialInSelection(serial: StockPositionAvailability) {
    if (
      !!handler.article?.isSerialNumberActive &&
      handler.colloSelector.items
        .filter(i => !i.id)
        .find(
          c =>
            serialnumberUtils.compareByNumber(c.serialnumber, serial.serialnumber) &&
            stockPositionUtils.comparePosition(c.deposit, serial.deposit, c.stockposition, serial.stockposition)
        )
    ) {
      return false
    }
    if (!serialFilter.current) return true
    if (!!serialFilter.current.stockPosition && !stockPositionUtils.compare(serial.stockposition, serialFilter.current.stockPosition)) return false
    if (
      !!serialFilter.current.serialNumber &&
      !serialnumberUtils.compareByNumber({ number: serialFilter.current.serialNumber } as SerialNumber, serial.serialnumber)
    ) {
      return false
    }
    return true
  }

  function checkSerialAlreadySelected(serial: StockPositionAvailability) {
    if (!serial) return false
    if (
      !handler.colloSelector.items
        .filter(i => !i.id)
        .find(
          i =>
            i.serialnumber?.id === serial.serialnumber?.id && i.stockposition?.id === serial.stockposition?.id && i.deposit?.id === serial.deposit?.id
        ) ||
      (handler.article?.isHistoricalSerialNumber &&
        !handler.colloSelector.items.find(
          i =>
            i.serialnumber?.number?.toLowerCase() === serial.serialnumber?.number?.toLowerCase() &&
            i.stockposition?.id === serial.stockposition?.id &&
            i.deposit?.id === serial.deposit?.id
        ))
    ) {
      return false
    }
    alert.info(i18n.t('SerialnumberAlreadySelected'))
    return true
  }

  function handleMultiSerialSelection(serials: StockPositionAvailability[]) {
    if (!serials) return
    let remainingQuantity = handler.missingQuantity
    for (const serial of serials) {
      addColloWithSerialFromPosition(serial, remainingQuantity)
      remainingQuantity -= serial.quantity ?? 0
    }
  }

  function handleSerialSelection(serialNumberAvailability: StockPositionAvailability[] | StockPositionAvailability) {
    if (Array.isArray(serialNumberAvailability)) {
      serialNumberAvailability.forEach(sna => addColloWithSerialFromPosition(sna))
    } else {
      addColloWithSerialFromPosition(serialNumberAvailability)
    }
  }

  return (
    <View style={props.style}>
      <MultiSerialSelectionModal
        allowMultiSelection
        header={
          <PackingListArticleCard
            item={handler.packingListArticle}
            navigateToArticleInfoOnPress
            showAvailability
            quantity={handler.selectedQuantity}
            showPackingLists={handler.packingListHandler.isPreConsignment || handler.packingListHandler.isProductionConsignment}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          />
        }
        title={`${i18n.t('SelectSerialnumber')} (${handler.missingQuantity} ${handler.anyMovement?.unitCode ?? ''})`}
        show={showSerialSelectionModal}
        article={handler.article}
        close={() => {
          serialFilter.current = undefined
          setShowSerialSelectionModal(false)
        }}
        filterItem={filterSerialInSelection}
        onSelected={serials => {
          serialFilter.current = undefined
          setShowSerialSelectionModal(false)
          handleMultiSerialSelection(serials)
        }}
        showWarrantyDate
      />
      <View style={{ flexDirection: 'row', marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
        {handler.packingListHandler.colloMode &&
          !handler.packingListHandler.isPreConsignment &&
          !handler.packingListHandler.isProductionConsignment && (
            <PackingListColloSelector
              style={{ flex: 1, marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS }}
              handler={handler}
            />
          )}

        {handler.article?.isSerialNumberActive ? (
          <SerialNumberAvailabilitySelector //Serialnumber selection
            article={handler.article}
            style={styles.inputView}
            inputStyle={styles.input}
            onSelected={handleSerialSelection}
            deposit={handler.deposit}
            stockPosition={handler.stockPosition}
            allowEmptyInput={!handler.article.isHistoricalSerialNumber && !handler.packingListHandler.forceScan}
            unitConversionFactor={packingListUtils.getMasterToUsedUnitConversionFactor(handler.anyMovement)}
            showResultInModal
            considerPickedQuantity={true}
            multiSelection={!handler.article.isLottoSerialNumber}
            allowOtherPositionsOnScan
            filterSerialNumber={sn =>
              !handler.colloSelector.items.find(
                c =>
                  c.serialnumber?.id === sn.serialnumber?.id &&
                  stockPositionUtils.comparePosition(c.deposit, sn.deposit, c.stockposition, sn.stockposition)
              )
            }
          />
        ) : (
          <>
            {!!handler.article?.hasStock && (
              <StockPositionWithAvailabilitySelectionView //StockPosition selection
                style={styles.inputView}
                inputStyle={styles.input}
                onSelected={selection => addColloFromPosition(selection.deposit, selection.stockposition)}
                showResultInModal
                deposit={handler.deposit}
                article={handler.article}
                noListButton={handler.packingListHandler.forceScan}
                unitConversionFactor={packingListUtils.getMasterToUsedUnitConversionFactor(handler.anyMovement)}
                filterNotProposedInPackingLists
                considerPickedQuantity={true}
                modalHeader={
                  <PackingListArticleCard
                    item={handler.packingListArticle}
                    navigateToArticleInfoOnPress
                    showAvailability
                    quantity={handler.selectedQuantity}
                    showPackingLists={handler.packingListHandler.isPreConsignment || handler.packingListHandler.isProductionConsignment}
                    style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
                  />
                }
              />
            )}
            {!handler.article?.hasStock && (
              <Button style={{ flex: 1 }} title={i18n.t('Add')} onPress={() => addColloFromPosition(handler.deposit, handler.stockPosition)} />
            )}
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  inputView: { flex: 2 },
  input: { marginHorizontal: 0 },
})
