import { LoginCustomQRCodeProcessorResult } from '@infominds/react-native-license'

const AppUtils = {
  loginQrCodeProcessor(code: string): LoginCustomQRCodeProcessorResult | null {
    try {
      if (!code) return null
      if (!code.includes(';')) return { licenseKey: code }
      const [username, encodedPassword] = code.split(';')
      const decodedPassword = decodeLoginPassword(encodedPassword)
      return { username, password: decodedPassword ?? '', tryLogin: !!decodedPassword }
    } catch (exception) {
      console.error(exception)
      return null
    }
  },
  encodePassword(password: string) {
    return encodeLoginPassword(password)
  },
  decodePassword(password: string) {
    return decodeLoginPassword(password)
  },
}

export default AppUtils

/**
 * Password decoder.
 * Format: hexnumber-pairs ex: "c8cadade"
 */
function decodeLoginPassword(password: string) {
  if (!password) return ''
  password = password.trim()
  try {
    let clearTextPassword = ''
    for (let i = 0; i < password.length; i += 2) {
      //take hexnumber pair
      let num = Number.parseInt(password.substring(i, i + 2), 16)
      //if it does not end in a 0 (binary) add 255. This makes no sense in my opinion and should never happen, since encoding shifts one bit to the left the number should always be even
      if (num % 2 !== 0) {
        num += 255
      }
      //shift 1 bit to right
      num >>= 1
      //add
      clearTextPassword += String.fromCharCode(num)
    }
    return clearTextPassword
  } catch (exception) {
    console.error('decodeLoginPassword Error', exception)
    return ''
  }
}

function encodeLoginPassword(password: string) {
  if (!password) return ''
  try {
    let encodedPassword = ''
    for (const char of password) {
      let charCode = Number.parseInt(char.charCodeAt(0).toString(16), 16)
      charCode <<= 1
      encodedPassword += charCode.toString(16)
    }
    return encodedPassword
  } catch (exception) {
    console.error('decodeLoginPassword Error', exception)
    return ''
  }
}
