import { useIsMounted, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'

import api from '../../apis/apiCalls'
import { TipType } from '../../apis/apiRequestTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import usePackingListHandler from '../../hooks/specific/usePackingListHandler'
import useAlert from '../../hooks/useAlert'
import useNavigationInterceptor from '../../hooks/useNavigationInterceptor'
import packingListUtils from '../../utils/packingListUtils'
import PackingListColloUnloadView from '../../views/PackingList/PackingListColloUnloadView'
import PackingListView from '../../views/PackingList/PackingListView'

export const runtimeStorageSelectedColloKey = 'packingListSelectedColloNumber'
export default function PackingListScreen() {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const navigation = useNavigation()
  const isMounted = useIsMounted()
  const handler = usePackingListHandler()
  const userSettings = useUserSettings()

  useNavigationInterceptor(e => {
    handler.setSelectedPackingListMovement(prev => {
      if (prev) e.preventDefault()
      return undefined
    })
  })

  useEffect(() => {
    loadTip().catch(console.error)
    setCurrentEmployeeAsPickerOfPickingList()
    load()
  }, [])

  function load(invisibleLoading?: boolean, openMovementWithId?: string) {
    handler.setSelectedPackingListMovement(undefined)
    handler
      .load(invisibleLoading)
      .then(movements => {
        if (!isMounted) return
        handler.setSelectedPackingListMovement(
          openMovementWithId ? movements.find(m => m.movements.find(mm => mm.movement.id === openMovementWithId)) : undefined
        )
      })
      .catch(reason => {
        if (!isMounted) return
        alert.error(
          i18n.t('FailedToLoadPackingListMovements'),
          packingListUtils.getPackingListsTitle(handler.packingLists ?? []),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          reason?.Message ?? ''
        )
        navigation.goBack()
      })
  }

  async function loadTip() {
    if (!handler.packingLists?.length) return

    const [tipTextCustomer, tipTextPackingList] = await Promise.all([
      api.getTip({ type: TipType.Customer, masterId: handler.packingLists[0].customerId }),
      api.getTip({ type: TipType.Document, masterId: handler.packingLists[0].documentId }),
    ])

    // Zusammenfassen und anzeigen
    let tipText = tipTextCustomer ? tipTextCustomer : ''
    if (tipTextPackingList) tipText = tipText ? `${tipText}\n${tipTextPackingList}` : tipTextPackingList
    if (tipText) alert.info(tipText)
  }

  function setCurrentEmployeeAsPickerOfPickingList() {
    if (!handler.packingLists?.length || !userSettings?.setCurrentEmployeeAsPickerOfPickingList) return
    api
      .patchPackingList({
        packingListIds: handler.packingLists?.map(item => item.id) ?? [],
        EmployeeId_CurrentPicker: userSettings?.employeeId,
      })
      .catch(console.error)
  }

  async function preNavigationCheck(type: 'goBack' | 'popToTop') {
    if (type !== 'goBack') return false

    if (handler.selectedPackingListMovement) {
      if (handler.hasUnsavedChanges) {
        const result = await alert.yesNo(i18n.t('LEAVING_ALERT'))
        if (!result) return true
      }

      handler.setSelectedPackingListMovement(undefined)
      return true
    }

    if (!handler.packingLists?.length) {
      return false
    }
    await api.patchPackingList({
      packingListIds: handler.packingLists?.map(item => item.id) ?? [],
      EmployeeId_CurrentPicker: '',
    })

    return false
  }

  return (
    <MWS_Screen title={handler.isPreConsignment ? i18n.t('PreConsignment') : i18n.t('Consignment')} preNavigationCallback={preNavigationCheck}>
      {handler.unloadCollos && (
        <PackingListColloUnloadView
          handler={handler}
          onDone={reload => {
            handler.setUnloadCollos(false)
            if (reload) load()
          }}
        />
      )}
      {!handler.unloadCollos && <PackingListView handler={handler} reload={openMovementWithId => load(false, openMovementWithId)} />}
    </MWS_Screen>
  )
}
