import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ColloCard(props: {
  collo: Collo | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  quantity?: string
}) {
  return (
    <MWS_BaseCard onPress={props.onPress} onDelete={props.onDelete} icon={'box-taped'} cardStyle={props.style} style={[{ flexDirection: 'row' }]}>
      <View style={{ flex: 1 }}>
        <IMText numberOfLines={1} primary style={{ flex: 1 }}>
          {props.collo?.number}
        </IMText>
      </View>
      {props.quantity && <IMText>{props.quantity}</IMText>}
    </MWS_BaseCard>
  )
}
