import { useApi } from '@infominds/react-api'
import { useLanguage, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ColloPacking } from '../../apis/apiRequestTypes'
import { Freighter, PrintingType } from '../../apis/apiTypes'
import SplitColloCard from '../../cards/Collo/SplitColloCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import ElusiveButton from '../../components/ElusiveButton'
import Picker from '../../components/MWS/Inputs/Picker'
import SubmitButton from '../../components/SubmitButton'
import SwitchWithText from '../../components/SwitchWithText'
import TextWithIconButton from '../../components/TextWithIconButton'
import { StorageKeys, STYLE_CONSTANTS } from '../../constants/Constants'
import { PackingListHandler } from '../../hooks/specific/usePackingListHandler'
import useModalController from '../../hooks/useModalController'
import ColloPackingModal from '../../modals/ColloPackingModal'
import colloUtils from '../../utils/colloUtils'
import { ColloPackingSelection } from './ColloPackingView'

export type PackingListCompletionViewProps = {
  show: boolean
  handler: PackingListHandler
  movements: PackingListArticle[]
  conclude: (
    selectedFreighter: Freighter | undefined,
    colloCount: number | undefined,
    weight: number | undefined,
    printing: boolean | undefined,
    createDeliveryNote: boolean | undefined,
    collos?: ColloPacking[]
  ) => Promise<void>
}

export type SplitCollo = {
  id: number
  number: number
  packing?: ColloPackingSelection
}

export default function PackingListCompletionView({ show, handler, movements, conclude }: PackingListCompletionViewProps) {
  const { i18n } = useLanguage()

  const packingModalController = useModalController<ColloPackingSelection>()
  const [splitCollos, setSplitCollos] = useState<SplitCollo[]>([])
  const selectedCollo = useRef<number | null>(null)
  const articleCount = useMemo(() => Utils.keepUniques(movements, q => q.article.id).length, [movements])
  const weightSum = Utils.sum(splitCollos, c => c.packing?.weight ?? 0)

  const [freighter, loadFreighter] = useApi(api.getFreighter, [])
  const presetFreighter = useMemo(() => Utils.getValueIfAllAreEqual(handler.packingLists, item => item.freighter?.id)?.freighter, [])
  const [selectedFreighter, setSelectedFreighter] = useState<Freighter | undefined>(presetFreighter)
  const [isPrinting, setPrinting] = useState(false)
  const [isCreateDeliveryNote, setIsCreateDeliveryNote] = useState(false)

  useEffect(() => {
    if (!freighter) loadFreighter().catch(console.error)
    if (!show) return
    if (!movements.length) {
      handleConclude().catch(console.error)
      return
    }
    if (isPrinting === undefined) {
      setPrinting(
        handler.packingLists !== undefined && handler.packingLists.length > 0 ? handler.packingLists[0].printing === PrintingType.printing : false
      )
    }
    if (!splitCollos.length) {
      packingModalController.show({ quantity: 1 })
    }
  }, [show])

  useEffect(() => {
    if (!show) return
    setPrinting(!!handler.packingLists?.length && handler.packingLists[0].printing === PrintingType.printing)
  }, [show])

  function handleColloPressed(collo: SplitCollo | undefined) {
    if (collo) {
      selectedCollo.current = collo.id
      packingModalController.show(collo?.packing)
      return
    }
    selectedCollo.current = null
    const anyCollo = splitCollos.find(s => s.packing?.packagingType)
    packingModalController.show({ ...anyCollo?.packing, quantity: 1 })
  }

  function handlePackingEndEdit(packing: ColloPackingSelection) {
    setSplitCollos(prev => {
      if (selectedCollo.current !== null) {
        const collo = prev.find(q => q.id === selectedCollo.current)
        if (collo) collo.packing = { ...packing }
        return [...prev]
      }
      if (!packing.quantity || packing.quantity < 0) return prev
      for (let c = 0; c < packing.quantity; c++) {
        let id = 0
        if (prev.length) id = Math.max(...prev.map(q => q.id)) + 1
        prev.push({ id, packing: { ...packing, quantity: 1 }, number: 0 })
      }

      return [...prev]
    })
  }

  async function handleConclude() {
    await conclude(
      selectedFreighter,
      splitCollos.length,
      weightSum,
      isPrinting,
      isCreateDeliveryNote,
      colloUtils.convertSplitCollosToPacking(splitCollos)
    ).catch(console.error)
  }

  if (!show) return <></>

  return (
    <View style={{ flex: 1 }}>
      <TextWithIconButton
        icon={'plus'}
        style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        onIconPressed={() => handleColloPressed(undefined)}>
        {Utils.stringValueReplacer(i18n.t('ArticlesAndCollos'), articleCount, splitCollos.length)}
      </TextWithIconButton>
      <FlatList
        data={splitCollos}
        renderItem={({ item }) => (
          <SplitColloCard
            collo={item}
            onPress={() => handleColloPressed(item)}
            onDelete={() => {
              setSplitCollos(prev => {
                prev = prev.filter(q => q.id !== item.id)
                return [...prev]
              })
            }}
          />
        )}
      />
      <Picker
        text={i18n.t('Freighter')}
        defaultValue={selectedFreighter}
        values={freighter ?? []}
        onSubmit={setSelectedFreighter}
        placeHolder={i18n.t('SELECT_PLACEHOLDER')}
        style={styles.freighterInput}
        captionExtractor={item => `${item.code} - ${item.description}`}
        disabled={!!presetFreighter}
      />
      <SwitchWithText
        text={i18n.t('Print_PackingList')}
        value={isPrinting === undefined ? false : isPrinting}
        setValue={setPrinting}
        style={{
          marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
        }}
      />
      {handler.packingLists !== undefined && handler.packingLists.length > 0 && handler.packingLists[0].isPossibleCreateDeliveryNote && (
        <SwitchWithText
          text={i18n.t('CREATEDELIVERYNOTE_CONCLUDE_PACKINGLIST')}
          value={isCreateDeliveryNote === undefined ? false : isCreateDeliveryNote}
          setValue={setIsCreateDeliveryNote}
          style={{
            marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
            marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          }}
          storageKey={StorageKeys.PACKINGLIST_CREATE_DELIVERY_NOTE_SWITCH}
        />
      )}
      <ElusiveButton style={{ marginTop: 0 }} title={i18n.t('ConcludePackingList')} onPress={() => handleConclude().catch} />
      <ColloPackingModal modalTitle={i18n.t('AddCollo')} controller={packingModalController} onConfirm={handlePackingEndEdit} />
    </View>
  )
}

const styles = StyleSheet.create({
  freighterInput: {
    marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
