import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/core'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { SerialNumber } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import ScannerInput from '../../components/MWS/Inputs/ScannerInput'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'

export default function SerialnumberManufacturerNumber(props: { serialnumber: SerialNumber }) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()
  const navigation = useNavigation()
  const article = useArticle(props.serialnumber.article)
  const [selectedManufacturerSN, setSelectedManufacturerSN] = useState<string>('')

  const serialNumberAlreadyAssigned = !!props.serialnumber.numberManufactor

  useEffect(() => {
    if (serialNumberAlreadyAssigned) {
      alert.info(i18n.t('ManufacturerSNAlreadyAssigned'), props.serialnumber.numberManufactor)
    }
  }, [])

  async function checkAndSetManufactorNumber() {
    try {
      const manufactorNumber = selectedManufacturerSN
      loader.setLoading(true)
      const resultManufactorNumberCheck = await api.checkSerialnumberManufactorNumberCheck({
        id: props.serialnumber.id,
        numberManufactor: manufactorNumber,
      })
      if (!resultManufactorNumberCheck) return
      const result = await api.checkSerialnumberChecks({ serialnumberManufactor: manufactorNumber })
      if (!result.isOk) {
        alert.error(result.warning)
        return
      }
      if (result.warning !== '') {
        const resultQuestion = await alert.yesNo(result.warning)
        if (!resultQuestion) return
      }
      await api.patchSerialnumber({ id: props.serialnumber.id, numberManufactor: manufactorNumber })
      alert.info(i18n.t('ManufacturerSNAssigned'))
      navigation.navigate(ScreenType.SerialnumberSelection, {})
    } catch (error) {
      loader.setLoading(false)
      alert.error(Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <View>
      <View>{article && <ArticleCard article={article.info} serialNumber={props.serialnumber} />}</View>
      {!serialNumberAlreadyAssigned && (
        <View>
          {!selectedManufacturerSN && (
            <ScannerInput
              style={styles.input}
              placeHolder={i18n.t('SelectManufacturerSerialnumber')}
              useInput={input => setSelectedManufacturerSN(input.scannerInput ?? input.textInput ?? '')}
            />
          )}
          {!!selectedManufacturerSN && (
            <View style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}>
              <SerialnumberCard
                serialnumber={selectedManufacturerSN}
                showInfoTexts
                hideManufacturer
                infoText={i18n.t('ManufacturerSN')}
                onDelete={() => setSelectedManufacturerSN('')}
              />
              <SubmitButton onPress={() => checkAndSetManufactorNumber()} title={i18n.t('AssignManufacturerSN')} />
            </View>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  infoView: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  input: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
