import { useLanguage } from '@infominds/react-native-components'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Collo, CustomerDeliveryAddress } from '../../apis/apiTypes'
import ColloNumbersForFinalPickingCard from '../../cards/Packinglist/CollonumbersForFinalPickingCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import IMText from '../../components/IMText'
import ScannerInput, { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useAlert from '../../hooks/useAlert'
import useItemSelector from '../../hooks/useItemSelector'
import useRouteParam from '../../hooks/useRouteParam'
import colloUtils from '../../utils/colloUtils'
import { Error_Utils } from '../../utils/ErrorUtils'

export default function ColloNumbersForFinalPickingScreen() {
  const customerDeliveryAddress = useRouteParam<CustomerDeliveryAddress>('customerDeliveryAddress')
  const { i18n } = useLanguage()
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  const collos = useItemSelector<Collo>([])
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  useEffect(() => {
    if (!isFocused) return
    return load()
  }, [isFocused])

  function load() {
    let aborted = false
    setLoading(true)
    api
      .getColloNumberForFinalPicking({
        CustomerId: customerDeliveryAddress?.customerId,
        DeliveryAddressId: customerDeliveryAddress?.deliveryaddressId,
      })
      .then(result => {
        if (!result || aborted) return
        result.sort(colloUtils.sortForShipping)
        collos.set(result)
        setLoading(false)
      })
      .catch(() => {
        if (aborted) return
        setLoading(false)
      })
    return () => {
      aborted = true
    }
  }

  function setColloNumberShippingData(colloNumber: string) {
    let aborted = false
    setLoading(true)
    api
      .postColloNumberShippingData({
        colloNumber: colloNumber,
      })
      .then(result => {
        if (!result || aborted) return
        load()
      })
      .catch(() => {
        if (aborted) return
        load()
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      aborted = true
    }
  }

  async function complete() {
    if (!collos) return
    const createDeliveryNote = await alert.yesNo(i18n.t('CreateBillsOfDelivery'))
    try {
      setLoading(true)
      const result = await api.postPackingListShipping({
        customerId: customerDeliveryAddress?.customerId,
        deliveryAddressId: customerDeliveryAddress?.deliveryaddressId,
        createDeliveryNote: createDeliveryNote,
      })
      if (!result) return
      navigation.goBack()
    } catch (error) {
      setLoading(false)
      alert.error(i18n.t('FailedToShippingPackinglist'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      setLoading(false)
    }
  }

  function handleInput(input: ScannerInputResult) {
    const colloNumber = input.textInput ?? input.scannerInput ?? ''
    if (!collos.items.find(q => q.number === colloNumber)) {
      alert.error(`${i18n.t('Collo')}: ${colloNumber} ${i18n.t('NotFound')}`)
      return
    }
    if (!loading) setColloNumberShippingData(colloNumber)
  }

  return (
    <MWS_Screen>
      <IMText primary style={{ textAlign: 'center' }}>
        {customerDeliveryAddress?.customer}
      </IMText>
      <IMText style={{ textAlign: 'center' }}>{customerDeliveryAddress?.deliveryaddress}</IMText>
      <IMText primary style={{ textAlign: 'center' }}>
        {i18n.t('Collo')}
      </IMText>
      <ScannerInput
        useInput={function (input: ScannerInputResult): void {
          handleInput(input)
        }}
      />
      <View style={styles.infoView}>
        <FlatList
          data={collos.items}
          renderItem={({ item }) => <ColloNumbersForFinalPickingCard item={item} />}
          refreshControl={<IMRefreshControl refreshing={loading} onRefresh={load} />}
        />
      </View>
      <SubmitButton hide={!collos.any} hideFromKeyBoard title={i18n.t('Complete')} onPress={complete} />
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  infoView: {
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    flex: 1,
  },
  input: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  cards: { margin: 0, marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN, marginBottom: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS },
})
