import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { KanbanBox } from '../../types'
import ArticleCard, { ArticleCardProps } from '../Article/ArticleCard'

export type KanbanBoxCardProps = {
  box: KanbanBox | undefined
} & Omit<ArticleCardProps, 'article' | 'stockPosition'>

export default function KanbanBoxCard({ box, ...props }: KanbanBoxCardProps) {
  const { i18n } = useLanguage()

  const quantityText = useMemo(() => box?.article.getQuantityWithUnitText(), [box?.article.quantity, box?.article.useUnit])
  const minQuantityText = useMemo(
    () =>
      box?.minQuantityStockPosition
        ? `${i18n.t('MIN_INVENTORY_QUANTITY')}: ${box?.article.getQuantityWithUnitText(box?.minQuantityStockPosition)}`
        : undefined,
    [box?.minQuantityStockPosition, box?.article.useUnit]
  )

  const suggestStockposition = useMemo(
    () =>
      box?.stockPositionQuantityForKanbanTransfer
        ? `${i18n.t('SUGGESTION')} ${i18n.t('StockPosition')}: ${box?.stockPositionQuantityForKanbanTransfer?.stockposition?.code ?? ''}`
        : '',
    [box?.stockPositionQuantityForKanbanTransfer]
  )

  if (!box) return <></>
  return (
    <ArticleCard
      article={box.article.info}
      stockPosition={box.stockPositionProduction}
      quantityText={quantityText}
      addInfo={`${minQuantityText ?? ''}\n${suggestStockposition}`}
      maxLinesDescription={1}
      {...props}
    />
  )
}
