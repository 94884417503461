import { useState } from 'react'

import api from '../../apis/apiCalls'
import { ArticleDTO, ArticleMeasurementUnit } from '../../apis/apiTypes'
import { articleUtils } from '../../utils/articleUtils'

export type MeasurementUnitNetWeightInputHandler = {
  required: boolean
  unit: ArticleMeasurementUnit | undefined
  setInput: React.Dispatch<React.SetStateAction<number>>
  input: number
  ok: boolean
  patch: () => Promise<boolean>
}

export default function useMeasurementUnitNetWeightInputHandler(
  article: ArticleDTO | undefined,
  required: boolean,
  unit: ArticleMeasurementUnit | undefined
): MeasurementUnitNetWeightInputHandler {
  const [input, setInput] = useState(unit?.netWeight ?? 0)
  const isRequired = required && !!unit

  async function patchWeight() {
    if (!article || !unit) throw new Error('Invalid Arguments')
    const result = await api.patchArticleMeasurementUnit({ articleId: article?.id, unitId: unit?.id, netWeight: input })
    return !!result
  }

  return {
    required: isRequired,
    unit,
    input,
    setInput,
    ok: !isRequired || !!articleUtils.quantityValidator(input),
    patch: patchWeight,
  }
}
