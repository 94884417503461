import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import ModalBottom from './ModalBottom'
import ModalCloseButton from './ModalCloseButton'

export default function ContentPopupModal(props: { isVisible: boolean; close: () => void; content: ReactNode; style?: StyleProp<ViewStyle> }) {
  return (
    <ModalBottom
      isVisible={props.isVisible}
      close={props.close}
      backdropOpacity={0.4}
      style={[
        {
          padding: 0,
          // boarderRadisTop: 8,
          width: '96%',
          marginLeft: '2%',
        },
        props.style,
      ]}
      propagateSwipe
      keyboardAvoiding={false}
      swipeDirection={[]}
      content={
        <View style={{}}>
          <View style={{ padding: 20, paddingBottom: 0 }}>
            <ModalCloseButton onPress={props.close} />
          </View>
          {props.content}
        </View>
      }
    />
  )
}
