import React from 'react'
import { StyleSheet, View } from 'react-native'

import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { IMLayout } from '../../../constants/Styles'
import { QualityCharacteristicInputProps } from '../../../views/QualityManagement/QualityCharacteristicInput'
import Switch from '../../Switch'
import QualityCharacteristicTitle from './QualityCharacteristicTitle'

export default function QualityControlLogicInput({ characteristic, value, setValue, validationResult }: QualityCharacteristicInputProps) {
  return (
    <View style={IMLayout.flex.row}>
      <QualityCharacteristicTitle characteristic={characteristic} style={styles.titleBoolean} validationResult={validationResult} />

      <View style={IMLayout.center}>
        <Switch value={!!value} onValueChange={setValue} offColor={Colors.light.textDetail} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleBoolean: {
    flex: 1,
    marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS,
  },
})
