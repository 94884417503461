import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { useToast } from 'react-native-toast-notifications'

import api from '../apis/apiCalls'
import { Collo, Deposit } from '../apis/apiTypes'
import ColloCard from '../cards/Collo/ColloCard'
import FullScreenModal from '../components/FullScreenModal'
import SubmitButton from '../components/SubmitButton'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../contexts/UserSettingsContext'
import useAlert from '../hooks/useAlert'
import { ModalController } from '../hooks/useModalController'
import { Error_Utils } from '../utils/ErrorUtils'
import { Utils } from '../utils/Utils'
import ColloPackingView, { ColloPackingSelection } from '../views/PackingList/ColloPackingView'

export default function ColloCompletionModal(props: {
  controller: ModalController<Collo[]>
  onCompleted: () => void
  onAborted?: () => void
  deposit?: Deposit
}) {
  const { i18n } = useLanguage()

  const alert = useAlert()
  const loader = useLoadingIndicator()
  const toast = useToast()
  const userSettings = useUserSettings()
  const collosToComplete = useMemo(() => [...(props.controller.data ?? [])], [props.controller.isShown])

  const [colloIndex, setColloIndex] = useState(0)
  const collo = collosToComplete.length > 0 && colloIndex < collosToComplete.length ? collosToComplete[colloIndex] : undefined

  const [colloPacking, setColloPacking] = useState<ColloPackingSelection>({})

  useEffect(() => {
    if (!props.controller.isShown) {
      setColloPacking({})
      return
    }
    const colloHasDimensions = collo?.length || collo?.weight || collo?.height
    if (colloHasDimensions) {
      setColloPacking(prev => ({ ...prev, length: collo?.length, width: collo?.width, height: collo?.height }))
    }
  }, [props.controller.isShown])

  useEffect(() => {
    if (!props.controller.isShown) return
    if (!collo) {
      props.controller.close()
      props.onCompleted()
      return
    }

    api
      .getColloWeight({ number: collo?.number ?? '' })
      .then(value => {
        setColloPacking(prev => ({ ...prev, weight: Math.max(Utils.roundToPrecision(value, 4), 0) }))
      })
      .catch(console.error)
  }, [props.controller.isShown, colloIndex])

  async function concludeCollo() {
    try {
      loader.setLoading(true)
      await api.concludeCollo({
        number: collo?.number ?? '',
        packagingTypeId: colloPacking.packagingType?.id ?? '',
        weight: colloPacking.weight,
        height: colloPacking.height,
        width: colloPacking.width,
        length: colloPacking.length,
        collaboratorId: userSettings?.employeeId,
        stockpositionId: colloPacking.targetStockPositions?.id,
      })
      loader.setLoading(false)
      setNextColloActive()
      toast.show(i18n.t('ConcludeColloSuccessful'))
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToConcludeCollo'), collo?.number, Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
    }
  }

  function setNextColloActive() {
    setColloIndex(prev => prev + 1)
  }

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
        if (props.onAborted) props.onAborted()
      }}
      title={i18n.t('ConcludeCollo')}>
      <ColloCard collo={collo} style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }} />

      <ColloPackingView
        style={{ flex: 1 }}
        colloPacking={colloPacking}
        setColloPacking={setColloPacking}
        deposit={props.deposit}
        stockPositionSelectionActive={userSettings?.isTransferPackinglistcollonumberActive}
      />

      <SubmitButton
        hideFromKeyBoard
        disabled={!colloPacking?.packagingType || !!loader.loading}
        title={i18n.t('ConcludeCollo')}
        onPress={concludeCollo}
      />
    </FullScreenModal>
  )
}
