import { Menu } from '../apis/apiTypes'
import { menuConfig } from '../constants/Config'
import { MenuActionType, MenuCollection, ScreenType } from '../types'
import { Utils } from './Utils'

const menuUtils = {
  getConfig(menuType: MenuActionType) {
    return menuConfig.find(m => m.type === menuType)
  },
  getIcon(menuType: MenuActionType) {
    return this.getConfig(menuType)?.icon || 'notDef'
  },
  getNavigationTarget(menu: MenuCollection) {
    if (!menu.subMenus || menu.subMenus.length <= 1) return this.getConfig(menu.menu.actionType)?.navigationTarget
    return ScreenType.SubMenu
  },
  getPresetData(menuType: MenuActionType) {
    return this.getConfig(menuType)?.presetData
  },
  getMenuCollection(menus: Menu[] | undefined) {
    if (!menus) return []
    //Filter non-implemented menu entries
    const filteredMenus = menus.filter(m => !!menuConfig.find(mc => mc.type === m.actionType))

    // show warnings for not implemented menus
    menus
      .filter(m => !filteredMenus.includes(m))
      .forEach(missingMenu => {
        console.debug('!! Found not implemented Menu type', missingMenu.actionType, ' !!')
      })

    return Utils.keepUniques(filteredMenus, menu => menu.group || menu.actionType + menu.title).map<MenuCollection>(menu => ({
      menu,
      subMenus: menu.group ? filteredMenus.filter(q => (!menu.group && menu.title === q.title) || q.group === menu.group) : undefined,
    }))
  },
}

export default menuUtils
