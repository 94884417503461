import React from 'react'
import { StyleSheet, View } from 'react-native'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { IMIconButton } from './IMIconButton'
import { IMIconName } from './IMIconNames'

export type FilterSortButtonsProps = {
  onFilterPressed: () => void
  onSortPressed: () => void
  sortIcon?: IMIconName
}

export default function FilterSortButtons({ onFilterPressed, onSortPressed, sortIcon }: FilterSortButtonsProps) {
  return (
    <View style={styles.main}>
      <IMIconButton
        style={[styles.filterButton, { backgroundColor: Colors.tint }]}
        iconColor={Colors.white}
        icon={'filter'}
        onPress={onFilterPressed}
      />
      <IMIconButton
        style={[styles.sortButton, { backgroundColor: Colors.tint }]}
        iconColor={Colors.white}
        icon={sortIcon ?? 'sort'}
        onPress={onSortPressed}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  filterButton: {
    padding: 10,
    borderTopLeftRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderBottomLeftRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    justifyContent: 'center',
  },
  sortButton: {
    marginLeft: 1,
    padding: 10,
    borderTopRightRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderBottomRightRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    justifyContent: 'center',
  },
  icon: {},
})
