import { IMLayout, InfomindsColors, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { RefreshControl, ScrollView, StyleProp, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { ItemStockPositionQuantity } from '../../apis/apiTypes'
import ItemStockPositionQuantityCard from '../../cards/StockPosition/ItemStockPositionQuantityCard'
import FullScreenModal from '../../components/FullScreenModal'
import { IMIconName } from '../../components/IMIconNames'
import ListSpacer from '../../components/MWS/ListSpacer'

export default function StockPositionItemQuantitiesSelectionView(props: {
  show: boolean
  depositId?: string
  stockPositionId?: string
  showNegativeQuantities?: boolean
  loadOngoingInventoryItems?: boolean
  ongoingInventoryDate?: string
  style?: StyleProp<ViewStyle>
  clearQuantity?: boolean
  onItemsSelected: (items: ItemStockPositionQuantity[]) => void
}) {
  const [items, setItems] = useState<ItemStockPositionQuantity[]>([])
  const [loading, setLoading] = useState(false)
  const { i18n } = useLanguage()

  useEffect(load, [props.show])

  function load() {
    setLoading(true)
    setItems([])
    loadStockPositionItemQuantities()
      .then(result => {
        if (!result?.length) return
        if (props.clearQuantity) {
          result.forEach(element => {
            element.quantity = undefined
          })
        }
        setItems(result)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  async function loadStockPositionItemQuantities(): Promise<ItemStockPositionQuantity[]> {
    if (!props.show || !props.depositId) return []
    if (props.loadOngoingInventoryItems) {
      const data = await api.getOngoingInventories({ stockpositionId: props.stockPositionId, toConfirm: true, date: props.ongoingInventoryDate })
      if (data) {
        // Filtering and mapping
        const dataFiltered = data.filter(s => s.date === props.ongoingInventoryDate && s.article)
        return dataFiltered.reduce<ItemStockPositionQuantity[]>((result, article) => {
          if (article.serialnumbers?.length) {
            article.serialnumbers.forEach(sn => {
              result.push({
                article: article.article,
                serialNumber: sn,
                stockPosition: article.stockposition,
                lastOngoingInventoryDate: article.date,
              })
            })
            return result
          }
          result.push({
            article: article.article,
            stockPosition: article.stockposition,
            lastOngoingInventoryDate: article.date,
          })
          return result
        }, [])
      }
      return []
    }
    return await api.getItemStockPositionQuantities({
      depositId: props.depositId,
      stockPositionId: props.stockPositionId,
      showNegativeQuantities: props.showNegativeQuantities,
    })
  }

  function itemQuantityConfirm(item: ItemStockPositionQuantity, quantity?: number) {
    setItems(prev => {
      const foundItem = prev.find(e => e === item)
      if (foundItem) foundItem.quantity = quantity
      return [...prev]
    })
  }

  if (!props.show) return <></>

  return (
    <FullScreenModal
      isVisible={props.show}
      close={() => props.onItemsSelected([])}
      title={i18n.t('INSERTQUANTITY')}
      closeIcon={'check' as IMIconName}
      onClose={() => props.onItemsSelected(items.filter(i => i.quantity !== undefined))}
      noTopPadding>
      <ScrollView
        style={IMLayout.flex.f1}
        keyboardShouldPersistTaps={'handled'}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={load} colors={[InfomindsColors.main]} />}>
        {!loading && (
          <>
            <ListSpacer />
            {items.map(item => (
              <ItemStockPositionQuantityCard
                itemStockPositionQuantity={item}
                key={`ItemStockPositionQuantityCard${item.article?.id ?? ''}-${item.serialNumber?.id ?? ''}`}
                onQuantityChanged={q => itemQuantityConfirm(item, q)}
              />
            ))}
            <ListSpacer />
          </>
        )}
      </ScrollView>
    </FullScreenModal>
  )
}
