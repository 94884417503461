import { useModalController, useTheme } from '@infominds/react-native-components'
import React, { useRef } from 'react'
import { StyleProp, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native'

import { Colors } from '../../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../../constants/Constants'
import { Utils } from '../../../../utils/Utils'
import BaseTextInput from '../../../BaseTextInput'
import IMIcon from '../../../IMIcon'
import IMText from '../../../IMText'
import CardBasic from '../../../old/CardBasic'
import DatePicker from './DatePicker'

interface DateSelectorProps {
  value?: Date
  onValueChanged: (value: Date | undefined) => void
  text?: string
  style?: StyleProp<ViewStyle>
  placeHolder?: string
}

export default function DateSelector(props: DateSelectorProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const inputRef = useRef<TextInput>(null)

  const controller = useModalController()

  return (
    <View style={[styles.main, props.style]}>
      <TouchableOpacity onPress={() => controller.show()}>
        <View style={{ flexDirection: 'row' }}>
          <CardBasic style={{ width: undefined, margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}>
            <IMIcon
              style={{ justifyContent: 'center' }}
              size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
              icon="calendar-days"
              color={theme.textDetail}
            />
            {props.text && (
              <IMText numberOfLines={1} style={{ textAlign: 'center' }}>
                {props.text}
              </IMText>
            )}
          </CardBasic>
          <BaseTextInput
            inputRef={inputRef}
            viewStyle={[{ flex: 2 }, styles.noBorderLeft]}
            style={{ textAlign: 'center' }}
            placeholder={props.placeHolder ?? ''}
            value={Utils.FormatDate(props.value, 'DD.MM.YYYY')}
            editable={false}
            onClearPressed={() => {
              props.onValueChanged(undefined)
            }}
          />
        </View>
      </TouchableOpacity>
      <DatePicker show={controller.isShown} close={controller.close} setDate={props.onValueChanged} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
})
