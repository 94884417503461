import { useMemo } from 'react'

import { Item, StockTemplate, UserSettings } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import { useTransfer } from '../../contexts/TransferContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { articleUtils } from '../../utils/articleUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import transferUtils from '../../utils/transferUtils'
import { Utils } from '../../utils/Utils'
import { ArticleAmountSelectionResult } from '../../views/Article/ArticleAmountSelectorV2'
import useItemSelector, { ItemSelector } from '../useItemSelector'
import useRouteParam from '../useRouteParam'
import useIsStockReceiptTransfer from './useIsStockReceiptTransfer'
import useMeasurementUnitNetWeightInputHandler, { MeasurementUnitNetWeightInputHandler } from './useMeasurementUnitNetWeightInputHandler'
import useTransferPositionHandler, { TransferPositionHandler } from './useTransferPositionHandler'

export interface TransferHandler {
  userSettings: UserSettings | undefined
  stockId: string | undefined
  article: Article | undefined
  item: Item | undefined
  quantity: number
  isSerialNumberActive: boolean
  stockTemplate: StockTemplate | undefined
  articleQuantities: ItemSelector<ArticleAmountSelectionResult>
  showTargetSuggestions?: boolean
  sourcePositionHandler: TransferPositionHandler
  targetPositionHandler: TransferPositionHandler
  weightInputHandler: MeasurementUnitNetWeightInputHandler
}

export default function useTransferHandler(): TransferHandler {
  const userSettings = useUserSettings()
  const item = useRouteParam<Item>('item')
  const {
    savedDeposit: savedDepositSource,
    savedDepositTarget,
    savedStockPosition: savedStockPositionSource,
    savedStockPositionTarget,
  } = useTransfer()
  const stockTemplate = useRouteParam<StockTemplate>('stockTemplate')
  const stockId = useRouteParam<string>('stockId')
  const article = useRouteParam<Article>('article')
  const isStockReceiptTransfer = useIsStockReceiptTransfer()
  const selectedQuantities = useRouteParam<ArticleAmountSelectionResult[]>('selectedQuantities')
  const articleQuantities = useItemSelector<ArticleAmountSelectionResult>(
    selectedQuantities?.sort((a, b) => serialnumberUtils.sort(a.serialNumber, b.serialNumber))
  )
  const depositSource = transferUtils.getDeposit(stockTemplate, savedDepositSource)
  const depositTarget = transferUtils.getDepositTarget(stockTemplate, savedDepositTarget)
  const stockPositionSource = transferUtils.getStockPosition(stockTemplate, savedStockPositionSource)
  const stockPositionTarget = transferUtils.getStockPositionTarget(stockTemplate, savedStockPositionTarget)
  const quantity = useMemo(() => Utils.sum(articleQuantities.items, q => q.quantity), [articleQuantities.items])
  const isSerialNumberActive = useMemo(() => articleUtils.isSerialNumberActive(item?.article, undefined, stockTemplate?.isVerifySerialNumber), [])
  const sourcePositionHandler = useTransferPositionHandler({
    isSource: true,
    inactive: !!stockTemplate?.isIncoming,
    positionRequired: !isSerialNumberActive,
    quantityRequired: !isSerialNumberActive && !quantity,
    presetPosition: {
      deposit: depositSource,
      depositFromTemplate: !!stockTemplate?.deposit,
      stockPosition: stockPositionSource,
      stockPositionFromTemplate: !!stockTemplate?.stockposition,
    },
    presetQuantity: isStockReceiptTransfer && !isSerialNumberActive ? article?.getQuantity() : undefined,
  })
  const targetPositionHandler = useTransferPositionHandler({
    isSource: false,
    inactive: !!stockTemplate?.isOutgoing,
    positionRequired: true,
    quantityRequired: !isSerialNumberActive && !quantity && !!stockTemplate?.isIncoming,
    presetPosition: stockTemplate?.isIncoming
      ? {
          deposit: depositSource,
          depositFromTemplate: !!stockTemplate?.deposit,
          stockPosition: stockPositionSource,
          stockPositionFromTemplate: !!stockTemplate?.stockposition,
        }
      : {
          deposit: depositTarget,
          depositFromTemplate: !!stockTemplate?.depositTarget,
          stockPosition: stockPositionTarget,
          stockPositionFromTemplate: !!stockTemplate?.stockpositionTarget,
        },
  })
  //show Suggestions if:
  //1. its a stockReceiptTransfer (Wareneingang)
  //2. bit isStockpositionTargetFixed is set and a depositTarget is given
  //3. bit isStockpositionTargetFixed is set and a deposit is given and its a incoming transaction
  const showTargetSuggestions =
    isStockReceiptTransfer ||
    (!!depositTarget &&
      !!depositTarget?.isStockpositionActive &&
      !stockTemplate?.stockpositionTarget &&
      !!stockTemplate?.isStockpositionTargetFixed) ||
    (!!stockTemplate?.isIncoming && !!depositSource && !!depositSource?.isStockpositionActive && !!stockTemplate.isStockpositionFixed)

  // show net weight input if userSetting is active and unit exists but does not have a valid net weight
  const weightInputHandler = useMeasurementUnitNetWeightInputHandler(
    article?.info,
    !!userSettings?.isArticleweightMandatoryAtGoodsreceiptWarehouse &&
      isStockReceiptTransfer &&
      !!article?.purchasingUnit &&
      !article.purchasingUnit.netWeight,
    article?.purchasingUnit
  )

  return {
    userSettings,
    stockId,
    article,
    item,
    quantity,
    isSerialNumberActive,
    stockTemplate,
    articleQuantities,
    showTargetSuggestions,
    sourcePositionHandler,
    targetPositionHandler,
    weightInputHandler,
  }
}
