import React from 'react'
import DarkModeToggle from 'react-dark-mode-toggle'
import type { Switch as DefaultSwitch } from 'react-native'

type Props = {
  isEnabled?: boolean
  onValueChange: () => void
  value?: boolean
}

export type SwitchDarkModeProps = Props & Omit<DefaultSwitch['props'], 'onChange'>

export default function SwitchDarkMode(props: SwitchDarkModeProps) {
  const { ...switchProps } = props

  return <DarkModeToggle onChange={() => props.onValueChange()} checked={props.value} size={60} {...switchProps} />
}
