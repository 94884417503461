import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Deposit, StockPosition, StockTemplate } from '../../apis/apiTypes'
import MWS_BaseCard from '../../cards/MWS_BaseCard'
import PositionCard from '../../cards/StockPosition/PositionCard'
import IMIcon from '../../components/IMIcon'
import { IMIconName } from '../../components/IMIconNames'
import Separator from '../../components/Separator'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useTransfer } from '../../contexts/TransferContext'
import { ScreenType } from '../../types'
import transferUtils from '../../utils/transferUtils'
import TransferHistoryView from '../Article/TransferHistoryView'

export default function TransferInfoView(props: { stockTemplate: StockTemplate | undefined; showHistory?: boolean; style?: StyleProp<ViewStyle> }) {
  const { savedDeposit: savedDepositSource, savedDepositTarget, savedStockPosition: savedStockPositionSource } = useTransfer()
  const depositSource = transferUtils.getDeposit(props.stockTemplate, savedDepositSource)
  const depositTarget = transferUtils.getDepositTarget(props.stockTemplate, savedDepositTarget)
  const stockPositionSource = transferUtils.getStockPosition(props.stockTemplate, savedStockPositionSource)
  const showPositions = depositSource || depositTarget
  const canDeletePosition = !props.stockTemplate?.isIncoming && !props.stockTemplate?.deposit
  const canDeletePositionTarget = !props.stockTemplate?.depositTarget

  const transferContext = useTransfer()

  function onDeletePosition() {
    if (transferContext.savedStockPosition) transferContext.setSavedStockPosition(undefined)
    else transferContext.setSavedDeposit(undefined)
  }
  function onDeletePositionTarget() {
    if (transferContext.savedStockPositionTarget) transferContext.setSavedStockPositionTarget(undefined)
    else transferContext.setSavedDepositTarget(undefined)
  }

  if (!showPositions && (!transferContext.stockAccounts?.length || !props.showHistory)) return <></>

  return (
    <View>
      {!!showPositions && (
        <View style={[{ flexDirection: 'row' }, props.style]}>
          <Card
            show={!props.stockTemplate?.isIncoming}
            showDummy={!depositSource}
            deposit={depositSource}
            stockPosition={stockPositionSource}
            onDelete={canDeletePosition ? onDeletePosition : undefined}
            style={{ marginLeft: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
          />
          <Icon stockTemplate={props.stockTemplate} />
          <RightCard stockTemplate={props.stockTemplate} onDelete={canDeletePositionTarget ? onDeletePositionTarget : undefined} />
        </View>
      )}
      {props.showHistory && <TransferHistoryView style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }} />}
      <Separator />
    </View>
  )
}

function Icon(props: { stockTemplate: StockTemplate | undefined }) {
  function getIcon(): IMIconName {
    if (props.stockTemplate?.isOutgoing) return 'arrow-right-to-bracket'
    if (props.stockTemplate?.isIncoming) return 'arrow-right-from-bracket'
    return 'arrow-right'
  }

  return (
    <IMIcon
      style={{ justifyContent: 'center', marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
      icon={getIcon()}
      size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
    />
  )
}

function RightCard(props: { stockTemplate: StockTemplate | undefined; onDelete?: () => void }) {
  const {
    savedDeposit: savedDepositSource,
    savedDepositTarget,
    savedStockPosition: savedStockPositionSource,
    savedStockPositionTarget,
  } = useTransfer()
  const deposit = props.stockTemplate?.isIncoming
    ? transferUtils.getDeposit(props.stockTemplate, savedDepositSource)
    : transferUtils.getDepositTarget(props.stockTemplate, savedDepositTarget)
  const stockPosition = props.stockTemplate?.isIncoming
    ? transferUtils.getStockPosition(props.stockTemplate, savedStockPositionSource)
    : transferUtils.getStockPositionTarget(props.stockTemplate, savedStockPositionTarget)

  return (
    <Card
      show={!props.stockTemplate?.isOutgoing}
      showDummy={!deposit}
      deposit={deposit}
      stockPosition={stockPosition}
      onDelete={props.onDelete}
      style={{ marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
    />
  )
}

function Card(props: {
  show: boolean
  showDummy: boolean
  deposit: Deposit | undefined
  stockPosition: StockPosition | undefined
  onDelete?: () => void
  style?: StyleProp<ViewStyle>
}) {
  const navigation = useNavigation()

  if (!props.show) return <></>
  if (props.showDummy) {
    return (
      <View style={[{ flex: 0.5 }, props.style]}>
        <DummyCard />
      </View>
    )
  }

  function getOnPressEvent() {
    if (!props.deposit || !props.stockPosition) return undefined
    // @ts-ignore todo
    return () => navigation.navigate(ScreenType.StockPositionInfo, { stockPosition: props.stockPosition })
  }

  return (
    <View style={[{ flex: 1 }, props.style]}>
      <PositionCard
        deposit={props.deposit}
        stockPosition={props.stockPosition}
        cardStyle={{ marginHorizontal: 0 }}
        style={{ marginHorizontal: 0 }}
        onPress={getOnPressEvent()}
        onDelete={props.onDelete}
        liteMode={true}
      />
    </View>
  )
}

function DummyCard() {
  return <MWS_BaseCard icon="warehouse-full" cardStyle={{ marginHorizontal: 0 }} />
}
