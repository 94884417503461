import { useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import ElusiveButton, { ElusiveButtonProps } from './ElusiveButton'

export type SubmitButtonProps = {
  busy?: boolean
  onPress: () => Promise<void>
} & Omit<ElusiveButtonProps, 'onPress'>

export default function SubmitButton({ title, busy, onPress, disabled, ...otherProps }: SubmitButtonProps) {
  const { i18n } = useLanguage()

  const [submitBusy, setSubmitBusy] = useState(false)

  function handleOnPress() {
    setSubmitBusy(true)
    onPress()
      .catch(console.error)
      .finally(() => setSubmitBusy(false))
  }

  return (
    <ElusiveButton
      style={styles.button}
      title={title ?? i18n.t('Submit')}
      disabled={!!disabled || busy || submitBusy}
      onPress={handleOnPress}
      {...otherProps}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
})
