import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Deposit } from '../../apis/apiTypes'
import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { IMLayout } from '../../constants/Styles'
import MWS_BaseCard, { MWSBaseCardEndIconButtonProps } from '../MWS_BaseCard'

export default function DepositCard(props: {
  deposit: Deposit | undefined
  style?: StyleProp<ViewStyle>
  onPress?: false | (() => void)
  onDelete?: false | (() => void)
  quantity?: string
  quantityNet?: string
  isMarked?: boolean | IMIconName
  endButton?: MWSBaseCardEndIconButtonProps
  liteMode?: boolean
}) {
  if (!props.deposit) return <></>

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={'warehouse-full'}
      isHiddenIcon={props.liteMode}
      cardStyle={props.style}
      style={[{ flexDirection: 'row' }]}
      isMarked={props.isMarked}
      endButton={props.endButton}>
      <View style={IMLayout.flex.f1}>
        {!props.liteMode && (
          <IMText numberOfLines={1} primary style={IMLayout.flex.row}>
            {props.deposit.code?.trim()}
          </IMText>
        )}
        {!!props.liteMode && (
          <IMTextWithIcon icon="warehouse-full" numberOfLines={1} primary style={IMLayout.flex.row} text={props.deposit.code?.trim()} />
        )}
        <IMText numberOfLines={1} secondary style={IMLayout.flex.row}>
          {props.deposit.description?.trim()}
        </IMText>
      </View>
      {props.quantity && (
        <View style={{ justifyContent: 'center' }}>
          <IMText style={{ textAlign: 'right' }}>{props.quantity}</IMText>
          {props.quantityNet && <IMText secondary>{props.quantityNet}</IMText>}
        </View>
      )}
    </MWS_BaseCard>
  )
}
