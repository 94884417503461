import { useIsFocused, useRoute } from '@react-navigation/native'
import { useMemo } from 'react'

/**
 * Gets the parameter with the given name from the route parameters.
 * @param parameter name of the parameter
 * @returns value of type T | undefined
 */
export default function useRouteParam<T>(parameter: string): T | undefined {
  const route = useRoute()
  const isFocused = useIsFocused()

  function getParameter<TObj>(obj: TObj, objectKey: string) {
    if (!obj || !objectKey) return undefined
    return obj[objectKey as keyof TObj] as T | undefined
  }

  return useMemo(() => getParameter(route.params, parameter), [isFocused])
}
