import { MenuConfig, ProductionType, QualityControlMode, ScreenType } from '../types'

export const menuConfig: MenuConfig[] = [
  {
    type: 'PDA_ACCESS_UMBUCHUNG',
    navigationTarget: ScreenType.TransferArticleSelection,
    icon: 'truck-arrow-right',
  },
  {
    type: 'PDA_ACCESS_UMBUCHUNG_WE',
    navigationTarget: ScreenType.TransferArticleSelection,
    icon: 'warehouse-full',
  },
  {
    type: 'PDA_PROD_KOMMISSIONIERUNG',
    navigationTarget: ScreenType.ProductionConsignmentListSelection,
    icon: 'clipboard-check-list',
  },
  {
    type: 'PDA_MPKT_VORKOMMISSIONIERUNG',
    navigationTarget: ScreenType.PreConsignmentListSelection,
    icon: 'clipboard-check-list',
  },
  {
    type: 'PDA_ACCESS_KOMMISSIONIERUNG',
    navigationTarget: ScreenType.PackingListSelection,
    icon: 'clipboard-check-list',
  },
  {
    type: 'PDA_ACCESS_ENDKOMMISSIONIERUNG',
    navigationTarget: ScreenType.CustomerAndDeliveriesForFinalPicking,
    icon: 'truck-ramp-box',
  },
  {
    type: 'PDA_ACCESS_UMBUCHUNGKOLLI',
    navigationTarget: ScreenType.TransferCollo,
    icon: 'hand-holding-box',
  },
  {
    type: 'PDA_ACCESS_WARENKORB',
    navigationTarget: ScreenType.ShoppingCartCustomerSelection,
    icon: 'shopping-cart',
  },
  {
    type: 'PDA_ACCESS_PRODUKTION_EINGANG',
    navigationTarget: ScreenType.ProductionSelection,
    icon: 'conveyor-belt-box',
    presetData: { type: ProductionType.In },
  },
  {
    type: 'PDA_ACCESS_PRODUKTION_AUSGANG',
    navigationTarget: ScreenType.ProductionSelection,
    icon: 'inbox-out',
    presetData: { type: ProductionType.Out },
  },
  {
    type: 'PDA_ACCESS_PRODUKTION_AUSSCHUSS',
    navigationTarget: ScreenType.ProductionSelection,
    icon: 'dumpster',
    presetData: { type: ProductionType.DefectiveMaterial },
  },
  {
    type: 'PDA_ACCESS_PRODUKTION_UMBUCHUNG',
    navigationTarget: ScreenType.ProductionTransferArticle,
    icon: 'truck-arrow-right',
  },
  {
    type: 'PDA_ACCESS_ZUWEISUNG_ANDERE_PRODUKTION',
    navigationTarget: ScreenType.ProductionMoveArticle,
    icon: 'swap',
  },
  {
    type: 'PDA_ACCESS_ERSTELLEN_VORPRODUKTION',
    navigationTarget: ScreenType.ProductionCreatePreProduction,
    icon: 'conveyor-belt-box',
  },
  {
    type: 'PDA_ACCESS_ZUWEISUNG_VORPRODUKTION',
    navigationTarget: ScreenType.ProductionAllocationListSelection,
    icon: 'conveyor-belt-box',
  },
  {
    type: 'PDA_ACCESS_ENTNAHME_PRODUKTION',
    navigationTarget: ScreenType.ProductionExtractionArticle,
    icon: 'cubes-stacked',
  },
  {
    type: 'PDA_ACCESS_ZUORDNUNG_HERSTELLERSERIENNUMMER',
    navigationTarget: ScreenType.SerialnumberSelection,
    icon: 'scanner-gun',
    presetData: { actiontype: '' },
  },
  {
    type: 'PDA_ACCESS_ZUORDNUNG_MACADRESSE',
    navigationTarget: ScreenType.SerialnumberSelection,
    icon: 'laptop-binary',
    presetData: { actionType: 'isOpenMacAddress' },
  },
  {
    type: 'PDA_ACCESS_ZUORDNUNG_ARTIKELBARCODE',
    navigationTarget: ScreenType.ArticleBarcodeArticleSelection,
    icon: 'barcode-read',
  },
  {
    type: 'PDA_ACCESS_ARTIKELDEPOTDATEN',
    navigationTarget: ScreenType.ArticleDepositDataArticleSelection,
    icon: 'warehouse-full',
  },
  {
    type: 'PDA_ACCESS_ZUORDNUNG_ARTIKELGEWICHT',
    navigationTarget: ScreenType.ArticleWeightArticleSelection,
    icon: 'weight-hanging',
  },
  {
    type: 'PDA_ACCESS_BESTELLVORSCHLAG',
    navigationTarget: ScreenType.OrderProposalDepositSelection,
    icon: 'truck-arrow-right',
  },
  {
    type: 'PDA_ACCESS_INVENTAR',
    navigationTarget: ScreenType.InventorySelection,
    icon: 'box-circle-check',
  },
  {
    type: 'PDA_ACCESS_LAUFENDE_INVENTUR',
    navigationTarget: ScreenType.OngoingInventoryCreation,
    icon: 'boxes-stacked',
  },
  {
    type: 'PDA_ACCESS_LAUFENDE_INVENTUR_NACHZAEHLUNG',
    navigationTarget: ScreenType.OngoingInventoryRecounting,
    icon: 'boxes-stacked',
  },
  {
    type: 'PDA_ACCESS_ARTIKELINFO',
    navigationTarget: ScreenType.ArticleSelection,
    icon: 'box-taped',
  },
  {
    type: 'PDA_ACCESS_STELLPLATZINFO',
    navigationTarget: ScreenType.StockPositionSelection,
    icon: 'shelf',
  },
  {
    type: 'PDA_ACCESS_ARBEITSSCHEIN_RUECKMELDEN',
    navigationTarget: ScreenType.WorksheetSelection,
    icon: 'conveyor-belt-box',
  },
  {
    type: 'PDA_ACCESS_QUALITAETSKONTROLLE_SN',
    navigationTarget: ScreenType.QualityManagementSelection,
    icon: 'list-check',
    presetData: { type: QualityControlMode.serialNumber },
  },
  {
    type: 'PDA_ACCESS_QUALITAETSKONTROLLE_PROD',
    navigationTarget: ScreenType.QualityManagementSelection,
    icon: 'list-check',
    presetData: { type: QualityControlMode.production },
  },
  {
    type: 'PDA_ACCESS_UMBUCHUNG_KANBAN',
    navigationTarget: ScreenType.KanbanCreateList,
    icon: 'shelf',
  },
  {
    type: 'PDA_ACCESS_VERLADEN_PAKETE',
    navigationTarget: ScreenType.ParcelSelection,
    icon: 'cart-flatbed-boxes',
  },
]
