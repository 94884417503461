import React, { PropsWithChildren, useContext, useState } from 'react'

import api from '../apis/apiCalls'
import { UserSettings } from '../apis/apiTypes'

interface UserSettingsContextType {
  settings: UserSettings | undefined
  set: (userSettings: UserSettings) => void
  load: () => Promise<UserSettings>
}

export const UserSettingsContext = React.createContext<UserSettingsContextType | null>(null)

export function UserSettingsContextProvider(props: PropsWithChildren) {
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>()

  function load() {
    return new Promise<UserSettings>((resolve, reject) => {
      api
        .getUserSettings()
        .then(result => {
          if (result) {
            setUserSettings(result)
            resolve(result)
          } else {
            reject('Failed to load userSettings')
          }
        })
        .catch(reject)
    })
  }
  return (
    <UserSettingsContext.Provider value={{ settings: userSettings, set: setUserSettings, load: load }}>{props.children}</UserSettingsContext.Provider>
  )
}

export function useUserSettings() {
  return useContext(UserSettingsContext)?.settings
}
