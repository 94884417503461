import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import { useMemo, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { ArticleDTO, Item, ProductionPart, SerialNumber, YesNotQuestionType } from '../../apis/apiTypes'
import ProductionData, { ProductionPartCollection } from '../../classes/ProductionData'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import productionUtils from '../../utils/ProductionUtils'
import { Utils } from '../../utils/Utils'
import { ArticleAmountSelectionResult } from '../../views/Article/ArticleAmountSelectorV2'
import useAlert from '../useAlert'
import useItemSelector, { ItemSelector } from '../useItemSelector'
import useOneTimeCallback from '../useOneTimeCallback'
import useToastMessage from '../useToastMessage'

export enum ProductionMode {
  default = 0, //noProcessMode
  processAmountSelection = 1, //Process (employeeTimeType) Amount selection
  processRestProduction = 2, //Process (employeeTimeType) Rest (serial) production
  lastProductionStep = 3, //Last ProductionStep (after last ProductionStep was scanned)
}

export interface ProductionHandler {
  production: ProductionData | undefined
  productionMode: ProductionMode
  parts: ItemSelector<ProductionPartCollection>
  filteredParts: ProductionPartCollection[]
  selectedPart: ProductionPartCollection | undefined
  employeeTimeType: string | undefined
  producedValue: number | undefined
  preSelectedSerialNumber?: SerialNumber
  setPartQuantities: (part: ProductionPart, quantities: ArticleAmountSelectionResult[]) => void
  addQuantity: (part: ProductionPart, addQuantity: ArticleAmountSelectionResult) => void
  removeSerial: (part: ProductionPart, removeSerial: SerialNumber) => void
  setSelectedPart: (setItem: ProductionPartCollection | undefined, preSelectedSerialNumber?: SerialNumber) => void
  containsItem: (item: Item, filterFinished?: boolean) => ProductionPartCollection | undefined
  findPartByArticle: (article: ArticleDTO) => ProductionPartCollection | undefined
  reloadParts: (useProductionProcess?: boolean) => Promise<ProductionPartCollection[]>
  handleProcessModeInput: (value: number) => Promise<void>
  completeProduction: () => Promise<void>
}

export default function useProductionHandler(productionData: ProductionData | undefined, quantityFromProductionIn?: number): ProductionHandler {
  const toast = useToastMessage({ placement: 'top' })
  const loader = useLoadingIndicator()
  const alert = useAlert()
  const navigation = useNavigation()
  const userSettings = useUserSettings()
  const { i18n } = useLanguage()

  const production = productionData
  const [productionMode, setProductionMode] = useState<ProductionMode>(() =>
    productionData?.employeeTimeTypeId || !!quantityFromProductionIn ? ProductionMode.processAmountSelection : ProductionMode.default
  )
  const parts = useItemSelector<ProductionPartCollection>(productionData?.parts ?? [], (a, b) => a.part.id === b.part.id)
  const [selectedPart, setSelectedPart] = useState<ProductionPartCollection | undefined>(undefined)
  const [preSelectedSerialNumber, setPreSelectedSerialNumber] = useState<SerialNumber | undefined>(undefined)

  const producedValue = useRef<number | undefined>(undefined)
  const filteredParts = sortParts(parts.items.filter(partFilter))
  const employeeTimeType = useMemo(
    () => (production?.employeeTimeTypeId ? parts.items.find(i => !!i.part.employeeTimeType)?.part.employeeTimeType : undefined),
    [productionData?.parts]
  )

  function sortParts(partsToSort: ProductionPartCollection[]) {
    if (production?.isDefectiveMaterialProduction) return partsToSort
    return partsToSort.sort(productionUtils.partSorter)
  }

  function partFilter(item: ProductionPartCollection) {
    return (
      (productionMode !== ProductionMode.lastProductionStep && productionMode !== ProductionMode.processRestProduction) ||
      !!item.article.isSerialNumberActive ||
      !item.autoCompleted
    )
  }

  function setPartQuantities(part: ProductionPart, quantities: ArticleAmountSelectionResult[]) {
    parts.set(prev => {
      const foundPartIndex = prev.findIndex(p => p.part.id === part.id)
      if (foundPartIndex < 0) return prev
      prev[foundPartIndex].selectedQuantities = [...(quantities ?? [])]
      return [...prev]
    })
    showAddedMessage(
      part,
      Utils.sum(quantities, item => item.quantity)
    )
  }

  function addQuantity(part: ProductionPart, quantityToAdd: ArticleAmountSelectionResult) {
    parts.set(prev => {
      const foundPartIndex = prev.findIndex(p => p.part.id === part.id)
      if (foundPartIndex < 0) return prev
      prev[foundPartIndex].selectedQuantities.push(quantityToAdd)
      return [...prev]
    })
    showAddedMessage(part, quantityToAdd.quantity)
  }

  function removeSerial(part: ProductionPart, serialToRemove: SerialNumber) {
    parts.set(prev => {
      const foundPartIndex = prev.findIndex(p => p.part.id === part.id)
      if (foundPartIndex < 0) return prev
      prev[foundPartIndex].selectedQuantities = prev[foundPartIndex].selectedQuantities.filter(q => q.serialNumber?.id !== serialToRemove.id)
      return [...prev]
    })
  }

  function showAddedMessage(part: ProductionPart, quantity: number) {
    if (productionMode === ProductionMode.processAmountSelection || quantity <= 0 || !!selectedPart) return
    toast.show(
      i18n.t('ProductionItemAdded'),
      part.article.code,
      quantity,
      parts.items.find(item => item.part.id === part.id)?.article?.getUsedUnitText()
    )
  }

  function findPartByArticle(article: ArticleDTO) {
    if (!article) return undefined
    return parts.items.find(i => i.article.info.id === article.id)
  }

  function containsItem(item: Item, useFilteredItems?: boolean) {
    if (!item) return undefined
    if (useFilteredItems) return filteredParts.find(i => i.part.article.id === item.article.id)
    return parts.items.find(i => i.part.article.id === item.article.id)
  }

  function reloadParts(filterByProductionProcess?: boolean) {
    return new Promise<ProductionPartCollection[]>((resolve, reject) => {
      production
        ?.loadParts(!!filterByProductionProcess, userSettings)
        .then(() => {
          parts.set(production.parts)
          resolve(production.parts)
        })
        .catch(reject)
    })
  }

  async function completeProduction() {
    try {
      if (!production?.production) {
        return
      }

      const checkResult = await preCompletionCheck()
      if (!checkResult) return

      const request = productionUtils.generateOutgoingPostRequest(production?.production, parts.items, production.isDefectiveMaterialProduction)
      loader.setLoading(true)
      await api.postProductionOutgoing(request)

      if (productionMode === ProductionMode.lastProductionStep) {
        navigateToProductionIn()
      } else {
        toast.show(i18n.t('ProductionCompletedSuccessfully'))
        navigation.goBack()
      }
    } catch (reason) {
      alert.error(i18n.t('ProductionCompletionFailure'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  async function preCompletionCheck() {
    if (productionMode === ProductionMode.default) return true
    const unfinishedParts = parts.items.filter(part => !!part.article.isSerialNumberActive && !productionUtils.isPartFinished(part))
    if (unfinishedParts.length === 0) return true
    return alert.yesNo(i18n.t('ProductionCompleteWithMissingItemsAlert'))
  }

  function navigateToProductionIn() {
    if (!production?.production || !producedValue.current) return
    loader.setLoading(true)
    productionUtils
      .createProductionArticle(production.production, userSettings?.isHistoricalSerialnumberActive)
      .then(result => {
        navigation.navigate(ScreenType.ProductionIn, {
          production: production?.production,
          article: result,
          quantityFromProductionOut: producedValue.current,
        })
      })
      .catch(reason => {
        console.error(reason)
        alert.error(i18n.t('FailedToLoadAvailability'), production.production.article.code)
      })
      .finally(() => loader.setLoading(false))
  }

  useOneTimeCallback(
    () => {
      alert
        .yesNo(i18n.t('AllProductionStepsCompleteAlert'))
        .then(result => {
          if (result) completeProduction().catch(console.error)
        })
        .catch(console.error)
    },
    !loader.loading && !selectedPart && parts.any && filteredParts.reduce((prev, current) => prev && productionUtils.isPartFinished(current), true)
  )

  //handle input done in production-process mode (Arbeitsvorgang)
  async function handleProcessModeInput(value: number) {
    try {
      //check if last production step was scanned
      if (!filteredParts.find(p => !!p.part.isLastWorkStep)) {
        autoCompleteParts(parts.items, value)
        return
      }

      let dialogResult = false
      if (!quantityFromProductionIn) {
        switch (userSettings?.createProductionIncomingAfterOutgoing) {
          case YesNotQuestionType.Question: //ask if incoming transactions should be created
            dialogResult = await alert.yesNo(i18n.t('LastProductionStepScannedAlert'), value)
            break
          case YesNotQuestionType.Yes:
            dialogResult = true
            break
          default:
            dialogResult = false
            break
        }
      }

      //if last production step was scanned and the user wants to create incoming transactions -> reload all parts
      if (dialogResult) {
        loader.setLoading(true)
        const partialProduction = !!production?.production.quantityOpen && production?.production.quantityOpen - value > 0
        const reloadedParts = await reloadParts(partialProduction)
        autoCompleteParts(reloadedParts, value, true)
      } else {
        autoCompleteParts(parts.items, value)
      }
    } catch (exception) {
      console.error(exception)
    }
    loader.setLoading(false)
  }

  // autoComplete parts (only non SN articles and with valid StockPosition are completed)
  function autoCompleteParts(partsToModify: ProductionPartCollection[], quantity: number, lastProductionStep?: boolean) {
    producedValue.current = quantity
    const updatedParts = productionUtils.modifyPartsAfterProcessInput(
      partsToModify,
      quantity,
      production?.production,
      production?.employeeTimeTypeId,
      !production?.stockTemplateOut?.deactivateAvailabilityCheck
    )
    parts.set(updatedParts)
    setProductionMode(lastProductionStep ? ProductionMode.lastProductionStep : ProductionMode.processRestProduction)
  }

  function handlePartSelection(setItem: ProductionPartCollection | undefined, serialToSelect?: SerialNumber) {
    setPreSelectedSerialNumber(setItem ? serialToSelect : undefined)
    setSelectedPart(setItem)
  }

  return {
    production,
    productionMode,
    parts,
    filteredParts,
    selectedPart,
    employeeTimeType,
    producedValue: producedValue.current,
    preSelectedSerialNumber,
    setPartQuantities,
    addQuantity,
    removeSerial,
    setSelectedPart: handlePartSelection,
    containsItem,
    findPartByArticle,
    reloadParts,
    handleProcessModeInput,
    completeProduction,
  }
}
