import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

const IMStyles = StyleSheet.create({
  text: {
    margin: 1,
    textAlignVertical: 'center',
  },
  primaryText: {
    margin: 1,
    textAlignVertical: 'center',
    fontWeight: 'bold',
  },
})

export const IMLayout = {
  flex: {
    f1: { flex: 1 } as StyleProp<ViewStyle>,
    row: { flexDirection: 'row' } as StyleProp<ViewStyle>,
  },
  center: { justifyContent: 'center', alignItems: 'center' } as StyleProp<ViewStyle>,
}

export default IMStyles
