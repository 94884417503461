import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { forwardRef } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import SorterTag from '../../components/filter/SorterTag'
import ListSpacer from '../../components/MWS/ListSpacer'
import useFilter from '../../hooks/filter/useFilter'
import { DataFilterConfigType } from '../../types'
import FilterFilterView from './FilterFilterView'
import FilterOrderView from './FilterOrderView'

function FilterView() {
  const { groups, orders, changeFilterStatus, filters } = useFilter()
  const { i18n } = useLanguage()

  return (
    <ScrollView>
      <ListSpacer />
      {!!groups.length && (
        <IM.View spacing={'horizontal'}>
          <IM.View style={styles.titleContainer}>
            <IM.Icon icon={['fal', 'layer-group']} spacing="right" />
            <IM.Text style={styles.title}>{i18n.t('GROUP_BY')}</IM.Text>
          </IM.View>
          <IM.View style={styles.groupContainer}>
            {groups.map(group => {
              return <SorterTag key={group.id} sorter={group} style={styles.tag} onPress={id => changeFilterStatus(DataFilterConfigType.Group, id)} />
            })}
          </IM.View>
        </IM.View>
      )}

      {!!filters.length && (
        <>
          <IM.View spacing={groups.length === 0 ? 'horizontal' : ['top', 'horizontal']}>
            <IM.View style={styles.titleContainer}>
              <IM.Icon icon={['fal', 'filter']} spacing="right" size={15} />
              <IM.Text style={styles.title}>{i18n.t('FILTERS')}</IM.Text>
            </IM.View>
          </IM.View>
          <FilterFilterView />
        </>
      )}

      {!!orders.length && <FilterOrderView />}
      <ListSpacer height={50} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  groupContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 14,
  },
  tag: { marginVertical: 3 },
})

export default forwardRef(FilterView)
