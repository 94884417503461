import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Collo, PackingListColloState } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import colloUtils from '../../utils/colloUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ColloNumbersForFinalPickingCard(props: { item: Collo; style?: StyleProp<ViewStyle>; onPress?: () => void }) {
  function getBackgroundColor() {
    if (props.item.state === PackingListColloState.shipped) return 'darkseagreen'
    return 'lightgray'
  }

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      icon={'box-circle-check'}
      style={{ justifyContent: 'flex-start', flexDirection: 'column', backgroundColor: getBackgroundColor() }}>
      <IMText primary>{props.item.number}</IMText>
      <IMTextWithIcon icon="box-taped" text={colloUtils.getDimension(props.item)} />
      {!!props.item.stockposition && (
        <IMTextWithIcon icon={'shelf'} text={stockPositionUtils.getTitle(props.item.stockposition, props.item.deposit)} />
      )}
    </MWS_BaseCard>
  )
}
