import api from '../apis/apiCalls'
import {
  ArticleDepositQuantity,
  ArticleDTO,
  ArticleMeasurementUnit,
  ArticleSerialNumberCreationType,
  Deposit,
  InventoryQuantityInsertType,
  Item,
  MeasurementUnitType,
  QuantityInsertType,
  SerialNumber,
  StockPosition,
  StockPositionAvailability,
} from '../apis/apiTypes'
import { ArticleAmountSelectionResult } from '../views/Article/ArticleAmountSelectorV2'
import { serialnumberUtils } from './serialnumberUtils'
import stockPositionUtils from './stockPositionUtils'
import { Utils } from './Utils'

export const articleUtils = {
  getArticleDepositNetQuantity(articleDepositQuantity: ArticleDepositQuantity) {
    if (!articleDepositQuantity) return 0
    return articleDepositQuantity.quantity - articleDepositQuantity.quantityCommissioned - articleDepositQuantity.quantityOrdered
  },
  getArticleTitle(article: ArticleDTO | undefined) {
    if (!article) return ''
    return article.code?.trim() + ' - ' + article.searchtext?.trim()
  },
  getArticleDescription(article: ArticleDTO | undefined, langId: string) {
    if (!article?.descriptions) return article?.searchtext ?? ''
    return article.descriptions.find(d => d?.language?.toLowerCase()?.trim() === langId?.toLowerCase())?.text ?? article.searchtext
  },
  getArticleTitleDescription(article: ArticleDTO | undefined, langId: string) {
    if (!article) return ''
    if (!article?.descriptions) return article.code?.trim() + ' - ' + article.searchtext?.trim()
    return (
      article.code?.trim() +
      ' - ' +
      (article.descriptions.find(d => d?.language?.toLowerCase()?.trim() === langId?.toLowerCase())?.text ?? article.searchtext)
    )
  },
  isSalesType(unitType: MeasurementUnitType | InventoryQuantityInsertType | QuantityInsertType | undefined) {
    return (
      unitType === MeasurementUnitType.Sales ||
      unitType === MeasurementUnitType.SalesPurchasing ||
      unitType === InventoryQuantityInsertType.SalesUnit ||
      unitType === QuantityInsertType.SalesUnit
    )
  },
  isPurchasingType(unitType: MeasurementUnitType | InventoryQuantityInsertType | QuantityInsertType | undefined) {
    return (
      unitType === MeasurementUnitType.Purchasing ||
      unitType === MeasurementUnitType.SalesPurchasing ||
      unitType === InventoryQuantityInsertType.PurchasingUnit ||
      unitType === QuantityInsertType.PurchasingUnit
    )
  },
  getSalesUnit(article: ArticleDTO) {
    return article?.measurementUnits?.find(u => u.type === MeasurementUnitType.Sales || u.type === MeasurementUnitType.SalesPurchasing)
  },
  getPurchasingUnit(article: ArticleDTO) {
    return article?.measurementUnits?.find(u => u.type === MeasurementUnitType.Purchasing || u.type === MeasurementUnitType.SalesPurchasing)
  },
  getUnitById(article: ArticleDTO | undefined, unitId: string | undefined) {
    if (!article || !unitId) return undefined
    return article?.measurementUnits?.find(u => u.id === unitId)
  },
  getDefaultUnit(article: ArticleDTO | undefined, useSalesUnit?: boolean): ArticleMeasurementUnit {
    if (!article) return { id: '', code: '', conversation: 1, type: MeasurementUnitType.None, netWeight: 0 }
    if (useSalesUnit) {
      const salesUnit = this.getSalesUnit(article)
      if (salesUnit) return salesUnit
    }
    if (article.measurementUnitId) {
      const unitById = this.getUnitById(article, article.measurementUnitId)
      if (unitById) return unitById
    }
    return (
      article?.measurementUnits?.find(u => u.conversation === 1) ??
      (article?.measurementUnits && article?.measurementUnits.length > 0
        ? article?.measurementUnits[0]
        : this.createMeasurementUnit(article.measurementUnitCode, article.measurementUnitId))
    )
  },
  getUnitByType(article: ArticleDTO, type: MeasurementUnitType | InventoryQuantityInsertType | QuantityInsertType | undefined) {
    if (this.isSalesType(type)) {
      const unit = this.getSalesUnit(article)
      if (unit) return unit
    }
    if (this.isPurchasingType(type)) {
      const unit = this.getPurchasingUnit(article)
      if (unit) return unit
    }
    return this.getDefaultUnit(article)
  },
  createMeasurementUnit(code?: string, id?: string, conversion?: number, type?: MeasurementUnitType, netWeight?: number) {
    return {
      id: id ?? '',
      code: code ?? '',
      conversation: conversion ?? 1,
      type: type ?? MeasurementUnitType.None,
      netWeight: netWeight ?? 0,
    }
  },
  createArticleAmountSelectionResult(
    quantity: number | undefined,
    unit?: ArticleMeasurementUnit,
    serialNumber?: SerialNumber,
    stockPosition?: StockPosition,
    deposit?: Deposit
  ) {
    return { quantity, unit, serialNumber, stockPosition, deposit } as ArticleAmountSelectionResult
  },
  formatQuantity(quantity: number | string | undefined, unit?: ArticleMeasurementUnit | string, conversion?: number) {
    if (!quantity && !unit) return ''
    let value = 0
    if (typeof quantity === 'number') value = quantity
    if (typeof quantity === 'string') value = Number.parseFloat(quantity) ?? 0
    const quantityRounded = Utils.roundToDefaultPrecision(value * (conversion || 1))
      .toString()
      .replace('.', ',')
    let unitString = ''
    if (unit) {
      if (typeof unit === 'string') unitString = ` ${unit.trim()}`
      if (typeof unit === 'object') unitString = ` ${unit.code?.trim()}`
    }
    return `${quantityRounded}${unitString}`
  },
  currencyFormat(price: number) {
    return price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' €'
  },
  getUnitText(unit: ArticleMeasurementUnit, units?: ArticleMeasurementUnit[]) {
    const mainUnit = units?.find(u => u.conversation === 1)
    if (!mainUnit) return unit?.code?.trim() ?? ''
    if (unit && unit.code !== mainUnit.code) {
      return unit.code?.trim() + ` (${unit.conversation} ${mainUnit.code?.trim()})`
    }
    return unit?.code ?? ''
  },
  /**
   * @returns quantity converted from sourceUnit to targetUnit
   */
  convertToUnit(quantity: number, targetUnit: ArticleMeasurementUnit | undefined, sourceUnit?: ArticleMeasurementUnit) {
    if (!quantity) return 0
    if (sourceUnit) quantity *= sourceUnit.conversation
    if (!targetUnit) return quantity
    return quantity / targetUnit.conversation
  },
  /**
   * @returns quantity converted back to base unit of conversion 1
   */
  convertFromUnit(quantity: number, unit: ArticleMeasurementUnit | undefined) {
    if (!quantity) return 0
    if (!unit) return quantity
    return quantity * unit.conversation
  },
  sortByCode(a: ArticleDTO, b: ArticleDTO) {
    return a.code > b.code ? 1 : -1
  },
  isSerialNumberActive(article: ArticleDTO | undefined, isHistoricalSerialnumberActive?: boolean, isVerifySerialNumber?: boolean) {
    if (!article) return false

    // Falls VerifySerialNumber undefine dann ohne dieses Flag kontrollieren
    if (isVerifySerialNumber === undefined) {
      return article.isSerialnumberActive && (!article.isSerialnumberOnlyForHistory || !!isHistoricalSerialnumberActive)
    }

    return article.isSerialnumberActive && isVerifySerialNumber && (!article.isSerialnumberOnlyForHistory || !!isHistoricalSerialnumberActive)
  },
  quantityValidator(quantity: number | undefined, availability?: number, availabilityTolerance?: number) {
    if (quantity) quantity = Math.abs(quantity)
    if (availability) availability = Math.abs(availability)
    if (availabilityTolerance) {
      return (
        !!quantity && availability && Number.isFinite(quantity) && Math.abs(availability - quantity) <= availability * (availabilityTolerance / 100)
      )
    }
    return !!quantity && Number.isFinite(quantity) && quantity > 0 && (availability === undefined || quantity <= availability)
  },
  filterAvailability(articleDepositQuantities: ArticleDepositQuantity[] | undefined) {
    if (!articleDepositQuantities) return
    const depositQuantities = articleDepositQuantities.filter(item => item.quantity > 0)
    depositQuantities.forEach(
      item => (item.stockpositionquantities = item.stockpositionquantities.filter(stockPos => stockPos.quantity && stockPos.quantity > 0))
    )
    return depositQuantities
  },
  convertDepositToStockPositionQuantity(depositQuantities: ArticleDepositQuantity[] | undefined, filterInvalidQuantity?: boolean) {
    if (!depositQuantities || depositQuantities.length === 0) return []
    const quantityResult: StockPositionAvailability[] = []

    depositQuantities.forEach(depositQuantity => {
      quantityResult.push(
        ...(depositQuantity.stockpositionquantities ?? [])
          .filter(item => !filterInvalidQuantity || (item.quantity && item.quantity > 0))
          .map(item => ({ ...item, deposit: depositQuantity.deposit }))
      )
    })
    return quantityResult
  },
  compare(a: ArticleDTO | undefined, b: ArticleDTO | undefined) {
    if (!a?.id || !b?.id) return false
    return a.id === b.id
  },
  compareArticleAmountSelectionResult(a: ArticleAmountSelectionResult | undefined, b: ArticleAmountSelectionResult | undefined) {
    if (!a || !b) return false
    return (
      serialnumberUtils.compare(a.serialNumber, b.serialNumber) &&
      ((!a.deposit && !b.deposit) || stockPositionUtils.comparePosition(a.deposit, b.deposit, a.stockPosition, b.stockPosition))
    )
  },
  autoCreateSerialNumbersForArticle(article: ArticleDTO | undefined) {
    return (
      !!article &&
      (article.createSerialnumber === ArticleSerialNumberCreationType.AutomaticallyGlobal ||
        article.createSerialnumber === ArticleSerialNumberCreationType.AutomaticallyPerArticle)
    )
  },
  async getArticleAvailability(article: ArticleDTO, deposit: Deposit, stockPosition?: StockPosition) {
    if (!article || !deposit) return null
    try {
      return await api.getArticleAvailability({ articleId: article.id, depositId: deposit.id, stockPositionId: stockPosition?.id })
    } catch (exception) {
      console.error(exception)
      return null
    }
  },
  getItemAvailability(item: Item, deposit?: Deposit, stockPosition?: StockPosition) {
    return new Promise<number>(resolve => {
      if (!item.article) {
        resolve(0)
        return
      }
      api
        .getItemAvailability({
          articleId: item.article.id,
          serialNumberId: item.serialnumber?.id,
          depositId: deposit?.id,
          stockPositionId: stockPosition?.id,
        })
        .then(resolve)
        .catch(() => resolve(0))
    })
  },
}
