import api from '../apis/apiCalls'
import usePrinter from './usePrinter'

const JobExecParameterCalls = {
  print: 'PRINTINGARTIKELETIKETTEN',
}

export default function useJobExecParameter() {
  const { printerNumber } = usePrinter()

  function printLabel(articleId: string, quantity: number, serialNumberId?: string) {
    return api.jobExecParameter({
      call: JobExecParameterCalls.print,
      state: 0,
      code1: 'artid',
      value1: articleId,
      code2: 'Druckernr',
      value2: printerNumber ?? '',
      code3: 'Menge',
      value3: quantity.toString(),
      code4: serialNumberId ? 'Snid' : undefined,
      value4: serialNumberId,
    })
  }

  return { printLabel }
}
