import { ModalController, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import FullScreenModal from '../../components/FullScreenModal'
import useFilter from '../../hooks/filter/useFilter'
import FilterView from '../../views/filter/FilterView'

export type FilterModalProps = { controller: ModalController }

export default function FilterModal({ controller }: FilterModalProps) {
  const { i18n } = useLanguage()

  const { clear } = useFilter()

  return (
    <FullScreenModal
      isVisible={controller.isShown}
      coverScreen
      title={i18n.t('FILTERS')}
      onClose={clear}
      close={() => controller.close()}
      closeIcon={'filter-circle-xmark'}
      noTopPadding>
      <FilterView />
    </FullScreenModal>
  )
}
