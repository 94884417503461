import { Storage, useDidUpdate } from '@infominds/react-native-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { StorageKeys } from '../../constants/Constants'
import { DataFilterValue, FilterConfig, FilterDataSorter, FilterStorageType, StorageType } from '../../types'
import { filterUtils } from '../../utils/FilterUtils'

export default function useDataFilterState<T extends object, TSub = void>(
  storageKeyUniqueId: string,
  config?: FilterConfig<T, TSub>
): [FilterDataSorter<T, TSub>[], Dispatch<SetStateAction<FilterDataSorter<T, TSub>[]>>, boolean] {
  const [state, setState] = useState<FilterDataSorter<T, TSub>[]>(filterUtils.initDataFilter(config))
  const [initOk, setInitOk] = useState(false)
  const storage = Storage<FilterStorageType[]>(StorageKeys.FILTER_CONTEXT_GROUP + (config?.type ?? '') + storageKeyUniqueId)

  useEffect(() => {
    storage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const initFilters = filterUtils.initDataFilter(config)
          const populatedFilter: FilterDataSorter<T, TSub>[] = []

          initFilters.forEach(initFilter => {
            const newValues: DataFilterValue[] = []
            const foundLoadedFilter = loaded.find(el => el.filterId === initFilter.id)

            if (foundLoadedFilter !== undefined) {
              foundLoadedFilter.values.forEach(el => newValues.push({ id: el.id, active: el.value === 'true', value: '' }))
              populatedFilter.push({ ...initFilter, values: newValues })
            } else {
              populatedFilter.push(initFilter)
            }
          })

          setState(populatedFilter)
        }
      })
      .catch(err => console.error('Failed loading DataFilterState', err))
      .finally(() => setInitOk(true))
  }, [])

  useDidUpdate(() => {
    const storageFilters: FilterStorageType[] = []
    state.forEach(filter => {
      const filterValues: StorageType[] = []

      filter.values.forEach(value => filterValues.push({ id: value.id, value: (value.active ?? false).toString(), isMax: false }))
      storageFilters.push({ filterId: filter.id, values: filterValues })
    })

    storage.save(storageFilters).catch(err => console.error('Failed saving dataFilterState ' + (config?.type ?? ''), err))
  }, [state])

  return [state, setState, initOk]
}
