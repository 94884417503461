import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../apis/apiCalls'
import { Collo } from '../../apis/apiTypes'
import ColloCard from '../../cards/Collo/ColloCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { SelectionViewProps } from '../../types'
import colloUtils from '../../utils/colloUtils'

export default function ColloSelectionView(props: SelectionViewProps<Collo>) {
  const { i18n } = useLanguage()

  function keepUniqueItems(items: Collo[]) {
    if (!items) return []
    return items.filter((item, index) => index === items.findIndex(firstItem => firstItem.number === item.number))
  }

  const RenderItem = useCallback((item: Collo) => <ColloCard collo={item} />, [])

  return (
    <MWS_Input
      {...props}
      type="Collo"
      style={props.style}
      load={input => api.getCollo({ number: input.scannerInput ?? input.textInput })}
      modifyResult={result => keepUniqueItems(result).sort(colloUtils.sort)}
      itemRenderer={RenderItem}
      allowEmptyInput={props.allowEmptyInput}
      title={i18n.t('SelectCollo')}
      modalTitle={i18n.t('SelectCollo')}
      history={props.history}
      historyNameExtractor={item => item.number ?? ''}
      loadFromHistory={item => api.getCollo({ id: item.id })}
    />
  )
}
