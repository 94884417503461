import { MenuActionType } from '../types'

export interface UserSettings {
  language: string
  employeeId: string
  depositId: string
  isPalletPickingActive: boolean
  stplid_Preconsignment: string
  isShowingVpk1Active: boolean
  isVpk1PickingActive: boolean // use vpk1 in consignment / packingLists
  isVpk1ShoppingcartActive: boolean // use vpk1 in shoppingCart
  isTransferPackinglistcollonumberActive: boolean
  isConsignmentArticledescriptionInUserLanguage: boolean
  isScanningArticleAndStockpositionMandatoryOnPicking: boolean
  isHistoricalSerialnumberActive: boolean
  isPackingAddItemActive: boolean
  isPackingReplaceItemActive: boolean
  isArticleweightMandatoryAtGoodsreceiptWarehouse: boolean
  additionalFieldDefId_CheckStockposition: string
  stockpositionIdKanban: string
  toleranceRunningInventory: number
  createProductionIncomingAfterOutgoing: YesNotQuestionType
  createProductionOutgoingAfterIncoming: boolean
  kanbanBoxSeparator?: string
  setCurrentEmployeeAsPickerOfPickingList: boolean
  isOngoingInventorySelectArticleSerialNumberActive: boolean
  isProductionOutputQueryWhetherAScannedProductionOrderShouldBeLoaded: boolean
  activeMinutesForAutomaticLogout?: number
  isConsiderScannedSerialNumberOnIncomingStockAccounting: boolean
  isPackingListCheckDifferencesOverCommissioningValid: boolean
}

export interface Menu {
  title: string
  actionType: MenuActionType
  parameterCode: string
  group?: string
}

export interface StockTemplate {
  id: string
  code: string
  description: string
  isInOut: boolean
  isIncoming: boolean
  isOutgoing: boolean
  quantityInsertTyp: QuantityInsertType
  deposit: Deposit
  depositTarget: Deposit
  stockposition: StockPosition
  stockpositionTarget: StockPosition
  deactivateAvailabilityCheck?: boolean
  isStockpositionFixed?: boolean
  isStockpositionTargetFixed?: boolean
  isVerifySerialNumber?: boolean
}

export enum QuantityInsertType {
  None = 'None',
  MainUnit = 'MainUnit',
  PurchasingUnit = 'PurchasingUnit',
  SalesUnit = 'SalesUnit',
}

export interface ArticleDTO {
  id: string
  companyId: string
  code: string
  searchtext: string
  barcode: string
  measurementUnitId: string
  measurementUnitCode: string
  model: string
  isSerialnumberActive: boolean
  isSerialnumberOnlyForHistory: boolean
  isLottoSerialnumber: boolean
  createSerialnumber: ArticleSerialNumberCreationType
  serialnumberPrefix: string
  articletype: ArticleType
  producer: string
  inactive: boolean
  blocked: Date
  isDiscontinued: boolean
  isStockmovement: boolean
  warrantyCustomer: string
  minimumOrderQuantitySales: number
  priceUnit: number
  intrastatTypeOfMovement?: ArticleIntrastatTypeOfMovement
  intrastatOriginCountry: string
  intrastatGroup: string
  intrastatConversion: number
  descriptionNumberArticle: number
  descriptionNumberOrder: number
  descriptionNumberOffer: number
  availabilityControl?: ArticleAvailabilityControlType
  grafikid: string
  descriptions: ArticleDescription[]
  measurementUnits: ArticleMeasurementUnit[]
  prices: ArticlePrice[]
  articlesheets: ArticleSheet[]
}

export enum ArticleSerialNumberCreationType {
  No = 'No',
  AutomaticallyPerArticle = 'AutomaticallyPerArticle',
  AutomaticallyGlobal = 'AutomaticallyGlobal',
  ConfigurationSerialnumber = 'ConfigurationSerialnumber',
  FixedSerialnumber = 'FixedSerialnumber',
}

export enum ArticleType {
  None = 'None',
  CommercialArticle = 'CommercialArticle',
  ProductionArticle = 'ProductionArticle',
  ArticleToBeOrdered = 'ArticleToBeOrdered',
  SemifinishedProduct = 'SemifinishedProduct',
  PrimaryProduct = 'PrimaryProduct',
  FictivePart = 'FictivePart',
}

export enum ArticleIntrastatTypeOfMovement {
  FromClassOfGoods = 'FromClassOfGoods',
  Products = 'Products',
  Service = 'Service',
  NoIntra = 'NoIntra ',
}

export enum ArticleAvailabilityControlType {
  Default = 'Default',
  Blocking = 'Blocking',
  Warning = 'Warning',
  NoControl = 'NoControl',
}

export interface ArticleDescription {
  number: number
  language: string
  text: string
}

export interface ArticleMeasurementUnit {
  id: string
  code: string
  type: MeasurementUnitType
  conversation: number
  netWeight: number
}

export enum MeasurementUnitType {
  None = 'None',
  Sales = 'Sales',
  Purchasing = 'Purchasing',
  SalesPurchasing = 'SalesPurchasing',
}

export interface ArticlePrice {
  id: string
  articleId: string
  customerId: string
  currency: string
  companyId: string
  pricekey: string
  validFrom: Date
  validUntil: Date
  amount: number
  surchargeDebit: number
  unit: number
  rounding: number
  option1: string
  option1Logic: boolean
  option1Date: Date
  dependancytype: number
  dependancyPricekey: string
}

export interface ArticleSheet {
  articlesheetsdefinitionId: string
  articlesheetsdefinitionCode: string
  language: string
  text: string
}
export interface SerialNumber {
  inactive: boolean
  id: string
  number: string
  numberManufactor: string
  alternativeIdMonitoringMapping: string
  article: ArticleDTO
  blocked: Date
  warrantySupplier: Date
  incomingDate: Date
  additionalinfo: string
}

export interface SerialnumberCheck {
  isOk: boolean
  warning: string
}
export interface Deposit {
  id: string
  code: string
  description: string
  location: string
  isStockpositionActive: boolean
  inactive: boolean
  blocked: Date
}
export interface StockPosition {
  id: string
  code: string
  deposit: Deposit
  state: StockPositionState
  notProposedInPackinglists: boolean
  exhibit: boolean
  deliveryStock: boolean
  production: boolean
}

export enum StockPositionState {
  All = 'All',
  Available = 'Available',
  Blocked = 'Blocked',
  Inactive = 'Inactive',
}

export interface StockPositionAvailability {
  article: ArticleDTO
  serialnumber: SerialNumber | undefined
  deposit: Deposit | undefined
  stockposition?: StockPosition
  quantity: number | undefined
  quantityOrdered?: number
  quantityCommissioned?: number
  quantityPurchasing?: number
  quantityFreeInputs?: number
}

export interface StockPositionAvailabilityWithMainSupplier {
  supplierId: string
  supplier: string
  article: ArticleDTO
  serialnumber: SerialNumber | undefined
  deposit: Deposit | undefined
  stockposition?: StockPosition
  quantity: number
  quantityCommissioned?: number
}

export interface OngoingInventory {
  id: string
  stockposition?: StockPosition
  article?: ArticleDTO
  serialnumbers?: SerialNumber[]
  quantity: number
  quantityCounted: number
  date?: string
  note: string
}

export interface OngoingInventoryToConfirm {
  stockposition?: StockPosition
  date?: string
}

export interface ArticleAvailability {
  article: ArticleDTO
  serialnumber?: SerialNumber
  depositquantities: ArticleDepositQuantity[]
  quantity: number
}

export interface ArticleDepositQuantity {
  deposit: Deposit
  quantity: number
  quantityOrdered: number
  quantityCommissioned: number
  quantityPurchasing: number
  quantityFreeInputs: number
  earliestPlannedEntrance?: Date
  lastWarehouseEntrance?: Date
  stockpositionquantities: StockPositionAvailability[]
}

export interface Item {
  article: ArticleDTO
  serialnumber?: SerialNumber
  barcodeQuantity?: number
  availability?: number
  deposit?: Deposit
  stockposition?: StockPosition
}

export interface ItemStockPositionQuantity {
  article?: ArticleDTO
  serialNumber?: SerialNumber
  stockPosition?: StockPosition
  quantity?: number
  lastOngoingInventoryDate?: string
}

export enum YesNotQuestionType {
  Yes = 'Yes',
  No = 'No',
  Question = 'Question',
}

export interface ArticleOrder {
  document: string
  customer: string
  quantity: number
  state: OrderState
}

export interface ArticleSalesPrice {
  price: number
  priceUnit: number
  priceOrigin: number
  discountOrigin: number
  discount1: number
  discount2: number
  discount3: number
  discount4: number
  discount5: number
  priceDiscount: number
  priceForSinglePiece: number
  priceForSinglePieceWithDiscount: number
  quantity: number
  priceTotal: number
  currency: string
  vaTincluded: boolean
}
export interface ArticleSalesPriceScale {
  price: number
  priceUnit: number
  discount1: number
  discount2: number
  discount3: number
  discount4: number
  discount5: number
  priceForSinglePiece: number
  priceForSinglePieceWithDiscount: number
  quantity: number
}

export enum OrderState {
  None = 'None',
  Open = 'Open',
  PartiallyDone = 'PartiallyDone',
  Blocked = 'Blocked',
  Done = 'Done',
}

export interface Customer {
  id: string
  companyId: string
  number: number
  addressId: string
  description: string
  optional: string
  salutation: string
  letterSalutation: string
  street: string
  streetnumber: string
  postalCode: string
  town: string
  province: string
  country: string
  phone: CustomerPhoneNumber[]
  fax: CustomerFaxNumber[]
  email: CustomerEmailAddress[]
  emailPEC: string
  url: string
  language: string
  isoVatnumber: CustomerIsoVat
  taxnumber: string
  legalform: number
  origin: number
  vatCalculation: number
  agent: string
  currency: string
  paymentmethod: string
  shipmentmethod: string
  vatcode: string
  invoicesubject_id: string
  numberInvoiceCopies: number
  accountNumber: string
  recipientCodePA: string
  recipientCodeB2B: string
  recipientCodeB2BPECMail: string
  referenceAdministration: string
  splitpayment: boolean
  invoiceSendElectronically: boolean
  invoiceSendElectronicallyViaPec: boolean
  invoiceSendElectronicallyEmail: string
  sendDemandsForPayments: boolean
  sendDemandsForPaymentsEmail: string
  sendDunnings: boolean
  sendDunningsEmail: string
  birthday: Date
  iban: string
  note: string
  abbreviation: string
  codeOld: string
  abc: string
  since: Date
  priceprintOnBillOfDelivery: number
  printRemainingOnInvoiceOrBill: number
  partialDeliveryAllowed: number
  dunninggroup: string
  creditlimit: number
  noteOfFidocontrol: string
  freighter: string
  deliverycondition: string
  inactive: boolean
  lockedSince: Date
  createdate: Date
  updatedate: Date
}

export interface CustomerDeliveryAddress {
  customerId: string
  deliveryaddressId: string
  customer: string
  deliveryaddress: string
}

export interface CustomerPhoneNumber {
  type: PhoneNumberType
  number: string
  description: string
}

export enum PhoneNumberType {
  Business = 'Business',
  Mobile = 'Mobile',
  Private = 'Private',
  Business2 = 'Business2',
  Phone5 = 'Phone5',
  Phone6 = 'Phone6',
  Phone7 = 'Phone7',
  Phone8 = 'Phone8',
  Phone9 = 'Phone9',
  Phone10 = 'Phone10',
  Phone11 = 'Phone11',
  Phone12 = 'Phone12',
  Phone13 = 'Phone13',
}

export interface CustomerEmailAddress {
  type: EmailType
  address: string
  description: string
}

export enum EmailType {
  Email = 'Email',
  Email2 = 'Email2',
  Email3 = 'Email3',
  Email4 = 'Email4',
  Email5 = 'Email5',
  Email6 = 'Email6',
  Email7 = 'Email7',
  Email8 = 'Email8',
  Email9 = 'Email9',
  Email1number = 'Email10',
  Email11 = 'Email11',
  Email12 = 'Email12',
  Email13 = 'Email13',
}

export interface CustomerFaxNumber {
  type: FaxNumberType
  number: string
  description: string
}
export enum FaxNumberType {
  Business = 'Business',
  Additional = 'Additional',
  Fax3 = 'Fax3',
  Fax4 = 'Fax4',
  Fax5 = 'Fax5',
  Fax6 = 'Fax6',
  Fax7 = 'Fax7',
  Fax8 = 'Fax8',
  Fax9 = 'Fax9',
  Fax1number = 'Fax10',
  Fax11 = 'Fax11',
  Fax12 = 'Fax12',
  Fax13 = 'Fax13',
}

export interface CustomerIsoVat {
  isocode: string
  vatNumber: string
}

export interface ShoppingCartEntry {
  id: string
  customerId: string
  article: ArticleDTO
  quantity: number
  listname: string
  description: string
  note: string
  price: number
  measurementUnit: ArticleMeasurementUnit
  option1: string
  serialNumber: SerialNumber
}

export interface InventoryEntry {
  id: string
  code: string
  description: string
  deposit: Deposit
  dateFrom: Date
  dateUntil: Date
  quantityInsertTyp: InventoryQuantityInsertType
}

export enum InventoryQuantityInsertType {
  None = 'None',
  MainUnit = 'MainUnit',
  PurchasingUnit = 'PurchasingUnit',
  SalesUnit = 'SalesUnit',
}

export interface InventoryAccounting {
  inventorydate: Date
  article: ArticleDTO
  deposit: Deposit
  stockposition: StockPosition
  measurementUnit: ArticleMeasurementUnit
  serialnumber: SerialNumber
  quantity: number
  stockpositionText: string
  id: string
}

export interface InventoryPostResult {
  inventorypositionId: string
  warning: string
}

export interface Collo {
  id?: string
  number?: string
  packinglistId: string
  packinglistmovementId: string
  packinglistmovementpartId: string
  quantity: number
  article: ArticleDTO
  serialnumber?: SerialNumber
  deposit?: Deposit
  stockposition?: StockPosition
  weight?: number
  length?: number
  width?: number
  height?: number
  packagingtypeId?: string
  collaboratorId?: string
  state?: PackingListColloState
}

export interface ColloUnloadPackingList {
  id: string
  number?: string
  customer?: string
  deliveryaddress?: string
  documentdate?: Date
  deliverydate?: Date
  packinglistmovements?: PackingListMovement[]
}

export interface PackagingType {
  id: string
  code: string
  description: string
  tareweight: number
  length: number
  width: number
  height: number
}

export enum PackingListColloState {
  open = 'open',
  unloaded = 'unloaded',
  packed = 'packed',
  transferred = 'transferred',
  transferredauto = 'transferredauto',
  shipped = 'shipped',
}

export enum PrintingType {
  default = 'Default',
  printing = 'Printing',
  notPrinting = 'NotPrinting',
}

export interface PackingList {
  id: string
  documentId: string
  customerId: string
  deliveryaddressId: string
  invoicetemplateId: string
  number: string
  numberForOrder: string
  customer: string
  deliveryaddress: string
  additionalinfos: string
  documentnote: string
  countOfColli: number
  internalPackinglist: boolean
  productionPackinglist: boolean
  type?: PackingListType
  deposit: Deposit | undefined
  freighter: Freighter
  freighterForOrder: string
  documentdate: Date
  deliverydate: Date
  printing: PrintingType | undefined
  packinglistmovements: PackingListMovement[]
  orders: PackingListOrder[]
  additionalfields: PackingListAdditionalField[]
  project?: PackingListProject
  production?: PackingListProduction
  isPossibleCreateDeliveryNote?: boolean
}

export enum PackingListType {
  Sales = 'Sales',
  Internal = 'Internal',
  Production = 'Production',
}

export type PackingListProject = {
  id: string
  code: string
  description: string
}

export type PackingListProduction = {
  id: string
  code: string
  description: string
}

export interface PackingListAdditionalField {
  fkId: string
  id: string
  definitionId: string
  definitionCode: string
  definitionDescription: string
  definitionFieldType: PackingListAdditionalFieldType
  definitionFieldFieldspec: string
  value: string
  valueDescription: string
}

export enum PackingListAdditionalFieldType {
  None = 'None',
  Text = 'Text',
  Numeric = 'Numeric',
  Date = 'Date',
  Boolean = 'Boolean',
  ForeignKey = 'ForeignKey',
  TextHTML = 'TextHTML',
  TextRTF = 'TextRTF',
  Selection = 'Selection',
}

export interface Freighter {
  id: string
  code: string
  description: string
}

export interface PackingListMovement {
  id: string
  packinglistId: string
  packinglistmovementpartId: string
  order: string
  orderId: string
  ordermovementId: string
  position: number
  sortkey: string
  article: ArticleDTO
  description: string
  descriptionHTML: string
  quantityDeposit: number
  quantityStoringposition: number
  unitIdMovement: string
  unitId: string
  unitCode: string
  quantity: number
  quantityPicked: number
  conversionMasterUnit: number
  conversionColloUnit: number
  state: number
  mandatoryScanningIndividualQuantities: boolean
  deposit?: Deposit
  stockposition?: StockPosition
  collonumbers?: Collo[]
  orderSerialnumberquantities: OrderSerialNumberQuantity[]
}

export interface OrderSerialNumberQuantity {
  article: ArticleDTO
  serialnumber: SerialNumber
  deposit: Deposit
  stockposition: StockPosition
  quantity: number
}

export interface PackingListOrder {
  id: string
  documentId: string
  customerId: string
  deliveryaddressId: string
  number: string
  movements: PackingListOrderMovement[]
}

export interface PackingListOrderMovement {
  id: string
  orderId: string
  position: number
  article: ArticleDTO
  description: string
  quantity: number
}
export interface Production {
  id: string
  code: string
  docId: string
  stuId: string
  stockposition: StockPosition
  deposit: Deposit
  quantity: number
  quantityBooked: number
  quantityOpen: number
  unitId: string
  unitCode: string
  prodstuId: string
  prodidProdstuid: string
  state: ProductionState
  customer: Customer
  article: ArticleDTO
  stockpositionOutgoing: StockPosition
  depositOutgoing: Deposit
  ordermovementId: string
  order: string
  orderSerialnumberquantities: OrderSerialNumberQuantity[]
  stocktemplateIdTransfer: string
  stocktemplateIdDefective: string
  stocktemplateIdIncoming: string
  stocktemplateIdOutgoing: string
  additionalDescription: string
}

export interface ProductionMaster {
  id: string
  code: string
  docId: string
  customer: Customer
  stuId: string
  article: ArticleDTO
}

export enum ProductionState {
  Open = 'Open',
  ManuallyDone = 'ManuallyDone',
  AutomaticallyDone = 'AutomaticallyDone',
}

export interface StockAccountingPostResult {
  documentId: string
  stockId: string
  stockaccountingId: string
  warning: string
}

export interface StockAccounting {
  stockId: string
  stockAccountingId?: string
  deposit?: Deposit
  stockPosition?: StockPosition
  depositTarget?: Deposit
  stockPositionTarget?: StockPosition
  article?: ArticleDTO
  quantity?: number
  serialNumber?: SerialNumber
}

export interface ArticleDepositData {
  deposit?: Deposit
  stockposition?: StockPosition
  id: string
  companyId: string
  articleId: string
  minQuantityDeposit?: number
  maxQuantityDeposit?: number
  minQuantityStoringposition?: number
  maxQuantityStoringposition?: number
  packagingUnit?: number
}

export interface ProductionPart {
  prodId: string
  id: string
  deposit: Deposit
  stockposition: StockPosition
  article: ArticleDTO
  articleMaster: ArticleDTO
  unitId_Article: string
  unitId: string
  employeeTimeTypeId: string
  employeeTimeType: string
  decimalplaces: number
  serialnumber: SerialNumber
  quantityFactor: number
  quantity: number
  quantityTotal: number
  quantityTransfered: number
  isLastWorkStep: boolean
  stockpositionAvailable?: StockPosition
  quantityStockpositionAvailable?: number
  quantityDepositAvailable?: number
}

export interface ProductionPartBooked {
  productionId: string
  production: string
  articleProduction: ArticleDTO
  article: ArticleDTO
  unitId: string
  quantity: number
  stockAccountings: ProductionPartStockAccounting[]
}

export interface ProductionPartStockAccounting {
  id: string
  stockId: string
  quantity: number
  serialNumbersBooked: ProductionPartStockAccountingSerialNumber[]
}

export interface ProductionPartStockAccountingSerialNumber {
  stockaccountingId: string
  serialnumber: SerialNumber
  quantity: number
}

export interface ProductionPartOfMasterProduction {
  productionPartId: string
  productionId: string
  position: number
  article: ArticleDTO
  preProductionId: string
  preProductionKey: string
  preProductionPercent: number
  quantity: number
}

export interface ProductionPartForExtraction {
  productionPartId: string
  productionId: string
  production: string
  articleProduction: ArticleDTO
  article: ArticleDTO
  unitId: string
  quantity: number
  serialNumber: SerialNumber
  state: number
}

export interface ProductionPartForTransfer {
  productionPartId: string
  productionId: string
  production: string
  articleProduction: ArticleDTO
  deposit: Deposit
  article: ArticleDTO
  unitId: string
  quantity: number
  quantityTransferred: number
}

export interface Freighter {
  id: string
  code: string
  description: string
}

export interface Worksheet {
  id: string
  number: number
  number_LastOfProduction: number
  docIdProduction: string
  prodId: string
  stuId: string
  stockpositionIdProduction: string
  arbaufid: string
  articleId: string
  quantity: number
  quantityBooked: number
  isOpen: boolean
}

export interface WorksheetSerialNumbers {
  worksheetId: string
  id: string
  number: string
  isLottoSerialnumber: boolean
  quantity: number
}

export interface WorksheetPart {
  worksheetId: string
  stockpositionId: string
  serialnumber: string
  quantity: number
}

export interface AdditionalField {
  fkId?: string
  id?: string
  defId?: string
  defCode?: string
  defDescriptionDE?: string
  defDescriptionIT?: string
  defDescriptionEN?: string
  defFeldType?: number
  defFeldFieldspec?: string
  stringValue?: string
  dateValue?: Date
  numberValue?: 0
  logicValue?: boolean
}

export interface QualityCharacteristic {
  id: string
  code?: string
  description?: string
  fieldTyp?: QualityCharacteristicType
  length?: number
  decimal?: number
  order?: number
  comment?: string
  details?: QualityCharacteristicDetail[]
  check?: QualityCharacteristicCheck
}

export enum QualityCharacteristicType {
  None = 'None',
  Text = 'Text',
  Numeric = 'Numeric',
  Selection = 'Selection',
  Boolean = 'Boolean',
}

export interface QualityCharacteristicDetail {
  id: string
  code: string
  description: string
}

export interface QualityCharacteristicCheck {
  isMandatory?: boolean
  targetBoolean?: boolean
  targetText?: string
  targetNumericFrom?: number
  targetNumericUntil?: number
  targetCompare?: boolean
}
export interface QualityControl {
  id: string
  articleId: string
  serialnumberId?: string
  productionId?: string
  employeeId?: string
  denomination?: string
  date?: string
  values?: QualityControlValue[]
}

export interface QualityControlValue {
  id: string
  qualityControlId: string
  qualityCharacteristicId: string
  note: string
  order: number
  stringValue: string
  numberValue: number
  logicValue: boolean
  targetBoolean: boolean
  targetText: string
  targetNumericFrom: number
  targetNumericUntil: number
  targetCompare: boolean
}

export interface ArticleStockPositionData {
  id: string
  companyId?: string
  articleId: string
  depositId?: string
  stockpositionId?: string
  minQuantityStoringposition?: number
  maxQuantityStoringposition?: number
}

export interface StockPositionQuantity {
  stockposition?: StockPosition
  articleId: string
  serialnumberId?: string
  quantity: number
}

export interface StockpositionQuantityForKanbanTransfer {
  articleId: string
  serialnumber?: SerialNumber
  stockposition?: StockPosition
  quantity: number
  incomingDate?: Date
}

export interface Parcel {
  id: string
  trackingnumber: string
  documentId: string
  freighterId: string
  protocol: string
}

export type WarehouseFilterConfig = {
  filterConfig?: ApiFilterConfig
  sortConfig?: ApiFilterConfig
  groupConfig?: ApiFilterConfig
}

export type ApiFilterConfig = ApiFilterConfigEntry[]
export type ApiFilterConfigEntry = {
  definitionId: string
  description: string
}
