import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { Production, SerialNumber } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import useRouteParam from '../../hooks/useRouteParam'
import { QualityControlMode, ScreenType } from '../../types'
import SerialnumberSelectionView from '../../views/InputViews/SerialnumberSelectionView'
import ProductionSelectionView from '../../views/Production/ProductionSelectionView'

export default function QualityManagementSelectionScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const type = useRouteParam<QualityControlMode>('type')

  function handleSNSelection(serialNumber: SerialNumber) {
    navigation.navigate(ScreenType.QualityManagement, { serialNumber })
  }

  function handleProductionSelected(production: Production) {
    navigation.navigate(ScreenType.QualityManagement, { production })
  }

  return (
    <MWS_Screen title={i18n.t('QUALITY_MANAGEMENT')}>
      {type === QualityControlMode.serialNumber && <SerialnumberSelectionView onSelected={handleSNSelection} />}
      {type === QualityControlMode.production && <ProductionSelectionView onSelected={handleProductionSelected} />}
    </MWS_Screen>
  )
}
