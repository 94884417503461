/* eslint-disable @typescript-eslint/no-floating-promises */

import { useIsMounted } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, ListRenderItem, NativeScrollEvent, NativeSyntheticEvent, StyleProp, ViewStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import useOnScreenFocus from '../hooks/useOnScreenFocus'
import IMLoadingIndicator from './IMLoadingIndicator'
import IMRefreshControl from './IMRefreshControl'
import ListSpacer from './MWS/ListSpacer'
import NoEntriesTag from './NoEntriesTag'

export interface GrowingListProps<T> {
  loadItems: (skip: number, take: number) => T[] | Promise<T[]>
  renderItem: ListRenderItem<T> | null | undefined
  take?: number
  onError?: (error: any) => void
  onLoaded?: (skippedElements?: number) => void
  keyExtractor?: (item: T, index: number) => string
  reloadOnFocus?: boolean
  style?: StyleProp<ViewStyle>
  loadRef?: React.MutableRefObject<{ load: (skip?: number) => Promise<void> }>
}

enum ReloadType {
  none,
  load,
  reload,
}

const defaultTakeItems = 10
const loadNextDistance = 20
export default function GrowingList<T>(props: GrowingListProps<T>) {
  const isMounted = useIsMounted()
  const listRef = useRef<FlatList<T>>(null)
  const [data, setData] = useState<T[]>([])
  const [loading, setLoading] = useState<ReloadType>(ReloadType.none)
  const endOfListReached = useRef<boolean>(false)
  const endOfData = useRef<boolean>(false)
  const take = useMemo(() => props.take ?? defaultTakeItems, [])

  if (props.loadRef) props.loadRef.current = { load: load }

  useOnScreenFocus(() => {
    if (!props.reloadOnFocus) return
    load(0)
  })

  useEffect(() => {
    load()
  }, [])

  async function load(skipElements?: number) {
    try {
      setLoading(!skipElements ? ReloadType.reload : ReloadType.load)
      const skip = skipElements ?? 0
      const result = await props.loadItems(skip, take)
      if (!isMounted.current) return

      endOfData.current = !result?.length
      endOfListReached.current = false
      if (props.onLoaded) props.onLoaded(skip)
      if (skip === 0) {
        setData(result ?? [])
        listRef.current?.scrollToOffset({ offset: 0 })
        return
      }
      setData(prev => [...prev, ...(result ?? [])])
    } catch (error) {
      console.error(error)
      if (!isMounted.current) return
      if (props.onError) props.onError(error)
      endOfData.current = false
      endOfListReached.current = false
    } finally {
      setLoading(ReloadType.none)
    }
  }

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    if (!endOfListReached.current) {
      if (event.nativeEvent.layoutMeasurement.height + event.nativeEvent.contentOffset.y >= event.nativeEvent.contentSize.height - loadNextDistance) {
        endOfListReached.current = true
        if (!endOfData.current) {
          load(data.length)
        }
      }
    }
  }

  return (
    <FlatList
      ref={listRef}
      style={props.style}
      data={data}
      renderItem={props.renderItem}
      keyExtractor={props.keyExtractor}
      refreshing={loading === ReloadType.reload}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      refreshControl={<IMRefreshControl refreshing={loading === ReloadType.reload} onRefresh={() => load(0)} />}
      ListFooterComponent={<ListFooter loading={loading === ReloadType.load} endOfData={endOfData.current} noData={!data || data.length === 0} />}
      onScroll={onScroll}
    />
  )
}

function ListFooter(props: { loading: boolean; endOfData: boolean; noData: boolean }) {
  if (props.loading) {
    return <IMLoadingIndicator isVisible={props.loading} style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }} />
  } else if (props.noData) {
    return <NoEntriesTag />
  }
  return <ListSpacer height={70} />
}
