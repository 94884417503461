export const InfomindsColors = {
  text: '#35312E',
  textDetail: '#6D716E',
  main: '#26d07c',
  teal: '#3AB398',
  tealDarker: '#40AC98',
  green: '#138B43',
  greenDarker: '#158C46',
  blue: '#1C8EC1',
  blueDarker: '#238BC0',
  yellow: '#EEB140',
  yellowDarker: '#F2B540',
  red: '#C5242B',
  redDarker: '#C3252D',
  purple: '#8E1957',
  purpleDarker: '#8C255D',
  grey: '#727474',
  greyDarker: '#6D716E',
  dark: '#35312E',
  darker: '#1C1D1F',
}

export const Colors = {
  tint: InfomindsColors.main,
  listItem: InfomindsColors.dark,
  icon: '#7C7C7D',
  error: InfomindsColors.red,
  info: InfomindsColors.yellow,
  light: {
    text: InfomindsColors.text,
    textDetail: InfomindsColors.textDetail,
    textPlaceholder: '#6D776E',
    background: '#fff',
    backgroundModal: '#fff',
    inputBackground: '#F6F6F6',
    inputBorder: '#E8E8E8',
    card: '#fff',
  },
  dark: {
    text: '#fff',
    textDetail: InfomindsColors.textDetail,
    textPlaceholder: '#999999',
    background: '#000',
    backgroundModal: '#0E0E0E',
    inputBackground: '#1C1D1F',
    inputBorder: InfomindsColors.dark,
    card: '#1C1D1F',
  },
  red: InfomindsColors.red,
  yellow: InfomindsColors.yellow,
  black: InfomindsColors.dark,
  neutral: InfomindsColors.darker,
  white: '#fff',
  grey: '#313B48',
  modern: {
    red: '#FF6262',
  },
}
