import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { PackingListMovement } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { Colors } from '../../constants/Colors'
import { MWS_COLORS } from '../../constants/Constants'
import { articleUtils } from '../../utils/articleUtils'
import packingListUtils from '../../utils/packingListUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export interface PackingListMovementCardProps {
  item: PackingListMovement
  style?: StyleProp<ViewStyle>
}

export default function PackingListMovementCard(props: PackingListMovementCardProps) {
  const anyMovement = props.item
  const description = anyMovement?.description ?? ''
  const quantityCommissioned = useMemo(
    () => Utils.sum(props.item.collonumbers, c => c.quantity) * packingListUtils.getColloToUsedUnitConversionFactor(props.item),
    [props.item.quantity, props.item]
  )
  const quantityText = `${articleUtils.formatQuantity(quantityCommissioned ?? 0) || '0'}/${articleUtils.formatQuantity(props.item.quantity ?? 0)} ${
    anyMovement?.unitCode
  }`

  function getBackgroundColor() {
    if (!props.item) return
    if (props.item && Utils.roundToDefaultPrecision(quantityCommissioned) >= Utils.roundToDefaultPrecision(props.item.quantity)) return MWS_COLORS.OK
    return MWS_COLORS.NOT_OK
  }
  function getQuantityTextColor() {
    if (quantityCommissioned > props.item.quantity) return Colors.red
    return Colors.light.text
  }

  return (
    <MWS_BaseCard
      icon={'box-taped'}
      cardStyle={props.style}
      style={{ justifyContent: 'flex-start', flexDirection: 'column', backgroundColor: getBackgroundColor() }}>
      <View style={{ flexDirection: 'row' }}>
        <IMText
          numberOfLines={1}
          primary
          style={{ flex: 1, marginRight: 10, justifyContent: 'flex-start', color: Colors.light.text }}>{`${props.item?.article.code.trim()} - ${
          props.item?.article.searchtext
        }`}</IMText>
        <IMText primary style={{ color: getQuantityTextColor() }}>
          {quantityText}
        </IMText>
      </View>
      <View>
        <IMText secondary style={{ color: Colors.light.textDetail }} numberOfLines={3}>
          {description}
        </IMText>
      </View>
    </MWS_BaseCard>
  )
}
