import { useApi } from '@infominds/react-api'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, OngoingInventoryToConfirm } from '../../apis/apiTypes'
import OngoingInventoryToConfirmListCard from '../../cards/Inventory/OngoingInventoryToConfirmListCard'
import DepositCard from '../../cards/StockPosition/DepositCard'
import FilterSortBar, { SortOption } from '../../components/FilterSortBar'
import IMRefreshControl from '../../components/IMRefreshControl'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { Utils } from '../../utils/Utils'

const sortOptions: SortOption[] = [
  {
    id: 'default',
    icon: 'shelf',
  },
  {
    id: 'article',
    icon: 'article',
  },
  {
    id: 'date',
    icon: 'calendar',
  },
]

export default function OngoingInventoryToConfirmListView(props: {
  onOngoingInventoryToConfirmSelected: (ongoingInventory: OngoingInventoryToConfirm) => void
}) {
  const [items, loadItems, loading] = useApi(api.getOngoingInventoriesToConfirm, [])
  const userSetting = useUserSettings()
  const depositList = useMemo(
    () =>
      Utils.keepUniques(
        (items?.filter(d => !!d?.stockposition?.deposit?.id).map(d => d.stockposition?.deposit) as Deposit[]) ?? [],
        deposit => deposit?.id
      ),
    [items]
  )
  const [filterByDeposit, setFilterByDeposit] = useState<Deposit[] | undefined>(undefined)
  const [sort, setSort] = useState<SortOption>(sortOptions[0])
  const isFocused = useIsFocused()

  const itemsFiltered = useMemo(
    () => items?.filter(item => !filterByDeposit?.length || !!filterByDeposit.find(deposit => item.stockposition?.deposit.id === deposit.id)),
    [filterByDeposit, items, sort]
  )

  useEffect(() => {
    load()
  }, [isFocused])

  function load() {
    loadItems({ toConfirm: true })
      .then(loadedItems => {
        const foundDeposit = loadedItems.find(q => q.stockposition?.deposit.id === userSetting?.depositId)?.stockposition?.deposit
        if (foundDeposit) setFilterByDeposit([foundDeposit])
      })
      .catch(console.error)
  }

  return (
    <View style={{ flex: 1 }}>
      <FilterSortBar
        style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        filterItems={depositList}
        activeFilter={filterByDeposit}
        setFilter={setFilterByDeposit}
        renderItem={(item, _index, selected) => <DepositCard deposit={item} isMarked={selected} />}
        sortOptions={sortOptions}
        activeSort={sort}
        setSort={setSort}
        activeFilterCaptionExtractor={item => item.code}
      />
      <FlatList
        data={itemsFiltered}
        renderItem={({ item }) => (
          <OngoingInventoryToConfirmListCard ongoingInventory={item} onPress={() => props.onOngoingInventoryToConfirmSelected(item)} />
        )}
        refreshControl={<IMRefreshControl refreshing={loading} onRefresh={load} />}
        ListFooterComponent={(itemsFiltered && itemsFiltered?.length > 0) || loading ? <ListSpacer /> : <NoEntriesTag />}
      />
    </View>
  )
}
