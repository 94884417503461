import React from 'react'
import { View } from 'react-native'

import { StockTemplate } from '../../apis/apiTypes'
import PositionCard from '../../cards/StockPosition/PositionCard'
import { Article } from '../../classes/Article'
import { TransferPositionHandler } from '../../hooks/specific/useTransferPositionHandler'
import { Position } from '../../types'
import PositionAvailabilitySelectionView from './PositionAvailabilitySelectionView'
import SuggestedPositionSelectionView from './SuggestedPositionSelectionView'

interface TransferPositionViewProps {
  show: boolean
  article: Article | undefined
  handler: TransferPositionHandler
  checkAvailability: boolean
  suggestedPositionsActive?: boolean
  showSuggestedPositions?: boolean
  sourcePositionForSuggestions?: Position
  selectDepositCaption?: string
  selectStockPositionCaption?: string
  stockTemplate?: StockTemplate
}

export default function TransferPositionSelectorView(props: TransferPositionViewProps) {
  const handler = props.handler
  const showSelector = !handler.isOk && props.show
  const showCard = handler.deposit && props.show

  const selectDeposit = handler.depositRequired && !handler.deposit
  const selectStockPosition = handler.stockPositionRequired && !handler.stockPosition && !!handler.deposit?.isStockpositionActive
  const showSuggestions = props.show && !!props.showSuggestedPositions && (selectDeposit || selectStockPosition)

  function getCardDeleteFunction() {
    if (
      handler.required &&
      ((handler.depositRequired && !!handler.deposit) ||
        (handler.stockPositionRequired && !!handler.stockPosition) ||
        (handler.quantityRequired && !!handler.quantity))
    ) {
      return () => handler.clear()
    }
    return undefined
  }

  function getCardPressedFunction() {
    if (handler.required && handler.quantityRequired && handler.isOk) {
      return () => handler.setQuantity(0)
    }
    return undefined
  }

  function getCardOnPinFunction() {
    if (handler.required && handler.stockPositionRequired) {
      return () => handler.setStockPositionPinned(prev => !prev)
    }
    return undefined
  }

  return (
    <View style={props.showSuggestedPositions && { flex: 1 }}>
      {showCard && (
        <PositionCard
          deposit={handler.deposit}
          stockPosition={handler.stockPosition}
          onDelete={getCardDeleteFunction()}
          onPress={getCardPressedFunction()}
          onPin={getCardOnPinFunction()}
          isPinned={handler.stockPositionPinned}
        />
      )}
      <PositionAvailabilitySelectionView
        show={!!showSelector}
        article={props.article}
        handler={handler}
        checkAvailability={props.checkAvailability}
        selectDepositCaption={props.selectDepositCaption}
        selectStockPositionCaption={props.selectStockPositionCaption}
      />

      <SuggestedPositionSelectionView
        active={!!props.suggestedPositionsActive}
        show={showSuggestions}
        stockTemplate={props.stockTemplate}
        article={props.article}
        onSelected={(deposit, stockPosition) => {
          handler.setDeposit(deposit)
          handler.setStockPosition(stockPosition)
        }}
        sourceDeposit={props.sourcePositionForSuggestions?.deposit}
        sourceStockPosition={props.sourcePositionForSuggestions?.stockPosition}
      />
    </View>
  )
}
