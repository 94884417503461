import { IM, OfflineNotice } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { Alert, LogBox, Platform, StyleSheet, useWindowDimensions, View } from 'react-native'
import BootSplash from 'react-native-bootsplash'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'

import { languageResources } from './assets/languages/LanguageResources'
import { DoubleClickContextProvider } from './contexts/DoubleClickContext'
import { LoadingIndicatorContextProvider } from './contexts/LoadingIndicatorContext'
import { RuntimeStoringContextProvider } from './contexts/RuntimeStorageContext'
import { UserSettingsContext, UserSettingsContextProvider } from './contexts/UserSettingsContext'
import Navigation from './navigation'

import './init'

import { ApiClientProvider } from '@infominds/react-api'
import { useBarcodeScanner } from '@infominds/react-native-barcode-scanner'
import {
  _INTERNAL_LicenseAuthenticationGlobals,
  _INTERNAL_LicenseDataGlobals,
  AuthenticationContainer,
  useStorageMigration,
} from '@infominds/react-native-license'

import ContextMigration from './components/ContextMigration'
import { TransferContextProvider } from './contexts/TransferContext'
import { themeExtension } from './ThemeExtension'
import versionsJSON from './versions.json'

export default function App() {
  const { fontScale } = useWindowDimensions()
  const migrated = useStorageMigration({ isExpensesApp: false })

  useBarcodeScanner(() => undefined) //required to cause initializing of Scanner, otherwise first usage will cause a short lag, before it can be used. DONT REFACTOR

  useEffect(() => {
    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
    }
  }, [])

  if (migrated === undefined) return <></>
  if (migrated === false) console.error('🛑 Migration failed!')

  return (
    <SafeAreaProvider key={fontScale}>
      <IM.LanguageProvider resources={languageResources}>
        <IM.LanguageContext.Consumer>
          {languageContext => {
            if (!languageContext?.initOk) return <IM.LoadingSpinner isVisible />

            return (
              <IM.ThemeProvider theme={themeExtension}>
                <IM.ThemeContext.Consumer>
                  {themeContext => (
                    <ContextMigration>
                      <UserSettingsContextProvider>
                        <UserSettingsContext.Consumer>
                          {userSettingsContext => {
                            const userSettings = userSettingsContext?.settings

                            return (
                              <AuthenticationContainer
                                config={{
                                  moduleCode: 'APP',
                                  projectCode: 'APP-RXMWS',
                                  apiType: '.net',
                                  disableSilentLogin: 'ask',
                                  keepLicenseOnLogout: true,
                                  aad: false,
                                  debug: __DEV__,
                                  inactivityTimeout:
                                    userSettings?.activeMinutesForAutomaticLogout !== undefined && userSettings?.activeMinutesForAutomaticLogout !== 0
                                      ? { minutes: userSettings?.activeMinutesForAutomaticLogout }
                                      : undefined,
                                }}
                                locale={languageContext.language}
                                versionsFile={versionsJSON}
                                onVersionCheckEnd={({ error }) => error && Alert.alert(error.title, error.message)}>
                                {authContext => {
                                  const authReady = authContext && authContext.isLoggedIn !== undefined

                                  authReady && BootSplash.hide().catch(console.error)

                                  if (authContext === undefined || authReady === undefined || authContext.isLoggedIn === undefined) {
                                    return <IM.LoadingSpinner isVisible />
                                  }

                                  return (
                                    <ApiClientProvider
                                      globalAuthenticationData={_INTERNAL_LicenseAuthenticationGlobals}
                                      globalConnectionData={_INTERNAL_LicenseDataGlobals}
                                      urlPrefix="/api/"
                                      debugLog
                                      useEncoding>
                                      <DoubleClickContextProvider>
                                        <RuntimeStoringContextProvider>
                                          <LoadingIndicatorContextProvider>
                                            <ToastProvider>
                                              <View style={[styles.screen, { backgroundColor: themeContext?.theme.background }]}>
                                                {Platform.OS !== 'web' ? <OfflineNotice text={languageContext.i18n.t('NO_INTERNET')} /> : <></>}
                                                <TransferContextProvider>
                                                  <Navigation />
                                                </TransferContextProvider>
                                              </View>
                                            </ToastProvider>
                                          </LoadingIndicatorContextProvider>
                                        </RuntimeStoringContextProvider>
                                      </DoubleClickContextProvider>
                                    </ApiClientProvider>
                                  )
                                }}
                              </AuthenticationContainer>
                            )
                          }}
                        </UserSettingsContext.Consumer>
                      </UserSettingsContextProvider>
                    </ContextMigration>
                  )}
                </IM.ThemeContext.Consumer>
              </IM.ThemeProvider>
            )
          }}
        </IM.LanguageContext.Consumer>
      </IM.LanguageProvider>
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  screen: { flex: 1 },
})
