import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { SerialNumber } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import SerialnumberSelectionView from '../../views/InputViews/SerialnumberSelectionView'

export default function SerialnumberSelectionScreen() {
  const actionType = useRouteParam<string>('actionType')
  const navigation = useNavigation()

  function navigateToInfoScreen(serialNumber: SerialNumber) {
    if (actionType === 'isOpenMacAddress') navigation.navigate(ScreenType.SerialnumberMacAddress, { serialnumber: serialNumber })
    else navigation.navigate(ScreenType.SerialnumberManufacturerNumber, { serialnumber: serialNumber })
  }

  return (
    <MWS_Screen>
      <SerialnumberSelectionView onSelected={navigateToInfoScreen} clearResultsOnScreenFocus style={{ flex: 1 }} />
    </MWS_Screen>
  )
}
