import { useEffect, useMemo } from 'react'

import { Collo, Deposit, PackingListMovement, SerialNumber, StockPosition } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import { PackingListArticle } from '../../classes/PackingListCollection'
import { articleUtils } from '../../utils/articleUtils'
import packingListUtils from '../../utils/packingListUtils'
import { Utils } from '../../utils/Utils'
import useArticle from '../useArticle'
import useItemSelector, { ItemSelector } from '../useItemSelector'
import { PackingListHandler } from './usePackingListHandler'

export interface PackingListMovementHandler {
  packingListHandler: PackingListHandler
  packingListArticle?: PackingListArticle
  scannedSerialNumber?: SerialNumber
  anyMovement?: PackingListMovement
  article?: Article
  deposit?: Deposit
  stockPosition?: StockPosition
  colloSelector: ItemSelector<Collo>
  movementCollos: Collo[]
  totalQuantity: number
  selectedQuantity: number
  missingQuantity: number
  mandatoryScanningIndividualQuantities?: boolean
}

export default function usePackingListMovementHandler(
  packingListHandler: PackingListHandler,
  packingListArticle: PackingListArticle,
  scannedSerialNumber?: SerialNumber,
  isPreConsignment?: boolean,
  isProductionConsignment?: boolean
): PackingListMovementHandler {
  const anyMovement = (packingListArticle?.movements ?? []).length > 0 ? packingListArticle?.movements[0].movement : undefined
  const article = useArticle(packingListArticle?.article, {
    useSalesUnit: packingListHandler.userSettings?.isVpk1PickingActive,
    isHistoricalSerialNumberActive: packingListHandler.userSettings?.isHistoricalSerialnumberActive && !packingListHandler.ignoreHistoricalSN,
    useUnit: articleUtils.createMeasurementUnit(
      anyMovement?.unitCode,
      anyMovement?.unitId,
      packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement)
    ),
  })
  const movementCollos = useMemo(() => packingListUtils.getCollosFromPackingListMovements(packingListArticle?.movements.map(m => m.movement)), [])
  const colloSelector = useItemSelector<Collo>(
    isPreConsignment || isProductionConsignment ? packingListUtils.joinSameCollos(movementCollos) : movementCollos
  )
  const totalQuantity = Math.max(
    0,
    Utils.sum(packingListArticle?.movements, m => m.movement.quantity)
  )
  const selectedQuantity = Utils.sum(colloSelector.items, item => item.quantity)
  const missingQuantity = Math.max(0, totalQuantity - selectedQuantity)

  const deposit = Utils.getValueIfAllAreEqual(packingListArticle?.movements, m => m.movement.deposit?.id)?.movement.deposit
  const stockPosition = Utils.getValueIfAllAreEqual(packingListArticle?.movements, m => m.movement.stockposition?.id)?.movement.stockposition

  const mandatoryScanningIndividualQuantities = Utils.getValueIfAllAreEqual(
    packingListArticle?.movements,
    m => m.movement.mandatoryScanningIndividualQuantities
  )?.movement.mandatoryScanningIndividualQuantities

  useEffect(() => {
    packingListHandler.setHasUnsavedChanges(!!colloSelector.items.find(c => !c.id))
  }, [colloSelector.items])

  return {
    packingListHandler,
    packingListArticle,
    scannedSerialNumber,
    anyMovement,
    article,
    deposit,
    stockPosition,
    colloSelector,
    movementCollos,
    totalQuantity,
    selectedQuantity,
    missingQuantity,
    mandatoryScanningIndividualQuantities,
  }
}
