import { useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { Modal, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Colors } from '../../constants/Colors'

type Props = {
  isVisible: boolean
  close: () => void
  content: ReactNode
  style?: StyleProp<ViewStyle>
  keyboardAvoiding?: boolean
}

export type ModalBottomProps = Props

export default function ModalBottom(props: ModalBottomProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { style, content, ...otherProps } = props

  return (
    <Modal transparent={true} animationType="fade" visible={props.isVisible} style={styles.view} {...otherProps}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}>
        <View
          style={[
            {
              height: '60%',

              width: '95%',
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,

              backgroundColor: theme.backgroundModal,
              alignSelf: 'flex-end',
            },
            style,
          ]}>
          {content}
        </View>
      </View>
    </Modal>
  )
}
const styles = StyleSheet.create({
  view: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  bottomButton: {
    marginBottom: 20,
  },
})
