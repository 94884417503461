import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { TipType } from '../../apis/apiRequestTypes'
import { Collo, SerialNumber } from '../../apis/apiTypes'
import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import StockPositionCheckSwitch from '../../components/StockPositionCheckSwitch'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { IMLayout } from '../../constants/Styles'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { PackingListHandler } from '../../hooks/specific/usePackingListHandler'
import usePackingListMovementHandler from '../../hooks/specific/usePackingListMovementHandler'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import PackingListColloEditorModal from '../../modals/PackingListColloEditorModal'
import PackingListReplaceMovementModal from '../../modals/PackingListReplaceMovementModal'
import colloUtils from '../../utils/colloUtils'
import PackingListMovementCollosView from './PackingListMovementCollosView'
import PackingListMovementInputView from './PackingListMovementInputView'
import PackingListMovementMultiScanView from './PackingListMovementMultiScanView'
import PackingListSubmitButtonsView from './PackingListSubmitButtonsView'

export default function PackingListMovementView(props: {
  packingListHandler: PackingListHandler
  packingListArticle: PackingListArticle
  scannedSerialNumber?: SerialNumber
  reloadPackingLists: (keepMovementWithId?: string) => void
}) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const userSettings = useUserSettings()
  const loader = useLoadingIndicator()
  const handler = usePackingListMovementHandler(
    props.packingListHandler,
    props.packingListArticle,
    props.scannedSerialNumber,
    props.packingListHandler.isPreConsignment,
    props.packingListHandler.isProductionConsignment
  )
  const editorModal = useModalController<Collo>()
  const changeArticleModal = useModalController()
  const [isTipLoaded, setIsTipLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (isTipLoaded) return
    loadTip()
  }, [])

  const articleCanBeChanged =
    !props.packingListHandler.isPreConsignment &&
    !props.packingListHandler.isProductionConsignment &&
    !!props.packingListHandler.userSettings?.isPackingReplaceItemActive &&
    handler.colloSelector.items.filter(item => !!item.id).length === 0

  const showMultiScanView =
    !props.packingListHandler.isPreConsignment &&
    !props.packingListHandler.isProductionConsignment &&
    props.packingListHandler.packingLists &&
    props.packingListHandler.packingLists?.length > 0 &&
    !props.packingListHandler.packingLists[0].internalPackinglist &&
    handler.mandatoryScanningIndividualQuantities

  function getQuantityFromCollos() {
    return colloUtils.getQuantity(handler.colloSelector.items)
  }

  function handleColloDelete(item: Collo) {
    if (!item?.id) return handler.colloSelector.remove(item)

    loader.setLoading(true)
    let collosToDelete: Collo[] = []
    if (handler.packingListHandler.isPreConsignment) {
      collosToDelete = handler.movementCollos.filter(
        collo => colloUtils.getIdentificationHash(collo, true, true) === colloUtils.getIdentificationHash(item, true, true)
      )
    } else {
      collosToDelete.push(item)
    }
    const requests = collosToDelete.map(collo => api.deleteCollo({ id: collo?.id ?? '' }))
    Promise.all(requests)
      .then(() => {
        handler.colloSelector.remove(item)
        handler.packingListHandler.removeCollos(collosToDelete)
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      .catch(reason => alert.error(reason?.Message ?? i18n.t('FailedToDeleteCollo')))
      .finally(() => loader.setLoading(false))
  }

  function handleColloQuantityChanged(quantity: number, collo: Collo | undefined) {
    if (!quantity || !collo) return
    handler.colloSelector.remove(collo)
    handler.colloSelector.add({ ...collo, quantity: quantity })
  }

  function loadTip() {
    if (!handler.article?.info) return

    api
      .getTip({ type: TipType.Article, masterId: handler.article?.info.id })
      .then(result => {
        if (result) alert.info(result)
        setIsTipLoaded(true)
      })
      .catch(console.error)
  }

  return (
    <View style={IMLayout.flex.f1}>
      <PackingListColloEditorModal
        article={handler.article}
        packingListMovement={handler.anyMovement}
        controller={editorModal}
        onSubmit={handleColloQuantityChanged}
      />
      <PackingListArticleCard
        item={handler.packingListArticle}
        navigateToArticleInfoOnPress
        showAvailability
        showOrderSerialnumber
        quantity={getQuantityFromCollos()}
        showPackingLists={handler.packingListHandler.isPreConsignment || handler.packingListHandler.isProductionConsignment}
        endButton={
          articleCanBeChanged
            ? {
                icon: 'pen',
                onPress: () => {
                  changeArticleModal.show()
                },
              }
            : undefined
        }
        style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
      />
      {!!handler.stockPosition &&
        userSettings?.additionalFieldDefId_CheckStockposition !== null &&
        userSettings?.additionalFieldDefId_CheckStockposition !== '' && <StockPositionCheckSwitch stockpositionId={handler.stockPosition.id} />}
      {showMultiScanView && (
        <PackingListMovementMultiScanView handler={handler} style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }} />
      )}
      {!showMultiScanView && (
        <PackingListMovementInputView handler={handler} style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }} />
      )}
      <PackingListMovementCollosView
        show={handler.colloSelector.any}
        packingListArticle={handler.packingListArticle}
        collos={handler.colloSelector.items}
        onDelete={handleColloDelete}
        onPress={editorModal.show}
      />
      <PackingListSubmitButtonsView handler={handler} />

      <PackingListReplaceMovementModal
        handler={handler}
        controller={changeArticleModal}
        onDone={() => {
          changeArticleModal.close()
          props.reloadPackingLists(handler.anyMovement?.id)
        }}
        onNotDeliverable={() => {
          changeArticleModal.close()
          props.reloadPackingLists()
        }}
      />
    </View>
  )
}
