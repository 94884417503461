import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'

import { Production } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { ScreenType } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import { customerUtils } from '../../utils/customerUtils'
import productionUtils from '../../utils/ProductionUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ProductionCard(props: {
  handler: Production | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  showQuantities?: boolean
  showQuantityToBeConfirmed?: boolean
  navigateToArticleInfoOnPress?: boolean
  employeeTimeType?: string
  requiredQuantity?: string
}) {
  const { i18n } = useLanguage()

  const navigation = useNavigation()
  const usedUnit = useMemo(() => articleUtils.getUnitById(props.handler?.article, props.handler?.unitId ?? ''), [props.handler])
  const showQuantityToBeConfirmed = !!props.showQuantityToBeConfirmed && !!props.handler?.quantityBooked
  const quantityToConfirmText = useMemo(() => articleUtils.formatQuantity(props.handler?.quantityBooked, usedUnit), [props.handler])
  const requiredQuantity = props.requiredQuantity ?? props.handler?.quantity ?? 0
  const quantityProduced = (props.handler?.quantity ?? 0) - (props.handler?.quantityOpen ?? 0)
  const quantityText = props.requiredQuantity ?? `${quantityProduced}/${requiredQuantity} ${(props.handler?.unitCode ?? '').trim()}`

  function navigateToArticleInfo() {
    if (!props.handler?.article) return
    navigation.navigate(ScreenType.ArticleAvailability, { item: { article: props.handler?.article } })
  }

  if (!props.handler) return <></>

  return (
    <MWS_BaseCard
      onPress={props.navigateToArticleInfoOnPress && !props.onPress ? navigateToArticleInfo : props.onPress}
      onDelete={props.onDelete}
      icon={'conveyor-belt-box'}
      cardStyle={props.style}
      style={{ flexDirection: 'column' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <IMText numberOfLines={1} primary>
          {productionUtils.getTitle(props.handler)}
        </IMText>
        {props.showQuantities && (
          <IMText numberOfLines={1} primary>
            {quantityText}
          </IMText>
        )}
      </View>
      {props.handler.article && (
        <IMTextWithIcon
          numberOfLines={2}
          secondary
          icon="article"
          text={articleUtils.getArticleTitleDescription(props.handler.article, i18n.t('ID'))}
          style={{ marginRight: 2 }}
        />
      )}
      {props.handler.customer && (
        <IMTextWithIcon numberOfLines={1} secondary icon="building" text={customerUtils.getCustomerTitle(props.handler.customer)} />
      )}
      {!!props.employeeTimeType && <IMTextWithIcon numberOfLines={1} secondary icon="angle-right" text={props.employeeTimeType.trim()} />}
      {showQuantityToBeConfirmed && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 1 }}>
            <CardText value={`${i18n.t('ToBeConfirmed')}: `} />
          </View>
          <View>
            <CardText value={quantityToConfirmText} />
          </View>
        </View>
      )}
      {props.handler.additionalDescription && (
        <IMText numberOfLines={1} secondary>
          {props.handler.additionalDescription}
        </IMText>
      )}
    </MWS_BaseCard>
  )
}

const CardText = (props: { value: string; style?: StyleProp<TextStyle> }) => (
  <IMText numberOfLines={1} style={props.style}>
    {props.value}
  </IMText>
)
