import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { ReactElement } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDTO, ArticleMeasurementUnit, Deposit, Item, SerialNumber, StockPosition } from '../../apis/apiTypes'
import { IMIconButton } from '../../components/IMIconButton'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { ScreenType } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import MWS_BaseCard, { MWSBaseCardEndIconButtonProps } from '../MWS_BaseCard'

export interface ArticleCardProps {
  article: ArticleDTO | undefined
  customerId?: string
  quantity?: number | string
  quantityText?: string
  maxLinesDescription?: number
  unit?: ArticleMeasurementUnit
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  selected?: boolean
  serialNumber?: SerialNumber
  price?: string
  imageUri?: string
  addInfo?: string
  useDefaultUnit?: boolean
  endButton?: MWSBaseCardEndIconButtonProps
  isMarked?: boolean
  navigateToArticleInfoOnPress?: boolean | 'reloadThenNavigate'
  stockPosition?: StockPosition
  stockPositionInfo?: string
  deposit?: Deposit
  subComponent?: ReactElement
  textColor?: string
  configurableDescription?: string
  macAddressLabel?: string
  priceScale?: boolean
}

export default function ArticleCard(props: ArticleCardProps) {
  const { i18n } = useLanguage()

  const navigation = useNavigation()
  const loader = useLoadingIndicator()

  function getQuantityText() {
    const unit = props.unit ?? (props.useDefaultUnit ? articleUtils.getDefaultUnit(props.article) : undefined)
    if (props.quantity) return articleUtils.formatQuantity(props.quantity ?? 0, unit)
    if (props.quantityText) return `${props.quantityText} ${unit ? articleUtils.getUnitText(unit) : ''}`.trim()
    return ''
  }

  function navigateToArticleInfo() {
    if (!props.article) return
    if (props.navigateToArticleInfoOnPress === 'reloadThenNavigate') {
      loader.setLoading(true)
      api
        .getArticle({ id: props.article.id })
        .then(article => {
          if (article?.length) navigation.navigate(ScreenType.ArticleAvailability, { item: { article: article[0] } })
        })
        .catch(error => {
          console.error(error)
          navigation.navigate(ScreenType.ArticleAvailability, { item: { article: props.article } as Item })
        })
        .finally(() => loader.setLoading(false))
    } else {
      navigation.navigate(ScreenType.ArticleAvailability, { item: { article: props.article } })
    }

    return
  }

  function navigateToArticleSalesPriceScaleInfo() {
    if (!props.article) return
    loader.setLoading(true)
    api
      .getArticleSalesPriceScale({ articleId: props.article.id, customerId: props.customerId, measurementUnitId: props.unit?.id })
      .then(prices => {
        navigation.navigate(ScreenType.ArticleSalesPriceScale, { article: props.article, articleSalesPriceScales: prices, unit: props.unit?.code })
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => loader.setLoading(false))

    return
  }

  if (!props.article) return <></>
  return (
    <MWS_BaseCard
      icon={'article'}
      imageUri={props.imageUri}
      onPress={!!props.navigateToArticleInfoOnPress && !props.onPress ? navigateToArticleInfo : props.onPress}
      onDelete={props.onDelete}
      isSelected={props.selected}
      endButton={props.endButton}
      cardStyle={props.style}
      isMarked={props.isMarked}
      style={{ flexDirection: 'column' }}>
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            flex: 1,
            justifyContent: props.imageUri ? 'flex-start' : 'center',
          }}>
          <IMText color={props.textColor} primary>
            {props.article.code}
          </IMText>
          {!!props.configurableDescription && (
            <IMText color={props.textColor} secondary>
              {props.configurableDescription}
            </IMText>
          )}
          <IMText numberOfLines={props.maxLinesDescription ?? 4} color={props.textColor} secondary>
            {articleUtils.getArticleDescription(props.article, i18n.t('ID'))}
          </IMText>
          {(!!props.deposit || !!props.stockPosition) && (
            <IMTextWithIcon
              color={props.textColor}
              icon={props.stockPosition ? 'shelf' : 'warehouse-full'}
              text={stockPositionUtils.getTitle(props.stockPosition, props.deposit) + (props.stockPositionInfo ?? '')}
              iconColor={props.textColor}
            />
          )}
          {!!props.addInfo && (
            <IMText color={props.textColor} secondary>
              {props.addInfo}
            </IMText>
          )}
          {props.serialNumber && (
            <IMText color={props.textColor} primary>{`${i18n.t('SerialNumberShort')}: ${serialnumberUtils.getSerialNumberString(
              props.serialNumber,
              false,
              props.macAddressLabel
            )}`}</IMText>
          )}
        </View>
        <View style={{ justifyContent: 'center' }}>
          {(props.quantity !== undefined || props.quantityText !== undefined) && (
            <IMText color={props.textColor} style={{ alignSelf: 'flex-end', marginLeft: 10 }}>
              {getQuantityText()}
            </IMText>
          )}
          {(props.price !== undefined || props.priceScale) && (
            <View style={{ flexDirection: 'row', alignContent: 'center' }}>
              {props.price !== undefined && (
                <IMText color={props.textColor} style={{ textAlign: 'right', marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
                  {props.price}
                </IMText>
              )}
              {props.priceScale && (
                <IMIconButton
                  style={{ justifyContent: 'center' }}
                  iconSize={20}
                  icon={'infoCircle'}
                  onPress={() => {
                    navigateToArticleSalesPriceScaleInfo()
                  }}
                />
              )}
            </View>
          )}
        </View>
      </View>
      {props.subComponent}
    </MWS_BaseCard>
  )
}
