import { useLanguage } from '@infominds/react-native-components'
import { StackActions, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Item } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import BarcodeCard from '../../cards/Barcode/BarcodeCard'
import ScannerInput from '../../components/MWS/Inputs/ScannerInput'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useOnScreenFocus from '../../hooks/useOnScreenFocus'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'

export default function ArticleBarcodeArticleSelectionScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const route = useRoute()
  const isFocused = useIsFocused()

  const [item, setItem] = useState<Item>()
  const article = useArticle(item?.article, { serialNumber: item?.serialnumber }, [item])
  const loader = useLoadingIndicator()
  const [scannedBarcode, setBarcode] = useState<string>('')

  useOnScreenFocus(() => {
    setItem(undefined)
    navigation.setParams({ ...route.params, transferOk: undefined })
  })

  useEffect(() => {
    if (!article || !isFocused) return
  }, [article])

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: item })
  }

  function handleItemSelection(selectedItem: Item) {
    setItem(selectedItem)
  }

  async function checkAndSetArticleBarcode() {
    if (!article) return
    if (!scannedBarcode) return

    try {
      loader.setLoading(true)
      await api.postArticleBarcode({ articleId: article.info.id, barcode: scannedBarcode })
      alert.info(i18n.t('AssignArticleBarcodeSuccessful'))
      navigation.dispatch(StackActions.popToTop)
    } catch (reason) {
      alert.error(i18n.t('AssignArticleBarcode'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <MWS_Screen title={i18n.t('AssignArticleBarcode')}>
      {!article && (
        <>
          <ItemSelectionView onSelected={handleItemSelection} showResultInModal />
        </>
      )}
      {article && (
        <View style={{ flex: 1 }}>
          <ArticleCard
            article={article.info}
            serialNumber={article.serialNumber}
            onDelete={() => {
              setItem(undefined)
            }}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            onPress={showArticleInfoScreen}
          />
          <View>
            {!scannedBarcode && (
              <ScannerInput
                style={styles.input}
                placeHolder={i18n.t('SelectBarcode')}
                useInput={input => setBarcode(input.scannerInput ?? input.textInput ?? '')}
              />
            )}
            {!!scannedBarcode && (
              <View style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}>
                <BarcodeCard barcode={scannedBarcode} onDelete={() => setBarcode('')} />
                <SubmitButton onPress={checkAndSetArticleBarcode} title={i18n.t('Assign')} />
              </View>
            )}
          </View>
        </View>
      )}
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  infoView: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  input: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
