import React from 'react'
import { StyleSheet } from 'react-native'

import IMModal from '../components/IMModal'
import MWS_Image from '../components/MWS/MWS_Image'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { ModalController } from '../hooks/useModalController'

export default function ImageModal(props: { controller: ModalController<string>; title?: string }) {
  return (
    <IMModal isVisible={props.controller.isShown} close={props.controller.close} title={props.title}>
      <MWS_Image
        imageUri={props.controller.data}
        style={[styles.image]}
        imageStyle={{
          borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
          resizeMode: 'center',
        }}
      />
    </IMModal>
  )
}

const styles = StyleSheet.create({
  image: {},
})
