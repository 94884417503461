import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { ArticleMeasurementUnit } from '../apis/apiTypes'
import IMModal from '../components/IMModal'
import VKUnitAmountSelector, { UnitSelectorCheckAvailability } from '../components/MWS/Inputs/VKUnitAmountSelector'
import { STYLE_CONSTANTS } from '../constants/Constants'

export default function AmountEditorModal(props: {
  show: boolean
  value: number | undefined
  close: () => void
  onSubmit: (value: number) => void
  unit?: ArticleMeasurementUnit
  headerRenderItem?: () => JSX.Element
  checkAvailability?: UnitSelectorCheckAvailability
}) {
  const { i18n } = useLanguage()

  return (
    <IMModal title={i18n.t('EditAmount')} isVisible={props.show} keyboardAvoiding close={props.close}>
      {props.headerRenderItem ? props.headerRenderItem() : <></>}
      <VKUnitAmountSelector
        style={{ marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        value={props.value}
        usedUnit={props.unit}
        setValue={props.onSubmit}
        checkAvailability={props.checkAvailability}
      />
    </IMModal>
  )
}
