import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../apis/apiCalls'
import { StockPosition } from '../../apis/apiTypes'
import KanbanBoxCard from '../../cards/Kanban/KanbanBoxCard'
import { Article } from '../../classes/Article'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import { KanbanBox, SelectionViewProps } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import { KanbanUtils } from '../../utils/KanbanUtils'

export default function KanbanBoxSelectionView({
  title,
  noInfo,
  kanbanStockPosition,
  ...props
}: SelectionViewProps<KanbanBox> & { noInfo?: boolean; kanbanStockPosition: StockPosition | null | undefined }) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const userSettings = useUserSettings()
  const separator = userSettings?.kanbanBoxSeparator ?? '-'

  async function load(scannerInput: ScannerInputResult) {
    const input = scannerInput.scannerInput ?? scannerInput.textInput ?? ''
    if (!input.includes(separator)) throw new Error('Invalid Code')
    const [articleCode, stockPositionCode] = input.split(separator)

    let deposit = kanbanStockPosition?.deposit
    if (!deposit) {
      deposit = (await api.getStockPosition({ id: userSettings?.stockpositionIdKanban }))?.pop()?.deposit
    }

    const [articles, stockPositions] = await Promise.all([
      api.getArticle({ code: articleCode }),
      api.getStockPosition({ depositId: deposit?.id, code: stockPositionCode }),
    ])
    const result: KanbanBox[] = []
    if (stockPositions.length !== 1) throw new Error(i18n.t('STOCKPOSITION_NOT_UNIQUE'))
    const stockPosition = stockPositions[0]
    if (articles.length !== 1) throw new Error(i18n.t('InvalidArticle'))
    const article = articles[0]

    const box = KanbanUtils.createBox(
      new Article(article, { isHistoricalSerialNumberActive: userSettings?.isHistoricalSerialnumberActive }),
      stockPosition
    )

    // read stockPositionQuantity for KanbanTransfer
    try {
      box.stockPositionQuantityForKanbanTransfer =
        (await api.getStockPositionQuantityForKanbanTransfer({
          articleId: article.id,
          depositId: stockPosition.deposit.id,
          stockpositionIdToIgnore: stockPosition.id,
        })) ?? undefined
    } catch (_exception) {}

    if (!box.stockPositionQuantityForKanbanTransfer) {
      throw new Error(i18n.t('PutTheBoxBackNoQuantityAvailable'))
    }

    if (!noInfo) {
      try {
        // read current availability
        const availability = await api.getItemAvailability({
          articleId: article.id,
          depositId: stockPosition.deposit.id,
          stockPositionId: stockPosition.id,
        })
        if (availability) box.article.quantity = availability
      } catch (_exception) {}
      try {
        // read stockPositionData and set max minQuantity
        const stockPositionData = await api.getArticleStockPositionData({ articleId: article.id, stockPositionId: stockPosition.id })
        if (stockPositionData.length) box.minQuantityStockPosition = Math.max(...stockPositionData.map(spd => spd.minQuantityStoringposition ?? 0))
      } catch (_exception) {}
      try {
        // read stockPositionQuantities
        box.stockPositionQuantities =
          (
            await api.getStockPositionQuantities({
              articleId: article.id,
              stockPositionId: stockPosition.id,
              depositId: stockPosition.deposit.id,
              forKanban: true,
            })
          )?.filter(q => q.quantity > 0) ?? []
      } catch (_exception) {}
    }
    result.push(box)
    return result
  }

  function handleError(input?: string, error?: unknown): boolean {
    const message = Error_Utils.extractErrorMessageFromException(error)
    if (message) {
      alert.error(message)
      return true
    }
    return false
  }

  const RenderItem = useCallback((item: KanbanBox) => <KanbanBoxCard box={item} />, [])

  return (
    <MWS_Input type={'Kanban'} load={load} itemRenderer={RenderItem} title={title ?? i18n.t('SELECT_BOX')} handleError={handleError} {...props} />
  )
}
