import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef } from 'react'
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import PackingListColloCard from '../../cards/Packinglist/PackingListColloCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import ListSpacer from '../../components/MWS/ListSpacer'
import Title from '../../components/MWS/Title'
import { articleUtils } from '../../utils/articleUtils'
import colloUtils from '../../utils/colloUtils'
import { Utils } from '../../utils/Utils'

export default function PackingListMovementCollosView(props: {
  show: boolean
  packingListArticle: PackingListArticle | undefined
  collos: Collo[] | undefined
  title?: string
  onDelete?: (collo: Collo) => void
  onPress?: (collo: Collo) => void
  style?: StyleProp<ViewStyle>
}) {
  const { i18n } = useLanguage()

  const listRef = useRef<FlatList<Collo> | null>(null)
  const anyMovement = (props.packingListArticle?.movements ?? []).length > 0 ? props.packingListArticle?.movements[0].movement : undefined
  const quantityToCommission = Utils.sum(props.packingListArticle?.movements, m => m.movement.quantity)

  function handleOnDelete(item: Collo) {
    if (props.onDelete) props.onDelete(item)
  }

  function handleOnPress(item: Collo) {
    if (props.onPress) props.onPress(item)
  }

  const items = useMemo(() => (props.collos ?? []).sort(colloUtils.sortForCommissioning), [props.collos])

  useEffect(() => {
    if (!listRef?.current) return
    listRef.current.scrollToEnd()
  }, [items])

  function getListTitles(item: Collo, index: number) {
    if (!item) return <></>
    if (!!item.id && index === 0) {
      return (
        <Title style={styles.title}>{`${i18n.t('Consigned')} (${articleUtils.formatQuantity(
          Utils.sum(
            items.filter(q => !!q.id),
            q => q.quantity
          )
        )}/${articleUtils.formatQuantity(quantityToCommission)} ${anyMovement?.unitCode ?? ''})`}</Title>
      )
    }
    if (!item.id && (index === 0 || !!items[index - 1].id)) {
      return (
        <Title style={styles.title}>{`${i18n.t('ToBeConfirmed')} (${articleUtils.formatQuantity(
          Utils.sum(
            items.filter(q => !q.id),
            q => q.quantity
          )
        )} ${anyMovement?.unitCode ?? ''})`}</Title>
      )
    }
    return <></>
  }

  if (!props.show) return <></>
  return (
    <View style={[styles.list, props.style]}>
      {props.title && items && <Title>{props.title}</Title>}
      <FlatList
        ref={listRef}
        data={items}
        renderItem={({ item, index }) => (
          <>
            {getListTitles(item, index)}
            <PackingListColloCard
              packingListMovement={anyMovement}
              collo={item}
              showSerialWarranty
              onDelete={!!props.onDelete && colloUtils.colloCanBeDeleted(item) && (() => handleOnDelete(item))}
              onPress={
                !!props.onPress && !item.id && (!articleUtils.isSerialNumberActive(item.article) || !!item.article.isLottoSerialnumber)
                  ? () => handleOnPress(item)
                  : undefined
              }
            />
          </>
        )}
        ListHeaderComponent={<ListSpacer />}
        ListFooterComponent={<ListSpacer />}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  title: {
    marginTop: 0,
  },
})
