import { IM, IMLayout, IMStyle, useDimensions, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Pressable, ScrollView, StyleSheet } from 'react-native'

import FilterTag from '../../components/filter/FilterTag'
import SorterTag from '../../components/filter/SorterTag'
import useFilter from '../../hooks/filter/useFilter'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useOnLayout from '../../hooks/useOnLayout'
import { DataFilterConfigType, OrderDataSorter } from '../../types'
import { filterUtils } from '../../utils/FilterUtils'

export type ActiveFilterViewProps = { showFilter?: () => void; barHeight?: number; filterButtonText?: string; noFilterText?: string } & Pick<
  ViewProps,
  'style' | 'spacing'
>

export default function ActiveFilterView({ showFilter, style, barHeight = 34, filterButtonText, noFilterText, ...viewProps }: ActiveFilterViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const { isSmallDevice } = useDimensions()
  const buttonLayout = useOnLayout()

  const { filters, groups, changeFilterStatus } = useFilter()
  const activeFilters = useMemo(() => filterUtils.getActiveFilters(filters), [filters])
  const activeGroups = useMemo(() => groups.filter(el => el.active && !el.options?.isDefault), [groups])
  const activeOrders: OrderDataSorter<object>[] = []

  return (
    <IM.View style={[styles.container, { backgroundColor: theme.backgroundSecondary, height: barHeight }, IMLayout.shadow, style]} {...viewProps}>
      <ScrollView
        horizontal
        style={{
          marginRight: buttonLayout.layout?.width ? buttonLayout.layout?.width - IMLayout.borderRadius : 0,
        }}
        contentContainerStyle={[styles.scrollView, {}]}
        showsHorizontalScrollIndicator={false}>
        <IM.View
          key={'dummy-left'}
          style={{ width: IMLayout.horizontalMargin }} // dummy view to space first tag/text
        />
        {(activeGroups ? activeGroups.length === 0 : true) &&
          (activeFilters ? activeFilters.length === 0 : true) &&
          activeOrders.length === 0 &&
          isSmallDevice && <IM.Text secondary>{noFilterText ?? i18n.t('NO_FILTER_ACTIVE')}</IM.Text>}

        {activeGroups?.map(el => {
          return (
            <SorterTag
              key={`group-${el.id}`}
              sorter={el}
              isHeader
              onPress={() => changeFilterStatus(DataFilterConfigType.Group, el.id, false)}
              icon={['fal', 'layer-group']}
            />
          )
        })}
        {activeOrders.map(el => {
          return (
            <SorterTag key={`order-${el.id}`} sorter={el} isHeader onPress={() => changeFilterStatus(DataFilterConfigType.Order, el.id, false)} />
          )
        })}
        {activeFilters?.map(filter =>
          filter.values.map(el => {
            return <FilterTag key={`filter-${filter.id}-${el.value}`} el={el} icon={filter.options?.icon ?? ['fal', 'filter']} isHeader />
          })
        )}
        <IM.View
          key={'dummy-right'}
          style={{ width: IMLayout.horizontalMargin }} // dummy view to space last tag/text
        />
      </ScrollView>
      <IM.View style={[styles.filterButtonBackView, { backgroundColor: theme.backgroundSecondary, height: barHeight }]}>
        <Pressable
          onPress={showFilter}
          style={({ pressed }) => [
            {
              backgroundColor: theme.primary,
              opacity: pressed ? 0.5 : 1,
              height: barHeight,
            },
            styles.filterButtonPressable,
            IMLayout.shadow,
          ]}
          onLayout={buttonLayout.onLayout}>
          <IM.TextWithIcon
            alignIcon="right"
            icon={['fal', 'chevron-right']}
            style={{ color: IMStyle.palette.white }}
            iconColor={IMStyle.palette.white}>
            {filterButtonText ?? i18n.t('FILTERS')}
          </IM.TextWithIcon>
        </Pressable>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  filterButtonPressable: {
    paddingHorizontal: IMStyle.layout.horizontalMargin,
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
    flex: 1,

    alignItems: 'center',
    justifyContent: 'center',
  },
  filterButtonBackView: {
    marginLeft: IMLayout.horizontalMargin,

    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
    position: 'absolute',
    right: 0,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
