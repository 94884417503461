import { ApiError } from '../types'

export const Error_Utils = {
  exceptionIsResponse(exception: unknown): exception is Response {
    return !!(exception as Response).status
  },
  exceptionIsError(exception: unknown): exception is Error {
    return !!(exception as Error).message
  },
  exceptionIsApiError(exception: unknown): exception is ApiError {
    return !!(exception as ApiError).Message
  },
  extractErrorMessageFromException(exception: unknown): string {
    if (this.exceptionIsResponse(exception)) {
      return `${exception.status} ${exception.statusText}`
    }
    if (this.exceptionIsError(exception)) {
      return exception.message
    }
    if (this.exceptionIsApiError(exception)) {
      return exception.Message
    }
    return ''
  },
}
