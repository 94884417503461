import useRouteParam from '../useRouteParam'

const IncomingTransferActionType = 'PDA_ACCESS_UMBUCHUNG_WE'

/**
 * Determines if transfer is a "stock-receipt" (Wareneingang) Transfer
 */
export default function useIsStockReceiptTransfer() {
  const showTargetSuggestions = useRouteParam<string>('actionType') === IncomingTransferActionType

  return !!showTargetSuggestions
}
