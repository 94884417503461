import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { IMLayout } from '../../../constants/Styles'
import { QualityCharacteristicInputProps } from '../../../views/QualityManagement/QualityCharacteristicInput'
import IMIcon from '../../IMIcon'
import IMText from '../../IMText'
import PickerModal from '../../PickerModal'
import CharacteristicTitle from './QualityCharacteristicTitle'

export default function QualitySelectInput({ characteristic, value, setValue, validationResult }: QualityCharacteristicInputProps) {
  const { i18n } = useLanguage()

  const details = useMemo(() => {
    const result = [...(characteristic.details ?? [])]
    if (!characteristic.check?.isMandatory && value) result.push({ code: '', description: i18n.t('UNSELECT'), id: '' })
    return result
  }, [characteristic, value])

  return (
    <View>
      <CharacteristicTitle characteristic={characteristic} validationResult={validationResult} />
      <PickerModal
        asCard
        data={details}
        value={characteristic.details?.find(d => d.code === value)}
        onSelect={item => setValue(item.code)}
        captionExtractor={item => item.description?.trim()}
        selectedViewStyle={IMLayout.flex.f1}
        renderItem={(item, isSelected) => (
          <View style={[styles.renderItem, isSelected && styles.renderItemSelected]}>
            <IMText style={styles.renderItemText}>{item.description?.trim()}</IMText>
            {!item.code && !item.id && !isSelected && <IMIcon style={styles.trashIcon} icon={'trash'} />}
          </View>
        )}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  renderItem: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  renderItemSelected: {
    justifyContent: 'flex-start',
  },
  renderItemText: {
    textAlign: 'center',
  },
  trashIcon: {
    marginLeft: 5,
  },
})
