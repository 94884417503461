import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { Production, ProductionState } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import productionUtils from '../../utils/ProductionUtils'
import ProductionSelectionView from './ProductionSelectionView'

export default function ProductionInSelectionView(props: { onProductionSelected: (production: Production, article: Article) => void }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loadingIndicator = useLoadingIndicator()
  const userSettings = useUserSettings()

  function handleProductionSelection(production: Production) {
    if (production.quantityOpen === 0 || production.state !== ProductionState.Open) {
      alert.info(i18n.t('ProductionAlreadyComplete'))
    }
    loadingIndicator.setLoading(true)
    productionUtils
      .createProductionArticle(production, !!userSettings?.isHistoricalSerialnumberActive)
      .then(result => {
        props.onProductionSelected(production, result)
      })
      .catch(reason => {
        console.error(reason)
        alert.error(i18n.t('FailedToLoadAvailability'), production.code)
      })
      .finally(() => loadingIndicator.setLoading(false))
  }

  return <ProductionSelectionView onSelected={handleProductionSelection} history filterResultByOrder />
}
