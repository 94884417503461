import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Deposit, SerialNumber, StockPosition } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useAlert from '../../hooks/useAlert'
import useItemSelector from '../../hooks/useItemSelector'
import { articleUtils } from '../../utils/articleUtils'
import ArticleAmountSelectorV2, { ArticleAmountSelectionResult } from '../Article/ArticleAmountSelectorV2'
import SerialSelectionListView from './SerialSelectionListView'

export default function MultiSelectionView(props: {
  article: Article | undefined
  deposit?: Deposit
  stockPosition?: StockPosition
  onComplete: (selectedItems: ArticleAmountSelectionResult[]) => void
  deleteItem?: () => void
  style?: StyleProp<ViewStyle>
  selectSerialsByPosition?: boolean
  showSelectedSerialsWithPosition?: boolean
  checkAvailability?: boolean
  autoSelectAvailability?: boolean
  proposedQuantity?: number
  listTitleProvider?: (items: ArticleAmountSelectionResult[]) => string
  autoComplete?: boolean
  confirmationButtonCaption?: string
  confirmationButtonDisabled?: (selectedItems: ArticleAmountSelectionResult[]) => boolean
  keepAlreadyAddedItems?: boolean
  allowNewSerialCreation?: boolean
  ignoreAvailability?: boolean
  hideAvailability?: boolean
  hidePositionFromSelectedSerial?: boolean
  allowConfirmationWithoutSerialSelection?: boolean
  ignoreSerialNumber?: boolean
  allowedSerialNumbers?: SerialNumber[]
  errorSerialNotAllowedText?: string
  noPreloadedData?: boolean
  availabilityCheckFailedWorkaroundMessage?: string
  availabilityTolerance?: number
  availability?: number
}) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const [hideConfirmationButton, setHideConfirmationButton] = useState(false)
  const articleSerialSelector = useItemSelector<ArticleAmountSelectionResult>([], articleUtils.compareArticleAmountSelectionResult)

  useEffect(() => {
    if (!props.article) {
      return
    }
    if (props.autoSelectAvailability) selectAll()

    return () => {
      articleSerialSelector.clear()
    }
  }, [props.article?.info.id])

  function selectAll() {
    if (!props.article) return
    if (props.article.isSerialNumberActive) {
      props.article.serialNumberQuantities?.forEach(q => {
        handleArticleAmountSelection({
          quantity: q.quantity ?? 0,
          quantityOrdered: q.quantityOrdered ?? 0,
          quantityCommissioned: q.quantityCommissioned ?? 0,
          serialNumber: q.serialnumber,
          unit: props.article?.getUsedUnit(),
          deposit: q.deposit,
          stockPosition: q.stockposition,
        })
      })
    }
  }

  function handleArticleAmountSelection(result: ArticleAmountSelectionResult) {
    if (!result) return
    if (result.serialNumber) {
      if (articleSerialSelector.includes(result)) {
        alert.info(i18n.t('SerialnumberAlreadySelected'))
        return
      }
      if (props.autoComplete && articleSerialSelector.count + 1 === props.article?.serialNumberQuantities?.length) {
        props.onComplete([...articleSerialSelector.items, result])
        return
      }

      articleSerialSelector.add(result)
      return
    }
    props.onComplete([result])
  }

  return (
    <View style={[{ flex: 1 }, props.style]}>
      {props.article && (
        <>
          <ArticleAmountSelectorV2
            article={props.article}
            deposit={props.deposit}
            stockPosition={props.stockPosition}
            onSelected={handleArticleAmountSelection}
            checkAvailability={props.checkAvailability}
            selectSerialsWithPosition={props.selectSerialsByPosition}
            amountSelectionActive={setHideConfirmationButton}
            selectedItemsToFilter={props.keepAlreadyAddedItems ? undefined : articleSerialSelector.items}
            preSelectedSerial={props.article.serialNumber}
            proposedQuantity={props.proposedQuantity}
            allowNewSerialCreation={props.allowNewSerialCreation}
            ignoreAvailability={props.ignoreAvailability}
            hideAvailability={props.hideAvailability}
            hidePositionFromSelectedSerial={props.hidePositionFromSelectedSerial}
            ignoreSerialNumber={props.ignoreSerialNumber}
            allowedSerialNumbers={props.allowedSerialNumbers}
            errorSerialNotAllowedText={props.errorSerialNotAllowedText}
            noPreloadedData={props.noPreloadedData}
            availabilityCheckFailedWorkaroundMessage={props.availabilityCheckFailedWorkaroundMessage}
            availabilityTolerance={props.availabilityTolerance}
            availability={props.availability}
          />
          <SerialSelectionListView
            style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            selector={articleSerialSelector}
            onCompleted={() => {
              props.onComplete(articleSerialSelector.items)
            }}
            usedUnit={props.article.getUsedUnit()}
            title={props.listTitleProvider ? props.listTitleProvider(articleSerialSelector.items) : i18n.t('SelectedSerialNumbers')}
            showPosition={props.showSelectedSerialsWithPosition}
            hideConfirmationButton={hideConfirmationButton}
            confirmationButtonCaption={props.confirmationButtonCaption}
            confirmationButtonDisabled={props.confirmationButtonDisabled ? props.confirmationButtonDisabled(articleSerialSelector.items) : undefined}
            confirmationButtonAlwaysActiveAndEnabled={props.allowConfirmationWithoutSerialSelection}
          />
        </>
      )}
    </View>
  )
}
