import React from 'react'
import { StyleSheet } from 'react-native'
import DefaultModal from 'react-native-modal'

import { IMIconName } from './IMIconNames'
import MWS_Screen from './MWS/MWS_Screen'

type Props = Partial<DefaultModal['props']> & {
  title?: string
  noTopPadding?: boolean
  onGoBack?: () => void
  onClose?: () => void
  closeIcon?: IMIconName
  close?: () => void
}

export default function FullScreenModal({ title, style, children, noTopPadding, onGoBack, onClose, isVisible, closeIcon, ...otherProps }: Props) {
  function handleNavigationButtons(type: 'goBack' | 'popToTop') {
    otherProps.close?.()
    return type === 'goBack' //return handled
  }

  return (
    <DefaultModal {...otherProps} isVisible={isVisible} coverScreen style={styles.modal}>
      <MWS_Screen
        title={title}
        style={style}
        noStatusBarAvoidance
        hideSettingsButton
        hideCloseButton={!!onGoBack && !onClose}
        noTopPadding={noTopPadding}
        onGoBack={onGoBack}
        onClose={onClose}
        closeIcon={closeIcon}
        preNavigationCallback={handleNavigationButtons}>
        {children}
      </MWS_Screen>
    </DefaultModal>
  )
}

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    flex: 1,
  },
})
