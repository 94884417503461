import React from 'react'

import { Collo, PackingListMovement } from '../apis/apiTypes'
import PackingListColloCard from '../cards/Packinglist/PackingListColloCard'
import { Article } from '../classes/Article'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { ModalController } from '../hooks/useModalController'
import packingListUtils from '../utils/packingListUtils'
import AmountEditorModal from './AmountEditorModal'

export default function PackingListColloEditorModal(props: {
  article: Article | undefined
  packingListMovement: PackingListMovement | undefined
  controller: ModalController<Collo>
  onSubmit: (value: number, collo: Collo | undefined) => void
}) {
  return (
    <AmountEditorModal
      show={props.controller.isShown}
      value={props.controller.data?.quantity}
      close={props.controller.close}
      unit={props.article?.getUsedUnit()}
      onSubmit={value => {
        props.controller.close()
        props.onSubmit(value, props.controller.data)
      }}
      checkAvailability={{
        articleId: props.controller.data?.article.id ?? '',
        depositId: props.controller.data?.deposit?.id,
        stockPositionId: props.controller.data?.stockposition?.id,
        serialNumberId: props.article?.isHistoricalSerialNumber ? undefined : props.controller.data?.serialnumber?.id,
        conversion: packingListUtils.getMasterToUsedUnitConversionFactor(props.packingListMovement),
        disabled: !props.article?.hasStock,
      }}
      headerRenderItem={
        props.controller.data
          ? () => (
              <PackingListColloCard
                packingListMovement={props.packingListMovement}
                collo={props.controller.data}
                style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS, marginBottom: 0 }}
              />
            )
          : undefined
      }
    />
  )
}
