export const SortUtils = {
  sortNumberString(a: string, b: string, divider: string) {
    const aParts = a
      .split(divider)
      .map(part => parseFloat(part.trim()))
      .filter(Boolean)
    const bParts = b
      .split(divider)
      .map(part => parseFloat(part.trim()))
      .filter(Boolean)
    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const aPart = aParts[i]
      const bPart = bParts[i]
      if (aPart === bPart) continue
      return aPart - bPart
    }

    if (aParts.length === bParts.length) return 0
    return aParts.length - bParts.length
  },
}
