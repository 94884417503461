import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { Deposit, StockPosition } from '../../apis/apiTypes'
import { useTransfer } from '../../contexts/TransferContext'
import { Position } from '../../types'

export type PresetPosition = Position & {
  depositFromTemplate?: boolean
  stockPositionFromTemplate?: boolean
}

export interface TransferPositionHandlerOptions {
  isSource: boolean
  presetPosition?: PresetPosition
  positionRequired: boolean
  quantityRequired?: boolean
  presetQuantity?: number
  inactive?: boolean
}

export interface TransferPositionHandler {
  active: boolean
  required: boolean
  depositRequired: boolean
  stockPositionRequired: boolean
  quantityRequired: boolean
  deposit?: Deposit
  setDeposit: Dispatch<SetStateAction<Deposit | undefined>>
  stockPosition?: StockPosition
  setStockPosition: Dispatch<SetStateAction<StockPosition | undefined>>
  clear: () => void
  quantity: number
  setQuantity: Dispatch<SetStateAction<number>>
  isOk: boolean
  stockPositionPinned: boolean
  setStockPositionPinned: Dispatch<SetStateAction<boolean>>
}

export default function useTransferPositionHandler(options?: TransferPositionHandlerOptions): TransferPositionHandler {
  const { clear: clearTransfer } = useTransfer()
  const required = !options?.inactive && !!options?.positionRequired
  const depositRequired = required && (!options?.presetPosition || !options.presetPosition.depositFromTemplate || !options?.presetPosition.deposit)
  const stockPositionRequired =
    required && (!options?.presetPosition || !options.presetPosition.stockPositionFromTemplate || !options?.presetPosition.stockPosition)
  const [deposit, setDeposit] = useState<Deposit | undefined>(() => options?.presetPosition?.deposit)
  const [stockPosition, setStockPosition] = useState<StockPosition | undefined>(() => options?.presetPosition?.stockPosition)
  const quantityRequired = useMemo(() => !options?.inactive && !!options?.quantityRequired, [])
  const [quantity, setQuantity] = useState<number>(options?.presetQuantity ?? 0)
  const [stockPositionPinned, setStockPositionPinned] = useState(
    !!options?.presetPosition?.stockPosition && !options.presetPosition.stockPositionFromTemplate
  )
  const isOk =
    !required ||
    ((!depositRequired || (!!deposit && (!deposit.isStockpositionActive || !!stockPosition))) &&
      (!stockPositionRequired || !deposit?.isStockpositionActive || !!stockPosition) &&
      (!quantityRequired || quantity > 0))

  function clear() {
    if (depositRequired && !options.presetPosition?.depositFromTemplate) setDeposit(undefined)
    if (stockPositionRequired && !options.presetPosition?.stockPositionFromTemplate) {
      setStockPosition(undefined)
      setStockPositionPinned(false)
    }
    clearTransfer(options?.isSource ? 'source' : 'target')
    setQuantity(0)
  }

  return {
    active: !options?.inactive,
    required,
    depositRequired,
    stockPositionRequired,
    quantityRequired,
    deposit,
    setDeposit,
    stockPosition,
    setStockPosition,
    clear,
    quantity,
    setQuantity,
    isOk,
    stockPositionPinned,
    setStockPositionPinned,
  }
}
