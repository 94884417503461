import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Alert, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDTO, Customer, Deposit, SerialNumber, StockPositionAvailability } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import ItemSelectionList from '../../components/ItemSelectionList'
import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useArticle from '../../hooks/useArticle'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import { shoppingCartUtils } from '../../utils/shoppingCartUtils'
import { Utils } from '../../utils/Utils'
import StockPositionAvailabilitySelectionView from '../../views/InputViews/StockPositionAvailabilitySelectionView'
import ShoppingCartInfoView from '../../views/ShoppingCart/ShoppingCartInfoView'

export default function ShoppingCartCreationScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const userSettings = useUserSettings()
  const loader = useLoadingIndicator()
  const customer = useRouteParam<Customer>('customer')
  const articleDTO = useRouteParam<ArticleDTO>('article')
  const serialNumber = useRouteParam<SerialNumber>('serialNumber')
  const article = useArticle(articleDTO, { forceUseSalesUnit: userSettings?.isVpk1ShoppingcartActive })
  const [stockPosition, setStockPosition] = useState<StockPositionAvailability | undefined>()
  const [selectedAmount, setSelectedAmount] = useState<number | undefined>()
  const stockPositions = article?.getStockPositions(true) ?? []
  const [deposit, setDeposit] = useState<Deposit | undefined>()

  useEffect(() => {
    load()
      .catch(console.error)
      .finally(() => loader.setLoading(false))
  }, [])

  async function load() {
    if (!article) {
      failedToLoad('')
      navigation.goBack()
      return
    }

    loader.setLoading(true)
    if (!deposit && userSettings?.depositId) {
      const results = await api.getDeposit({ id: userSettings?.depositId })
      if (!results || results.length === 0) setDeposit(undefined)
      setDeposit(results[0])
    }

    await article.loadAvailability(false, userSettings?.depositId)
    await article.loadSalesPrice(customer, article.getUsedUnit().id, 1)
  }

  async function loadSalesPrice() {
    if (!article) {
      failedToLoad('')
      navigation.goBack()
      return
    }
    loader.setLoading(true)
    await article.loadSalesPrice(customer, article.getUsedUnit().id, 1)
  }

  function failedToLoad(code: string) {
    loader.setLoading(false)
    Alert.alert(i18n.t('INFO_TITLE'), Utils.stringValueReplacer(i18n.t('NoArticleWithCode'), code))
  }

  function completeShoppingCart(amount: number) {
    if (!customer || !article || !amount) return
    if (article.hasStock && deposit?.isStockpositionActive && !stockPosition) return
    loader.setLoading(true)
    const listName = shoppingCartUtils.generateListName(customer)
    api
      .postShoppingCart({
        customerId: customer.id,
        articleId: article.info.id,
        quantity: amount,
        listName: listName,
        unitId: article.getUsedUnit().id,
        storingPositionId: stockPosition?.stockposition?.id ?? '',
        option1: serialNumber?.id,
      })
      .then(() => {
        navigation.navigate(ScreenType.ShoppingCart, { listName: listName, customer: customer })
      })
      .catch(() => {
        loader.setLoading(false)
        Alert.alert(i18n.t('INFO_TITLE'), i18n.t('FailedToCreateShoppingCart'))
      })
  }

  return (
    <MWS_Screen>
      {article && customer && (
        <View style={{ flex: 1 }}>
          <ShoppingCartInfoView customer={customer} article={article} serialNumber={serialNumber} deposit={deposit} />
          {deposit?.isStockpositionActive && !stockPosition && article.hasStock && (
            <>
              <StockPositionAvailabilitySelectionView depositId={deposit.id} onSelected={setStockPosition} showResultInModal />
              {!!stockPositions.length && (
                <Title style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}>{i18n.t('Availability')}</Title>
              )}
              <ItemSelectionList
                style={{ flex: 1 }}
                items={stockPositions}
                onSelected={item => {
                  setStockPosition(item)
                  setSelectedAmount(Math.max(item.quantity ?? 0, 0))
                }}
                itemRenderer={item => (
                  <StockPositionCard stockPosition={item.stockposition} quantity={article.getQuantityWithUnitText(item.quantity, true)} />
                )}
              />
            </>
          )}
          {deposit?.isStockpositionActive && stockPosition && (
            <View>
              <StockPositionCard
                stockPosition={stockPosition.stockposition}
                quantity={article.getQuantityWithUnitText(stockPosition.quantity, true)}
                onDelete={() => {
                  setStockPosition(undefined)
                }}
              />
              <VKUnitAmountSelector
                style={{
                  marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                }}
                value={selectedAmount ?? 0}
                setValue={value => {
                  setSelectedAmount(value)
                  completeShoppingCart(value)
                }}
                usedUnit={article.getUsedUnit()}
                units={article.info.measurementUnits}
                onUnitSelected={unit => {
                  article.setUsedUnit(unit)
                  setStockPosition(prev => (prev ? { ...prev } : undefined)) //hack to trigger rerendering
                  loadSalesPrice()
                    .catch(console.error)
                    .finally(() => loader.setLoading(false))
                }}
                autoFocus
              />
            </View>
          )}
          {(!deposit?.isStockpositionActive || !article.hasStock) && (
            <View>
              <DepositCard deposit={deposit} />
              <VKUnitAmountSelector
                style={{
                  marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                }}
                value={selectedAmount ?? 0}
                setValue={value => {
                  setSelectedAmount(value)
                  completeShoppingCart(value)
                }}
                usedUnit={article.getUsedUnit()}
                units={article.info.measurementUnits}
                onUnitSelected={unit => {
                  article.setUsedUnit(unit)
                  setDeposit(prev => (prev ? { ...prev } : undefined))
                  loadSalesPrice()
                    .catch(console.error)
                    .finally(() => loader.setLoading(false))
                }}
                autoFocus
              />
            </View>
          )}
        </View>
      )}
    </MWS_Screen>
  )
}
