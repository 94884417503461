import { useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import IMIcon from '../components/IMIcon'
import { IMIconName } from '../components/IMIconNames'
import MWS_Image from '../components/MWS/MWS_Image'
import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'

export interface MWSBaseCardProps {
  isHiddenIcon?: boolean
  icon?: IMIconName
  detailButton?: MWSBaseCardEndIconButtonProps
  endButton?: MWSBaseCardEndIconButtonProps
  onPress?: (() => void) | false
  onLongPress?: (() => void) | false
  onDelete?: (() => void) | false
  imageUri?: string
  isSelected?: boolean
  selectedColor?: string
  children?: ReactNode
  cardStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  isMarked?: boolean | IMIconName
  noContentSpacing?: boolean
}

export interface MWSBaseCardEndIconButtonProps {
  icon: IMIconName
  onPress?: (() => void) | false
  onLongPress?: (() => void) | false
  disabled?: boolean
  buttonStyle?: StyleProp<ViewStyle>
}

export default function MWS_BaseCard(props: MWSBaseCardProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const hasEndComponent = !!props.onDelete || props.endButton || !!props.isMarked
  const customBackgroundColor = StyleSheet.flatten(props.style)?.backgroundColor

  const themeStyle = StyleSheet.create({
    borderColor: {
      borderColor: props.isSelected ? props.selectedColor ?? Colors.red : theme.inputBorder,
    },
  })

  return (
    <TouchableOpacity
      onPress={props.onPress ? props.onPress : undefined}
      onLongPress={props.onLongPress ? props.onLongPress : undefined}
      disabled={!props.onPress}
      style={[styles.main, { backgroundColor: theme.card, borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT }, props.cardStyle]}>
      {!props.isHiddenIcon && <StartComponent imageUri={props.imageUri} icon={props.icon} style={themeStyle.borderColor} />}
      {props.detailButton && <DetailButtonComponent {...props.detailButton} style={[themeStyle.borderColor]} />}

      <View
        style={[
          styles.content,
          !props.noContentSpacing && styles.contentSpacing,
          themeStyle.borderColor,
          hasEndComponent ? { paddingRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2 } : styles.endComponent,
          props.style,
          !!props.isHiddenIcon && !props.detailButton && styles.contentWithoutStartComponent,
        ]}>
        {props.children}
      </View>

      {!!props.isMarked && (
        <Icon
          icon={typeof props.isMarked === 'string' ? props.isMarked : 'check'}
          borderStyle={themeStyle.borderColor}
          style={[(props.endButton || props.onDelete) && styles.noLastComponent, { backgroundColor: customBackgroundColor }]}
        />
      )}
      {props.onDelete && (
        <IconButton
          onPress={props.onDelete}
          style={[props.endButton && styles.noLastComponent, { backgroundColor: customBackgroundColor }]}
          borderStyle={themeStyle.borderColor}
        />
      )}
      {props.endButton && <EndButtonComponent {...props.endButton} style={[themeStyle.borderColor]} />}
    </TouchableOpacity>
  )
}

function StartComponent(props: { imageUri?: string; icon?: IMIconName; style?: StyleProp<ViewStyle> }) {
  const hasContent = !!props.imageUri || !!props.icon

  return (
    <View style={[styles.startComponent, hasContent && styles.startComponentHasContent, props.style]}>
      {!!props.imageUri && (
        <MWS_Image
          imageUri={props.imageUri}
          style={[styles.image]}
          imageStyle={{
            borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
            resizeMode: 'contain',
          }}
        />
      )}
      {props.icon && !props.imageUri && (
        <IMIcon style={{ justifyContent: 'center' }} size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} color={Colors.white} icon={props.icon} />
      )}
    </View>
  )
}

function IconButton(props: { onPress?: () => void; borderStyle: StyleProp<ViewStyle>; style?: StyleProp<ViewStyle>; icon?: IMIconName }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return (
    <View style={[styles.endComponent, props.borderStyle, props.style]}>
      <TouchableOpacity onPress={props.onPress} disabled={!props.onPress} style={{ padding: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2 }}>
        <IMIcon size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} color={theme.textDetail} icon={props.icon ?? 'times'} />
      </TouchableOpacity>
    </View>
  )
}

function Icon(props: { borderStyle: StyleProp<ViewStyle>; style?: StyleProp<ViewStyle>; icon?: IMIconName }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return (
    <View style={[styles.endComponent, props.borderStyle, props.style, { padding: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2 }]}>
      <IMIcon size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} color={theme.textDetail} icon={props.icon ?? 'times'} />
    </View>
  )
}

function DetailButtonComponent(props: { style?: StyleProp<ViewStyle> } & MWSBaseCardEndIconButtonProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const disabled = !!props.disabled

  return (
    <View
      style={[
        styles.startComponent,
        styles.startComponentHasContent,
        { backgroundColor: disabled ? theme.inputBorder : Colors.tint },
        props.style,
        props.buttonStyle,
      ]}>
      <TouchableOpacity
        onPress={props.onPress ? props.onPress : undefined}
        onLongPress={props.onLongPress ? props.onLongPress : undefined}
        disabled={disabled}>
        <IMIcon size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} color={disabled ? theme.inputBorder : Colors.white} icon={props.icon} />
      </TouchableOpacity>
    </View>
  )
}

function EndButtonComponent(props: { style?: StyleProp<ViewStyle> } & MWSBaseCardEndIconButtonProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const disabled = !!props.disabled

  return (
    <TouchableOpacity
      style={[styles.endComponent, styles.endButton, { backgroundColor: disabled ? theme.inputBorder : Colors.tint }, props.style, props.buttonStyle]}
      onPress={props.onPress ? props.onPress : undefined}
      onLongPress={props.onLongPress ? props.onLongPress : undefined}
      disabled={disabled}>
      <IMIcon size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} color={disabled ? theme.inputBorder : Colors.white} icon={props.icon} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginBottom: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS,
    borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
  },
  image: {
    width: STYLE_CONSTANTS.DEFAULT_IMAGE_SIZE,
    height: STYLE_CONSTANTS.DEFAULT_IMAGE_SIZE,
    borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  startComponent: {
    padding: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    justifyContent: 'center',
    borderTopLeftRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderBottomLeftRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderWidth: 1,
    borderRightWidth: 0,
  },
  contentWithoutStartComponent: {
    borderTopLeftRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderBottomLeftRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderLeftWidth: 1,
    // paddingLeft: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  startComponentHasContent: {
    backgroundColor: Colors.grey,
    borderColor: Colors.grey,
  },
  content: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  contentSpacing: {
    paddingLeft: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2,
    paddingRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    paddingVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2,
  },
  endComponent: {
    justifyContent: 'center',
    borderTopRightRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderBottomRightRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  noLastComponent: {
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  endButton: {
    padding: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
})
