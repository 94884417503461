import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import { ProductionPartForExtraction } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useArticle from '../../hooks/useArticle'
import { articleUtils } from '../../utils/articleUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ProductionPartExtractionCard(props: {
  item: ProductionPartForExtraction
  onSetQuantityToExtract: (item: ProductionPartForExtraction, quantity: number) => void
}) {
  const { i18n } = useLanguage()
  const article = useArticle(props.item?.article, { serialNumber: props.item?.serialNumber }, [props.item])
  const articleProduction = useArticle(props.item?.articleProduction, {}, [props.item])

  function getQuantityText() {
    const unit = articleUtils.getUnitById(props.item?.article, props.item.unitId)
    if (props.item.quantity) return articleUtils.formatQuantity(props.item.quantity ?? 0, unit)
    return ''
  }
  function getUnitText() {
    const unit = articleUtils.getUnitById(props.item?.article, props.item.unitId)
    return unit?.code
  }

  function getStateText() {
    if (props.item.state === 4) return i18n.t('Consigned')
    if (props.item.state === 2) return i18n.t('INSTALLED')
    return ''
  }

  function setQuantityToExtract(quantity: number) {
    props.onSetQuantityToExtract(props.item, quantity)
  }

  if (!article) return <></>
  return (
    <MWS_BaseCard icon={'article'} style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, justifyContent: 'center', marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}>
        <IMText primary>{i18n.t('ProductionSelection') + ': ' + props.item.production}</IMText>
        {articleProduction && (
          <IMText numberOfLines={2} secondary>
            {articleProduction.info.code?.trim()} - {articleUtils.getArticleDescription(articleProduction?.info, i18n.t('ID'))}
          </IMText>
        )}
        <IMText secondary>{i18n.t('State') + ': ' + getStateText()}</IMText>
        <IMText secondary>{i18n.t('BOOKED') + ': ' + getQuantityText()}</IMText>
        <QuantityInput onValueChanged={setQuantityToExtract} icon={'cubes-stacked'} unit={getUnitText()} />
      </View>
    </MWS_BaseCard>
  )
}
