import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Customer, CustomerDeliveryAddress } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { customerUtils } from '../../utils/customerUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function CustomerCard(props: {
  customer?: Customer
  customerDeliveryAddress?: CustomerDeliveryAddress
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
}) {
  const title = useMemo(
    () => (props.customer ? customerUtils.getCustomerTitle(props.customer, true) : props.customerDeliveryAddress?.customer),
    [props.customer]
  )

  return (
    <MWS_BaseCard onPress={props.onPress} onDelete={props.onDelete} icon="building" style={{ justifyContent: 'center', flexDirection: 'column' }}>
      <IMText primary>{title}</IMText>
      {!!props.customerDeliveryAddress?.deliveryaddress && <IMText secondary>{props.customerDeliveryAddress.deliveryaddress}</IMText>}
    </MWS_BaseCard>
  )
}
