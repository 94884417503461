import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import ElusiveButton from '../../components/ElusiveButton'
import { ProductionHandler } from '../../hooks/specific/useProductionHandler'
import ProductionPartSelectedSerialListView from './ProductionPartSelectedSerialListView'

export default function ProductionPartSelectedQuantitiesView(props: { handler: ProductionHandler | undefined }) {
  const { i18n } = useLanguage()

  const hasSelection = (props.handler?.selectedPart?.selectedQuantities ?? []).length > 0

  function close() {
    props.handler?.setSelectedPart(undefined)
  }

  if (!props.handler?.selectedPart) return <></>
  return (
    <View style={{ flex: 1 }}>
      <ProductionPartSelectedSerialListView part={props.handler.selectedPart} handler={props.handler} />
      <ElusiveButton
        hide={!props.handler.selectedPart.article.isSerialNumberActive || !hasSelection}
        hideFromKeyBoard
        title={i18n.t('Confirm')}
        onPress={() => close()}
      />
    </View>
  )
}
