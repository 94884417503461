import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDepositQuantity, ArticleDTO, Deposit } from '../../apis/apiTypes'
import BaseTextInput from '../../components/BaseTextInput'
import DateSelector from '../../components/MWS/Inputs/Calendar/DateSelector'
import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useRouteParam from '../../hooks/useRouteParam'
import { articleUtils } from '../../utils/articleUtils'
import OrderProposalInfoView from '../../views/OrderProposal/OrderProposalInfoView'

export default function OrderProposalCreationScreen() {
  const navigation = useNavigation()
  const alert = useAlert()
  const { i18n } = useLanguage()

  const loader = useLoadingIndicator()
  const deposit = useRouteParam<Deposit>('deposit')
  const articleDTO = useRouteParam<ArticleDTO>('article')
  const article = useArticle(articleDTO, { useUnit: articleDTO ? articleUtils.getPurchasingUnit(articleDTO) : undefined })
  const [selectedAmount, setSelectedAmount] = useState<number | undefined>()
  const articleDepositQuantities = article?.depositQuantities ?? []
  const [note, setNote] = useState<string>('')
  const [desiredDate, setDesiredDate] = useState<Date>()
  const inputRef = useRef<TextInput>(null)
  const [_refreshCounter, setRefreshCounter] = useState(0)

  useEffect(() => {
    if (!article || !deposit) {
      failedToLoad('')
      navigation.goBack()
      return
    }
    loader.setLoading(true)
    Promise.allSettled([article.loadAvailability(false, deposit?.id)])
      .catch(console.error)
      .finally(() => {
        loader.setLoading(false)
      })
  }, [])

  function failedToLoad(code: string) {
    loader.setLoading(false)
    alert.error(i18n.t('NoArticleWithCode'), code)
  }

  function getArticleDepositQuantity() {
    if (!articleDepositQuantities || articleDepositQuantities.length === 0) {
      return { deposit: deposit, quantity: 0, quantityCommissioned: 0, quantityOrdered: 0, quantityPurchasing: 0 } as ArticleDepositQuantity
    }
    return articleDepositQuantities[0]
  }

  function completeOrderProposal(amount: number) {
    if (!deposit || !article || !amount) return

    loader.setLoading(true)
    api
      .postPurchaseOrderProposal({
        articleId: article.info.id,
        depositId: deposit.id,
        unitId: article.getUsedUnit().id,
        quantity: amount,
        desiredDate: desiredDate,
        note: note,
      })
      .then(() => {
        navigation.goBack()
      })
      .catch(() => {
        loader.setLoading(false)
        alert.error(i18n.t('FailedToCreateOrderProposal'))
      })
      .finally(() => {
        loader.setLoading(false)
      })
  }

  return (
    <MWS_Screen title={i18n.t('CreateOrderProposal')}>
      <ScrollView style={{ flex: 1 }}>
        {article && deposit && (
          <View>
            <OrderProposalInfoView deposit={deposit} article={article} articleDepositQuantity={getArticleDepositQuantity()} />
            <BaseTextInput
              inputRef={inputRef}
              viewStyle={[{}, styles.main]}
              style={{ textAlign: 'left', textAlignVertical: 'top', height: 60 }}
              placeholder={i18n.t('Note')}
              value={note}
              onChangeText={setNote}
              selectTextOnFocus
              multiline
            />
            <DateSelector
              placeHolder={i18n.t('DesiredDate')}
              value={desiredDate}
              onValueChanged={value => {
                setDesiredDate(value)
              }}
            />
            <View>
              <VKUnitAmountSelector
                style={{
                  marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS,
                  marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                }}
                value={selectedAmount ?? 0}
                setValue={value => {
                  setSelectedAmount(value)
                  completeOrderProposal(value)
                }}
                usedUnit={article.getUsedUnit()}
                units={article.info.measurementUnits}
                onUnitSelected={unit => {
                  article.setUsedUnit(unit)
                  setRefreshCounter(prev => prev + 1)
                }}
                autoFocus
              />
            </View>
          </View>
        )}
      </ScrollView>
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
