import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import api from '../../apis/apiCalls'
import { Production, ProductionState } from '../../apis/apiTypes'
import ProductionData from '../../classes/ProductionData'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import { articleUtils } from '../../utils/articleUtils'
import ProductionSelectionView from './ProductionSelectionView'

export default function ProductionOutSelectionView(props: {
  onProductionSelected: (production: ProductionData) => void
  isDefectiveMaterialProduction?: boolean
}) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const loadingIndicator = useLoadingIndicator()
  const userSettings = useUserSettings()

  function loadProduction(production: Production, employeeTimeTypeId?: unknown) {
    if (props.isDefectiveMaterialProduction) employeeTimeTypeId = undefined
    const productionHandler = new ProductionData(production, employeeTimeTypeId?.toString() ?? '', props.isDefectiveMaterialProduction)
    loadingIndicator.setLoading(true)
    Promise.all([productionHandler.loadParts(true, userSettings), productionHandler.loadStockTemplates()])
      .then(() => {
        props.onProductionSelected(productionHandler)
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message ?? i18n.t('FailedToLoadProductionParts'), production.code)
      })
      .finally(() => loadingIndicator.setLoading(false))
  }

  function handleProductionSelection(production: Production, employeeTimeTypeId?: unknown) {
    if ((production.quantityOpen === 0 || production.state !== ProductionState.Open) && !props.isDefectiveMaterialProduction) {
      alert.info(i18n.t('ProductionAlreadyComplete'))
    }
    if (userSettings?.isProductionOutputQueryWhetherAScannedProductionOrderShouldBeLoaded && !props.isDefectiveMaterialProduction) {
      // Abfrage ob geladen werden soll
      alert
        .yesNo(
          i18n.t('LOADPRODUCTIONCONFIRMATION'),
          production.code + ': ' + articleUtils.getArticleTitleDescription(production.article, i18n.t('ID'))
        )
        .then(answer => {
          if (answer) {
            loadProduction(production, employeeTimeTypeId)
          }
        })
        .catch(console.error)
    } else {
      loadProduction(production, employeeTimeTypeId)
    }
  }

  return <ProductionSelectionView onSelected={handleProductionSelection} filterResultByOrder showResultInModal allowEmptyInput history />
}
