import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'
import { IMTextWithIcon } from './IMTextWithIcon'

export type CompletedTagProps = {
  style?: StyleProp<ViewStyle>
  children: string
}

export default function CompletedTag({ style, children }: CompletedTagProps) {
  return (
    <View style={[styles.main, style]}>
      <IMTextWithIcon iconColor={Colors.tint} iconSize={20} iconStyle={{}} icon={'check'} text={children} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
