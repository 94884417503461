import React from 'react'
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'

import IMIcon from './IMIcon'
import IMText from './IMText'
import CardBasic from './old/CardBasic'

export type ChipProps = {
  text: string
  style?: StyleProp<ViewStyle>
  onDelete?: () => void
  maxWidth: number
}

export default function Chip({ text, style, maxWidth, onDelete }: ChipProps) {
  return (
    <TouchableOpacity style={[styles.main, { maxWidth }, style]} onPress={onDelete}>
      <CardBasic style={[styles.card]}>
        <IMText style={styles.text} numberOfLines={1}>
          {text}
        </IMText>
        {!!onDelete && <IMIcon size={15} style={styles.deleteIcon} icon={'times'} />}
      </CardBasic>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 3,
    paddingVertical: 3,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  card: {
    paddingVertical: 2,
    marginHorizontal: 0,
    marginVertical: 0,
    flexDirection: 'row',
    flexShrink: 1,
  },
  text: {
    flexShrink: 1,
  },
  deleteIcon: {
    alignSelf: 'center',
    marginRight: 0,
  },
})
