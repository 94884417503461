import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Collo, PackingListMovement } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { articleUtils } from '../../utils/articleUtils'
import colloUtils from '../../utils/colloUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function PackingListColloCard(props: {
  packingListMovement: PackingListMovement | undefined
  collo: Collo | undefined
  style?: StyleProp<ViewStyle>
  onPress?: (() => void) | false
  onDelete?: (() => void) | false
  showSerialWarranty?: boolean
}) {
  const warrantyDate =
    !!props.collo?.serialnumber && props.showSerialWarranty ? Utils.parseDate(props.collo.serialnumber?.warrantySupplier) : undefined

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={'cart-flatbed-boxes'}
      cardStyle={props.style}
      style={{ justifyContent: 'flex-start' }}>
      <View style={{ justifyContent: 'center', flexDirection: 'column', flex: 1 }}>
        {!!props.collo?.serialnumber && (
          <IMTextWithIcon icon="barcode" text={serialnumberUtils.getSerialNumberString(props.collo.serialnumber, true)} />
        )}
        {(!!props.collo?.stockposition || !!props.collo?.deposit) && (
          <IMTextWithIcon
            icon={props.collo.stockposition ? 'shelf' : 'warehouse-full'}
            text={stockPositionUtils.getTitle(props.collo?.stockposition, props.collo?.deposit)}
          />
        )}
        {!!props.collo?.number && props.collo?.number !== '0' && <IMTextWithIcon icon="box-taped" text={colloUtils.getTitle(props.collo)} />}
        {!!warrantyDate && <IMTextWithIcon icon="calendar" text={Utils.FormatDate(warrantyDate, 'DD.MM.YYYY') ?? ''} />}
      </View>
      <View style={{ justifyContent: 'center' }}>
        <IMText>{articleUtils.formatQuantity(props.collo?.quantity, props.packingListMovement?.unitCode)}</IMText>
      </View>
    </MWS_BaseCard>
  )
}
