import { useApi } from '@infominds/react-api'
import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import api from '../../../apis/apiCalls'
import { ItemAvailabilityRequest } from '../../../apis/apiRequestTypes'
import { ArticleMeasurementUnit } from '../../../apis/apiTypes'
import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { IMLayout } from '../../../constants/Styles'
import useAlert from '../../../hooks/useAlert'
import { articleUtils } from '../../../utils/articleUtils'
import { Utils } from '../../../utils/Utils'
import BaseTextInput from '../../BaseTextInput'
import IMIcon from '../../IMIcon'
import Button from '../../old/Button'
import CardBasic from '../../old/CardBasic'
import UnitPicker from './UnitPicker'

interface VKUnitAmountSelectorProps {
  value?: number
  proposedQuantity?: number
  setValue: (value: number) => void
  usedUnit?: ArticleMeasurementUnit | string
  units?: ArticleMeasurementUnit[]
  onUnitSelected?: (unit: ArticleMeasurementUnit) => void
  style?: StyleProp<ViewStyle>
  autoFocus?: boolean
  checkAvailability?: UnitSelectorCheckAvailability
  confirmButtonCaption?: string
  disabled?: boolean
}

export type UnitSelectorCheckAvailability = {
  conversion?: number
  disabled?: boolean
} & ItemAvailabilityRequest

export default function VKUnitAmountSelector(props: VKUnitAmountSelectorProps) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const alert = useAlert()

  const [value, setValue] = useState<string>('')
  const inputRef = useRef<TextInput>(null)
  const [availability, loadAvailability] = useApi(api.getItemAvailability)
  const [busy, setBusy] = useState(false)
  const [usedUnit, setUsedUnit] = useState<ArticleMeasurementUnit | string | undefined>(props.usedUnit)

  // @ts-ignore todo
  useEffect(() => {
    if (props.autoFocus && inputRef?.current) {
      const timeoutId = setTimeout(() => {
        inputRef.current?.blur()
        inputRef.current?.focus()
      }, 750)

      return () => clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    setValue(Utils.convertNumberToString(props.value))
  }, [props.value])

  function onTextChange(text: string) {
    setValue(text.replace(/([^0-9.,-])/g, ''))
  }

  function onDone(useValue?: string) {
    const valueToCheck = useValue ?? value
    if (valueToCheck) {
      setBusy(true)
      let parsedValue = Number.parseFloat(valueToCheck.replace(',', '.'))
      if (!Number.isFinite(parsedValue)) parsedValue = 0
      checkAvailability(parsedValue)
        .then(result => {
          if (result) props.setValue(parsedValue)
        })
        .catch(console.error)
        .finally(() => setBusy(false))
    }
  }

  function checkAvailability(checkValue: number) {
    return new Promise<boolean>(resolve => {
      if (!props.checkAvailability || props.checkAvailability.disabled) {
        resolve(true)
        return
      }
      if (availability !== undefined) {
        if (articleUtils.quantityValidator(checkValue, availability)) {
          resolve(true)
        } else {
          notEnoughAvailability(availability)
          resolve(false)
        }
        return
      }
      const { conversion, ...request } = props.checkAvailability
      loadAvailability(request, result => result * (conversion || 1))
        .then(result => {
          if (articleUtils.quantityValidator(checkValue, result)) {
            resolve(true)
          } else {
            notEnoughAvailability(result)
            resolve(false)
          }
        })
        .catch(() => {
          notEnoughAvailability(0)
          resolve(false)
        })
    })
  }

  function notEnoughAvailability(availableValue: number) {
    alert.info(i18n.t('SelectedAmountInvalidAlert'), Utils.roundToPrecision(availableValue, 4))
  }

  function handleUnitChange(unit: ArticleMeasurementUnit) {
    if (props.onUnitSelected) props.onUnitSelected(unit)
    setUsedUnit(unit)
  }

  return (
    <View style={[styles.main, props.style]}>
      <View style={IMLayout.flex.row}>
        <CardBasic style={{ margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}>
          <IMIcon style={{ justifyContent: 'center' }} size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} icon="article" color={theme.textDetail} />
        </CardBasic>
        <BaseTextInput
          inputRef={inputRef}
          viewStyle={[IMLayout.flex.f1, styles.noBorderLeft, !!props.usedUnit && styles.noBorderRight]}
          style={{ textAlign: 'center' }}
          placeholder={i18n.t('Amount')}
          value={value}
          onChangeText={onTextChange}
          onSubmitEditing={() => onDone()}
          keyboardType={'numeric'}
          selectTextOnFocus
          editable={!props.disabled}
        />
        {!!usedUnit && (
          <UnitPicker
            style={styles.unitPicker}
            unit={typeof usedUnit === 'string' ? articleUtils.createMeasurementUnit(usedUnit) : usedUnit}
            units={props.units}
            onUnitSelected={handleUnitChange}
            captionExtractor={unit => articleUtils.getUnitText(unit, props.units)}
            selectedCaptionExtractor={unit => unit.code?.trim()}
            asCard
          />
        )}
      </View>
      <View>
        <Button
          style={{ margin: 0, marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}
          title={props.confirmButtonCaption ?? i18n.t('Confirm')}
          disabled={busy || props.disabled}
          onPress={() => onDone()}
        />
        {!!props.proposedQuantity && props.value !== props.proposedQuantity && (
          <Button
            style={{ margin: 0, marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}
            title={`${i18n.t('TotalQuantity')} (${articleUtils.formatQuantity(props.proposedQuantity, props.usedUnit)})`}
            disabled={busy || props.disabled}
            onPress={() => onDone(props.proposedQuantity?.toString())}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  unitPicker: {
    justifyContent: 'center',
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
})
