import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FlatList, View } from 'react-native'

import { ProductionMaster } from '../apis/apiTypes'
import ProductionMasterCard from '../cards/Production/ProductionMasterCard'
import ElusiveButton from '../components/ElusiveButton'
import FullScreenModal from '../components/FullScreenModal'
import Title from '../components/MWS/Title'
import Separator from '../components/Separator'
import useItemSelector from '../hooks/useItemSelector'
import { ModalController } from '../hooks/useModalController'

export default function ProductionAllocationListSelectionFilterModal(props: {
  controller: ModalController<ProductionMaster[]>
  onSelected: (selection: ProductionMaster[]) => void
  multiSelection?: boolean
  lastUsed?: ProductionMaster[]
}) {
  const { i18n } = useLanguage()

  const itemSelector = useItemSelector<ProductionMaster>([], (a, b) => a.id === b.id)
  const productionLists = useMemo(() => props.controller.data ?? [], [props.controller.data])

  const lastUsed = useMemo(() => props.lastUsed?.filter(p => productionLists.find(item => item.id === p.id)), [productionLists, props.lastUsed])
  const productionListsToShow = useMemo(() => productionLists.filter(pl => !lastUsed?.find(lu => lu.id === pl.id)), [productionLists, lastUsed])

  useEffect(() => {
    if (!props.controller.isShown) return
    itemSelector.clear()
  }, [props.controller.isShown])

  function handlePackingListSelected(productionList: ProductionMaster) {
    if (!props.multiSelection) {
      onDone([productionList])
      return
    }
    itemSelector.toggle(productionList)
  }

  function onDone(selectedItems: ProductionMaster[]) {
    props.controller.close()
    props.onSelected(selectedItems)
  }

  const productionListRenderItem = useCallback(
    (item: ProductionMaster) => (
      <ProductionMasterCard production={item} onPress={() => handlePackingListSelected(item)} isMarked={itemSelector.includes(item)} key={item.id} />
    ),
    [itemSelector]
  )

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
      }}
      title={i18n.t('SelectProductionMaster')}>
      <View style={{ flex: 1 }}>
        <FlatList
          data={productionListsToShow}
          ListHeaderComponent={
            <>
              {!!lastUsed?.length && (
                <View>
                  <Title>{i18n.t('LAST_USED')}</Title>
                  {lastUsed.map(item => productionListRenderItem(item))}
                  <Separator />
                </View>
              )}
            </>
          }
          renderItem={({ item }) => productionListRenderItem(item)}
        />
      </View>
      <ElusiveButton hide={!itemSelector.any} title={i18n.t('SELECT')} onPress={() => onDone(itemSelector.items)} />
    </FullScreenModal>
  )
}
