import { IMLayout } from '@infominds/react-native-components'

import { MenuActionType } from '../types'

export const STYLE_CONSTANTS = {
  TITLE_FONTSIZE: 16,
  DEFAULT_HEADER_ICON_SIZE: 20,
  DEFAULT_ICON_BUTTON_SIZE: 25,
  DEFAULT_ICON_BUTTON_SIZE_M: 30,
  DEFAULT_ICON_BUTTON_SIZE_L: 50,
  DEFAULT_ICON_BUTTON_SIZE_XL: 75,
  DEFAULT_IMAGE_SIZE: 100,
  DEFAULT_HORIZONTAL_MARGIN: IMLayout.horizontalMargin,
  DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS: 5,
  DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS: IMLayout.verticalMargin,
  DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS: 15,
  DEFAULT_BORDER_RADIUS_COMPONENT: IMLayout.borderRadius,
  VERTICAL_DISTANCE_BETWEEN_CARDS: 3,
  MODAL_BACKGROUND_OPACITY: 0.4,
}

export const CONSTANTS = {
  HISTORY_ENTRIES: 7,
  REQUEST_TIMEOUT: 60000,
  NUMBER_PRECISION: 2,
  DOUBLE_CLICK_DETECTION_WINDOW: 500, //time between 2 clicks to be detected as a double click [ms]
  PRINTER_ENABLED_IF_MENU_EXISTS: ['PDA_ACCESS_UMBUCHUNG_KANBAN'] as MenuActionType[],
}

export const MWS_COLORS = {
  OK: 'darkseagreen',
  PARTIALLY_OK: 'cornsilk',
  NOT_STARTED: 'lightgray',
  NOT_AVAILABLE: 'orange',
  NOT_OK: 'red',
}

export const StorageKeys = {
  doubleClickConfig: 'DOUBLE_CLICK_CONFIG',
  printerNr: 'PRINTER_NR',
  selectedCompany: 'selectedCompany',
  FILTER_CONTEXT_GROUP: 'FilterContextGroupStorage',
  FILTER_CONTEXT_FILTER: 'FilterContextFilterStorage',
  FILTER_CONTEXT_ORDER: 'FilterContextOrderStorage',
  PACKINGLIST_CREATE_DELIVERY_NOTE_SWITCH: 'PackingListCreateDeliverNoteSwitch',
}

export const REGEX = { DATE: /^[0-9]{4}-[0-9]{2}-[0-9]{2}/ }
