import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Keyboard, View } from 'react-native'

import Modal from '../components/Modal'
import LoadingIndicator from '../components/old/LoadingIndicator'
import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { Utils } from '../utils/Utils'

type LoadingIndicatorContextType = {
  loader: string[]
  addLoader: (owner: string) => void
  removeLoader: (owner: string) => void
}

const LoadingIndicatorContext = createContext<LoadingIndicatorContextType>({ loader: [], addLoader: () => null, removeLoader: () => null })

export function LoadingIndicatorContextProvider(props: { children: any }) {
  const [loader, setLoader] = useState<string[]>([])

  useEffect(() => {
    setLoader([])
  }, [])

  function addLoader(owner: string) {
    setLoader(prev => {
      if (prev.includes(owner)) return prev
      return [...prev, owner]
    })
  }

  function removeLoader(owner: string) {
    setLoader(prev => (prev.includes(owner) ? [...prev.filter(i => i !== owner)] : prev))
  }

  return (
    <LoadingIndicatorContext.Provider value={{ loader, addLoader, removeLoader }}>
      <LoadingIndicatorModal loading={loader.length > 0} />
      {props.children}
    </LoadingIndicatorContext.Provider>
  )
}

function LoadingIndicatorModal(props: { loading?: boolean }) {
  const loadingIndicator = useLoadingIndicator()
  const isVisible = loadingIndicator.loading || !!props.loading

  if (!isVisible) return <></>

  return (
    <Modal
      statusBarTranslucent
      backdropOpacity={STYLE_CONSTANTS.MODAL_BACKGROUND_OPACITY}
      onModalShow={() => Keyboard.dismiss()}
      isVisible={isVisible}>
      <View>
        <LoadingIndicator color={Colors.tint} isVisible={true} />
      </View>
    </Modal>
  )
}

export function useLoadingIndicator(loaderId?: string, keepAfterDestruction?: boolean) {
  const loader = useMemo(() => loaderId || `loader${Utils.getUid()}`, [])
  const loadingIndicator = useContext(LoadingIndicatorContext)
  const loading = loadingIndicator.loader.length > 0

  //remove loader on destruction
  // @ts-ignore todo
  useEffect(() => {
    if (!keepAfterDestruction) {
      return () => {
        loadingIndicator.removeLoader(loader)
      }
    }
  }, [])

  function setLoading(value: boolean) {
    if (value) loadingIndicator.addLoader(loader)
    else loadingIndicator.removeLoader(loader)
  }

  return { loading, setLoading }
}
