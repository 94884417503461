import React, { useEffect, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import { Article } from '../../classes/Article'
import SerialNumberAvailabilitySelector from '../../components/MWS/Inputs/SerialNumberAvailabilitySelector'
import TransferQuantitySelector from '../../components/MWS/Transfer/TransferQuantitySelector'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import useRxMWSAlerts from '../../hooks/useRxMWSAlerts'
import MultiSerialSelectionModal from '../../modals/MultiSerialSelectionModal'
import { depositUtils } from '../../utils/depositUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import SerialnumberSelectionView from '../InputViews/SerialnumberSelectionView'
import { ArticleAmountSelectionResult } from './ArticleAmountSelectorV2'

interface ArticleAmountSelectorProps {
  article: Article | undefined
  deposit?: Deposit
  stockPosition?: StockPosition
  preSelectedSerial?: SerialNumber
  onSelected: (result: ArticleAmountSelectionResult) => void
  proposedQuantity?: number
  style?: StyleProp<ViewStyle>
  selectSerialsWithPosition?: boolean
  checkAvailability?: boolean
  selectedItemsToFilter?: ArticleAmountSelectionResult[]

  errorSerialNotAllowedText?: string
  allowedSerialNumbers?: SerialNumber[]
  availabilityCheckFailedWorkaroundMessage?: string
  availabilityTolerance?: number
  ignoreSerialNumber?: boolean
  allowUnityChange?: boolean
}

export default function ArticleAmountSelectorV3({
  article,
  deposit,
  stockPosition,
  preSelectedSerial,
  onSelected,
  proposedQuantity,
  style,
  selectSerialsWithPosition,
  checkAvailability,
  selectedItemsToFilter,
  errorSerialNotAllowedText,
  allowedSerialNumbers,
  availabilityCheckFailedWorkaroundMessage,
  availabilityTolerance,
  ignoreSerialNumber,
  allowUnityChange,
}: ArticleAmountSelectorProps) {
  const alerts = useRxMWSAlerts()
  const alert = useAlert()
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<StockPositionAvailability | undefined>()
  const showAmountSelector = !article?.isSerialNumberActive || !!selectedSerialNumber || ignoreSerialNumber
  const showSerialSelector = !!article?.isSerialNumberActive && !selectedSerialNumber && !ignoreSerialNumber
  const preSerialSelectionModal = useModalController<StockPositionAvailability[]>()
  const selectorModal = useModalController<StockPositionAvailability[]>()

  useEffect(() => {
    if (!article) return

    if (preSelectedSerial && article.isSerialNumberActive) {
      if (checkAvailability) {
        api
          .getSerialnumberQuantities({
            depositId: deposit?.id,
            serialnumberId: preSelectedSerial.id,
            articleId: article.info.id,
            stockPositionId: stockPosition?.id,
          })
          .then(result => {
            if (!result?.length) {
              alerts.error.serialNotAvailable(preSelectedSerial)
              return
            }
            if (result.length === 1) handleSerialSelection(result[0])
            else preSerialSelectionModal.show(result)
          })
          .catch(error => {
            console.error(error)
            alerts.error.serialNotAvailable(preSelectedSerial)
          })
      } else {
        handleSerialSelection(serialnumberUtils.createAvailability(preSelectedSerial, 1, 0, 0, article.info, deposit, stockPosition))
      }
    }
  }, [])

  function handleSerialSelection(stockPositionAvailability: StockPositionAvailability) {
    if (
      !!stockPositionAvailability.serialnumber &&
      !!allowedSerialNumbers?.length &&
      !serialnumberUtils.serialNumberInList(stockPositionAvailability.serialnumber, allowedSerialNumbers)
    ) {
      if (errorSerialNotAllowedText) alert.info(errorSerialNotAllowedText)

      return
    }
    if (article?.isLottoSerialNumber) setSelectedSerialNumber(stockPositionAvailability)
    else handleCompletion(1, stockPositionAvailability)
  }

  function handleMultiSerialSelection(stockPositionAvailability: StockPositionAvailability[]) {
    const firstArt = stockPositionAvailability?.length ? stockPositionAvailability[0] : undefined
    if (!firstArt) return
    if (article?.isLottoSerialNumber) setSelectedSerialNumber(firstArt)
    else handleCompletion(1, firstArt)
  }

  function handleCompletion(quantity: number, stockPositionAvailability: StockPositionAvailability | undefined) {
    setSelectedSerialNumber(undefined)
    onSelected({
      serialNumber: ignoreSerialNumber ? undefined : stockPositionAvailability?.serialnumber,
      quantity: quantity,
      unit: article?.getUsedUnit(),
      stockPosition: stockPositionAvailability?.stockposition,
      deposit: stockPositionAvailability?.deposit,
    })
  }

  return (
    <View style={style}>
      {selectedSerialNumber && (
        <SerialnumberCard
          serialnumber={selectedSerialNumber.serialnumber}
          quantity={checkAvailability && selectSerialsWithPosition && article?.getQuantityWithUnitText(selectedSerialNumber.quantity)}
          onDelete={() => setSelectedSerialNumber(undefined)}
          infoText={
            selectedSerialNumber.deposit &&
            selectSerialsWithPosition &&
            depositUtils.getTitleWithStockPosition(selectedSerialNumber.deposit, selectedSerialNumber.stockposition)
          }
          hideManufacturer
        />
      )}
      {showAmountSelector && (
        <TransferQuantitySelector
          article={article}
          onQuantitySelected={value => handleCompletion(value, selectedSerialNumber)}
          availability={checkAvailability ? selectedSerialNumber?.quantity ?? article?.getQuantity() : undefined}
          proposedQuantity={proposedQuantity ?? selectedSerialNumber?.quantity}
          availabilityCheckFailedWorkaroundMessage={availabilityCheckFailedWorkaroundMessage}
          availabilityTolerance={availabilityTolerance}
          ignoreAvailability={!checkAvailability}
          allowUnityChange={allowUnityChange}
        />
      )}
      <View>
        {showSerialSelector && selectSerialsWithPosition && (
          <SerialNumberAvailabilitySelector
            onSelected={handleSerialSelection}
            article={article}
            allowEmptyInput
            showResultInModal
            stockPosition={stockPosition}
            deposit={deposit}
            considerPickedQuantity={false}
          />
        )}
        {showSerialSelector && !selectSerialsWithPosition && (
          <SerialnumberSelectionView
            onSelected={serial => handleSerialSelection(serialnumberUtils.createAvailability(serial, 1, 0, 0, article.info, deposit, stockPosition))}
            article={article.info}
            showResultInModal
            allowEmptyInput
          />
        )}
        <MultiSerialSelectionModal
          show={preSerialSelectionModal.isShown}
          article={article}
          close={preSerialSelectionModal.close}
          onSelected={items => {
            preSerialSelectionModal.close()
            handleMultiSerialSelection(items)
          }}
        />
        <MultiSerialSelectionModal
          show={selectorModal.isShown}
          close={selectorModal.close}
          onSelected={items => {
            selectorModal.close()
            handleMultiSerialSelection(items)
          }}
          deposit={deposit}
          article={article}
          noDepositSelectionMode={!article?.isSerialNumberActive}
          selectSerialsWithoutPosition={!selectSerialsWithPosition}
          filterItem={item => !selectedItemsToFilter?.find(filterItem => serialnumberUtils.compareAny(filterItem, item))}
        />
      </View>
    </View>
  )
}
