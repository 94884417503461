import { useApi } from '@infominds/react-api'
import { Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ColloUnloadPackingList, PackingListMovement, PackingListOrder } from '../../apis/apiTypes'
import PackingListOrderCard from '../../cards/Packinglist/PackingListOrderCard'
import Title from '../../components/MWS/Title'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { PackingListHandler } from '../../hooks/specific/usePackingListHandler'
import useModalController from '../../hooks/useModalController'
import useNavigationInterceptor from '../../hooks/useNavigationInterceptor'
import ColloUnloadModal from '../../modals/ColloUnloadModal'
import packingListUtils from '../../utils/packingListUtils'

export default function PackingListColloUnloadView(props: { handler: PackingListHandler; onDone: (reload: boolean) => void }) {
  const loader = useLoadingIndicator()
  const handler = props.handler
  const [unloadList, loadUnloadList] = useApi(api.getColloUnloadPackingList, [])
  const modalController = useModalController<PackingListMovement[]>()
  const changed = useRef(false)

  useEffect(() => {
    load()
  }, [])

  function load() {
    if (!handler.activeCollo) {
      done()
      return
    }
    loader.setLoading(true)
    loadUnloadList({ colloNumber: handler.activeCollo })
      .then(result => {
        if (!result?.length) {
          done()
          return
        }
      })
      .catch(console.error)
      .finally(() => loader.setLoading(false))
  }

  useNavigationInterceptor(e => {
    if (e.data?.action?.type === 'GO_BACK') {
      e.preventDefault()
      done()
    }
  })

  function handleUnloadingCompletion(movements: PackingListMovement[] | undefined) {
    if (!movements) return
    changed.current = true
    load()
  }

  function done() {
    props.onDone(changed.current)
  }

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={unloadList}
        renderItem={({ item }) => <PackingListMovementOrders packingList={item} onPress={() => modalController.show(item.packinglistmovements)} />}
      />
      <ColloUnloadModal controller={modalController} onCompleted={handleUnloadingCompletion} />
    </View>
  )
}

function PackingListMovementOrders(props: { packingList: ColloUnloadPackingList; onPress: () => void }) {
  const orders = useMemo(() => {
    return Utils.keepUniques(props.packingList.packinglistmovements?.map(m => packingListUtils.getOrderFromPackingListMovement(m)) ?? [], o => o?.id)
  }, [props.packingList])

  function getArticleCount(order: PackingListOrder | undefined) {
    if (!order) return 0
    return props.packingList.packinglistmovements?.filter(m => m.orderId === order.id && m.packinglistId === props.packingList.id)?.length ?? 0
  }

  function getArticleInfos() {
    return Utils.keepUniques(props.packingList.packinglistmovements?.map(item => item.article?.code?.trim() ?? '') ?? [], q => q).join(', ')
  }

  return (
    <View>
      <Title>{props.packingList.number}</Title>
      <FlatList
        data={orders}
        renderItem={({ item }) => (
          <PackingListOrderCard order={item} quantity={`${getArticleCount(item)}`} onPress={props.onPress} articles={getArticleInfos()} />
        )}
      />
    </View>
  )
}
