import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'

import { StockTemplate } from '../../apis/apiTypes'
import IMIcon from '../../components/IMIcon'
import { IMIconName } from '../../components/IMIconNames'
import CardBasic from '../../components/old/CardBasic'
import Text from '../../components/old/Text'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'

export default function TransferIndicatorCard(props: {
  quantity: string | undefined
  stockTemplate: StockTemplate | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
}) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  if (!props.quantity) return <></>

  function getLeftIcon(): IMIconName {
    if (props.stockTemplate?.isIncoming) return 'truck-arrow-right'
    if (props.stockTemplate?.isOutgoing) return 'arrow-right'
    return 'arrow-down'
  }

  function getRightIcon(): IMIconName {
    if (props.stockTemplate?.isOutgoing) return 'truck-arrow-right'
    return 'arrow-down'
  }

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          borderWidth: 0,
          marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
          padding: 0,
          paddingVertical: 5,
          alignContent: 'center',
        },
        props.style,
      ]}>
      <IMIcon
        icon={getLeftIcon()}
        style={{ justifyContent: 'center' }}
        color={theme.textPlaceholder}
        size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
      />
      <TouchableOpacity
        style={{ justifyContent: 'center' }}
        disabled={!props.onPress}
        onPress={() => {
          if (props.onPress) props.onPress()
        }}>
        <CardBasic style={{ flexDirection: 'row', margin: 0, padding: 5, paddingHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
          <IMIcon
            style={{ justifyContent: 'center', marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2 }}
            icon="cart-flatbed-boxes"
            color={theme.text}
            size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE - 5}
          />
          <Text>{props.quantity}</Text>
        </CardBasic>
      </TouchableOpacity>
      <IMIcon
        icon={getRightIcon()}
        style={{ justifyContent: 'center' }}
        color={theme.textPlaceholder}
        size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
      />
    </View>
  )
}
