import { Utils } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import { IMLayout } from '../../../constants/Styles'
import { QualityUtils } from '../../../utils/QualityUtils'
import { QualityCharacteristicInputProps } from '../../../views/QualityManagement/QualityCharacteristicInput'
import BaseTextInput from '../../BaseTextInput'
import QualityCharacteristicTitle from './QualityCharacteristicTitle'

export default function QualityControlNumericInput({ characteristic, value, setValue, validationResult }: QualityCharacteristicInputProps) {
  function handleEndEditing(text: string) {
    if (!text) {
      setValue('')
      return
    }
    const parsedNumber = QualityUtils.parseNumericInput(text, characteristic)
    setValue(Utils.convertNumberToString(parsedNumber))
  }

  return (
    <View>
      <QualityCharacteristicTitle characteristic={characteristic} validationResult={validationResult} />
      <View style={styles.inputView}>
        <View style={IMLayout.flex.f1}>
          <BaseTextInput
            value={value?.toString() ?? ''}
            onChangeText={text => setValue(text)}
            keyboardType="numeric"
            onEndEditing={e => handleEndEditing(e.nativeEvent.text)}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  inputView: {
    flexDirection: 'row',
  },
})
