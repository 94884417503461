import React from 'react'
import { FlatList } from 'react-native'

import MenuItemCard from '../cards/menus/MenuItemCard'
import ListSpacer from '../components/MWS/ListSpacer'
import MWS_Screen from '../components/MWS/MWS_Screen'
import useRouteParam from '../hooks/useRouteParam'
import { MenuCollection } from '../types'

export default function SubMenuScreen() {
  const menu = useRouteParam<MenuCollection>('menu')

  return (
    <MWS_Screen noTopPadding title={menu?.menu.group}>
      <FlatList
        data={menu?.subMenus}
        renderItem={({ item }) => <MenuItemCard menu={{ menu: item }} />}
        ListHeaderComponent={<ListSpacer />}
        ListFooterComponent={<ListSpacer />}
      />
    </MWS_Screen>
  )
}
