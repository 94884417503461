import { ClientProvider } from '@infominds/react-api'

import {
  SerialNumberAvailabilityRequest,
  SerialnumberChecksRequest,
  SerialNumberManufactorNumberCheckRequest,
  SerialNumberOrderRequest,
  SerialnumberPatchCommand,
  SerialnumberPostRequest,
  SerialnumberQuantityRequest,
  SerialnumberRequest,
} from './apiRequestTypes'
import { ArticleAvailability, ArticleOrder, SerialNumber, SerialnumberCheck, StockPositionAvailability } from './apiTypes'

export const serialNumber = ClientProvider<SerialNumber[], SerialnumberRequest, string, SerialnumberPostRequest, string, SerialnumberPatchCommand>(
  'serialNumbers'
)
export const serialNumberAvailability = ClientProvider<ArticleAvailability, SerialNumberAvailabilityRequest>('serialNumbers/Availability')
export const serialNumberCheck = ClientProvider<SerialnumberCheck, SerialnumberChecksRequest>('serialNumbers/checks')
export const serialNumberOrders = ClientProvider<ArticleOrder[], SerialNumberOrderRequest>('serialNumbers/orders')
export const serialNumberQuantity = ClientProvider<StockPositionAvailability[], SerialnumberQuantityRequest>('serialNumbers/quantities')
export const serialNumberManufactorNumberCheck = ClientProvider<boolean, SerialNumberManufactorNumberCheckRequest>(
  'serialNumbers/manufactorNumberCheck'
)
