//import { ParcelOutgoingPostRequest, ParcelPostQuantity } from '../apis/apiRequestTypes'

import { ParcelPatchRequest } from '../apis/apiRequestTypes'
import { Parcel } from '../apis/apiTypes'
import { Utils } from './Utils'

const parcelUtils = {
  generatePatchRequest(parcel: Parcel, text: string, ok: boolean): ParcelPatchRequest {
    let protocolText = `${Utils.FormatDate(new Date(), 'DD.MM.yyyy hh:mm:ss') ?? ''}` + ' - ' + text
    if (parcel.protocol !== undefined && parcel.protocol !== '') protocolText = parcel.protocol + '\n' + protocolText
    return { id: parcel?.id, protocol: protocolText, loadingDateTime: ok ? new Date() : undefined }
  },
}

export default parcelUtils
