import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { StockPosition, Worksheet, WorksheetSerialNumbers } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import WorksheetCard from '../../cards/Worksheet/WorksheetCard'
import { Article } from '../../classes/Article'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import worksheetUtils from '../../utils/WorksheetUtils'
import StockPositionSelectionView from '../../views/InputViews/StockPositionSelectionView'

export default function WorksheetScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const navigation = useNavigation()

  const handler = useRouteParam<Worksheet>('worksheet')
  const article = useRouteParam<Article>('article')
  const loader = useLoadingIndicator()
  const [selectedAmount, setSelectedAmount] = useState<number | undefined>()
  const [worksheetSerialNumbers, setWorksheetSerialNumbers] = useState<WorksheetSerialNumbers[] | undefined>()
  const [stockPositionProposal, setStockPositionProposal] = useState<StockPosition | undefined>()
  const [stockPosition, setStockPosition] = useState<StockPosition | undefined>()
  const [netWeight, setNetWeight] = useState(0)
  const [netWeightToConfirm, setNetWeightToConfirm] = useState(false)

  useEffect(() => {
    load()
  }, [])

  function load() {
    if (!handler) {
      navigation.navigate(ScreenType.WorksheetSelection)
      return
    }

    if (!handler.isOpen) {
      alert.info(i18n.t('WorksheetDone'))
      navigation.navigate(ScreenType.WorksheetSelection)
      return
    }

    if (handler.number !== handler.number_LastOfProduction) {
      alert.info(i18n.t('WorksheetNotLastOfProduction'))
      navigation.navigate(ScreenType.WorksheetSelection)
      return
    }

    if (!article) {
      alert.info(i18n.t('NoArticle'))
      navigation.navigate(ScreenType.WorksheetSelection)
      return
    }

    if (article && article.mainUnit) {
      setNetWeightToConfirm(article.mainUnit.netWeight === 0)
    }
  }

  function loadWorksheetSerialNumbers() {
    if (!handler) {
      return
    }
    if (!selectedAmount) {
      return
    }
    if (!article) {
      return
    }
    loader.setLoading(true)
    api
      .getWorksheetSerialNumbers({ worksheetId: handler.id, quantity: selectedAmount })
      .then(result => {
        if (result && result.length > 0) {
          setWorksheetSerialNumbers(result)
          loader.setLoading(false)
          /*
          api
            .getStockPositionProposal({ articleId: article.info.id, palletType: 0 })
            .then((result) => {
              if (result) {
                setStockPositionProposal(result)
              }
            })
            .catch((reason) => {
              alert.error(reason?.Message)
              console.error(reason)
            })
            .finally(() => loader.setLoading(false))
            */
        } else {
          alert.error(i18n.t('NoSerialNumbersFound'))
          loader.setLoading(false)
        }
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message)
        console.error(reason)
        loader.setLoading(false)
      })
  }

  function onDeleteStockPosition() {
    setStockPosition(undefined)
  }

  function onSetNetWeight(netWeightToSet: number) {
    setNetWeight(netWeightToSet)
  }

  async function onConfirmNetWeight() {
    setNetWeightToConfirm(false)
    if (!article) {
      return
    }
    try {
      loader.setLoading(true)
      await api.patchArticleMeasurementUnit({ articleId: article.info.id, unitId: article.info.measurementUnitId, netWeight: netWeight })
    } catch (reason) {
      alert.error(i18n.t('ProductionCompletionFailure'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  function onPressStockPositionProposal() {
    if (stockPositionProposal && !stockPosition) setStockPosition(stockPositionProposal)
  }

  async function onConfirm() {
    if (!handler) {
      return
    }
    if (!article) {
      return
    }
    if (!selectedAmount) {
      return
    }
    if (!stockPosition) {
      return
    }
    if (!worksheetSerialNumbers) {
      return
    }
    try {
      loader.setLoading(true)
      const request = worksheetUtils.generateConfirmPostRequest(handler, stockPosition.id, selectedAmount, worksheetSerialNumbers)
      const result = await api.postWorksheetConfirm(request)
      if (result) {
        alert.info(i18n.t('WorksheetConfirmed'))
        navigation.navigate(ScreenType.WorksheetSelection)
      }
    } catch (reason) {
      alert.error(i18n.t('FailedToAddProductionPart'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  if (!handler) {
    navigation.navigate(ScreenType.WorksheetSelection)
    return <></>
  }

  return (
    <MWS_Screen>
      <WorksheetCard handler={handler} article={article} />
      {worksheetUtils.showInsertArticleNetWeight(netWeightToConfirm) && (
        <View>
          <Title>{`${i18n.t('Article')}  - ${i18n.t('Weight')}`}</Title>
          <QuantityInput
            text={i18n.t('Net')}
            unit={i18n.t('WeightUnit')}
            value={0}
            onValueChanged={onSetNetWeight}
            placeHolder={''}
            style={styles.input}
          />
          <SubmitButton
            style={{ marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
            title={i18n.t('Confirm')}
            onPress={onConfirmNetWeight}
          />
        </View>
      )}
      {worksheetUtils.showQuantitySelector(netWeightToConfirm, worksheetSerialNumbers) && (
        <VKUnitAmountSelector
          style={{
            marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
          }}
          value={handler?.quantity - handler?.quantityBooked ?? 0}
          setValue={value => {
            setSelectedAmount(value)
            loadWorksheetSerialNumbers()
          }}
          usedUnit={article?.mainUnit}
          onUnitSelected={unit => {
            article?.setUsedUnit(unit)
          }}
          autoFocus
        />
      )}
      {worksheetUtils.showStockpositionSelectorAndConfirm(netWeightToConfirm, worksheetSerialNumbers) && (
        <View style={{ flex: 1 }}>
          <Title>{i18n.t('SerialNumbers')}</Title>
          <View style={{ maxHeight: '50%' }}>
            <FlatList
              data={worksheetSerialNumbers ?? []}
              renderItem={({ item }) => <SerialnumberCard serialnumber={item.number} quantity={article?.getQuantityWithUnitText(item.quantity)} />}
            />
          </View>
          {stockPositionProposal && !stockPosition && (
            <View>
              <Title>{i18n.t('StockPositionProposal')}</Title>
              <StockPositionCard stockPosition={stockPositionProposal} onPress={onPressStockPositionProposal} />
            </View>
          )}
          {!stockPosition && (
            <View>
              <StockPositionSelectionView showResultInModal onSelected={setStockPosition} />
            </View>
          )}
          {stockPosition && (
            <>
              <View style={{ flex: 1 }}>
                <Title>{i18n.t('StockPosition')}</Title>
                <StockPositionCard stockPosition={stockPosition} onDelete={onDeleteStockPosition} />
              </View>
              <SubmitButton style={{ marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }} title={i18n.t('Confirm')} onPress={onConfirm} />
            </>
          )}
        </View>
      )}
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  input: { marginBottom: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS },
  content: {},
})
