import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ArticleOrder } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { articleUtils } from '../../utils/articleUtils'
import MWS_BaseCard, { MWSBaseCardProps } from '../MWS_BaseCard'

export interface ArticleOrderCardProps {
  order: ArticleOrder | undefined
}

export default function ArticleOrderCard(props: ArticleOrderCardProps & MWSBaseCardProps) {
  const { order, ...cardProps } = props

  if (!order) return <></>
  return (
    <MWS_BaseCard icon={'article'} {...cardProps}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
        }}>
        <IMText primary>{order.document}</IMText>
        <IMText numberOfLines={1} secondary>
          {order.customer}
        </IMText>
      </View>
      <View style={{ justifyContent: 'center' }}>
        {order.quantity !== undefined && (
          <IMText style={{ alignSelf: 'flex-end', marginLeft: 10 }}>{articleUtils.formatQuantity(order.quantity)}</IMText>
        )}
      </View>
    </MWS_BaseCard>
  )
}

const styles = StyleSheet.create({
  card: {},
  contentView: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    paddingHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
})
