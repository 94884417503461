import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/core'
import React from 'react'

import { ProductionMaster } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import ProductionAllocationListSelectionView from '../../views/InputViews/ProductionAllocationListSelectionView'

export default function ProductionAllocationListSelectionScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  function showProduction(production: ProductionMaster) {
    navigation.navigate(ScreenType.ProductionAllocationPreProduction, { productionMaster: production })
  }

  return (
    <MWS_Screen title={i18n.t('SelectProductionMaster')}>
      <ProductionAllocationListSelectionView onSelected={showProduction} allowEmptyInput style={{ flex: 1 }} filterResultByOrder />
    </MWS_Screen>
  )
}
