import { QualityCharacteristic, QualityCharacteristicType, QualityControl, QualityControlValue } from '../apis/apiTypes'
import { QualityCharacteristicValue, QualityValidationResult, QualityValidationResultType } from '../types'
import { Utils } from './Utils'

export const QualityUtils = {
  sortCharacteristic(a: QualityCharacteristic, b: QualityCharacteristic) {
    if (a.order || b.order) return (a.order ?? 0) - (b.order ?? 0)
    return 0
  },
  sortQualityControlByDate(a: QualityControl, b: QualityControl) {
    return Utils.compareStringsForSort(a.date, b.date)
  },
  validateValue(value: QualityCharacteristicValue, characteristic: QualityCharacteristic) {
    const result: QualityValidationResult = {
      id: characteristic.id,
      ok: false,
      check: characteristic.check?.targetCompare ? QualityValidationResultType.ok : QualityValidationResultType.notRequired,
      required: characteristic.check?.isMandatory ? QualityValidationResultType.ok : QualityValidationResultType.notRequired,
    }

    if (characteristic.check?.targetCompare) {
      switch (characteristic.fieldTyp) {
        case QualityCharacteristicType.Text:
          if (!value) {
            result.check = QualityValidationResultType.noValue
          } else if (!!characteristic.check.targetText && !Utils.compareStrings(characteristic.check.targetText, value?.toString())) {
            result.check = QualityValidationResultType.notOk
          }
          break
        case QualityCharacteristicType.Selection:
          if (!value) {
            result.check = QualityValidationResultType.noValue
          } else if (!!characteristic.check.targetText && !Utils.compareStrings(characteristic.check.targetText, value?.toString())) {
            result.check = QualityValidationResultType.notOk
          }
          break
        case QualityCharacteristicType.Boolean:
          if (characteristic.check?.targetBoolean !== undefined && characteristic.check?.targetBoolean !== !!value) {
            result.check = QualityValidationResultType.notOk
          }
          break
        case QualityCharacteristicType.Numeric:
          if (value === null || value === undefined || value === '') {
            result.check = QualityValidationResultType.noValue
          } else {
            const numericValue = this.parseNumericInput(value?.toString(), characteristic)
            if (
              (characteristic.check.targetNumericFrom && numericValue < characteristic.check.targetNumericFrom) ||
              (characteristic.check.targetNumericUntil && numericValue > characteristic.check.targetNumericUntil)
            ) {
              result.check = QualityValidationResultType.notOk
            }
            break
          }
      }
    }

    if (characteristic.check?.isMandatory) {
      switch (characteristic.fieldTyp) {
        case QualityCharacteristicType.Text:
          if (!value?.toString().trim()) result.required = QualityValidationResultType.notOk
          break
        case QualityCharacteristicType.Selection:
          if (!value?.toString().trim()) result.required = QualityValidationResultType.notOk
          break
        case QualityCharacteristicType.Numeric:
          if (value === undefined || value === null || value === '') result.required = QualityValidationResultType.notOk
          break
      }
    }
    result.ok =
      (result.check === QualityValidationResultType.notRequired || result.check === QualityValidationResultType.ok) &&
      (result.required === QualityValidationResultType.notRequired || result.required === QualityValidationResultType.ok)
    return result
  },
  parseValueFromQualityControlValue(value: QualityControlValue, characteristics: QualityCharacteristic[]) {
    const characteristic = characteristics.find(c => c.id === value.qualityCharacteristicId)
    if (!characteristic) return undefined
    switch (characteristic.fieldTyp) {
      case QualityCharacteristicType.Text:
        return value.stringValue
      case QualityCharacteristicType.Selection:
        return value.stringValue
      case QualityCharacteristicType.Numeric:
        return Utils.convertNumberToString(value.numberValue?.toString())
      case QualityCharacteristicType.Boolean:
        return value.logicValue
      default:
        return undefined
    }
  },
  parseNumericInput(value: string, characteristic: QualityCharacteristic) {
    let valueNumber = Utils.parseFloatFromText(value)
    if (characteristic.decimal) valueNumber = Utils.parseFloatFromText(valueNumber.toFixed(characteristic.decimal))
    return valueNumber
  },
  numberCheck(value: number, characteristic: QualityCharacteristic) {
    return (
      (!characteristic.check?.targetNumericFrom || value >= characteristic.check.targetNumericFrom) &&
      (!characteristic.check?.targetNumericUntil || value <= characteristic.check.targetNumericUntil)
    )
  },
  formatValueForRequest(value: QualityCharacteristicValue, characteristic: QualityCharacteristic | undefined) {
    if (!characteristic) return undefined
    switch (characteristic.fieldTyp) {
      case QualityCharacteristicType.Text:
        return value
      case QualityCharacteristicType.Selection:
        return value
      case QualityCharacteristicType.Numeric:
        return Utils.convertNumberToString(value?.toString(), true)
      case QualityCharacteristicType.Boolean:
        return !!value
      default:
        return undefined
    }
  },
}
