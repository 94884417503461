import { ClientProvider } from '@infominds/react-api'

import {
  DeleteStockAccountingRequest,
  DepositRequest,
  PatchStockAccountingRequest,
  StockAccountingPostRequest,
  StockPositionAvailabilityRequest,
  StockPositionProposalRequest,
  StockPositionQuantitiesRequest,
  StockPositionQuantityForKanbanTransferRequest,
  StockPositionRequest,
  StockTemplateRequest,
} from './apiRequestTypes'
import {
  Deposit,
  StockAccountingPostResult,
  StockPosition,
  StockPositionAvailability,
  StockPositionAvailabilityWithMainSupplier,
  StockPositionQuantity,
  StockpositionQuantityForKanbanTransfer,
  StockTemplate,
} from './apiTypes'

export const deposit = ClientProvider<Deposit[], DepositRequest>('wareHouse/deposits')
export const stockPosition = ClientProvider<StockPosition[], StockPositionRequest>('wareHouse/stockPositions')
export const stockTemplate = ClientProvider<StockTemplate[], StockTemplateRequest>('wareHouse/stockTemplates')
export const stockPositionAvailability = ClientProvider<StockPositionAvailability[], StockPositionAvailabilityRequest>(
  'wareHouse/stockPositionAvailabilities'
)
export const stockPositionQuantity = ClientProvider<StockPositionQuantity[], StockPositionQuantitiesRequest>('wareHouse/stockPositionQuantities')
export const stockPositionAvailabilityWithSupplier = ClientProvider<StockPositionAvailabilityWithMainSupplier[], StockPositionAvailabilityRequest>(
  'wareHouse/stockpositionAvailabilitiesWithMainSuppliers'
)
export const stockAccounting = ClientProvider<
  unknown,
  unknown,
  StockAccountingPostResult,
  StockAccountingPostRequest,
  boolean,
  PatchStockAccountingRequest,
  boolean,
  unknown,
  DeleteStockAccountingRequest
>('wareHouse/stockAccounting')
export const stockPositionProposal = ClientProvider<StockPosition, StockPositionProposalRequest>('wareHouse/StockPositionProposal')
export const stockPositionQuantityForKanban = ClientProvider<StockpositionQuantityForKanbanTransfer, StockPositionQuantityForKanbanTransferRequest>(
  'wareHouse/stockpositionQuantityForKanbanTransfer'
)
