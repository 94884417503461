import React from 'react'

import { InventoryAccounting } from '../../apis/apiTypes'
import { Utils } from '../../utils/Utils'
import ArticleCard from '../Article/ArticleCard'

export default function InventoryAccountingCard(props: {
  accounting: InventoryAccounting[]
  isSelected: boolean
  useSalesUnit: boolean
  onPress: () => void
  onSerialPress: () => void
}) {
  const item = props.accounting.find(item => item.article)
  const quantity = Utils.sum(props.accounting, item => item.quantity)
  const endButtonActive = props.accounting.filter(item => !!item.serialnumber).length > 0

  return (
    <ArticleCard
      article={item?.article}
      quantity={quantity}
      endButton={{ icon: 'list-ul', disabled: !endButtonActive, onPress: props.onSerialPress }}
      selected={props.isSelected}
      unit={item?.measurementUnit}
      onPress={props.onPress}
    />
  )
}
