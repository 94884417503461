import { useApi } from '@infominds/react-api'
import { useBarcodeScanner } from '@infominds/react-native-barcode-scanner'
import { IM, Language, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FlatList } from 'react-native'

import api from '../apis/apiCalls'
import MenuItemCard from '../cards/menus/MenuItemCard'
import IMRefreshControl from '../components/IMRefreshControl'
import ListSpacer from '../components/MWS/ListSpacer'
import MWS_Screen from '../components/MWS/MWS_Screen'
import NoEntriesTag from '../components/NoEntriesTag'
import { UserSettingsContext } from '../contexts/UserSettingsContext'
import useAlert from '../hooks/useAlert'
import menuUtils from '../utils/MenuUtils'

export default function HomeScreen() {
  const alert = useAlert()
  const { i18n, language, setLanguageAsync } = useLanguage()
  const { updateToken } = useAuthentication()
  const userSettings = useContext(UserSettingsContext)

  const [menuList, loadMenus] = useApi(api.getMenu, [])
  const menuCollection = useMemo(() => menuUtils.getMenuCollection(menuList), [menuList])
  const [loading, setLoading] = useState(true)

  useEffect(load, [])

  function load() {
    setLoading(true)

    userSettings
      ?.load()
      .then(result => {
        if (!result) return
        console.debug('Loaded UserSettings', result)
        //Change AppLanguage to UserLanguage set in Radix
        const changeLang = result.language?.toLowerCase() as Language
        if (__DEV__ || language === changeLang) return loadMenu()

        setLanguageAsync(changeLang)
          .then(() => {
            updateToken(undefined, changeLang)
              .then(() => {
                loadMenu()
              })
              .catch(console.error)
          })
          .catch(console.error)
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert.error(i18n.t('FailedToLoadUserSettings'), reason?.Message)
        console.error(reason)
        loadMenu()
      })
  }

  const loadMenu = () => {
    loadMenus()
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert.error(i18n.t('FailedToLoadMenu'), reason?.Message)
        console.error(reason)
      })
      .finally(() => setLoading(false))
  }

  return (
    <MWS_Screen noTopPadding onSettingsClosed={load}>
      {loading ? (
        <IM.View spacing="all" style={{ width: '100%', alignItems: 'center' }}>
          <IM.Text secondary>{i18n.t('LOADING')}</IM.Text>
        </IM.View>
      ) : (
        <FlatList
          data={menuCollection}
          renderItem={({ item }) => <MenuItemCard menu={item} />}
          refreshControl={<IMRefreshControl refreshing={loading} onRefresh={load} />}
          ListHeaderComponent={<ListSpacer />}
          ListFooterComponent={!loading && !menuCollection?.length ? <NoEntriesTag /> : <ListSpacer />}
        />
      )}
    </MWS_Screen>
  )
}
