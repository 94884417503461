import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, TextStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import Text from './old/Text'

export default function NoEntriesTag(props: { text?: string; style?: StyleProp<TextStyle>; hide?: boolean }) {
  const { i18n } = useLanguage()

  if (props.hide) return <></>

  return (
    <Text
      style={[
        {
          marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
          textAlign: 'center',
        },
        props.style,
      ]}>
      {props.text || i18n.t('NO_ENTRIES')}
    </Text>
  )
}
