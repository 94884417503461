import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, StyleProp, View, ViewStyle } from 'react-native'

import { ArticleMeasurementUnit } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import ElusiveButton from '../../components/ElusiveButton'
import Title from '../../components/MWS/Title'
import { ItemSelector } from '../../hooks/useItemSelector'
import { articleUtils } from '../../utils/articleUtils'
import { depositUtils } from '../../utils/depositUtils'
import { ArticleAmountSelectionResult } from '../Article/ArticleAmountSelectorV2'

export default function SerialSelectionListView(props: {
  selector: ItemSelector<ArticleAmountSelectionResult>
  usedUnit?: ArticleMeasurementUnit
  onCompleted: () => void
  style?: StyleProp<ViewStyle>
  title?: string
  showPosition?: boolean
  hideConfirmationButton?: boolean
  confirmationButtonCaption?: string
  confirmationButtonDisabled?: boolean
  confirmationButtonAlwaysActiveAndEnabled?: boolean
}) {
  const { i18n } = useLanguage()

  return (
    <View style={[{ flex: 1 }, props.style]}>
      {props.title && props.selector.any && <Title>{props.title}</Title>}
      <FlatList
        style={{ flex: 1 }}
        data={[...props.selector.items].reverse()}
        renderItem={({ item }) => (
          <SerialnumberCard
            serialnumber={item.serialNumber?.number ?? ''}
            infoText={props.showPosition && item.deposit ? depositUtils.getTitleWithStockPosition(item.deposit, item.stockPosition) : undefined}
            quantity={articleUtils.formatQuantity(item.quantity, props.usedUnit)}
            reservedQuantity={articleUtils.formatQuantity((item.quantityCommissioned ?? 0) + (item.quantityOrdered ?? 0), props.usedUnit)}
            onDelete={() => props.selector.remove(item)}
          />
        )}
      />
      <ElusiveButton
        hide={(!props.selector.any || props.hideConfirmationButton) && !props.confirmationButtonAlwaysActiveAndEnabled}
        hideFromKeyBoard
        title={props.confirmationButtonCaption || i18n.t('Continue')}
        disabled={props.confirmationButtonDisabled && !props.confirmationButtonAlwaysActiveAndEnabled}
        onPress={props.onCompleted}
      />
    </View>
  )
}
