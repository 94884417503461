import { useTheme } from '@infominds/react-native-components'

import { ThemeExtension } from '../ThemeExtension'

export default function useExtendedTheme() {
  const theme = useTheme<ThemeExtension>()

  function getColorByIndex(index: number) {
    const colorIndex = index % theme.theme.randomColors.length
    return theme.theme.randomColors[colorIndex]
  }

  return { ...theme, getColorByIndex }
}
