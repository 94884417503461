import {
  ArticleDTO,
  Freighter,
  PackingList,
  PackingListAdditionalField,
  PackingListOrder,
  PackingListProduction,
  PackingListProject,
  Production,
} from '../apis/apiTypes'
import { DataFilterConfig, DataFilterConfigType, FilterConfigOptions, GroupConfigOptions } from '../types'
import { SortUtils } from '../utils/SortUtils'

function filterOptionsProvider<TSub = void>(options: FilterConfigOptions<TSub>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
  return options as any
}

function groupOptionsProvider<T extends object, TSub = void>(options: GroupConfigOptions<T, TSub>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
  return options as any
}

export type PackingListSubTypes = Freighter | PackingListOrder | PackingListAdditionalField | PackingListProject | PackingListProduction
export const PackingListFilterConfig: Pick<DataFilterConfig<PackingList, PackingListSubTypes>, 'filter' | 'order' | 'group'> = {
  filter: {
    type: DataFilterConfigType.Filter,
    config: [
      ['customer', 'CUSTOMER', { icon: ['fal', 'building'] }],
      ['type', 'Type', { icon: ['fal', 'box-taped'] }],
      [
        'freighter',
        'Freighter',
        filterOptionsProvider<Freighter>({
          filterType: 'object',
          valueExtractor: item => ({ id: item.id, value: item?.description }),
          icon: ['fal', 'truck-arrow-right'],
        }),
      ],
      ['deliverydate', 'DELIVERY_DATE', { icon: ['fal', 'calendar'] }],
      [
        'orders',
        'Order',
        filterOptionsProvider<PackingListOrder>({
          filterType: 'array',
          valueExtractor: item => ({ id: item.id, value: item?.number }),
          icon: ['fal', 'clipboard-list-check'],
        }),
      ],
    ],
  },
  order: {
    type: DataFilterConfigType.Order,
    config: [
      ['numberForOrder', 'NUMBER', { isDefault: true }],
      ['numberForOrder', 'NUMBER', { modifier: 'inverse' }],
      ['deliverydate', 'DELIVERY_DATE'],
      ['deliverydate', 'DELIVERY_DATE', { modifier: 'inverse' }],
      ['customer', 'CUSTOMER'],
      ['customer', 'CUSTOMER', { modifier: 'inverse' }],
      ['type', 'Type'],
      ['type', 'Type', { modifier: 'inverse' }],
      ['freighterForOrder', 'Freighter'],
      ['freighterForOrder', 'Freighter', { modifier: 'inverse' }],
    ],
  },
  group: {
    type: DataFilterConfigType.Group,
    config: [
      ['customer', 'CUSTOMER'],
      ['type', 'Type'],
      [
        'freighter',
        'Freighter',
        groupOptionsProvider<PackingList, Freighter>({ noGroupTextKey: 'NO_FREIGHTER', subObjectValueProvider: item => item?.description }),
      ],
      ['deliverydate', 'DELIVERY_DATE', { noGroupTextKey: 'NO_DATE' }],
      [
        'orders',
        'Order',
        groupOptionsProvider<PackingList, PackingListOrder>({
          noGroupTextKey: 'NO_ORDER',
          subObjectKey: 'number',
          titleSortFunction: (a, b) => (!a && b ? 1 : !b && a ? -1 : SortUtils.sortNumberString(a, b, '-')),
        }),
      ],
    ],
  },
}

export const PackingListPreConsignmentFilterConfig: Pick<DataFilterConfig<PackingList, PackingListSubTypes>, 'filter' | 'order' | 'group'> = {
  filter: {
    type: DataFilterConfigType.Filter,
    config: [
      ['customer', 'CUSTOMER', { icon: ['fal', 'building'] }],
      [
        'freighter',
        'Freighter',
        filterOptionsProvider<Freighter>({
          filterType: 'object',
          valueExtractor: item => ({ id: item.id, value: item?.description }),
          icon: ['fal', 'truck-arrow-right'],
        }),
      ],
      ['deliverydate', 'DELIVERY_DATE', { icon: ['fal', 'calendar'] }],
      [
        'orders',
        'Order',
        filterOptionsProvider<PackingListOrder>({
          filterType: 'array',
          valueExtractor: item => ({ id: item.id, value: item?.number }),
          icon: ['fal', 'clipboard-list-check'],
        }),
      ],
    ],
  },
  order: {
    type: DataFilterConfigType.Order,
    config: [
      ['numberForOrder', 'NUMBER', { isDefault: true }],
      ['numberForOrder', 'NUMBER', { modifier: 'inverse' }],
      ['deliverydate', 'DELIVERY_DATE'],
      ['deliverydate', 'DELIVERY_DATE', { modifier: 'inverse' }],
      ['customer', 'CUSTOMER'],
      ['customer', 'CUSTOMER', { modifier: 'inverse' }],
    ],
  },
  group: {
    type: DataFilterConfigType.Group,
    config: [
      ['customer', 'CUSTOMER'],
      [
        'freighter',
        'Freighter',
        groupOptionsProvider<PackingList, Freighter>({ noGroupTextKey: 'NO_FREIGHTER', subObjectValueProvider: item => item?.description }),
      ],
      ['deliverydate', 'DELIVERY_DATE', { noGroupTextKey: 'NO_DATE' }],
      [
        'orders',
        'Order',
        groupOptionsProvider<PackingList, PackingListOrder>({
          noGroupTextKey: 'NO_ORDER',
          subObjectKey: 'number',
          titleSortFunction: (a, b) => (!a && b ? 1 : !b && a ? -1 : SortUtils.sortNumberString(a, b, '-')),
        }),
      ],
    ],
  },
}

export const PackingListProductionFilterConfig: Pick<DataFilterConfig<PackingList, PackingListSubTypes>, 'filter' | 'order' | 'group'> = {
  filter: {
    type: DataFilterConfigType.Filter,
    config: [
      ['customer', 'CUSTOMER', { icon: ['fal', 'building'] }],
      [
        'project',
        'PROJECT',
        {
          filterType: 'object',
          valueExtractor: item => ({
            id: (item as PackingListProject).id,
            value: projectValueProvider(item),
          }),
        },
      ],
      [
        'production',
        'PRODUCTION',
        { filterType: 'object', valueExtractor: item => ({ id: (item as PackingListProject).id, value: productionValueProvider(item) }) },
      ],
    ],
  },
  order: {
    type: DataFilterConfigType.Order,
    config: [
      ['numberForOrder', 'NUMBER', { isDefault: true }],
      ['numberForOrder', 'NUMBER', { modifier: 'inverse' }],
      ['customer', 'CUSTOMER'],
      ['customer', 'CUSTOMER', { modifier: 'inverse' }],
      [
        'project',
        'PROJECT',
        {
          subObjectValueProvider: projectValueProvider,
        },
      ],
      [
        'project',
        'PROJECT',
        {
          subObjectValueProvider: projectValueProvider,
          modifier: 'inverse',
        },
      ],
      ['production', 'PRODUCTION', { subObjectValueProvider: productionValueProvider }],
      ['production', 'PRODUCTION', { subObjectValueProvider: productionValueProvider, modifier: 'inverse' }],
    ],
  },
  group: {
    type: DataFilterConfigType.Group,
    config: [
      ['customer', 'CUSTOMER'],
      [
        'project',
        'PROJECT',
        {
          subObjectValueProvider: projectValueProvider,
          noGroupTextKey: 'NO_PROJECT',
        },
      ],
      ['production', 'PRODUCTION', { subObjectValueProvider: productionValueProvider, noGroupTextKey: 'NO_PRODUCTION' }],
    ],
  },
}

export const ProductionFilterConfig: Pick<DataFilterConfig<Production, ArticleDTO>, 'order' | 'searchKeys'> = {
  order: {
    type: DataFilterConfigType.Order,
    config: [
      ['code', 'NUMBER', { isDefault: true }],
      ['code', 'NUMBER', { modifier: 'inverse' }],
      ['article', 'Article', { subObjectValueProvider: article => article.code }],
      ['article', 'Article', { subObjectValueProvider: article => article.code, modifier: 'inverse' }],
      ['additionalDescription', 'ADDITIONALDESCRIPTION', { isDefault: true }],
      ['additionalDescription', 'ADDITIONALDESCRIPTION', { modifier: 'inverse' }],
    ],
  },
  searchKeys: [
    'code',
    [
      'article',
      (obj: unknown) => {
        if (!obj) return ''
        const article: ArticleDTO = obj as ArticleDTO
        const result: (string | undefined)[] = []
        result.push(article.code)
        result.push(...article.descriptions.map(q => q.text))

        return result.filter(Boolean).join('')
      },
    ],
    'additionalDescription',
  ],
}

function projectValueProvider(item: PackingListSubTypes) {
  return [(item as PackingListProject).code, (item as PackingListProject).description].filter(Boolean).join(' ').trim()
}

function productionValueProvider(item: PackingListSubTypes) {
  return [(item as PackingListProduction).code, (item as PackingListProduction).description].filter(Boolean).join(' ').trim()
}
