import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../apis/apiCalls'
import { StockPosition, StockPositionState } from '../../apis/apiTypes'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { SelectionViewProps } from '../../types'
import stockPositionUtils from '../../utils/stockPositionUtils'

export default function StockPositionSelectionView(
  props: {
    depositId?: string
    doPreload?: boolean
    withAvailableArticleId?: string
    filterNotProposedInPackingLists?: boolean
    filterProduction?: boolean
  } & SelectionViewProps<StockPosition>
) {
  const { i18n } = useLanguage()

  const { depositId, doPreload, title, withAvailableArticleId, ...inputProps } = props

  function load(scannerInput: ScannerInputResult): Promise<StockPosition[]> {
    const input = scannerInput.scannerInput ?? scannerInput.textInput ?? ''
    if (!withAvailableArticleId || !!input) {
      return api.getStockPosition({
        code: input,
        depositId: depositId,
        state: StockPositionState.Available,
        NotProposedInPackinglists: props.filterNotProposedInPackingLists ? false : undefined,
        production: props.filterProduction ? false : undefined,
      })
    }
    return stockPositionUtils.getStockPositionsWithArticleAvailable(withAvailableArticleId, depositId)
  }

  const RenderItem = useCallback((item: StockPosition) => <StockPositionCard stockPosition={item} />, [])
  return (
    <MWS_Input
      type="Stock-Position"
      load={load}
      sortResult={stockPositionUtils.sort}
      itemRenderer={RenderItem}
      failedToLoadText={i18n.t('NoStockPosWithCode')}
      modalTitle={i18n.t('SelectStockPosition')}
      historyNameExtractor={item => stockPositionUtils.getTitle(item)}
      loadFromHistory={item => api.getStockPosition({ id: item.id })}
      preload={doPreload ? () => api.getStockPosition({ depositId: depositId }) : undefined}
      title={title ?? i18n.t('SelectStockPosition')}
      {...inputProps}
    />
  )
}
