import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { PackingListConclusionType } from '../../apis/apiRequestTypes'
import { PackingList, PackingListMovement } from '../../apis/apiTypes'
import PackingListCard from '../../cards/Packinglist/PackingListCard'
import PackingListMovementCard from '../../cards/Packinglist/PackingListMovementCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import ElusiveButton from '../../components/ElusiveButton'
import IMText from '../../components/IMText'
import ItemSelectionList from '../../components/ItemSelectionList'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import usePackingListHandler from '../../hooks/specific/usePackingListHandler'
import useAlert from '../../hooks/useAlert'
import useToastMessage from '../../hooks/useToastMessage'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'

export default function PackingListToComplete() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const loader = useLoadingIndicator()
  const alert = useAlert()
  const toast = useToastMessage()

  const handler = usePackingListHandler()
  const [packingLists, setPackingLists] = useState<PackingList[]>([])
  const [packingListToComplete, setPackingListToComplete] = useState<PackingList>()
  const [allPackingListMovements, setAllPackingListMovements] = useState<PackingListArticle[]>([])
  const [packingListMovementsToShow, setPackingListMovementsToShow] = useState<PackingListMovement[]>([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (loaded) return

    loader.setLoading(true)
    handler
      .load()
      .then(movements => {
        setAllPackingListMovements(movements)
        setLoaded(true)
      })
      .catch(reason => {
        console.error(reason)
        navigation.goBack()
      })
      .finally(() => {
        loader.setLoading(false)
      })

    setPackingLists(handler.packingLists ? handler.packingLists : [])
    if (handler.packingLists?.length === 1) {
      const packagingList = handler.packingLists[0]
      if (!packagingList) return

      loader.setLoading(true)
      Promise.resolve(
        api.postPackingListConclude({
          packingListId: packagingList.id,
          conclusionTyp: PackingListConclusionType.concludeAndCreateTransfer,
          collaboratorId: handler.userSettings?.employeeId,
        })
      )
        .then(() => onConcludeDone(packagingList))
        .catch(showErrorAlert)
        .finally(() => loader.setLoading(false))
    }
  }, [])

  function close() {
    navigation.navigate(ScreenType.ProductionConsignmentListSelection)
  }

  const RenderItem = useCallback((item: PackingList) => <PackingListCard packingList={item} showDate />, [])

  function selectItem(item: PackingList) {
    setPackingListToComplete(item)
    setPackingListMovementsToShow([])

    const movements = packingListMovementsToShow
    allPackingListMovements?.forEach(element => {
      const movementsToAdd = element.movements.filter(q => q.packingList.id === item.id)
      if (movementsToAdd) {
        movementsToAdd.forEach(movement => {
          movements.push(...[movement?.movement])
        })
      }
    })
    setPackingListMovementsToShow(movements)
  }

  async function conclude() {
    if (!packingListToComplete) return

    try {
      loader.setLoading(true)
      const request = {
        packingListId: packingListToComplete.id,
        conclusionTyp: PackingListConclusionType.concludeAndCreateTransfer,
        collaboratorId: handler.userSettings?.employeeId,
      }
      await api.postPackingListConclude(request)
      onConcludeDone(packingListToComplete)
    } catch (exception) {
      showErrorAlert(exception)
    } finally {
      loader.setLoading(false)
    }
  }

  function onConcludeDone(packingListCompleted: PackingList) {
    toast.show(i18n.t('PackingListConclusionSuccessful'))
    if (!packingListCompleted) return

    // Remove concluded Packinglist from List
    const array = packingLists // make a separate copy of the array
    const index = array.indexOf(packingListCompleted)
    if (index !== -1) {
      array.splice(index, 1)
      setPackingLists(array)
    }
    setPackingListToComplete(undefined)

    if (packingLists?.length === 0) close()
  }

  function showErrorAlert(reason?: unknown) {
    setPackingListToComplete(undefined)
    console.error(reason)
    alert.error(i18n.t('FailedToConcludePackingList'), Error_Utils.extractErrorMessageFromException(reason))
  }

  return (
    <View style={{ flex: 1 }}>
      {!packingListToComplete && (
        <View style={{ flex: 1 }}>
          <ItemSelectionList items={packingLists} onSelected={selectItem} itemRenderer={RenderItem} />
          <ElusiveButton title={i18n.t('Close')} onPress={() => close()} />
        </View>
      )}
      {packingListToComplete && (
        <View style={{ flex: 1 }}>
          <IMText
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
            }}>
            {packingListToComplete.number}
          </IMText>
          <View style={{ flex: 1 }}>
            <FlatList data={packingListMovementsToShow} renderItem={({ item }) => <PackingListMovementCard item={item} />} />
          </View>
          <ElusiveButton title={i18n.t('ConcludePackingList')} onPress={() => conclude().catch} />
        </View>
      )}
    </View>
  )
}
