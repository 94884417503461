import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Colors } from '../../constants/Colors'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import IMIcon from '../IMIcon'

export default function OutOfStockIndicator(props: { style?: StyleProp<ViewStyle> }) {
  const userSettings = useUserSettings()
  return (
    <IMIcon
      icon={userSettings?.language?.toUpperCase() === 'DE' ? 'A' : userSettings?.language?.toUpperCase() === 'IT' ? 'U' : 'L'}
      size={20}
      color={Colors.grey}
      style={props.style}
    />
  )
}
