import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { Collo } from '../../apis/apiTypes'
import SubmitButton from '../../components/SubmitButton'
import { useDoubleClick } from '../../contexts/DoubleClickContext'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useRuntimeStorage } from '../../contexts/RuntimeStorageContext'
import { PackingListMovementHandler } from '../../hooks/specific/usePackingListMovementHandler'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import ColloCompletionModal from '../../modals/ColloCompletionModal'
import { runtimeStorageSelectedColloKey } from '../../screens/Packinglist/PackingListScreen'
import { Error_Utils } from '../../utils/ErrorUtils'
import packingListUtils from '../../utils/packingListUtils'

export default function PackingListSubmitButtonsView(props: { handler: PackingListMovementHandler }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const handler = props.handler
  const colloStorage = useRuntimeStorage(runtimeStorageSelectedColloKey)
  const activeCollo = handler.packingListHandler.colloMode ? colloStorage.getValue<string>() : undefined
  const colloCompletionModal = useModalController<Collo[]>()
  const anyCollosToConclude = handler.colloSelector.items.find(item => !item.id)

  useDoubleClick(handleDoubleClick)

  function handleDoubleClick() {
    if (!handler.colloSelector.any || !anyCollosToConclude) return
    completeMovement().catch(console.error)
  }

  async function completeMovement(concludeCollo?: boolean) {
    try {
      if (!handler.packingListArticle) return
      if (!(await prePostCheck())) return
      loader.setLoading(true)
      const results = await packingListUtils.postPackingListMovements(
        handler.packingListArticle,
        handler.colloSelector.items,
        !!handler.article?.isSerialNumberActive,
        handler.packingListHandler.userSettings,
        handler.packingListHandler.colloMode ? activeCollo : undefined,
        handler.packingListHandler.isPreConsignment,
        handler.packingListHandler.isProductionConsignment
      )
      if (results && results.length > 0) {
        const lastColloNumber = results[results.length - 1].assignedColloNumber
        colloStorage.setValue(lastColloNumber)
        return loadColloToComplete(lastColloNumber, concludeCollo)
      }
      onDone()
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToCreateCollo'), Error_Utils.extractErrorMessageFromException(error))
    }
  }

  function prePostCheck() {
    if (!handler.packingListArticle) return false
    if (handler.selectedQuantity <= handler.totalQuantity || !handler.colloSelector.items.find(item => !item.id)) return true
    if (
      handler.packingListHandler.isPreConsignment ||
      !handler.packingListHandler.userSettings?.isPackingListCheckDifferencesOverCommissioningValid
    ) {
      alert.error(i18n.t('PackingListQuantityExceedsRequiredError'), handler.selectedQuantity, handler.totalQuantity)
      return false
    }
    return alert.yesNo(i18n.t('PackingListQuantityExceedsRequiredWarning'), handler.selectedQuantity, handler.totalQuantity)
  }

  function onDone() {
    handler.packingListHandler
      .updateCollosOf(handler.packingListArticle)
      .then(result => {
        if (!result) handler.packingListHandler.load().catch(console.error)
      })
      .catch(reason => {
        console.error(reason)
        handler.packingListHandler.load().catch(console.error)
      })
      .finally(() => {
        handler.packingListHandler.setSelectedPackingListMovement(undefined)
        loader.setLoading(false)
      })
  }

  function loadColloToComplete(colloNumber: string | undefined, conclude?: boolean) {
    if (!colloNumber || !conclude) {
      return onDone()
    }
    loader.setLoading(true)
    api
      .getCollo({ number: colloNumber })
      .then(result => {
        if (!result || result.length === 0) {
          failedToConcludeCollo(colloNumber)
          return
        }
        colloCompletionModal.show([result[0]])
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      .catch(reason => failedToConcludeCollo(colloNumber, reason?.Message ?? ''))
      .finally(() => loader.setLoading(false))
  }

  function failedToConcludeCollo(colloNumber: string, reason?: string) {
    alert.error(i18n.t('FailedToConcludeCollo'), colloNumber, reason)
  }

  async function handleColloConcluded() {
    colloStorage.setValue(undefined)
    await handler.packingListHandler.load()
    handler.packingListHandler.setSelectedPackingListMovement(undefined)
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <SubmitButton
        style={{ flex: 2, marginRight: 0, paddingHorizontal: 0 }}
        hide={
          !handler.colloSelector.any ||
          !handler.packingListHandler.colloMode ||
          handler.packingListHandler.isPreConsignment ||
          handler.packingListHandler.isProductionConsignment
        }
        disabled={(!activeCollo && !anyCollosToConclude) || !!loader.loading}
        hideFromKeyBoard
        title={anyCollosToConclude ? i18n.t('ConfirmAndCompleteCollo') : i18n.t('ConcludeCollo')}
        onPress={() => completeMovement(true)}
      />
      <SubmitButton
        style={{ flex: 1, paddingHorizontal: 0 }}
        hide={!handler.colloSelector.any}
        disabled={!anyCollosToConclude || !!loader.loading}
        hideFromKeyBoard
        title={i18n.t('Confirm')}
        onPress={completeMovement}
      />
      <ColloCompletionModal
        controller={colloCompletionModal}
        onAborted={onDone}
        onCompleted={() => {
          handleColloConcluded().catch(console.error)
        }}
        deposit={handler.deposit}
      />
    </View>
  )
}
