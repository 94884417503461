import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Freighter, PrintingType } from '../../apis/apiTypes'
import ElusiveButton from '../../components/ElusiveButton'
import Picker from '../../components/MWS/Inputs/Picker'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import SubmitButton from '../../components/SubmitButton'
import SwitchWithText from '../../components/SwitchWithText'
import { StorageKeys, STYLE_CONSTANTS } from '../../constants/Constants'
import { PackingListHandler } from '../../hooks/specific/usePackingListHandler'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'
import { Utils } from '../../utils/Utils'

export default function PackingListColloModeCompletionView(props: {
  show: boolean
  handler: PackingListHandler
  conclude: (
    selectedFreighter: Freighter | undefined,
    colloCount: number | undefined,
    weight: number | undefined,
    printing: boolean | undefined,
    createDeliveryNote: boolean | undefined
  ) => Promise<void>
}) {
  const handler = props.handler
  const { i18n } = useLanguage()

  const [freighter, loadFreighter] = useApi(api.getFreighter, [])
  const [colloCount, setColloCount] = useState(0)
  const [weight, setWeight] = useState(0)
  const presetFreighter = useMemo(() => Utils.getValueIfAllAreEqual(handler.packingLists, item => item.freighter?.id)?.freighter, [])
  const [selectedFreighter, setSelectedFreighter] = useState<Freighter | undefined>(presetFreighter)
  const [isPrinting, setPrinting] = useState(false)
  const [isCreateDeliveryNote, setIsCreateDeliveryNote] = useState(false)

  useEffect(() => {
    loadFreighter().catch(console.error)
  }, [])

  useEffect(() => {
    if (!props.show) return
    setPrinting(!!handler.packingLists?.length && handler.packingLists[0].printing === PrintingType.printing)
  }, [props.show])

  useEffect(() => {
    if (!props.show || handler.isPreConsignment) return
    const colloList = packingListUtils.getCollosFromPackingListArticles(handler.movements, true)
    setColloCount(colloList.length)
    Promise.all(colloList.map(c => colloUtils.getColloWeight(c.number)))
      .then(results => {
        if (!results) return setWeight(0)
        setWeight(Utils.roundToPrecision(Utils.sum(results), 4))
      })
      .catch(console.error)
  }, [props.show])

  if (!props.show || handler.isPreConsignment) return <></>
  return (
    <View>
      <QuantityInput
        value={colloCount}
        onValueChanged={setColloCount}
        text={i18n.t('ColloCount')}
        unit={i18n.t('PiecesShort')}
        style={styles.input}
      />
      <QuantityInput value={weight} onValueChanged={setWeight} unit={i18n.t('WeightUnit')} text={i18n.t('Weight')} style={styles.input} />
      <Picker
        text={i18n.t('Freighter')}
        defaultValue={selectedFreighter}
        values={freighter ?? []}
        onSubmit={setSelectedFreighter}
        placeHolder={i18n.t('SELECT_PLACEHOLDER')}
        style={styles.input}
        captionExtractor={item => `${item.code} - ${item.description}`}
        disabled={!!presetFreighter}
      />
      <SwitchWithText
        text={i18n.t('Print_PackingList')}
        value={isPrinting}
        setValue={setPrinting}
        style={{
          marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
        }}
      />
      {handler.packingLists !== undefined && handler.packingLists.length > 0 && handler.packingLists[0].isPossibleCreateDeliveryNote && (
        <SwitchWithText
          text={i18n.t('CREATEDELIVERYNOTE_CONCLUDE_PACKINGLIST')}
          value={isCreateDeliveryNote === undefined ? false : isCreateDeliveryNote}
          setValue={setIsCreateDeliveryNote}
          style={{
            marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
            marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          }}
          storageKey={StorageKeys.PACKINGLIST_CREATE_DELIVERY_NOTE_SWITCH}
        />
      )}
      <ElusiveButton
        title={i18n.t('ConcludePackingList')}
        onPress={() => props.conclude(selectedFreighter, colloCount, weight, isPrinting, isCreateDeliveryNote).catch}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
