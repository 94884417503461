import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import colloUtils from '../../utils/colloUtils'
import { SplitCollo } from '../../views/PackingList/PackingListCompletionView'
import MWS_BaseCard from '../MWS_BaseCard'

export type SplitColloCardProps = {
  collo: SplitCollo | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
}

export default function SplitColloCard({ collo, style, onPress, onDelete }: SplitColloCardProps) {
  const { i18n } = useLanguage()

  const packing = collo?.packing

  const dimensions = colloUtils.getDimension({ ...packing } as Collo)

  const title = useMemo(() => {
    let result = collo?.number ? collo?.number.toString() : ''
    const packingTitle = packing?.packagingType?.code?.trim()
    if (packingTitle) {
      if (result) result += ' - '
      result += packingTitle
    }
    return result
  }, [collo?.packing?.packagingType])

  return (
    <MWS_BaseCard onPress={onPress} onDelete={onDelete} icon={'box-taped'} cardStyle={style}>
      <View style={{ flex: 1 }}>
        <IMText numberOfLines={1} primary style={{}}>
          {title}
        </IMText>
        {!!packing?.packagingType?.description && (
          <IMText numberOfLines={1} secondary style={{}}>
            {packing.packagingType.description?.trim()}
          </IMText>
        )}
        {!!dimensions && (
          <IMText numberOfLines={1} secondary style={{ flex: 1 }}>
            {dimensions}
          </IMText>
        )}
      </View>
      <View style={{ justifyContent: 'center' }}>
        {!!packing?.weight && (
          <IMText numberOfLines={1} primary style={{ marginLeft: 5 }}>
            {`${packing?.weight} ${i18n.t('WeightUnit')}`}
          </IMText>
        )}
      </View>
    </MWS_BaseCard>
  )
}
