import { useLanguage } from '@infominds/react-native-components'
import { StackActions, useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { AdditionalActions } from '../../apis/apiRequestTypes'
import { Item } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import TransferQuantitySelector from '../../components/MWS/Transfer/TransferQuantitySelector'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useTransfer } from '../../contexts/TransferContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useIsStockReceiptTransfer from '../../hooks/specific/useIsStockReceiptTransfer'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useOnScreenFocus from '../../hooks/useOnScreenFocus'
import useRoute from '../../hooks/useRoute'
import useRouteParam from '../../hooks/useRouteParam'
import useStockTemplate from '../../hooks/useStockTemplate'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import transferUtils from '../../utils/transferUtils'
import { ArticleAmountSelectionResult } from '../../views/Article/ArticleAmountSelectorV2'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import MultiSelectionView from '../../views/SerialNumber/MultiSelectionView'
import TransferArticleAvailabilityView from '../../views/Transfer/TransferArticleAvailabilityView'
import TransferInfoView from '../../views/Transfer/TransferInfoView'

export default function TransferArticleSelectionScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const routeParams = useRoute<'TransferArticleSelection'>()
  const isFocused = useIsFocused()
  const isStockReceiptTransfer = useIsStockReceiptTransfer()
  const transferContext = useTransfer()
  const userSettings = useUserSettings()

  const { stockTemplate } = useStockTemplate(reason => {
    alert.error(i18n.t('StockTemplateFailedToLoad'), Error_Utils.extractErrorMessageFromException(reason))
    navigation.dispatch(StackActions.popToTop())
  })
  const stockId = useRouteParam<string>('stockId')

  const [item, setItem] = useState<Item>()
  const article = useArticle(
    item?.article,
    {
      useUnitType: stockTemplate?.quantityInsertTyp,
      serialNumber: serialnumberUtils.acceptSerialNumberIfNotHistorical(item?.serialnumber),
      isVerifySerialNumber: stockTemplate?.isVerifySerialNumber,
    },
    [item]
  )
  const [articleLoaded, setArticleLoaded] = useState(false)
  const [serialNumberLoaded, setSerialNumberLoaded] = useState(false)
  const [createSerialNumbers, setCreateSerialNumbers] = useState(false)
  const loader = useLoadingIndicator()

  useOnScreenFocus(() => {
    setItem(undefined)
    setArticleLoaded(false)
    setCreateSerialNumbers(false)
    setSerialNumberLoaded(false)
    navigation.setParams({ ...routeParams, transferOk: undefined })
  })

  useEffect(() => {
    return () => {
      transferContext.clear('all')
    }
  }, [])

  useEffect(() => {
    if (!article || !stockTemplate || !isFocused) return
    if (stockTemplate.isIncoming && !article.isSerialNumberActive) {
      handleNavigation()
      return
    }
    if (stockTemplate.isIncoming && article.isSerialNumberActive) {
      if (item?.serialnumber && userSettings?.isConsiderScannedSerialNumberOnIncomingStockAccounting) {
        setCreateSerialNumbers(false)
        setArticleLoaded(true)
        if (!article.isLottoSerialNumber) {
          handleNavigation([{ quantity: 1, serialNumber: item.serialnumber, unit: article.getUsedUnit() }])
        } else {
          setSerialNumberLoaded(true)
        }
        return
      } else if (article.autoCreateSerialNumbers) {
        alert
          .yesNo(i18n.t('AutoCreateSerialNumbersAlert'), article.info.code)
          .then(answer => {
            if (answer) {
              setCreateSerialNumbers(true)
            } else {
              loadAvailability()
            }
          })
          .catch(console.error)
        return
      }
    }
    loadAvailability()
  }, [article, stockTemplate])

  function loadAvailability() {
    if (!article || !stockTemplate) return
    loader.setLoading(true)
    transferUtils
      .loadRequiredArticleData(article, stockTemplate, transferContext.savedDeposit, transferContext.savedStockPosition)
      .then(availableQuantity => {
        if (availableQuantity <= 0) {
          noAvailabilityAlert()
          return
        }
        if (!article.isSerialNumberActive) {
          handleNavigation()
          return
        }
        setArticleLoaded(true)
      })
      .finally(() => loader.setLoading(false))
  }

  function noAvailabilityAlert() {
    alert.info(i18n.t('NoAvailability'), article?.serialNumber?.number ?? article?.info.code)
    setItem(undefined)
  }

  function handleItemSelection(selectedItem: Item) {
    if (selectedItem?.serialnumber) selectedItem.serialnumber.numberManufactor = ''
    setItem(selectedItem)
  }

  function handleNavigation(selectedQuantities?: ArticleAmountSelectionResult[]) {
    if (!stockTemplate) return
    if (!selectedQuantities || selectedQuantities.length === 0) {
      navigation.navigate(ScreenType.TransferPositionSelection, {
        ...routeParams,
        selectedQuantities: [{ quantity: 0, unit: article?.getUsedUnit(), serialNumber: undefined }],
        item: item,
        stockTemplate: stockTemplate,
        stockId: stockId,
        transferOk: false,
        article: article,
      })
      setItem(undefined)
      return
    }
    navigation.navigate(ScreenType.TransferPositionSelection, {
      ...routeParams,
      selectedQuantities: selectedQuantities,
      item: item,
      stockTemplate: stockTemplate,
      stockId: stockId,
      transferOk: false,
      article: article,
    })
  }

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: item })
  }

  return (
    <MWS_Screen title={transferUtils.getScreenTitle(stockTemplate) ?? ''}>
      <TransferInfoView stockTemplate={stockTemplate} showHistory={!item} />

      {(!item || (!articleLoaded && !createSerialNumbers)) && (
        <>
          <ItemSelectionView
            onSelected={handleItemSelection}
            depositId={stockTemplate?.isIncoming ? undefined : transferUtils.getDeposit(stockTemplate, transferContext.savedDeposit)?.id}
            stockPositionId={
              stockTemplate?.isIncoming ? undefined : transferUtils.getStockPosition(stockTemplate, transferContext.savedStockPosition)?.id
            }
            additionalActions={stockTemplate?.isIncoming ? [AdditionalActions.CreateSerialNumberAfterKochBarcodeDecoding] : undefined}
            showResultInModal
          />
          <TransferArticleAvailabilityView
            show={!stockTemplate?.isIncoming ? !!transferUtils.getStockPosition(stockTemplate, transferContext.savedStockPosition) : false}
            template={stockTemplate}
            style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            onItemSelected={handleItemSelection}
          />
        </>
      )}
      {article && (articleLoaded || createSerialNumbers) && (
        <View style={{ flex: 1 }}>
          <ArticleCard
            article={article.info}
            onDelete={() => {
              setItem(undefined)
              setArticleLoaded(false)
              setCreateSerialNumbers(false)
            }}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            onPress={showArticleInfoScreen}
          />
          {articleLoaded && !serialNumberLoaded && (
            <MultiSelectionView
              article={article}
              onComplete={handleNavigation}
              showSelectedSerialsWithPosition
              deposit={!stockTemplate?.isIncoming ? transferUtils.getDeposit(stockTemplate, transferContext.savedDeposit) : undefined}
              selectSerialsByPosition={!stockTemplate?.isIncoming || isStockReceiptTransfer}
              checkAvailability={stockTemplate?.isInOut || stockTemplate?.isOutgoing}
              allowNewSerialCreation={stockTemplate?.isIncoming && article.isSerialNumberActive}
            />
          )}
          {serialNumberLoaded && (
            <>
              <SerialnumberCard serialnumber={item?.serialnumber} />
              <TransferQuantitySelector
                article={article}
                proposedQuantity={item?.barcodeQuantity}
                onQuantitySelected={value => handleNavigation([{ quantity: value, serialNumber: item?.serialnumber, unit: article.getUsedUnit() }])}
              />
            </>
          )}
          {createSerialNumbers && (
            <TransferQuantitySelector
              article={article}
              onQuantitySelected={value => handleNavigation([{ quantity: value, serialNumber: undefined, unit: article.getUsedUnit() }])}
            />
          )}
        </View>
      )}
    </MWS_Screen>
  )
}
