import { useIsMounted, useLanguage } from '@infominds/react-native-components'
import { useEffect, useState } from 'react'

import api from '../../apis/apiCalls'
import { ArticleDTO, Collo, PackingList, PackingListMovement, UserSettings } from '../../apis/apiTypes'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useRuntimeStorage } from '../../contexts/RuntimeStorageContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { runtimeStorageSelectedColloKey } from '../../screens/Packinglist/PackingListScreen'
import packingListUtils from '../../utils/packingListUtils'
import { Utils } from '../../utils/Utils'
import useAlert from '../useAlert'
import useRouteParam from '../useRouteParam'

export interface PackingListArticleMovement {
  movement: PackingListMovement
  packingList: PackingList
}

export interface PackingListArticle {
  id: string
  article: ArticleDTO
  movements: PackingListArticleMovement[]
}

export interface PackingListHandler {
  packingLists: PackingList[] | undefined
  movements: PackingListArticle[] | undefined
  selectedPackingListMovement: PackingListArticle | undefined
  isPreConsignment: boolean
  isProductionConsignment: boolean
  load: (invisibleLoading?: boolean) => Promise<PackingListArticle[]>
  userSettings?: UserSettings
  forceScan: boolean
  colloMode: boolean
  unloadCollos: boolean
  activeCollo: string | undefined
  removeCollos: (collosToDelete: Collo[] | undefined) => void
  updateCollosOf: (updatePackingListArticle: PackingListArticle | undefined) => Promise<boolean>
  setSelectedPackingListMovement: React.Dispatch<React.SetStateAction<PackingListArticle | undefined>>
  setUnloadCollos: React.Dispatch<React.SetStateAction<boolean>>
  updateActiveCollo: (movements: PackingListArticle[]) => void
  ignoreHistoricalSN: boolean
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: (value: boolean) => void
}

export default function usePackingListHandler(defaultMovements?: PackingListArticle[]): PackingListHandler {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const loader = useLoadingIndicator()
  const userSettings = useUserSettings()
  const isMounted = useIsMounted()
  const forceScan = !!userSettings?.isScanningArticleAndStockpositionMandatoryOnPicking
  const packingLists = useRouteParam<PackingList[]>('packingLists')
  const isPreConsignment = !!useRouteParam<boolean>('isPreConsignment')
  const isProductionConsignment = !!useRouteParam<boolean>('isProductionConsignment')
  const colloMode = (!!userSettings?.isPalletPickingActive && !isProductionConsignment) || isPreConsignment
  const [movements, setMovements] = useState<PackingListArticle[]>(defaultMovements ?? [])
  const [selectedPackingListMovement, setSelectedPackingListMovement] = useState<PackingListArticle | undefined>()
  const [unloadCollos, setUnloadCollos] = useState(false)
  const colloStorage = useRuntimeStorage(runtimeStorageSelectedColloKey)
  const activeCollo = colloStorage.getValue<string>()
  const ignoreHistoricalSN = !!packingLists?.find(pl => !!pl.internalPackinglist)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  useEffect(() => {
    updateActiveCollo(movements)
  }, [movements])

  useEffect(() => {
    if (!selectedPackingListMovement) setHasUnsavedChanges(false)
  }, [selectedPackingListMovement])

  async function load(invisibleLoading?: boolean) {
    return new Promise<PackingListArticle[]>((resolve, reject) => {
      if (!packingLists) return reject()
      if (!invisibleLoading) loader.setLoading(true)
      api
        .getPackingListMovements({ packingListIds: packingLists?.map(item => item.id) ?? [] })
        .then(result => {
          if (!result || !isMounted.current) return
          loader.setLoading(false)
          const newMovements: PackingListArticle[] = (
            groupMovementsByArticle(result, packingLists, !isPreConsignment && !isProductionConsignment) ?? []
          ).sort(packingListUtils.sortMovementBySortKey)
          setMovements(newMovements)
          resolve(newMovements)
        })
        .catch(reason => {
          if (!isMounted.current) return
          console.error(reason)
          loader.setLoading(false)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          alert.error(i18n.t('FailedToLoadPackingListMovements'), packingListUtils.getPackingListsTitle(packingLists), reason?.Message ?? '')
          reject(reason)
        })
    })
  }

  async function updateCollosOf(updatePackingListArticle: PackingListArticle | undefined) {
    if (!updatePackingListArticle) return false
    try {
      const movementCollos = await Promise.all(
        updatePackingListArticle.movements.map(m =>
          api.getCollo({
            packingListIds: [m.movement.packinglistId ?? ''],
            packingListMovementId: m.movement.id ?? '',
            packingListMovementPartId: m.movement.packinglistmovementpartId,
          })
        )
      )
      setMovements(prevMovements => {
        const foundArticleMovement = prevMovements.find(m => m.id === updatePackingListArticle.id)
        if (!foundArticleMovement) return prevMovements
        movementCollos.forEach((collos, index) => {
          const foundMovement = foundArticleMovement?.movements.find(m => m.movement.id === updatePackingListArticle.movements[index].movement.id)
          if (!foundMovement) return
          foundMovement.movement.collonumbers = collos.filter(
            c =>
              c.packinglistmovementpartId === foundMovement?.movement.packinglistmovementpartId ||
              (!c.packinglistmovementpartId && !foundMovement?.movement.packinglistmovementpartId)
          )
          foundMovement.movement.quantityPicked = Utils.sum(collos, c => c.quantity)
        })
        return [...prevMovements].sort(packingListUtils.sortMovementBySortKey)
      })
      return true
    } catch (err) {
      console.error(err)
    }
    return false
  }

  function updateActiveCollo(movementsToUpdate: PackingListArticle[]) {
    const foundOpenCollo = packingListUtils.getOpenColloNumber(packingListUtils.getCollosFromPackingListArticles(movementsToUpdate))
    if (foundOpenCollo && (isPreConsignment || !activeCollo)) {
      colloStorage.setValue(foundOpenCollo?.number)
    }
    //if stored colloNumber is not in movementList delete it
    else if (
      activeCollo &&
      (!packingListUtils.packingListMovementsContainColloNumber(movementsToUpdate, activeCollo) ||
        !packingListUtils.isColloNrOpen(activeCollo, movementsToUpdate))
    ) {
      colloStorage.setValue(undefined)
    }
  }

  function removeCollos(collosToDelete: Collo[] | undefined) {
    if (!collosToDelete) return
    setMovements(prev => {
      prev.forEach(article => {
        article.movements.forEach(movement => {
          movement.movement.collonumbers = (movement.movement.collonumbers ?? []).filter(c => !collosToDelete.find(ctd => ctd.id === c.id))
          movement.movement.quantityPicked = Utils.sum(movement.movement.collonumbers, c => c.quantity)
        })
      })
      return [...prev].sort(packingListUtils.sortMovementBySortKey)
    })
  }

  return {
    packingLists,
    movements,
    selectedPackingListMovement,
    isPreConsignment,
    isProductionConsignment,
    load,
    userSettings,
    forceScan,
    colloMode,
    unloadCollos,
    activeCollo,
    removeCollos,
    updateCollosOf,
    setSelectedPackingListMovement,
    setUnloadCollos,
    updateActiveCollo,
    ignoreHistoricalSN,
    hasUnsavedChanges,
    setHasUnsavedChanges,
  }
}

function groupMovementsByArticle(movementsToGroup: PackingListMovement[] | undefined, packingLists: PackingList[] | undefined, dontGroup?: boolean) {
  if (!movementsToGroup || !packingLists) return undefined
  const movements: PackingListArticle[] = []
  for (const movementToAdd of movementsToGroup) {
    const foundPackingList = packingLists.find(pl => pl.id === movementToAdd.packinglistId)
    if (!foundPackingList) {
      console.error('No packingList found for packingListMovement', movementToAdd)
      continue
    }

    if (!dontGroup) {
      const foundArticle = movements.find(m => packingListUtils.movementsAreJoinable(m.movements[0].movement, movementToAdd))
      if (foundArticle) {
        foundArticle.movements.push({ movement: movementToAdd, packingList: foundPackingList })
        continue
      }
    }
    movements.push({
      id: Utils.getUid(),
      article: movementToAdd.article,
      movements: [{ movement: movementToAdd, packingList: foundPackingList }],
    })
  }
  return movements
}
