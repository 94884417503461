import { useNavigation } from '@react-navigation/core'
import React from 'react'

import { PackingList } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import PackingListSelectionView from '../../views/InputViews/PackingListSelectionView'

export default function PackingListSelectionScreen() {
  const navigation = useNavigation()

  function showPackingList(packingList: PackingList) {
    navigation.navigate(ScreenType.PackingList, { packingLists: [packingList] })
  }

  return (
    <MWS_Screen>
      <PackingListSelectionView onSelected={showPackingList} allowEmptyInput style={{ flex: 1 }} filterResultByOrder />
    </MWS_Screen>
  )
}
