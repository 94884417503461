import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Freighter, Parcel } from '../apis/apiTypes'
import IMText from '../components/IMText'
import MWS_BaseCard from './MWS_BaseCard'

export default function ParcelCard(props: { handler: Parcel | undefined; freighter: Freighter | undefined; style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()

  if (!props.handler) return <></>

  return (
    <MWS_BaseCard icon={'box-taped'} cardStyle={props.style} style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <IMText primary>
            {i18n.t('TrackingNumber')}: {props.handler.trackingnumber}
          </IMText>
          <IMText secondary>
            {i18n.t('Freighter')}: {props.freighter?.code} {props.freighter?.description}
          </IMText>
        </View>
      </View>
    </MWS_BaseCard>
  )
}
