import NotificationSounds, { playSampleSound } from 'react-native-notification-sounds'

export default function useNotificationSound() {
  function playAlertSound() {
    try {
      NotificationSounds.getNotifications('notification')
        .then(soundsList => {
          //console.warn('SOUNDS', JSON.stringify(soundsList))
          playSampleSound(soundsList[1])
        })
        .catch(console.error)
    } catch (e) {
      console.error(e)
    }
  }
  return { playAlertSound }
}
