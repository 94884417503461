import { IconProps, IM, IMLayout, IMStyle } from '@infominds/react-native-components'
import React from 'react'
import { Platform, Pressable, StyleProp, StyleSheet, Text, ViewStyle } from 'react-native'

import useExtendedTheme from '../../hooks/useExtendedTheme'

export type TagProps = Partial<IconProps> & {
  id: number | string
  name: string
  active: boolean
  isHeader?: boolean
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: (id: string) => void
  subName?: string
}

export default function Tag({ id, name, active, disabled, style, isHeader, onPress, subName, ...iconProps }: TagProps) {
  const { theme } = useExtendedTheme()

  return (
    <IM.View
      style={[
        styles.filter,
        styles.common,
        styles.groupedFilter,
        {
          backgroundColor: theme.filterTag.default.background,
          borderColor: theme.filterTag.default.border,
        },
        !isHeader &&
          active && {
            backgroundColor: theme.filterTag.active.background,
            borderColor: theme.filterTag.active.border,
          },
        isHeader && {
          backgroundColor: theme.filterTag.header.background,
          borderColor: theme.filterTag.header.border,
        },
        IMLayout.shadow,
        style,
      ]}>
      <Pressable onPress={() => onPress?.(id.toString())} disabled={disabled} style={styles.common}>
        {iconProps.icon && (
          <IM.Icon
            icon={iconProps.icon}
            style={styles.icon}
            color={!isHeader && active ? theme.filterTag.active.text : theme.filterTag.default.text}
            {...iconProps}
          />
        )}
        <Text style={[styles.text, { color: !isHeader && active ? theme.filterTag.active.text : theme.filterTag.default.text }]}>{name}</Text>
        {!!subName && <Text style={[styles.text, styles.subText, { color: theme.textPlaceholder }]}>{subName}</Text>}
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  common: {
    minWidth: 52,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 2,
    paddingHorizontal: 5,
  },
  icon: { paddingRight: 4 },
  filter: {
    borderRadius: IMLayout.borderRadius,
    justifyContent: 'center',
  },
  groupedFilter: {
    marginRight: 7,
  },
  text: {
    fontSize: IMStyle.typography.fontSizeSmall - 2,
    ...Platform.select({ web: { userSelect: 'none' } }),
  },
  subText: {
    marginLeft: 3,
  },
})
