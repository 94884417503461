import { IM, useItemSelector, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ProductionOutgoingPostRequest } from '../../apis/apiRequestTypes'
import { Item, Production } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ProductionCard from '../../cards/Production/ProductionCard'
import ProductionPartTransferCard from '../../cards/Production/ProductionPartTransferCard'
import { ProductionPartToTransfer } from '../../classes/ProductionData'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import ProductionTransferDetailQuantitySelectionView from '../../components/MWS/Production/ProductionTransferDetailQuantitySelectionView'
import Separator from '../../components/Separator'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import ProductionSelectionView from '../../views/Production/ProductionSelectionView'

export default function ProductionTransferScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()
  const navigation = useNavigation()
  const { theme } = useTheme()

  const [productionSelected, setProduction] = useState<Production>()
  const [productionPartSelected, setPartSelected] = useState<ProductionPartToTransfer>()
  const [itemSelected, setItemSelected] = useState<Item>()

  const productionPartsToTransfer = useItemSelector<ProductionPartToTransfer>([])

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: itemSelected })
  }

  function handleProductionSelection(production: Production) {
    setProduction(production)
    setItemSelected(undefined)
    productionPartsToTransfer.clear()
  }

  function onDeleteProduction() {
    setProduction(undefined)
    setItemSelected(undefined)
    productionPartsToTransfer.clear()
  }

  function handleItemSelection(item: Item) {
    if (!item) return
    setItemSelected(item)
    loadParts(item)
  }

  function handlePressPart(item: ProductionPartToTransfer) {
    loader.setLoading(true)
    api
      .getItem({ articleCode: item.article?.code })
      .then(result => {
        if (result && result.length > 0) {
          setPartSelected(item)
          setItemSelected(result[0])
        }
      })
      .finally(() => loader.setLoading(false))
  }

  function loadParts(item: Item) {
    if (!productionSelected || !item) {
      productionPartsToTransfer.clear()
      return
    }

    loader.setLoading(true)
    api
      .getPartsForTransfer({
        productionId: productionSelected.id,
        articleId: item.article.id,
      })
      .then(result => {
        productionPartsToTransfer.clear()
        result.forEach(itemToTransfer => {
          productionPartsToTransfer.add({
            productionPartId: itemToTransfer.productionPartId,
            productionId: itemToTransfer.productionId,
            production: itemToTransfer.production,
            articleProduction: itemToTransfer.articleProduction,
            article: itemToTransfer.article,
            deposit: itemToTransfer.deposit,
            unitId: itemToTransfer.unitId,
            quantity: itemToTransfer.quantity,
            quantityTransferred: itemToTransfer.quantityTransferred,
            quantities: [],
          })
        })
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  async function handleConfirm() {
    if (!productionPartsToTransfer) return
    if (productionPartsToTransfer.items.length === 0) return

    try {
      loader.setLoading(true)
      const requests: ProductionOutgoingPostRequest[] = []
      productionPartsToTransfer.items
        .filter(q => q.productionId && q.quantities.length > 0)
        .forEach(item => {
          let request = requests.find(q => q.productionId === item.productionId)
          if (!request) {
            request = { productionId: item.productionId, isTransfer: true, quantities: [] }
            requests.push(request)
          }
          if (!request.quantities) {
            request.quantities = []
          }
          item.quantities.forEach(quantity => {
            request?.quantities.push({
              articleId: item.article?.id,
              productionPartId: item.productionPartId,
              quantity: quantity.quantity,
              serialnumberId: quantity.serialNumber?.id,
              storingpositionId: quantity.stockPositionId,
            })
          })
        })
      await Promise.all(
        requests.map(async item => {
          for (const quantityItem of item.quantities) {
            if (!quantityItem.productionPartId || quantityItem.productionPartId === undefined) {
              const newProductionPart = await api.postProductionPart({
                articleId: quantityItem.articleId ?? '',
                productionId: item.productionId ?? '',
                quantity: quantityItem.quantity ?? 0,
                stockpositionId: '',
                isCreateOutgoing: false,
                isCheckAvailability: false,
              })
              if (newProductionPart) {
                quantityItem.productionPartId = newProductionPart.id
              }
            }
          }
          await api.postProductionOutgoing(item)
        })
      )
      alert.info(i18n.t('PRODUCTIONTRANSFERSUCCESSFULLY'))
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('PRODUCTIONTRANSFERERROR'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
      setProduction(undefined)
    }
  }

  function onDeleteItem() {
    setItemSelected(undefined)
    setPartSelected(undefined)
    productionPartsToTransfer.clear()
  }

  return (
    <MWS_Screen title={i18n.t('PRODUCTIONTRANSFER')}>
      <View style={{ flex: 1 }}>
        {!productionSelected && <ProductionSelectionView onSelected={item => handleProductionSelection(item)} history filterResultByOrder />}
        {productionSelected && (
          <>
            <ProductionCard handler={productionSelected} onDelete={onDeleteProduction} />
            {!itemSelected && <ItemSelectionView onSelected={item => handleItemSelection(item)} showResultInModal />}
            {itemSelected && (
              <ArticleCard
                article={itemSelected?.article}
                serialNumber={itemSelected?.serialnumber}
                onDelete={onDeleteItem}
                onPress={showArticleInfoScreen}
              />
            )}
            <Separator />
            <View style={[{ flex: 1 }]}>
              <FlatList
                style={{ flex: 1 }}
                data={productionPartsToTransfer.items ?? []}
                renderItem={({ item }) => (
                  <IM.View
                    style={[
                      productionPartSelected &&
                        item === productionPartSelected && {
                          backgroundColor: theme.modal.background,
                          marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                          padding: 0,
                          borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
                          marginBottom: 30,
                          paddingBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
                        },
                    ]}>
                    <ProductionPartTransferCard
                      item={item}
                      showQuantities={true}
                      onPress={() => handlePressPart(item)}
                      style={[productionPartSelected && item === productionPartSelected && { marginHorizontal: 0 }]}
                    />
                    {productionPartSelected && item === productionPartSelected && (
                      <ProductionTransferDetailQuantitySelectionView
                        item={itemSelected}
                        partToTransfer={productionPartSelected}
                        onTransfer={partTransfer => {
                          const partToModify = productionPartsToTransfer.items.find(
                            i => i.productionId === partTransfer.productionId && i.article?.id === partTransfer.article?.id
                          )
                          if (partToModify) partToModify.quantities = partTransfer.quantities
                          setPartSelected(undefined)
                        }}
                      />
                    )}
                  </IM.View>
                )}
              />
              {productionPartsToTransfer.items.length > 0 && !productionPartSelected && (
                <SubmitButton title={i18n.t('Transfer')} onPress={handleConfirm} hideFromKeyBoard />
              )}
            </View>
          </>
        )}
      </View>
    </MWS_Screen>
  )
}
