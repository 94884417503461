import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import IMIcon from './IMIcon'
import { IMIconName } from './IMIconNames'
import IMText, { IMTextProps } from './IMText'

export function IMTextWithIcon(
  props: {
    icon: IMIconName
    text: string
    viewStyle?: StyleProp<ViewStyle>
    iconStyle?: StyleProp<ViewStyle>
    iconColor?: string
    iconSize?: number
  } & IMTextProps
) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { icon, text, viewStyle, iconStyle, iconColor, ...textProps } = props

  return (
    <View style={[{ flexDirection: 'row', justifyContent: 'flex-start' }, viewStyle]}>
      <IMIcon
        color={iconColor ?? (props.secondary ? theme.textDetail : undefined) ?? theme.text}
        style={[{ justifyContent: 'center', marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS }, iconStyle]}
        icon={icon}
        size={props.iconSize ?? 15}
      />
      <IMText {...textProps}>{text}</IMText>
    </View>
  )
}
