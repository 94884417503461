/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { FlatList } from 'react-native'

import { ArticleDTO, ArticleSalesPriceScale } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ArticleSalesPriceScaleCard from '../../cards/Article/ArticleSalesPriceScaleCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import NoEntriesTag from '../../components/NoEntriesTag'
import useRouteParam from '../../hooks/useRouteParam'

export default function ArticleSalesPriceScaleScreen(props: { navigation: any }) {
  const { i18n } = useLanguage()
  const article = useRouteParam<ArticleDTO>('article')
  const unit = useRouteParam<string>('unit')
  const articleSalesPriceScales = useRouteParam<ArticleSalesPriceScale[]>('articleSalesPriceScales')

  return (
    <MWS_Screen title={i18n.t('PRICESCALE')} hideCloseButton={true}>
      {article && (
        <>
          <ArticleCard article={article} />
          <FlatList
            data={articleSalesPriceScales}
            renderItem={({ item }) => <ArticleSalesPriceScaleCard priceScale={item} unit={unit ?? ''} />}
            refreshing={false}
            ListFooterComponent={articleSalesPriceScales?.length === 0 ? <NoEntriesTag /> : undefined}
          />
        </>
      )}
    </MWS_Screen>
  )
}
