import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../apis/apiCalls'
import { ArticleDTO, SerialNumber } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import { SelectionViewProps } from '../../types'
import { serialnumberUtils } from '../../utils/serialnumberUtils'

export default function SerialnumberSelectionView(
  props: {
    article?: ArticleDTO
    allowNewSerialCreation?: boolean
    serialsToFilter?: SerialNumber[]
    clearResultsOnScreenFocus?: boolean
  } & SelectionViewProps<SerialNumber>
) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()
  const userSettings = useUserSettings()

  const { article, onSelected, serialsToFilter, ...otherProps } = props

  function handleError(input?: string, error?: unknown) {
    //if SN was not found it can be created if the bit isSerialnumberOnlyForHistory is true
    if (
      !!error ||
      !props.allowNewSerialCreation ||
      !input ||
      !props.article?.id ||
      !props.article.isSerialnumberActive ||
      !props.article.isSerialnumberOnlyForHistory ||
      !userSettings?.isHistoricalSerialnumberActive
    ) {
      return false
    }
    loader.setLoading(true)
    handleCreateSerialNumber(input)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .catch(reason => alert.error(i18n.t('FailedToCreateSerialNumber'), reason.Message ?? ''))
      .finally(() => loader.setLoading(false))

    return true
  }

  async function handleCreateSerialNumber(input: string) {
    if (!input) return
    // ask if serialNumber should be created. (if SN ist only for history, then it will always be created automatically (todo 446748))
    if (!article?.isSerialnumberOnlyForHistory) {
      const yesNoResult = await alert.yesNo(i18n.t('SelectedSerialDoesNotExistCreateAlert'), input)
      if (!yesNoResult) return
    }
    console.debug('Creating SerialNumber', input)
    const createdSerial = await api.postSerialnumber({ number: input, articleId: props.article?.id })

    if (!createdSerial) return
    onSelected(serialnumberUtils.create(input, createdSerial, article))
  }

  function filterSerials(values: SerialNumber[], input?: string | SerialNumber | undefined): SerialNumber[] {
    if (!serialsToFilter || !!input) return values
    return values.filter(sn => !serialsToFilter.find(snf => snf.id === sn.id))
  }

  const RenderItem = useCallback((item: SerialNumber) => <SerialnumberCard serialnumber={item} />, [])

  return (
    <MWS_Input
      type="Serialnumber"
      title={i18n.t('SelectSerialnumber')}
      load={input => api.getSerialnumber({ number: input.scannerInput ?? input.textInput, articleId: article?.id })}
      itemRenderer={RenderItem}
      failedToLoadText={i18n.t('NoSerialnumberWithCode')}
      modalTitle={i18n.t('SelectSerialnumber')}
      historyNameExtractor={item => `${item.article.code} - ${item.number}`}
      loadFromHistory={item => api.getSerialnumber({ id: item.id })}
      handleError={handleError}
      onSelected={onSelected}
      modifyResult={filterSerials}
      {...otherProps}
    />
  )
}
