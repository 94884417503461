import { Layout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { Keyboard, KeyboardAvoidingView, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import DefaultModal from 'react-native-modal'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import IMIcon from './IMIcon'
import { IMIconName } from './IMIconNames'
import Text from './old/Text'

export interface IMModalProps {
  isVisible: boolean
  close: () => void
  title?: string
  children?: ReactNode
  style?: StyleProp<ViewStyle>
  keyboardAvoiding?: boolean
  noHeader?: boolean
  closeIcon?: IMIconName
  onModalHide?: (() => void) | undefined
}

export default function IMModal(props: IMModalProps) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return (
    <DefaultModal
      onBackButtonPress={props.close}
      statusBarTranslucent
      backdropOpacity={STYLE_CONSTANTS.MODAL_BACKGROUND_OPACITY}
      onBackdropPress={props.close}
      onModalShow={() => Keyboard.dismiss()}
      isVisible={props.isVisible}
      onModalHide={props.onModalHide}>
      <KeyboardAvoidingView style={styles.modal} behavior="padding" enabled={props.keyboardAvoiding}>
        <View style={[styles.content, props.style]}>
          {!props.noHeader && (
            <View style={[styles.header, { borderColor: theme.textDetail, borderWidth: 1, borderBottomWidth: 0 }]}>
              <Text style={{ flex: 1, color: Colors.white }}>{props.title ?? i18n.t('SELECT')}</Text>
              <TouchableOpacity
                onPress={() => {
                  props.close()
                }}>
                <IMIcon icon={props.closeIcon ?? 'times'} color={Colors.white} style={{ alignSelf: 'flex-end' }} size={25} />
              </TouchableOpacity>
            </View>
          )}
          <View
            style={[
              {
                backgroundColor: theme.backgroundModal,
                borderColor: theme.textDetail,
              },
              styles.children,
              !!props.noHeader && styles.noHeaderChildren,
            ]}>
            {props.children}
          </View>
        </View>
      </KeyboardAvoidingView>
    </DefaultModal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
  },
  header: {
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.grey,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    maxHeight: '85%',
    width: Layout.isSmallDevice ? '90%' : '60%',
    borderRadius: 10,
  },
  children: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    maxHeight: '100%',
  },
  noHeaderChildren: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
})
