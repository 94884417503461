import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Deposit, StockPosition, StockPositionAvailability } from '../../../apis/apiTypes'
import StockPositionCard from '../../../cards/StockPosition/StockPositionCard'
import stockPositionUtils from '../../../utils/stockPositionUtils'
import StockPositionSelectionView from '../../../views/InputViews/StockPositionSelectionView'
import Title from '../Title'

export default function StockPositionSelector(props: {
  stockPosition?: StockPosition
  deposit?: Deposit
  style?: StyleProp<ViewStyle>
  onSelected: (stockPosition: StockPosition | undefined) => void
  disabled?: boolean
  title?: string
  availability?: StockPositionAvailability[]
  showListLoader?: boolean
}) {
  const { i18n } = useLanguage()

  function getStockPositionsFromAvailability() {
    return Promise.resolve(stockPositionUtils.getStockPositionsFromAvailability(props.availability))
  }

  return (
    <View style={props.style}>
      {props.title && <Title>{props.title}</Title>}
      {props.stockPosition ? (
        <StockPositionCard stockPosition={props.stockPosition} onDelete={props.disabled ? undefined : () => props.onSelected(undefined)} />
      ) : (
        <StockPositionSelectionView
          onSelected={props.onSelected}
          title={i18n.t('SELECTTARGETSTOCKPOSITION')}
          showResultInModal
          allowEmptyInput
          depositId={props.deposit?.id}
          loadOnListButtonPressed={!!props.availability?.length ? getStockPositionsFromAvailability : undefined}
          showListLoader={props.showListLoader}
        />
      )}
    </View>
  )
}
