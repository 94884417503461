import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { DateUtils, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { REGEX } from '../../constants/Constants'
import useFilter from '../../hooks/filter/useFilter'
import { DataFilterConfigType, DataFilterValue } from '../../types'
import Tag, { TagProps } from './Tag'

export type FilterTagProps = {
  el: DataFilterValue
  icon?: IconProp
} & Pick<TagProps, 'isHeader'>

export default function FilterTag({ el, icon, ...tagProps }: FilterTagProps) {
  const { changeFilterStatus } = useFilter()
  const { language } = useLanguage()

  const name = useMemo(() => {
    let result = el.value
    if (REGEX.DATE.test(el.value)) {
      try {
        const date = DateUtils.dateify(el.value)
        const formattedDate = DateUtils.formatDate(date, 'P', language)
        if (formattedDate) result = formattedDate
      } catch (_) {}
    }

    return result
  }, [])

  return (
    <Tag
      id={el.id}
      name={name}
      subName={el.count && !tagProps.isHeader ? `(${el.count})` : undefined}
      active={!!el.active}
      style={styles.tag}
      onPress={id => changeFilterStatus(DataFilterConfigType.Filter, id)}
      icon={icon}
      {...tagProps}
    />
  )
}

const styles = StyleSheet.create({
  tag: { marginVertical: 3 },
})
