import React from 'react'

import FullScreenModal from '../components/FullScreenModal'
import MultiLevelSelector, { MultiLevelSelectorProps } from '../components/MultiLevelSelector'
import { IMLayout } from '../constants/Styles'
import { ModalController } from '../hooks/useModalController'

export default function MultiLevelSelectionModal<T1, T2>(
  props: {
    controller: ModalController<T1>
    title?: string
    header?: JSX.Element
  } & MultiLevelSelectorProps<T1, T2>
) {
  const { controller, title, header, style, ...multiLevelSelectionProps } = props

  return (
    <FullScreenModal isVisible={controller.isShown} close={controller.close} title={title}>
      {controller.isShown && (
        <>
          {!!header && header}
          <MultiLevelSelector style={[IMLayout.flex.f1, style]} {...multiLevelSelectionProps} />
        </>
      )}
    </FullScreenModal>
  )
}
