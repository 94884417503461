import { useTheme } from '@infominds/react-native-components'
import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'

export default function Separator(props: { style?: StyleProp<ViewStyle> }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return <View style={[styles.separator, { borderBottomColor: theme.inputBorder }, props.style]} />
}

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
    marginVertical: 10,
  },
})
