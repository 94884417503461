import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { FlatList } from 'react-native'

import api from '../apis/apiCalls'
import { PackingListMovement, StockPosition } from '../apis/apiTypes'
import ArticleCard from '../cards/Article/ArticleCard'
import FullScreenModal from '../components/FullScreenModal'
import StockPositionSelector from '../components/MWS/StockPosition/StockPositionSelector'
import Title from '../components/MWS/Title'
import SubmitButton from '../components/SubmitButton'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../contexts/UserSettingsContext'
import useAlert from '../hooks/useAlert'
import useItemSelector from '../hooks/useItemSelector'
import { ModalController } from '../hooks/useModalController'
import { articleUtils } from '../utils/articleUtils'
import { Error_Utils } from '../utils/ErrorUtils'
import { Utils } from '../utils/Utils'

export default function ColloUnloadModal(props: {
  controller: ModalController<PackingListMovement[]>
  onCompleted: (unloadedMovements: PackingListMovement[]) => void
  onAborted?: () => void
}) {
  const { i18n } = useLanguage()

  const alert = useAlert()
  const loader = useLoadingIndicator()
  const userSettings = useUserSettings()
  const preConsignmentStockPositionId = userSettings?.stplid_Preconsignment

  const selectedItems = useItemSelector<PackingListMovement>([])
  const anyItem = !!props.controller.data && props.controller.data.length > 0 ? props.controller.data[0] : undefined
  const [stockPosition, setStockPosition] = useState<StockPosition | undefined>()
  const [preConsignmentStockPosition, loadPreConsignmentStockPosition, loadingPreConsignmentStockPosition] = useApi(api.getStockPosition, [])

  useEffect(() => {
    selectedItems.set(props.controller.isShown ? props.controller.data ?? [] : [])
    if (!preConsignmentStockPositionId) return
    loadPreConsignmentStockPosition({ id: preConsignmentStockPositionId, depositId: anyItem?.deposit?.id })
      .then(result => {
        if (!result?.length) return
        setStockPosition(result[0])
      })
      .catch(console.error)
  }, [props.controller.isShown])

  async function handleUnloading() {
    const collosToUnload = Utils.to1DList(selectedItems.items, item => item.collonumbers ?? [])
    if (!collosToUnload) return

    try {
      loader.setLoading(true)
      await api.unloadCollo({
        collonumberIds: collosToUnload.map(c => c.id ?? ''),
        collaboratorId: userSettings?.employeeId,
        stockpositionId: stockPosition?.id,
      })
      props.onCompleted(selectedItems.items)
      props.controller.close()
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToUnloadCollo'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
        if (props.onAborted) props.onAborted()
      }}
      title={i18n.t('Unload')}>
      <StockPositionSelector
        title={i18n.t('TransferTo')}
        stockPosition={stockPosition}
        onSelected={setStockPosition}
        deposit={!!anyItem?.deposit?.isStockpositionActive ? anyItem?.deposit : undefined}
        disabled={!!preConsignmentStockPositionId && (loadingPreConsignmentStockPosition || !!preConsignmentStockPosition?.length)}
      />
      <Title>{i18n.t('SelectedArticles')}</Title>
      <FlatList
        style={{ flex: 1 }}
        data={props.controller.data ?? []}
        renderItem={({ item }) => (
          <ArticleCard
            article={item.article}
            quantityText={articleUtils.formatQuantity(item.quantity, item.unitCode)}
            isMarked={selectedItems.includes(item)}
            onPress={() => selectedItems.toggle(item)}
          />
        )}
      />
      <SubmitButton title={i18n.t('Unload')} hideFromKeyBoard disabled={!selectedItems.any || !stockPosition} onPress={handleUnloading} />
    </FullScreenModal>
  )
}
