import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/core'
import React from 'react'
import { View } from 'react-native'

import { InventoryEntry, Item, StockPosition } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useRoute from '../../hooks/useRoute'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import inventoryUtils from '../../utils/inventoryUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import InventoryList from '../../views/Inventory/InventoryList'

export default function InventoryListScreen() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const routeParams = useRoute<'InventoryListScreen'>()

  const inventory = useRouteParam<InventoryEntry>('inventory')
  const stockPosition = useRouteParam<StockPosition>('stockPosition')

  if (!inventory) {
    navigation.goBack()
    return <></>
  }

  function handleItemSelection(item: Item) {
    navigation.navigate(ScreenType.InventoryCreation, { ...routeParams, item })
  }

  return (
    <MWS_Screen title={inventoryUtils.getTitle(inventory, stockPosition)}>
      <View style={{ flex: 1 }}>
        <ItemSelectionView onSelected={handleItemSelection} title={i18n.t('SelectArticle')} showResultInModal />
        <InventoryList
          style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          inventory={inventory}
          stockPosition={stockPosition}
        />
      </View>
    </MWS_Screen>
  )
}
