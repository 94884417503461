import { useTheme } from '@infominds/react-native-components'
import { LoginScreen as LicenseLoginScreen, OverrideThemeColors } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

import DemoMode from '../constants/DemoMode'
import AppUtils from '../utils/AppUtils'

export default function LoginScreen() {
  const nav = useNavigation()
  const { colorScheme } = useTheme()

  const colors: OverrideThemeColors = {
    dark: { background: '#1E1E1E', button: { default: { background: { disable: '#2F2F2F' }, text: { disable: '#4E4E4E' } } } },
    light: { background: '#F5F5F5', button: { default: { background: { disable: '#ECECEC' }, text: { disable: '#BBBBBB' } } } },
  }

  return (
    <LicenseLoginScreen
      onSuccess={() => {
        nav.navigate('Root')
      }}
      demoData={{ license: DemoMode.DEMO_LICENSE_KEY, username: DemoMode.DEMO_USERNAME, password: DemoMode.DEMO_PASSWORD }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      iconSource={require('../assets/img/icon.png')}
      colorScheme={colorScheme}
      overrideThemeColors={colors}
      // TODO: update
      customQRCodeProcessor={AppUtils.loginQrCodeProcessor}
    />
  )
}
