import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Deposit, SerialNumber, StockPosition } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useItemSelector from '../../hooks/useItemSelector'
import useRxMWSAlerts from '../../hooks/useRxMWSAlerts'
import { articleUtils } from '../../utils/articleUtils'
import { ArticleAmountSelectionResult } from '../Article/ArticleAmountSelectorV2'
import ArticleAmountSelectorV3 from '../Article/ArticleAmountSelectorV3'
import SerialSelectionListView from './SerialSelectionListView'

export type MultiSelectionViewV2Props = {
  article: Article | undefined
  deposit?: Deposit
  stockPosition?: StockPosition
  onComplete: (selectedItems: ArticleAmountSelectionResult[]) => void
  deleteItem?: () => void
  style?: StyleProp<ViewStyle>
  selectSerialsByPosition?: boolean
  showSelectedSerialsWithPosition?: boolean
  checkAvailability?: boolean
  autoSelectAvailability?: boolean
  proposedQuantity?: number
  listTitleProvider?: (items: ArticleAmountSelectionResult[]) => string
  autoComplete?: boolean
  confirmationButtonCaption?: string
  confirmationButtonDisabled?: (selectedItems: ArticleAmountSelectionResult[]) => boolean
  keepAlreadyAddedItems?: boolean
  ignoreAvailability?: boolean
  hideAvailability?: boolean
  hidePositionFromSelectedSerial?: boolean
  allowConfirmationWithoutSerialSelection?: boolean
  ignoreSerialNumber?: boolean
  allowedSerialNumbers?: SerialNumber[]
  errorSerialNotAllowedText?: string
  noPreloadedData?: boolean
  availabilityCheckFailedWorkaroundMessage?: string
  availabilityTolerance?: number
  availability?: number
  preSelectedSerial?: SerialNumber
  submitAfterSelectionCount?: number
  allowUnityChange?: boolean
}

export default function MultiSelectionViewV2({
  article,
  deposit,
  stockPosition,
  onComplete,
  style,
  selectSerialsByPosition,
  showSelectedSerialsWithPosition,
  checkAvailability,
  autoSelectAvailability,
  proposedQuantity,
  listTitleProvider,
  autoComplete,
  confirmationButtonCaption,
  confirmationButtonDisabled,
  keepAlreadyAddedItems,
  allowConfirmationWithoutSerialSelection,
  ignoreSerialNumber,
  allowedSerialNumbers,
  errorSerialNotAllowedText,
  availabilityCheckFailedWorkaroundMessage,
  availabilityTolerance,
  preSelectedSerial,
  submitAfterSelectionCount,
  allowUnityChange,
}: MultiSelectionViewV2Props) {
  const { i18n } = useLanguage()

  const alerts = useRxMWSAlerts()
  const articleSerialSelector = useItemSelector<ArticleAmountSelectionResult>([], articleUtils.compareArticleAmountSelectionResult)

  useEffect(() => {
    if (!article) {
      return
    }
    if (autoSelectAvailability) selectAll()

    return () => {
      articleSerialSelector.clear()
    }
  }, [article?.info.id])

  function selectAll() {
    if (!article) return
    if (article.isSerialNumberActive) {
      article.serialNumberQuantities?.forEach(q =>
        handleArticleAmountSelection({
          quantity: q.quantity ?? 0,
          quantityOrdered: q.quantityOrdered ?? 0,
          quantityCommissioned: q.quantityCommissioned ?? 0,
          serialNumber: q.serialnumber,
          unit: article?.getUsedUnit(),
          deposit: q.deposit,
          stockPosition: q.stockposition,
        })
      )
    }
  }

  function handleArticleAmountSelection(result: ArticleAmountSelectionResult) {
    if (!result) return
    if (result.serialNumber) {
      if (articleSerialSelector.includes(result)) {
        alerts.info.serialAlreadySelected()
        return
      }
      if (
        (autoComplete && articleSerialSelector.count + 1 === article?.serialNumberQuantities?.length) ||
        (submitAfterSelectionCount && articleSerialSelector.count + 1 === submitAfterSelectionCount)
      ) {
        onComplete([...articleSerialSelector.items, result])
        return
      }

      articleSerialSelector.add(result)
      return
    }
    onComplete([result])
  }

  return (
    <View style={[{ flex: 1 }, style]}>
      {article && (
        <>
          <ArticleAmountSelectorV3
            article={article}
            deposit={deposit}
            stockPosition={stockPosition}
            onSelected={handleArticleAmountSelection}
            checkAvailability={checkAvailability}
            selectSerialsWithPosition={selectSerialsByPosition}
            selectedItemsToFilter={keepAlreadyAddedItems ? undefined : articleSerialSelector.items}
            preSelectedSerial={preSelectedSerial}
            proposedQuantity={proposedQuantity}
            ignoreSerialNumber={ignoreSerialNumber}
            allowedSerialNumbers={allowedSerialNumbers}
            errorSerialNotAllowedText={errorSerialNotAllowedText}
            availabilityCheckFailedWorkaroundMessage={availabilityCheckFailedWorkaroundMessage}
            availabilityTolerance={availabilityTolerance}
            allowUnityChange={allowUnityChange}
          />
          <SerialSelectionListView
            style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            selector={articleSerialSelector}
            onCompleted={() => {
              onComplete(articleSerialSelector.items)
            }}
            usedUnit={article.getUsedUnit()}
            title={listTitleProvider ? listTitleProvider(articleSerialSelector.items) : i18n.t('SelectedSerialNumbers')}
            showPosition={showSelectedSerialsWithPosition}
            confirmationButtonCaption={confirmationButtonCaption}
            confirmationButtonDisabled={confirmationButtonDisabled ? confirmationButtonDisabled(articleSerialSelector.items) : undefined}
            confirmationButtonAlwaysActiveAndEnabled={allowConfirmationWithoutSerialSelection}
          />
        </>
      )}
    </View>
  )
}
