import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, Item, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import PositionCard from '../../cards/StockPosition/PositionCard'
import PackingListColloSelector from '../../components/MWS/PackingList/PackingListColloSelector'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { PackingListMovementHandler } from '../../hooks/specific/usePackingListMovementHandler'
import useAlert from '../../hooks/useAlert'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import ItemSelectionView from '../InputViews/ItemSelectionView'
import StockPositionSelectionView from '../InputViews/StockPositionSelectionView'

export default function PackingListMovementMultiScanView(props: { handler: PackingListMovementHandler; style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const handler = props.handler
  const serialFilter = useRef<{ serialNumber: string | undefined; stockPosition: StockPosition | undefined } | undefined>(undefined)
  const loadedDepositSerialNumberAvailability = useRef(false)
  const [deposit, setDeposit] = useState<Deposit | undefined>()
  const [stockPosition, setStockposition] = useState<StockPosition | undefined>()
  const showStockPositionSelector = !!deposit && !!deposit.isStockpositionActive && !stockPosition
  const showItemSelector = (!!deposit && !deposit.isStockpositionActive) || (deposit?.isStockpositionActive && stockPosition)

  useEffect(() => {
    if (!handler.packingListArticle) return
    if (handler.deposit) setDeposit(handler.deposit)
    if (handler.stockPosition) setStockposition(handler.stockPosition)
    if (handler.packingListHandler.forceScan) return
  }, [])

  function handleError(error: unknown) {
    loader.setLoading(false)
    console.error(error)
  }

  function addColloFromPosition() {
    if (!handler.anyMovement || !handler.article || handler.article.isSerialNumberActive) return

    let quantityTotal = 1
    const colloFound = handler.colloSelector.items.find(
      c =>
        !c.id && c.article?.id === handler.article?.info.id && stockPositionUtils.comparePosition(c.deposit, deposit, c.stockposition, stockPosition)
    )
    if (colloFound) {
      quantityTotal += colloFound.quantity
    }

    loader.setLoading(true)
    api
      .getItemAvailability({
        articleId: handler.article.info.id,
        depositId: deposit?.id,
        stockPositionId: stockPosition?.id,
      })
      .then(result => {
        if (!result || result <= 0) {
          alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
          return
        }
        result *= packingListUtils.getMasterToUsedUnitConversionFactor(handler.anyMovement)
        if (result < quantityTotal) {
          alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
          return
        }
        if (handler.anyMovement) {
          if (colloFound) {
            colloFound.quantity += 1
          } else {
            handler.colloSelector.add(colloUtils.create(handler.anyMovement, 1, deposit, stockPosition))
          }
        }
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function filterSerials(serial: StockPositionAvailability) {
    if (!serial) return false
    if (serial.stockposition?.notProposedInPackinglists) return false
    if (!handler.anyMovement?.orderSerialnumberquantities?.length) return true
    return !!handler.anyMovement.orderSerialnumberquantities.find(orderSN => serialnumberUtils.compare(serial.serialnumber, orderSN.serialnumber))
  }

  function loadSerialAvailability(scannedSerialNumber: SerialNumber | undefined) {
    if (!handler.anyMovement || !handler.article || !scannedSerialNumber) return

    let quantityTotal = 1
    let colloFound = handler.colloSelector.items.find(
      c =>
        serialnumberUtils.compareByNumber(c.serialnumber, scannedSerialNumber) &&
        stockPositionUtils.comparePosition(c.deposit, deposit, c.stockposition, stockPosition)
    )
    if (!colloFound && !handler.article.isLottoSerialNumber) {
      colloFound = handler.colloSelector.items.find(c => serialnumberUtils.compareByNumber(c.serialnumber, scannedSerialNumber))
      if (colloFound) {
        alert.error(i18n.t('SerialnumberAlreadySelected'))
        return
      }
    }
    if (colloFound) {
      quantityTotal += colloFound.quantity
    }

    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    handler.article
      .loadSerialNumberQuantities(deposit, stockPosition, scannedSerialNumber, filterSerials, undefined, true, true)
      .then(() => {
        const serials = handler.article?.getConvertedSerialNumberQuantities() ?? []
        if (serials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), scannedSerialNumber.number)
        if (serials.length >= 1) {
          const serial = serials[0]
          if (serial !== null && serial.quantity) {
            if (serial.quantity < quantityTotal) {
              alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
              return
            }
            return addColloWithSerialFromPosition(serial)
          }
        }
      })
      .catch(() => {
        handleError
        alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
      })
      .finally(() => loader.setLoading(false))
  }

  function handleHistoricalSerialScanned(scannedSerialNumber: SerialNumber | undefined) {
    if (!handler.anyMovement || !handler.article || !scannedSerialNumber) return
    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    packingListUtils
      .getHistoricalSerialNumberAvailability(scannedSerialNumber, handler.article.info, deposit, stockPosition)
      .then(availability => {
        serialFilter.current = { serialNumber: undefined, stockPosition: undefined }
        if (handler.article) handler.article.serialNumberQuantities = availability ?? []
        if (!availability) return
        const matchingSerials = availability.filter(s => serialnumberUtils.compareByNumber(s.serialnumber, scannedSerialNumber))
        if (matchingSerials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), scannedSerialNumber.number)
        return addColloWithSerialFromPosition(matchingSerials[0])
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function addColloWithSerialFromPosition(serialNumberAvailability: StockPositionAvailability) {
    if (!handler.anyMovement || !handler.article || !serialNumberAvailability) return

    if (checkSerialAlreadySelectedAndIncreaseQuantity(serialNumberAvailability)) return

    handler.colloSelector.add(
      colloUtils.create(
        handler.anyMovement,
        1,
        serialNumberAvailability.deposit,
        serialNumberAvailability.stockposition,
        serialNumberAvailability.serialnumber
      )
    )
  }

  function checkSerialAlreadySelectedAndIncreaseQuantity(serial: StockPositionAvailability) {
    if (!serial) return false

    // Bei EinzelSN kontrollieren ob bereits vorhanden
    if (!handler.article?.isLottoSerialNumber && !handler.article?.isHistoricalSerialNumber) {
      if (handler.colloSelector.items.filter(i => !i.id).find(i => i.serialnumber?.id === serial.serialnumber?.id)) {
        alert.info(i18n.t('SerialnumberAlreadySelected'))
        return true
      }
    }

    // Kontrolle ob SN bereits vorhanden, dann einfache Menge erhöhen
    const colloFound = handler.colloSelector.items.find(
      c =>
        !c.id &&
        serialnumberUtils.compareByNumber(c.serialnumber, serial.serialnumber) &&
        stockPositionUtils.comparePosition(c.deposit, deposit, c.stockposition, stockPosition)
    )
    if (colloFound) {
      colloFound.quantity += 1
      return true
    }

    // Normal hinzufügen
    return false
  }

  function handlePositionDelete() {
    if (stockPosition) {
      setStockposition(undefined)
      return
    }
  }

  function handleItemSelection(selectedItem: Item) {
    if (!selectedItem) return
    if (handler.article?.info.id === selectedItem.article.id) {
      if (handler.article?.isHistoricalSerialNumber && !!selectedItem.serialnumber) {
        handleHistoricalSerialScanned(selectedItem?.serialnumber)
      } else if (handler.article?.isSerialNumberActive && !!selectedItem.serialnumber) {
        loadSerialAvailability(selectedItem?.serialnumber)
      } else if (!handler.article?.isSerialNumberActive) {
        addColloFromPosition()
      } else {
        alert.info(i18n.t('SerialNumberMustBeSpecified'))
      }
    } else {
      alert.info(i18n.t('InvalidArticle'))
    }
  }

  return (
    <View style={props.style}>
      {(!!deposit || !stockPosition) && <PositionCard deposit={deposit} stockPosition={stockPosition} onDelete={handlePositionDelete} />}
      {showStockPositionSelector && <StockPositionSelectionView depositId={deposit.id} onSelected={setStockposition} showResultInModal />}
      {showItemSelector && (
        <View style={{ flexDirection: 'row', gap: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
          {handler.packingListHandler.colloMode && <PackingListColloSelector style={{ flex: 1 }} handler={handler} />}
          <ItemSelectionView style={{ flex: 2 }} fetchArticleAndSerial onSelected={handleItemSelection} showResultInModal />
        </View>
      )}
    </View>
  )
}
