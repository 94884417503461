import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Language } from '@infominds/react-native-components'
import { StyleProp, ViewStyle } from 'react-native'

import { ColloPostRequest } from './apis/apiRequestTypes'
import {
  ArticleDTO,
  Deposit,
  Menu,
  StockAccountingPostResult,
  StockPosition,
  StockPositionAvailability,
  StockPositionQuantity,
  StockpositionQuantityForKanbanTransfer,
} from './apis/apiTypes'
import { translation } from './assets/languages/i18next'
import { Article } from './classes/Article'
import { IMIconName } from './components/IMIconNames'

export type i18nKey = keyof typeof translation.en
export type LanguageSelectType<T> = Record<Language, T>

export interface Mandant {
  id: number
  description: string
}
export interface Company {
  id: string
  description: string
}

export interface AppSetting {
  key: string
  value: string
}
export interface ApiError {
  Code: number
  Message: string
}

export type MenuActionType =
  | 'PDA_ACCESS_UMBUCHUNG'
  | 'PDA_ACCESS_UMBUCHUNG_WE'
  | 'PDA_ACCESS_UMBUCHUNGKOLLI'
  | 'PDA_MPKT_VORKOMMISSIONIERUNG'
  | 'PDA_ACCESS_KOMMISSIONIERUNG'
  | 'PDA_PROD_KOMMISSIONIERUNG'
  | 'PDA_ACCESS_ENDKOMMISSIONIERUNG'
  | 'PDA_ACCESS_WARENKORB'
  | 'PDA_ACCESS_PRODUKTION_EINGANG'
  | 'PDA_ACCESS_PRODUKTION_AUSGANG'
  | 'PDA_ACCESS_PRODUKTION_AUSSCHUSS'
  | 'PDA_ACCESS_PRODUKTION_UMBUCHUNG'
  | 'PDA_ACCESS_ZUWEISUNG_ANDERE_PRODUKTION'
  | 'PDA_ACCESS_ERSTELLEN_VORPRODUKTION'
  | 'PDA_ACCESS_ZUWEISUNG_VORPRODUKTION'
  | 'PDA_ACCESS_ENTNAHME_PRODUKTION'
  | 'PDA_ACCESS_ARBEITSSCHEIN_RUECKMELDEN'
  | 'PDA_ACCESS_ZUORDNUNG_HERSTELLERSERIENNUMMER'
  | 'PDA_ACCESS_ZUORDNUNG_MACADRESSE'
  | 'PDA_ACCESS_ZUORDNUNG_ARTIKELBARCODE'
  | 'PDA_ACCESS_ARTIKELDEPOTDATEN'
  | 'PDA_ACCESS_ZUORDNUNG_ARTIKELGEWICHT'
  | 'PDA_ACCESS_BESTELLVORSCHLAG'
  | 'PDA_ACCESS_INVENTAR'
  | 'PDA_ACCESS_LAUFENDE_INVENTUR'
  | 'PDA_ACCESS_LAUFENDE_INVENTUR_NACHZAEHLUNG'
  | 'PDA_ACCESS_ARTIKELINFO'
  | 'PDA_ACCESS_STELLPLATZINFO'
  | 'PDA_ACCESS_QUALITAETSKONTROLLE_SN'
  | 'PDA_ACCESS_QUALITAETSKONTROLLE_PROD'
  | 'PDA_ACCESS_UMBUCHUNG_KANBAN'
  | 'PDA_ACCESS_VERLADEN_PAKETE'

export enum ScreenType {
  Login = 'Login',
  Root = 'Root',
  SubMenu = 'SubMenu',
  DoubleClickConfig = 'DoubleClickConfig',
  ArticleSelection = 'ArticleSelection',
  ArticleAvailability = 'ArticleAvailability',
  ArticleOrders = 'ArticleOrders',
  ArticleBarcodeArticleSelection = 'ArticleBarcode',
  ArticleDepositDataArticleSelection = 'ArticleDepositData',
  ArticleWeightArticleSelection = 'ArticleWeight',
  ArticleSalesPriceScale = 'ArticleSalesPriceScale',
  StockPositionSelection = 'StockPositionSelection',
  StockPositionInfo = 'StockPositionInfo',
  StockPositionSerial = 'StockPositionSerial',
  ShoppingCart = 'ShoppingCart',
  ShoppingCartCustomerSelection = 'ShoppingCartCustomerSelection',
  ShoppingCartCreation = 'ShoppingCartCreation',
  SerialnumberManufacturerNumber = 'SerialnumberManufacturerNumber',
  SerialnumberMacAddress = 'SerialnumberMacAddress',
  SerialnumberSelection = 'SerialnumberSelection',
  SerialnumberQuantitySelection = 'SerialnumberQuantitySelection',
  InventorySelection = 'InventorySelection',
  InventoryListScreen = 'InventoryListScreen',
  InventoryCreation = 'InventoryCreation',
  InventorySerial = 'InventorySerial',
  OngoingInventoryCreation = 'OngoingInventoryCreation',
  OngoingInventoryRecounting = 'OngoingInventoryRecounting',
  TransferCollo = 'TransferCollo',
  PackingListSelection = 'PackingListSelection',
  PackingList = 'PackingList',
  PackingListCompletion = 'PackingListCompletion',
  PreConsignmentListSelection = 'PreConsignmentListSelection',
  ProductionConsignmentListSelection = 'ProductionConsignmentListSelection',
  CustomerAndDeliveriesForFinalPicking = 'CustomerAndDeliveriesForFinalPicking',
  ColloNumbersForFinalPickingScreen = 'ColloNumbersForFinalPickingScreen',
  ProductionSelection = 'ProductionSelection',
  ProductionIn = 'ProductionIn',
  ProductionOut = 'ProductionOut',
  ProductionMoveArticle = 'ProductionMoveArticle',
  ProductionExtractionArticle = 'ProductionExtractionArticle',
  ProductionTransferArticle = 'ProductionTransferArticle',
  ProductionCreatePreProduction = 'ProductionCreatePreProduction',
  ProductionAllocationListSelection = 'ProductionAllocationListSelection',
  ProductionAllocationPreProduction = 'ProductionAllocationPreProduction',
  TransferArticleSelection = 'TransferArticleSelection',
  TransferPositionSelection = 'TransferPositionSelection',
  WorksheetSelection = 'WorksheetSelection',
  Worksheet = 'Worksheet',
  OrderProposal = 'OrderProposal',
  OrderProposalDepositSelection = 'OrderProposalDepositSelection',
  OrderProposalCreation = 'OrderProposalCreation',
  QualityManagementSelection = 'QualityManagementSelection',
  QualityManagement = 'QualityManagement',
  KanbanCreateList = 'KanbanCreateList',
  KanbanWorkList = 'KanbanWorkList',
  ParcelSelection = 'ParcelSelection',
  Parcel = 'Parcel',
}

export enum ProductionType {
  None,
  In,
  Out,
  DefectiveMaterial,
  Transfer,
}

export interface MenuConfig {
  type: MenuActionType
  navigationTarget: ScreenType
  icon: IMIconName
  presetData?: object
}

export type SelectionType =
  | 'Article'
  | 'Item'
  | 'Stock-Position'
  | 'Customer'
  | 'Serialnumber'
  | 'Serialnumber-Availability'
  | 'Manufacturer-Serialnumber'
  | 'Collo'
  | 'PackingList'
  | 'Production'
  | 'Deposit'
  | 'Position'
  | 'Worksheet'
  | 'Kanban'
  | 'Parcel'
  | 'Freighter'
  | 'ProductionAllocationList'

export interface InventoryCreationEntry {
  article: ArticleDTO
  quantities: InventoryCreationQuantity[]
}

export interface InventoryCreationQuantity {
  inventoryPositionId: string
  serialNumber: string
  quantity: number
  unitId: string
}

export interface SelectionViewProps<T> {
  title?: string
  style?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<ViewStyle>
  hideHistory?: boolean
  history?: boolean
  allowEmptyInput?: boolean
  hideSelectButton?: boolean
  onSelected: (selection: T, info?: unknown) => void
  overwriteLoadingSpinner?: (setLoading: boolean) => void
  showResultInModal?: boolean
  handleShowListButtonPressed?: () => void
  preLoad?: () => Promise<T[]> | Promise<T>
  loadOnListButtonPressed?: () => Promise<T> | Promise<T[]>
  donStopLoadingSpinner?: boolean
  denyTextInput?: boolean
  loadOnMount?: boolean
  showListLoader?: boolean
}

export interface MenuCollection {
  menu: Menu
  subMenus?: Menu[]
}

export interface PackingListMovementColloPostResult {
  colloPostRequest: ColloPostRequest | undefined
  assignedColloNumber: string
}

export interface Position {
  deposit?: Deposit
  stockPosition?: StockPosition
}

export enum QualityControlMode {
  serialNumber,
  production,
}

export type QualityCharacteristicValue = string | number | boolean | null | undefined
export enum QualityValidationResultType {
  notRequired = 'not-required',
  noValue = 'no-value',
  notOk = 'not-ok',
  ok = 'ok',
}
export type QualityValidationResult = {
  id: string
  ok: boolean
  required: QualityValidationResultType
  check: QualityValidationResultType
}

export type KanbanBox = {
  id: string
  article: Article
  stockPositionProduction: StockPosition
  stockPositionAvailability?: StockPositionAvailability
  quantity?: number
  serialNumberAvailability?: StockPositionAvailability
  minQuantityStockPosition?: number
  transferResult?: StockAccountingPostResult
  stockPositionQuantities?: StockPositionQuantity[]
  stockPositionQuantityForKanbanTransfer?: StockpositionQuantityForKanbanTransfer
}

// ######### Filter #############

export type SearchKey<T> = keyof T | [string, ...(keyof T)[]] | [keyof T, (value: unknown) => string | number]

export type DataFilterConfig<T, TSub = void> = {
  group: GroupConfig<T, TSub>
  order: OrderConfig<T, TSub>
  filter: FilterConfig<T, TSub>
  searchKeys: SearchKey<T>[]
}

export type OrderConfig<T, T2 = void> = {
  type: DataFilterConfigType.Order
  config: Config<T, OrderConfigOptions<T, T2>>
}

export type GroupConfig<T, T2 = void> = {
  type: DataFilterConfigType.Group
  config: Config<T, GroupConfigOptions<T, T2>>
}

export type FilterConfig<T, T2 = void> = {
  type: DataFilterConfigType.Filter
  config: Config<T, FilterConfigOptions<T2>>
}

export type FilterConfigText = i18nKey | { text: string }
export type Config<T, Y> = ([keyof T, FilterConfigText] | [keyof T, FilterConfigText, Y])[]

export type OrderConfigOptions<T, TSub = void> = {
  modifier?: 'inverse'
  idSuffix?: string
  textKey?: keyof T | LanguageSelectType<keyof T>
  textProvider?: (value: unknown) => i18nKey
  isDefault?: boolean
  subObjectKey?: keyof TSub
  subObjectValueProvider?: (subObj: TSub, obj: T) => string | number | undefined | null | string[] | number[] | false
  noGroupTextKey?: i18nKey
  titleSortFunction?: (a: string, b: string) => number
}

export type GroupConfigOptions<T, TSub = void> = OrderConfigOptions<T, TSub>

export type FilterConfigOptions<TSub> = { icon?: IconProp; idSuffix?: string } & (
  | DefaultFilterConfigOption
  | BooleanFilterConfigOption
  | ArrayFilterConfigOption<TSub>
  | ArraySubObjectConfigOption<TSub>
)

export type DefaultFilterConfigOption = {
  filterType?: undefined
}

export type BooleanFilterConfigOption = {
  filterType: 'boolean'
  trueText: i18nKey
  falseText: i18nKey
}

export type FilterValueExtractorResult = {
  value: string
  id: string
}
export type ArrayFilterConfigOption<TSub> = {
  filterType: 'array'
  valueExtractor: (el: TSub) => FilterValueExtractorResult
}

export type ArraySubObjectConfigOption<TSub> = {
  filterType: 'object'
  valueExtractor: (el: TSub) => FilterValueExtractorResult
}

export enum DataFilterConfigType {
  Group = 'group',
  Order = 'order',
  Filter = 'filter',
  ApiFilter = 'api-filter',
}

type CommonDataSorter<T> = {
  id: string
  fieldId: string
  dataKey: keyof T
  textKey: FilterConfigText
}

export type FilterDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Filter
  values: DataFilterValue[]
  options?: FilterConfigOptions<TSub>
}

export type GroupDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Group
  active: boolean
  order?: number
  options?: GroupConfigOptions<T, TSub>
  apiRequestKey?: string
}

export type OrderDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Order
  active: boolean
  order?: number
  options?: OrderConfigOptions<T, TSub>
  apiRequestKey?: string
}

export type DataFilterValue = {
  id: string
  value: string
  active?: boolean
  count?: number
  booleanTypeTrueTag?: boolean
  arrayElementId?: number
  isDateRangeMax?: boolean
}

export interface GroupedData<T> {
  key: string | null
  title: string
  rawTitle: string
  data: T[]
}

export type FilterStorageType = {
  filterId: string
  values: StorageType[]
}

export type StorageType = {
  id: string
  value: string | number | boolean
  text?: string
  isMax: boolean
}
