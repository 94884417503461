import React from 'react'
import { TextProps } from 'react-native'

import { STYLE_CONSTANTS } from '../../constants/Constants'
import Text from '../old/Text'

export default function Title(props: TextProps) {
  const { style, ...otherProps } = props

  return <Text style={[{ textAlign: 'center', marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }, style]} {...otherProps} />
}
