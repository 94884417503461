import { useLanguage, Utils } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { ProductionPartForTransfer } from '../../apis/apiTypes'
import { ProductionPartToTransfer } from '../../classes/ProductionData'
import IMText from '../../components/IMText'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useArticle from '../../hooks/useArticle'
import { articleUtils } from '../../utils/articleUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ProductionPartTransferToMoveCard(props: {
  item: ProductionPartForTransfer
  showQuantities: boolean
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}) {
  const { i18n } = useLanguage()
  const articleProduction = useArticle(props.item?.articleProduction, {}, [props.item])

  function getQuantityText(quantity: number | undefined) {
    let unit = articleUtils.getUnitById(props.item?.article, props.item?.unitId)
    if (unit === undefined) unit = articleUtils.getUnitById(props.item?.article, props.item?.article?.measurementUnitId)
    return articleUtils.formatQuantity(quantity ?? 0, unit)
  }

  return (
    <MWS_BaseCard icon={'article'} style={[{ flexDirection: 'row' }]} cardStyle={props.style} onPress={props.onPress}>
      <View style={{ flex: 1, justifyContent: 'center', marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}>
        <IMText primary>{i18n.t('ProductionSelection') + ': ' + props.item.production}</IMText>
        {articleProduction && (
          <IMText numberOfLines={2} secondary>
            {articleProduction.info.code?.trim()} - {articleUtils.getArticleDescription(articleProduction?.info, i18n.t('ID'))}
          </IMText>
        )}
        {props.showQuantities && (
          <>
            <IMText secondary>{i18n.t('Amount') + ': ' + getQuantityText(props.item?.quantity)}</IMText>
            <IMText secondary>{i18n.t('BOOKED') + ': ' + getQuantityText(props.item?.quantityTransferred)}</IMText>
          </>
        )}
      </View>
    </MWS_BaseCard>
  )
}
