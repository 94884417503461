import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, View } from 'react-native'

import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import Button from '../../components/old/Button'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'

export default function PackingLisIncompleteMovementsView(props: {
  show: boolean
  incompletePackingListArticles: PackingListArticle[] | undefined
  onContinue: () => void
}) {
  const { i18n } = useLanguage()

  if (!props.show) return <></>
  return (
    <View style={{ flex: 1 }}>
      <IMTextWithIcon
        viewStyle={{ justifyContent: 'center', marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        iconColor={Colors.info}
        icon={'exclamationTriangle'}
        text={i18n.t('PackingListIncompleteMovements')}
      />
      <View style={{ flex: 1 }}>
        <FlatList data={props.incompletePackingListArticles} renderItem={({ item }) => <PackingListArticleCard item={item} />} />
      </View>
      <Button title={i18n.t('PackingListContinueWithIncompleteMovements')} onPress={() => props.onContinue()} />
    </View>
  )
}
