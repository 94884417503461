import { useApi } from '@infominds/react-api'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo } from 'react'

import api from '../../apis/apiCalls'
import { WarehouseFilterConfigRequest } from '../../apis/apiRequestTypes'
import { FilterConfig, GroupConfig, OrderConfig } from '../../types'

export type UseFilterConfigProps<T, TSub> = {
  groupConfig?: GroupConfig<T, TSub>
  orderConfig?: OrderConfig<T, TSub>
  filterConfig?: FilterConfig<T, TSub>
  valueProvider: (definitionId: string, item: TSub | TSub[]) => string | false | null | undefined
}

export default function useFilterConfig<T, TSub = void>(
  type: WarehouseFilterConfigRequest['type'],
  { groupConfig: inGroupConfig, orderConfig: inOrderConfig, filterConfig: inFilterConfig, valueProvider }: UseFilterConfigProps<T, TSub>
) {
  const [config, loadConfig, loadingConfig] = useApi(api.getFilterConfig)

  useEffect(() => {
    loadConfig({ type }).catch(console.error)
  }, [])

  const filterConfig = useMemo(() => {
    if (!config) return inFilterConfig
    const result = cloneDeep(inFilterConfig)
    config.filterConfig?.forEach(c => {
      if (!c.definitionId) return
      result?.config.push([
        'additionalfields' as keyof T,
        { text: c.description },
        {
          filterType: 'array',
          idSuffix: c.definitionId,
          valueExtractor: (item: TSub) => {
            const value = valueProvider(c.definitionId, item)
            return { id: value ? value.toString() : '', value: value ? value.toString() : '' }
          },
        },
      ])
      result?.config.push()
    })
    return result
  }, [config, inGroupConfig])

  const groupConfig = useMemo(() => {
    if (!config) return inGroupConfig
    const result = cloneDeep(inGroupConfig)
    config.groupConfig?.forEach(c => {
      if (!c.definitionId) return
      result?.config.push([
        'additionalfields' as keyof T,
        { text: c.description },
        {
          idSuffix: c.definitionId,
          subObjectValueProvider: (item: TSub) => valueProvider(c.definitionId, item),
        },
      ])
      result?.config.push()
    })
    return result
  }, [config, inGroupConfig])

  const orderConfig = useMemo(() => {
    if (!config) return inOrderConfig
    const result = cloneDeep(inOrderConfig)
    config.sortConfig?.forEach(c => {
      if (!c.definitionId) return
      result?.config.push([
        'additionalfields' as keyof T,
        { text: c.description },
        {
          idSuffix: c.definitionId,
          subObjectValueProvider: (item: TSub) => valueProvider(c.definitionId, item),
        },
      ])
      result?.config.push([
        'additionalfields' as keyof T,
        { text: c.description },
        {
          idSuffix: c.definitionId,
          subObjectValueProvider: (item: TSub) => valueProvider(c.definitionId, item),
          modifier: 'inverse',
        },
      ])
      result?.config.push()
    })
    return result
  }, [config, inOrderConfig])

  return { groupConfig, orderConfig, filterConfig, loadingConfig }
}
