import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/core'
import React, { useMemo } from 'react'

import api from '../../apis/apiCalls'
import { InventoryPostRequest } from '../../apis/apiRequestTypes'
import { InventoryPostResult, InventoryQuantityInsertType, QuantityInsertType } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useRoute from '../../hooks/useRoute'
import inventoryUtils from '../../utils/inventoryUtils'
import { ArticleAmountSelectionResult } from '../../views/Article/ArticleAmountSelectorV2'
import MultiSelectionViewV2 from '../../views/SerialNumber/MultiSelectionViewV2'

export default function InventoryCreationScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const loader = useLoadingIndicator()

  const routeParams = useRoute<'InventoryCreation'>()
  const inventory = routeParams?.inventory
  const stockPosition = routeParams?.stockPosition
  const article = useArticle(routeParams?.item?.article, {
    useUnitType:
      inventory?.quantityInsertTyp === InventoryQuantityInsertType.SalesUnit
        ? QuantityInsertType.SalesUnit
        : inventory?.quantityInsertTyp === InventoryQuantityInsertType.PurchasingUnit
          ? QuantityInsertType.PurchasingUnit
          : QuantityInsertType.MainUnit,
    serialNumber: routeParams?.item?.serialnumber,
  })

  const proposedQuantity = useMemo(
    () =>
      article?.isSerialNumberActive
        ? undefined
        : article
            ?.getConvertedDepositQuantities()
            .find(dq => dq.deposit?.id === inventory?.deposit.id)
            ?.stockpositionquantities.find(sq => sq.stockposition?.id === stockPosition?.id)?.quantity,
    []
  )

  function completeInventory(items: ArticleAmountSelectionResult[]) {
    if (!article || !inventory) return
    loader.setLoading(true)
    Promise.all(
      items.map(element =>
        postInventory(inventoryUtils.createPostRequest(inventory, article.info, stockPosition, element.quantity, element.unit, element.serialNumber))
      )
    )
      .then(() => {
        loader.setLoading(false)
        navigation.goBack()
      })
      .catch(reason => {
        loader.setLoading(false)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert.error(i18n.t('FailedToCreateInventory'), reason?.Message ?? '')
      })
  }

  return (
    <MWS_Screen title={inventoryUtils.getTitle(inventory, stockPosition)}>
      {article && (
        <>
          <ArticleCard
            article={article.info}
            maxLinesDescription={4}
            serialNumber={article?.serialNumber}
            unit={article.getUsedUnit()}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          />

          <MultiSelectionViewV2
            article={article}
            onComplete={completeInventory}
            autoComplete
            ignoreAvailability
            hidePositionFromSelectedSerial
            proposedQuantity={proposedQuantity ? Math.max(proposedQuantity, 0) : undefined}
            deposit={inventory?.deposit}
            stockPosition={stockPosition}
            preSelectedSerial={article.serialNumber}
            submitAfterSelectionCount={article.serialNumber ? 1 : undefined}
            allowUnityChange
          />
        </>
      )}
    </MWS_Screen>
  )
}

function postInventory(item: InventoryPostRequest) {
  return new Promise<InventoryPostResult>((resolve, reject) => {
    api
      .postInventory(item)
      .then(result => {
        resolve(result)
      })
      .catch(reject)
  })
}
