import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../apis/apiCalls'
import { Deposit } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { SelectionViewProps } from '../../types'

export default function DepositSelectionView(props: SelectionViewProps<Deposit>) {
  const { i18n } = useLanguage()

  const { title, ...inputProps } = props

  const RenderItem = useCallback((item: Deposit) => <DepositCard deposit={item} />, [])

  return (
    <MWS_Input<Deposit>
      type="Deposit"
      title={title ?? i18n.t('SelectDeposit')}
      load={input => api.getDeposit({ code: input.scannerInput ?? input.textInput })}
      itemRenderer={RenderItem}
      failedToLoadText={i18n.t('NoDepositPosWithCode')}
      modalTitle={i18n.t('SelectDeposit')}
      {...inputProps}
    />
  )
}
