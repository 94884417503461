import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'

import FullScreenModal from '../components/FullScreenModal'
import { ModalController } from '../hooks/useModalController'
import ColloPackingView, { ColloPackingSelection } from '../views/PackingList/ColloPackingView'

export default function ColloPackingModal(props: {
  controller: ModalController<ColloPackingSelection>
  onConfirm: (packing: ColloPackingSelection) => void
  onAborted?: () => void
  modalTitle?: string
}) {
  const { i18n } = useLanguage()

  const [colloPacking, setColloPacking] = useState<ColloPackingSelection>({})

  useEffect(() => {
    if (!props.controller.isShown) {
      setColloPacking({})
      return
    }
    setColloPacking(props.controller.data ?? {})
  }, [props.controller.isShown])

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
        if (props.onAborted) props.onAborted()
      }}
      title={props.modalTitle || i18n.t('ConcludeCollo')}
      onClose={() => {
        props.controller.close()
        props.onConfirm(colloPacking)
      }}
      closeIcon={'check'}>
      <ColloPackingView style={{ flex: 1 }} colloPacking={colloPacking} setColloPacking={setColloPacking} showQuantityInput focusWeightInput />
    </FullScreenModal>
  )
}
