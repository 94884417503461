import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'

interface SearchBoxProps {
  value: string
  searchOnChange?: boolean
  searchOnChangeDelay?: number
  onChangeText: (text: string) => void
  style?: StyleProp<ViewStyle>
  onFocusChanged?: (focused: boolean) => void
  onIncomingChange?: () => void
}

export default function SearchBox(props: SearchBoxProps) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const [text, setText] = useState(props.value)
  const [isFocused, setIsFocused] = useState(false)
  const [instantSearchTimeout, setInstantSearchTimeout] = useState<NodeJS.Timeout | undefined>()

  useEffect(() => {
    setText(props.value)
  }, [props.value])

  function onTextChange(newText: string, instant?: boolean) {
    setText(newText)
    if (instantSearchTimeout) {
      clearTimeout(instantSearchTimeout)
      setInstantSearchTimeout(undefined)
    }
    if (instant) {
      props.onChangeText(newText)
    } else if (props.searchOnChange) {
      if (!props.searchOnChangeDelay) {
        props.onChangeText(newText)
        return
      }
      if (props.onIncomingChange) props.onIncomingChange()
      const memTimeout = setTimeout(() => {
        props.onChangeText(newText)
        setInstantSearchTimeout(undefined)
      }, props.searchOnChangeDelay)

      setInstantSearchTimeout(memTimeout)
    }
  }

  return (
    <View
      style={[
        styles.searchSection,
        {
          backgroundColor: theme.inputBackground,
          borderColor: theme.inputBorder,
        },
        props.style,
      ]}>
      <TextInput
        style={[styles.input, { color: theme.text }]}
        value={text}
        placeholder={i18n.t('SEARCH')}
        underlineColorAndroid="transparent"
        placeholderTextColor={theme.textPlaceholder}
        onFocus={() => {
          setIsFocused(true)
          if (props.onFocusChanged) props.onFocusChanged(true)
        }}
        onChangeText={onTextChange}
        onEndEditing={() => {
          onTextChange(text, true)
          setIsFocused(false)
          if (props.onFocusChanged) props.onFocusChanged(false)
        }}
      />
      {text ? (
        <IM.PressableIcon
          style={styles.searchIcon}
          icon={['fal', 'times']}
          size={20}
          color={theme.textDetail}
          onPress={() => {
            onTextChange('', !isFocused)
          }}
        />
      ) : (
        <IM.Icon style={styles.searchIcon} icon={['fal', 'search']} size={20} color={theme.textDetail} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    flex: 1,
    paddingHorizontal: 12,
    height: '100%',
  },
  searchSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 8,
    paddingRight: 5,
  },
  searchIcon: {
    padding: 5,
  },
})
