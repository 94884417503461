import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { Deposit, StockPosition } from '../apis/apiTypes'
import FullScreenModal from '../components/FullScreenModal'
import DepositSelectorView from '../views/Deposit/DepositSelectorView'
import StockPositionSelectionView from '../views/InputViews/StockPositionSelectionView'

export default function PositionSelectionModal(props: {
  show: boolean
  deposits?: Deposit[]
  close: () => void
  onSelected: (deposit: Deposit, stockPosition?: StockPosition) => void
  selectDepositCaption?: string
  selectStockPositionCaption?: string
}) {
  const { i18n } = useLanguage()

  const [deposit, setDeposit] = useState<Deposit | undefined>()

  useEffect(() => {
    if (props.show) {
      if (props.deposits && props.deposits.length === 1) {
        setDeposit(props.deposits[0])
      }
    }
  }, [props.show])

  function handleDepositSelected(selectedDeposit: Deposit | undefined) {
    if (selectedDeposit && !selectedDeposit.isStockpositionActive) {
      props.onSelected(selectedDeposit)
      return
    }
    setDeposit(selectedDeposit)
  }

  return (
    <FullScreenModal
      isVisible={props.show}
      close={() => {
        setDeposit(undefined)
        props.close()
      }}
      title={deposit ? props.selectStockPositionCaption || i18n.t('SelectStockPosition') : props.selectDepositCaption || i18n.t('SelectDeposit')}>
      <DepositSelectorView item={deposit} setItem={handleDepositSelected} getItemList={() => props.deposits ?? []} />
      {deposit && (
        <View style={{ flex: 1 }}>
          <StockPositionSelectionView
            title={props.selectStockPositionCaption}
            depositId={deposit.id}
            onSelected={stockPosition => props.onSelected(deposit, stockPosition)}
            allowEmptyInput
          />
        </View>
      )}
    </FullScreenModal>
  )
}
