import React from 'react'
import { View } from 'react-native'

import ProductionCard from '../../cards/Production/ProductionCard'
import Separator from '../../components/Separator'
import { ProductionHandler, ProductionMode } from '../../hooks/specific/useProductionHandler'
import ProductionOutMasterInputView from './ProductionOuMastertInputView'
import ProductionPartsView from './ProductionPartsView'

export default function ProductionOutMasterView(props: { handler: ProductionHandler }) {
  const handler = props.handler
  return (
    <View style={{ flex: 1 }}>
      <ProductionCard
        handler={handler.production?.production}
        showQuantities={!handler.production?.isDefectiveMaterialProduction}
        navigateToArticleInfoOnPress
        employeeTimeType={handler.productionMode === ProductionMode.lastProductionStep ? undefined : handler.employeeTimeType}
      />
      <Separator />
      <ProductionOutMasterInputView handler={handler} />
      <ProductionPartsView handler={handler} />
    </View>
  )
}
