import { useEffect, useRef } from 'react'

/**
 * Calls callback if dependency is true. But only one time in its lifetime
 */
export default function useOneTimeCallback(callback: () => void, dependency: any) {
  const called = useRef<boolean>(false)
  useEffect(() => {
    if (!called.current && !!dependency) {
      called.current = true
      callback()
    }
  }, [dependency])
}
