import { useLanguage } from '@infominds/react-native-components'
import React, { useContext, useState } from 'react'
import { FlatList, View } from 'react-native'

import KeyCodeCard from '../cards/KeyCodeCard'
import MWS_Screen from '../components/MWS/MWS_Screen'
import Title from '../components/MWS/Title'
import Separator from '../components/Separator'
import { DoubleClickContext, useDoubleClick } from '../contexts/DoubleClickContext'

export default function DoubleClickConfigScreen() {
  const { i18n } = useLanguage()

  const [lastDetectedKey, setLastDetectedKey] = useState<number | null>(null)

  const doubleClickContext = useContext(DoubleClickContext)
  const anyButtons = !!doubleClickContext.allowedKeyCodes?.length
  useDoubleClick(doubleClickCallback, true)

  function doubleClickCallback(keyCode: number) {
    doubleClickContext.addKeyCode(keyCode).catch(console.error)
    setLastDetectedKey(keyCode)
  }

  return (
    <MWS_Screen title={i18n.t('ConfigureDoubleClick')}>
      <View style={{ flex: 1 }}>
        {anyButtons && (
          <>
            <Title>{i18n.t('DoubleClickConfigText')}</Title>
            <Separator />
          </>
        )}

        <FlatList
          data={doubleClickContext.allowedKeyCodes}
          renderItem={({ item }) => (
            <KeyCodeCard
              keyCode={item}
              onDelete={() => {
                doubleClickContext.removeKeyCode(item).catch(console.error)
              }}
              lastUsed={lastDetectedKey === item}
            />
          )}
          ListFooterComponent={
            <View>
              <Title>{i18n.t('DoubleClickAddText')}</Title>
            </View>
          }
        />
      </View>
    </MWS_Screen>
  )
}
