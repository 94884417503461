import { useLanguage, useToastMessage } from '@infominds/react-native-components'
import { StackActions, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { Item } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useMeasurementUnitNetWeightInputHandler from '../../hooks/specific/useMeasurementUnitNetWeightInputHandler'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useOnScreenFocus from '../../hooks/useOnScreenFocus'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'

export default function ArticleWeightArticleSelectionScreen() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const route = useRoute()
  const isFocused = useIsFocused()
  const loader = useLoadingIndicator()
  const toastMessage = useToastMessage()
  const alert = useAlert()

  const [item, setItem] = useState<Item>()
  const article = useArticle(item?.article, { serialNumber: item?.serialnumber }, [item])
  const weightInputHandler = useMeasurementUnitNetWeightInputHandler(article?.info, true, article?.mainUnit)

  useOnScreenFocus(() => {
    setItem(undefined)
    navigation.setParams({ ...route.params, transferOk: undefined })
  })

  useEffect(() => {
    if (!article || !isFocused) return
  }, [article])

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: item })
  }

  function handleItemSelection(selectedItem: Item) {
    setItem(selectedItem)
  }

  async function complete() {
    if (!article) return
    loader.setLoading(true)
    try {
      await weightInputHandler.patch()
      navigation.dispatch(StackActions.popToTop)
    } catch (exception: unknown) {
      console.error('completeTransfer error', exception)
      alert.error(i18n.t('FailedToUpdateNetWeight'), Error_Utils.extractErrorMessageFromException(exception))
    }
    toastMessage.show(i18n.t('ASSIGNARTICLEWEIGHTSUCCESSFULL'))
    loader.setLoading(false)
  }

  return (
    <MWS_Screen title={i18n.t('ASSIGNARTICLEWEIGHT')}>
      {!article && (
        <>
          <ItemSelectionView onSelected={handleItemSelection} showResultInModal />
        </>
      )}
      {article && (
        <View style={{ flex: 1 }}>
          <ArticleCard
            article={article.info}
            serialNumber={article.serialNumber}
            onDelete={() => {
              setItem(undefined)
            }}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            onPress={showArticleInfoScreen}
          />
          <QuantityInput value={0} onValueChanged={weightInputHandler.setInput} text={`1 ${article.getUnitText()}`} unit={i18n.t('WeightUnit')} />
          <SubmitButton onPress={complete} title={i18n.t('Assign')} />
        </View>
      )}
    </MWS_Screen>
  )
}
