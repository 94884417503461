import { DefaultTheme, IMStyle, ThemeColorsType, ThemeType } from '@infominds/react-native-components'

export interface ThemeExtension extends ThemeColorsType {
  filterTag: { default: FilterTag; active: FilterTag; header: FilterTag }
  backgroundSecondary: string
}

interface FilterTag {
  background: string
  border: string
  text: string
}

export const themeExtension: ThemeType<ThemeExtension> = {
  light: {
    ...DefaultTheme.light,
    backgroundSecondary: DefaultTheme.light.input.border,
    filterTag: {
      default: {
        background: '#FFFFFF',
        border: IMStyle.palette.neutral,
        text: DefaultTheme.light.text,
      },
      active: {
        background: '#535F71',
        border: '#535F71',
        text: DefaultTheme.dark.text,
      },
      header: {
        background: '#FFFFFF',
        border: IMStyle.palette.neutral,
        text: DefaultTheme.light.text,
      },
    },
  },
  dark: {
    ...DefaultTheme.dark,
    backgroundSecondary: DefaultTheme.dark.input.border,
    filterTag: {
      default: {
        background: '#272829',
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
      active: {
        background: DefaultTheme.dark.textDetail,
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
      header: {
        background: DefaultTheme.dark.textDetail,
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
    },
  },
}
