import { useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import MultiTransferPositionCard from '../../cards/StockPosition/MultiTransferPositionCard'
import TransferIndicatorCard from '../../cards/Transfer/TransferIndicatorCard'
import IMIcon from '../../components/IMIcon'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useTransfer } from '../../contexts/TransferContext'
import { TransferHandler } from '../../hooks/specific/useTransferHandler'
import transferUtils from '../../utils/transferUtils'
import { Utils } from '../../utils/Utils'
import TransferPositionSelectorView from './TransferPositionSelectorView'

interface TransferPositionViewProps {
  handler: TransferHandler
}

export default function TransferPositionView(props: TransferPositionViewProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { i18n } = useLanguage()

  const handler = props.handler
  const sourceHandler = handler.sourcePositionHandler
  const targetHandler = handler.targetPositionHandler
  const { savedDepositTarget } = useTransfer()

  const showMultiSource = handler.isSerialNumberActive && handler.articleQuantities.any && sourceHandler.active
  const showQuantity = sourceHandler.quantity || handler.quantity || targetHandler.quantity
  const showSource = sourceHandler.active && !showMultiSource
  const showSuggestedPositions = !!handler.showTargetSuggestions && sourceHandler.isOk && !targetHandler.isOk

  function getIndicatorPressFun() {
    if (sourceHandler.isOk && !!sourceHandler.quantityRequired) return () => sourceHandler.setQuantity(0)
    if (targetHandler.isOk && !!targetHandler.quantityRequired) return () => targetHandler.setQuantity(0)
    return undefined
  }

  return (
    <View style={{ flex: 1 }}>
      {showMultiSource && (
        <MultiTransferPositionCard
          positions={handler.articleQuantities.items.map(item => ({ deposit: item.deposit, stockPosition: item.stockPosition }))}
        />
      )}
      <TransferPositionSelectorView
        show={showSource}
        article={handler.article}
        handler={sourceHandler}
        checkAvailability={sourceHandler.quantityRequired}
        selectDepositCaption={Utils.stringValueReplacer(i18n.t('SelectDepositVariable'), i18n.t('Source'))}
        selectStockPositionCaption={Utils.stringValueReplacer(i18n.t('SelectStockPositionVariable'), i18n.t('Source'))}
      />
      <TransferIndicatorCard
        quantity={showQuantity ? handler.article?.getQuantityWithUnitText(showQuantity, true) : undefined}
        stockTemplate={handler.stockTemplate}
        style={!showSource && { marginTop: 0 }}
        onPress={getIndicatorPressFun()}
      />
      {handler.stockTemplate?.isInOut && targetHandler.isOk && !sourceHandler.isOk && (
        <IMIcon
          style={{ marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS, justifyContent: 'center' }}
          iconStyle={{ alignSelf: 'center' }}
          icon={'arrow-down'}
          color={theme.textPlaceholder}
          size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
        />
      )}
      <TransferPositionSelectorView
        show={targetHandler.isOk || (!!sourceHandler.isOk && targetHandler.active)}
        article={handler.article}
        handler={targetHandler}
        checkAvailability={false}
        suggestedPositionsActive={!!handler.showTargetSuggestions}
        showSuggestedPositions={showSuggestedPositions}
        sourcePositionForSuggestions={{
          deposit:
            transferUtils.getDepositTarget(handler.stockTemplate, savedDepositTarget) ??
            transferUtils.getDepositTarget(handler.stockTemplate, savedDepositTarget) ??
            sourceHandler.deposit,
          stockPosition: sourceHandler.stockPosition,
        }}
        selectDepositCaption={Utils.stringValueReplacer(i18n.t('SelectDepositVariable'), i18n.t('Target'))}
        selectStockPositionCaption={Utils.stringValueReplacer(i18n.t('SelectStockPositionVariable'), i18n.t('Target'))}
        stockTemplate={handler.stockTemplate}
      />
    </View>
  )
}
