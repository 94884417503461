import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { View } from 'react-native'

import { IMIconName } from '../../components/IMIconNames'
import Text from '../../components/old/Text'
import { IMLayout } from '../../constants/Styles'
import { ScreenType } from '../../types'
import MWS_BaseCard from '../MWS_BaseCard'

export default function NavigationCard(props: { icon?: IMIconName; title: string; navigationTarget: ScreenType | undefined; data?: unknown }) {
  const navigation = useNavigation()

  function handleOnPress() {
    //@ts-ignore data is pre-defined by api data
    if (props.navigationTarget) navigation.navigate(props.navigationTarget, props.data)
  }

  return (
    <MWS_BaseCard onPress={handleOnPress} icon={props.icon}>
      <View style={[IMLayout.flex.f1, { justifyContent: 'center' }]}>
        <Text>{props.title}</Text>
      </View>
    </MWS_BaseCard>
  )
}
