import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'

import ProductionData from '../../../classes/ProductionData'
import MWS_Screen from '../../../components/MWS/MWS_Screen'
import useProductionHandler from '../../../hooks/specific/useProductionHandler'
import useAlert from '../../../hooks/useAlert'
import useRouteParam from '../../../hooks/useRouteParam'
import ProductionOutDetailView from '../../../views/Production/ProductionOutDetailView'
import ProductionOutMasterView from '../../../views/Production/ProductionOutMasterView'

export default function ProductionOutScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const productionData = useRouteParam<ProductionData>('productionHandler')
  const quantityFromProductionIn = useRouteParam<number>('quantityFromProductionIn')
  const handler = useProductionHandler(productionData, quantityFromProductionIn)

  useEffect(() => {
    if (quantityFromProductionIn) {
      handler.handleProcessModeInput(quantityFromProductionIn).catch(console.error)
    }
  }, [])

  function handleNavigation(type: 'goBack' | 'popToTop') {
    // @ts-ignore todo
    return new Promise<boolean>(resolve => {
      if (type === 'goBack' && handler.selectedPart) {
        handler.setSelectedPart(undefined)
        resolve(true)
        return
      }
      if (handler.parts.items.find(item => item.selectedQuantities.length > 0)) {
        alert
          .yesNo(i18n.t('LeavingProductionAlert'))
          .then(result => {
            resolve(!result)
          })
          .catch(console.error)
        return true
      }
      resolve(false)
    })
  }

  return (
    <MWS_Screen
      preNavigationCallback={handleNavigation}
      title={handler.production?.isDefectiveMaterialProduction ? i18n.t('ProductionDefective') : undefined}>
      {!handler.selectedPart && <ProductionOutMasterView handler={handler} />}
      {handler.selectedPart && <ProductionOutDetailView handler={handler} />}
    </MWS_Screen>
  )
}
