import { Layout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Text as DefaultText } from 'react-native'

import { Colors } from '../../constants/Colors'

type Props = {
  color?: string
}

export type TextProps = Props & DefaultText['props']

export default function Text(props: TextProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { style, ...otherProps } = props

  const color = props.color !== undefined ? props.color : theme.text

  return (
    <DefaultText
      style={[
        Layout.defaultComponentsStyles,
        {
          color: color,
        },
        style,
      ]}
      {...otherProps}
    />
  )
}
