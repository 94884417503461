import React, { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import Button, { ButtonProps } from './old/Button'

export type ElusiveButtonProps = { hide?: boolean; hideFromKeyBoard?: boolean } & ButtonProps

export default function ElusiveButton(props: ElusiveButtonProps) {
  const [hideButtonFromKeyboard, setHideButtonFromKeyBoard] = useState(false)

  const { hide, hideFromKeyBoard, style, ...touchableProps } = props

  useEffect(() => {
    if (!hideFromKeyBoard) return
    const subScriptions = [
      Keyboard.addListener('keyboardDidShow', () => setHideButtonFromKeyBoard(true)),
      Keyboard.addListener('keyboardDidHide', () => setHideButtonFromKeyBoard(false)),
    ]

    return () => {
      subScriptions.forEach(sub => sub.remove())
    }
  }, [])

  if (hide || hideButtonFromKeyboard) return <></>
  return <Button style={[{ marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }, style]} {...touchableProps} />
}
