import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import useModalController from '../../../hooks/useModalController'
import SelectionModal from '../../../modals/SelectionModal'
import IMIcon from '../../IMIcon'
import { IMIconName } from '../../IMIconNames'
import IMText from '../../IMText'
import CardBasic from '../../old/CardBasic'

interface PickerProps<T> {
  defaultValue?: T
  values: T[]
  onSubmit: (value: T) => void
  icon?: IMIconName
  text?: string
  style?: StyleProp<ViewStyle>
  captionExtractor?: (value: T) => string
  placeHolder?: string
  modalTitle?: string
  disabled?: boolean
  loading?: boolean
  onModalHide?: () => void
}

export default function Picker<T>(props: PickerProps<T>) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { i18n } = useLanguage()
  const pickerModal = useModalController<T[]>()
  const [value, setValue] = useState<T | undefined>()

  const disabled = !!props.disabled || !!props.loading

  useEffect(() => {
    if (props.defaultValue) setValue(props.defaultValue)
  }, [props.defaultValue])

  function getText(item: T | undefined) {
    if (props.loading) return i18n.t('LOADING')
    const defaultValue = props.placeHolder ?? ''
    if (!item) return defaultValue
    if (props.captionExtractor) return props.captionExtractor(item)
    if (typeof item === 'string' || typeof item === 'number') return item.toString().trim()
    return defaultValue
  }

  return (
    <View style={[styles.main, props.style]}>
      <View style={{ flexDirection: 'row' }}>
        <CardBasic
          style={{ width: props.icon ? undefined : '30%', margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}>
          {props.icon && (
            <IMIcon style={{ justifyContent: 'center' }} size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} icon="article" color={theme.textDetail} />
          )}
          {props.text && (
            <IMText numberOfLines={1} style={{ textAlign: 'center' }}>
              {props.text}
            </IMText>
          )}
        </CardBasic>
        <TouchableOpacity
          disabled={disabled}
          style={{ flex: 1, flexDirection: 'row' }}
          onPress={() => {
            pickerModal.show(props.values)
          }}>
          <CardBasic
            style={{
              flex: 1,
              margin: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              justifyContent: 'center',
              flexDirection: 'row',
            }}>
            <IMText numberOfLines={1} secondary={!!disabled} style={{ textAlign: 'center', flex: 1 }}>
              {getText(value)}
            </IMText>
            <IMIcon
              color={!!disabled ? theme.card : theme.text}
              icon={'angle-down'}
              style={{ paddingLeft: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN, justifyContent: 'center' }}
            />
          </CardBasic>
        </TouchableOpacity>
        <SelectionModal
          title={props.modalTitle}
          show={pickerModal.isShown}
          items={pickerModal.data ?? []}
          close={pickerModal.close}
          onModalHide={props.onModalHide}
          renderItem={item => (
            <TouchableOpacity
              onPress={() => {
                setValue(item)
                pickerModal.close()
                props.onSubmit(item)
              }}>
              <CardBasic>
                <IMText style={{ textAlign: 'center' }}>{getText(item)}</IMText>
              </CardBasic>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
})
