import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Freighter, Parcel } from '../../apis/apiTypes'
import FreighterCard from '../../cards/FreighterCard'
import ParcelCard from '../../cards/ParcelCard'
import IMIcon from '../../components/IMIcon'
import IMText from '../../components/IMText'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useNotificationSound from '../../hooks/useNotificationSound'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import parcelUtils from '../../utils/ParcelUtils'

export default function ParcelScreen() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()

  const handler = useRouteParam<Parcel>('parcel')
  const parcelFreighter = useRouteParam<Freighter>('freighter')
  const loader = useLoadingIndicator()
  const [selectedFreighter, setSelectedFreighter] = useState<Freighter | undefined>(undefined)
  const [checkResult, setCheckResult] = useState<boolean | undefined>(undefined)

  const { playAlertSound } = useNotificationSound()

  useEffect(() => {
    load()
  }, [])

  function load() {
    if (!handler) {
      navigation.navigate(ScreenType.ParcelSelection)
      return
    }
  }

  function selectedAndCheckFreighter(item: Freighter) {
    if (item.id === handler?.freighterId) {
      setSelectedFreighter(item)
      setCheckResult(true)
      onConfirm(true)
      return
    }
    setCheckResult(false)
    onConfirm(false)
    playAlertSound()
  }

  const RenderItem = useCallback((item: Freighter) => <FreighterCard freighter={item} />, [])

  function onDeleteFreighter() {
    setSelectedFreighter(undefined)
  }

  function onConfirm(isOk: boolean) {
    if (!handler) {
      return
    }

    loader.setLoading(true)
    const request = parcelUtils.generatePatchRequest(handler, isOk ? i18n.t('LoadingParcelOk') : i18n.t('LoadingParcelNotOk'), isOk)
    api
      .patchParcel(request)
      .then(result => {
        if (result && isOk) {
          setTimeout(() => {
            navigation.navigate(ScreenType.ParcelSelection)
          }, 500)
        }
      })
      .catch(reason => {
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  if (!handler) {
    navigation.navigate(ScreenType.ParcelSelection)
    return <></>
  }

  function loadFreighter(inputResult: ScannerInputResult) {
    const input = inputResult.scannerInput ?? inputResult.textInput ?? ''
    return api.getFreighter({ code: input })
  }

  return (
    <MWS_Screen title={i18n.t('LoadingParcel')}>
      <View style={{ flex: 1 }}>
        <ParcelCard handler={handler} freighter={parcelFreighter} />
        {!selectedFreighter && (
          <MWS_Input
            title={i18n.t('Freighter')}
            load={loadFreighter}
            itemRenderer={RenderItem}
            failedToLoadText={i18n.t('NOFREIGHTER')}
            modalTitle={i18n.t('Freighter')}
            type={'Freighter'}
            onSelected={function (item: Freighter): void {
              selectedAndCheckFreighter(item)
            }}
            showResultInModal
          />
        )}
        {selectedFreighter && <FreighterCard freighter={selectedFreighter} onDelete={onDeleteFreighter} />}
        {checkResult !== undefined && (
          <View
            style={{
              borderColor: checkResult ? Colors.tint : Colors.red,
              borderWidth: 3,
              borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
              margin: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
              flexDirection: 'row',
            }}>
            <IMIcon
              style={[
                { justifyContent: 'center', padding: 10, marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS },
                styles.image,
              ]}
              icon={checkResult ? 'circle-check' : 'triangle-exclamation'}
              size={100}
              color={checkResult ? Colors.tint : Colors.red}
            />
            <IMText primary color={checkResult ? Colors.tint : Colors.red} style={[{ padding: 20 }]}>
              {checkResult ? i18n.t('LoadingParcelOk') : i18n.t('LoadingParcelNotOk')}
            </IMText>
          </View>
        )}
      </View>
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  image: {
    color: Colors.red,
    width: STYLE_CONSTANTS.DEFAULT_IMAGE_SIZE,
    height: STYLE_CONSTANTS.DEFAULT_IMAGE_SIZE,
    borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
