/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useLanguage } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { CustomerDeliveryAddress } from '../../apis/apiTypes'
import CustomerCard from '../../cards/ShoppingCart/CustomerCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useItemSelector from '../../hooks/useItemSelector'
import { ScreenType } from '../../types'

export default function CustomerAndDeliveriesForFinalPickingScreen(props: { navigation: any; route: any }) {
  const { i18n } = useLanguage()

  const [loading, setLoading] = useState(false)
  const customerDeliveryAddresses = useItemSelector<CustomerDeliveryAddress>([])
  const isFocused = useIsFocused()
  const allowCardPress = !loading

  useEffect(() => {
    if (!isFocused) return
    return load()
  }, [isFocused])

  function load() {
    let aborted = false
    setLoading(true)
    api
      .getCustomerAndDeliveriesForFinalPicking({})
      .then(result => {
        if (!result || aborted) return
        customerDeliveryAddresses.set(result)
        setLoading(false)
      })
      .catch(() => {
        if (aborted) return
        setLoading(false)
      })
    return () => {
      aborted = true
    }
  }

  function handleCustomerAndDeliveryAddressForFinalPickingSelected(customerDeliveryAddress: CustomerDeliveryAddress) {
    if (customerDeliveryAddress != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      props.navigation.navigate(ScreenType.ColloNumbersForFinalPickingScreen, {
        customerDeliveryAddress: customerDeliveryAddress,
      })
    }
  }

  return (
    <MWS_Screen noTopPadding>
      <View style={styles.infoView}>
        <Title>{i18n.t('CustomerAndDeliveryAddresses')}</Title>
        <FlatList
          data={customerDeliveryAddresses.items}
          renderItem={({ item }) => (
            <CustomerCard
              customerDeliveryAddress={item}
              onPress={allowCardPress ? () => handleCustomerAndDeliveryAddressForFinalPickingSelected(item) : undefined}
            />
          )}
          refreshControl={<IMRefreshControl refreshing={loading} onRefresh={load} />}
        />
      </View>
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  infoView: {
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    flex: 1,
  },
  input: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  cards: { margin: 0, marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN, marginBottom: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS },
})
