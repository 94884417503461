import { useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { View } from 'react-native'

import { ArticleDepositQuantity } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import IMIcon from '../../components/IMIcon'
import IMText from '../../components/IMText'
import Separator from '../../components/Separator'
import TextRow from '../../components/TextRow'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { ScreenType } from '../../types'
import { depositUtils } from '../../utils/depositUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ArticleDepositAvailabilityCard(props: {
  article: Article
  depositQuantity: ArticleDepositQuantity
  defaultOpen?: boolean
  quantitiesAlreadyConverted?: boolean
}) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const navigation = useNavigation()

  const total = useMemo(() => round(props.depositQuantity.quantity), [props.depositQuantity])
  const netAmount = useMemo(
    () => round(props.depositQuantity.quantity - props.depositQuantity.quantityCommissioned - props.depositQuantity.quantityOrdered),
    [props.depositQuantity]
  )
  const orderedAmount = useMemo(() => round(props.depositQuantity.quantityPurchasing), [props.depositQuantity])
  const earliestPlannedEntrance = useMemo(() => props.depositQuantity.earliestPlannedEntrance, [props.depositQuantity])
  const lastWarehouseEntrance = useMemo(() => props.depositQuantity.lastWarehouseEntrance, [props.depositQuantity])
  const stockPositionQuantities = props.depositQuantity.stockpositionquantities?.filter(s => s.quantity !== 0) ?? []

  const [cardOpen, setCardOpen] = useState(() => !!props.defaultOpen)

  function round(number: number) {
    return Utils.roundToDefaultPrecision(number)
  }

  function getQuantityWithUnit(quantity: number) {
    return props.article.getQuantityWithUnitText(quantity, props.quantitiesAlreadyConverted)
  }

  function showSerialNumbers() {
    navigation.navigate(ScreenType.SerialnumberQuantitySelection, { article: props.article.info, deposit: props.depositQuantity.deposit })
  }

  const ClosedCard = (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <IMText numberOfLines={1} primary style={{ flex: 10 }}>
        {depositUtils.getDepositTitle(props.depositQuantity.deposit)}
      </IMText>
      <IMText numberOfLines={1} style={{ flex: 4, textAlign: 'right' }}>
        {getQuantityWithUnit(total)}
      </IMText>
      <IMIcon style={{ flex: 1, justifyContent: 'center' }} iconStyle={{ alignSelf: 'center' }} icon={'angle-down'} color={theme.text} />
    </View>
  )

  const OpenCard = (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: 'row',
          marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          justifyContent: 'center',
        }}>
        <View style={{ flex: 1 }} />
        <IMText primary numberOfLines={1} style={{ flex: 13, textAlign: 'center' }}>
          {depositUtils.getDepositTitle(props.depositQuantity.deposit)}
        </IMText>
        <IMIcon style={{ flex: 1, justifyContent: 'center' }} iconStyle={{ alignSelf: 'center' }} icon={'angle-up'} color={theme.text} />
      </View>
      <TextRow
        textStyle={{ margin: 0 }}
        texts={[
          { text: i18n.t('Total'), style: { textAlign: 'left' } },
          { text: i18n.t('Net'), style: { textAlign: 'center' } },
          { text: i18n.t('Ordered'), style: { textAlign: 'right' } },
        ]}
      />
      <TextRow
        textStyle={{ margin: 0 }}
        texts={[
          { text: getQuantityWithUnit(total), style: { textAlign: 'left' } },
          { text: getQuantityWithUnit(netAmount), style: { textAlign: 'center' } },
          { text: getQuantityWithUnit(orderedAmount), style: { textAlign: 'right' } },
        ]}
      />
      {(earliestPlannedEntrance || lastWarehouseEntrance) && <Separator />}
      {earliestPlannedEntrance && (
        <TextRow
          textStyle={{ margin: 0 }}
          texts={[
            {
              text: `${i18n.t('EarliestPlannedEntrance')}: ${Utils.FormatDate(earliestPlannedEntrance, 'DD.MM.yyyy') ?? ''}`,
              style: { textAlign: 'left' },
            },
          ]}
        />
      )}
      {lastWarehouseEntrance && (
        <TextRow
          textStyle={{ margin: 0 }}
          texts={[
            {
              text: `${i18n.t('LastWarehouseEntrance')}: ${Utils.FormatDate(lastWarehouseEntrance, 'DD.MM.yyyy') ?? ''}`,
              style: { textAlign: 'left' },
            },
          ]}
        />
      )}
      {stockPositionQuantities.length > 0 && (
        <>
          <Separator />
          <View>
            <TextRow
              textStyle={{ marginHorizontal: 0, marginTop: 0 }}
              texts={[
                { text: i18n.t('StockPosition'), style: { textAlign: 'left' } },
                { text: i18n.t('Amount'), style: { textAlign: 'right' } },
              ]}
            />
            {stockPositionQuantities.map((item, index) => (
              <View key={'stockPositionQuantity' + index.toString()}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <IMText style={{ flex: 1 }}>{item.stockposition?.code.trim()}</IMText>
                  <IMText>{getQuantityWithUnit(round(item.quantity ?? 0))}</IMText>
                </View>
                {index < stockPositionQuantities.length - 1 && <Separator style={{ padding: 0, marginVertical: 0 }} />}
              </View>
            ))}
          </View>
        </>
      )}
    </View>
  )

  return (
    <MWS_BaseCard
      icon="warehouse-full"
      isHiddenIcon={props.article?.isSerialNumberActive}
      detailButton={props.article?.isSerialNumberActive ? { icon: 'barcode', onPress: () => showSerialNumbers() } : undefined}
      onPress={() => {
        setCardOpen(prev => !prev)
      }}>
      {cardOpen ? OpenCard : ClosedCard}
    </MWS_BaseCard>
  )
}
